// Some DSP macros look like ES6 template interpolations - ESLint doesn't need to warn us about these.
/* eslint-disable no-template-curly-in-string */


/** When URL-encoding URLs - eg for redirect tags - use these regexes to separate and preserve macros in the target URL, so the user's DSP can process them. */

/*
MACRO EVALUATION
Due to Elasticsearch being Elasticsearch, fields which aren't declared in DataLogEvent.java get stuffed into the "props"
member, which holds an array of {k: keyName, v: value} objects.
Some of the macros below were historically written with incorrect field names like "width", "height", "usi".
We can't directly perform breakdowns on these.

Procedure for inspecting values:
- Be logged into portal
- Set params "breakdown=none" (to leave out aggregation) and "size=10000" (to add more examples) on DataLog URL
- Visit DataLog URL and open console
- Run code below

// This first line is specific to Chromium with "JSON Formatter" extension installed
// - https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa
// const json = document.querySelector('#jsonFormatterRaw > pre').innerHTML;
// This is for stock Chromium, or large JSON docs that the extension gives up on
const json = document.querySelector('body > pre').innerHTML;
const data = JSON.parse(json);
// count frequency of values since a LOT will be the same
let counts = {};
// Replace with the relevant key
let key = 'usi';
data.cargo.examples.map(ex => ex._source.props.find(prop => prop.k === key)?.v).forEach(v => {
	if (counts[v]) {
		counts[v]++;
	} else {
		counts[v] = 1;
	}
});
counts;

- NB DataLog performs a pseudorandom (but somewhat stable, per-cluster-node) selection to pull out its example set.
There will be a few different sets, depending on which server you hit, but a strictly limited number.
*/


const MACROS = {
	none: {
		name: 'No macros'
	},
	dv360: {
		// Creative ID, site URL
		// https://support.google.com/displayvideo/answer/2789508?hl=en
		// Removed Universal Site ID param as legacy GAT data suggests it never resolved: &usi=${UNIVERSAL_SITE_ID}
		// ${CREATIVE_ID} resolves to DV360 creative ID.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:dv360&breakdown=vert&start=2023-01-01
		// ${SOURCE_URL_ENC} resolves to impression URL.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:dv360&breakdown=url&start=2023-01-01
		// ${UNIVERSAL_SITE_ID} resolves to a DV360-specific site identifier.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:dv360&breakdown=none&start=2023-01-01&size=10000
		// Many unresolved values, but these appear likely to be misapplied macros.
		// (NB Recorded under key "usi" which isn't a DataLogEvent field)
		name: 'Google Display & Video 360',
		params: {
			macro_type: 'dv360',
			creative_id: '${CREATIVE_ID}',
			url_macro: '${SOURCE_URL_ENC}',
			site_id: '${UNIVERSAL_SITE_ID}',
		},
		regex: /(\$\{\w+\})/g, // eg ${CREATIVE_ID}
	},
	cm360: {
		// Creative ID, site ID
		// https://support.google.com/campaignmanager/table/6096962?hl=en
		// %ecid! resolves to CM360 creative ID.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:cm360&breakdown=vert&start=2023-01-01
		// %s,%esid! resolves to a pair of CM360-specific site identifiers, e.g. "N8433.1932304XAXISAU,2483047"
		// Before the comma is the CM360 "site keyname", after is a numeric value only described as "Site ID".
		// -- see https://lg.good-loop.com/data?d=green&q=macro:cm360&breakdown=pid&start=2023-01-01
		name: 'Campaign Manager 360',
		params: {
			macro_type: 'cm360',
			creative_id: '%ecid!',
			site_id: '%s,%esid!',
		},
		regex: /(%\w+!?)/g, // eg %s or %esid!
	},
	gam: {
		// Impression domain, impression URL
		// https://support.google.com/admanager/answer/2376981?hl=en
		// %%SITE%% was previously filed to "pub", appears to be domain
		// -- see https://lg.good-loop.com/data?d=green&q=macro:gam&breakdown=pub&start=2023-01-01
		// %%REFERRER_URL_ESC%% appears to reliably resolve to URL.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:gam&breakdown=url&start=2023-01-01
		// %%WIDTH%%, %%HEIGHT%% reliably resolve to display width and height.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:gam&breakdown=none&start=2023-01-01&size=10000
		// (NB recorded under keys "width" and "height" which aren't DataLogEvent fields)
		// TODO maybe reinstate &w=%%WIDTH%%&h=%%HEIGHT%%
		name: 'Google Ad Manager',
		params: {
			macro_type: 'gam',
			domain_macro: '%%SITE%%',
			url_macro: '%%REFERRER_URL_ESC%%',
		},
		regex: /(%%\w+%%)/g, // eg %%SITE%%
	},
	ttd: {
		// Creative ID, device type, site domain
		// https://www.reddit.com/r/adops/comments/aibke3/inserting_macros_into_the_trade_desk_tracker_url/
		// https://www.reddit.com/r/adops/comments/mz8w2l/ttd_dv360_and_mediamath_macros/
		// Removed %%TTD_ADFORMAT%% param as it only ever resolved to "9544x9544" in legacy GAT data/
		// -- see https://lg.good-loop.com/data?d=green&q=macro:ttd&breakdown=size&start=2023-01-01
		// %%TTD_SITE%% was previously filed to "pub", legacy data suggests it's hostname.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:ttd&breakdown=pub&start=2023-01-01
		// %%TTD_DEVICETYPE%% resolves reasonably well to "PC", "Mobile", "Tablet", "Other".
		// -- see https://lg.good-loop.com/data?d=green&q=macro:ttd&breakdown=env&start=2023-01-01
		// %%TTD_CREATIVEID%% reliably resolves to TTD creative ID.
		// -- see https://lg.good-loop.com/data?d=green&q=macro:ttd&breakdown=vert&start=2023-01-01
		name: 'The Trade Desk',
		params: {
			macro_type: 'ttd',
			creative_id: '%%TTD_CREATIVEID%%',
			device_type: '%%TTD_DEVICETYPE%%',
			host_macro: '%%TTD_SITE%%',
		},
		regex: /(%%\w+%%)/g, // eg %%TTD_CREATIVE_ID%%
	},
	xandr: {
		// Creative ID, size string, width, height, site URL
		// (TODO reinstate somehow) Removed "&pub=${SITE_ID}" as it was polluting records with numeric values, xandr does not have a "site domain" macro
		// https://docs.xandr.com/bundle/invest_invest-standard/page/topics/supported-creative-macros.html
		// ${CREATIVE_ID} - see https://lg.good-loop.com/data?d=green&q=macro:xandr&breakdown=vert&start=2023-01-01
		// ${CREATIVE_SIZE} - see https://lg.good-loop.com/data?d=green&q=macro:xandr&breakdown=size&start=2023-01-01
		// ${REFERER_URL_ENC} - see https://lg.good-loop.com/data?d=green&q=macro:xandr&breakdown=url&start=2023-01-01
		// ${WIDTH}, ${HEIGHT} - Very poor resolve rate - in excess of 99.5% "${WIDTH}" / "${HEIGHT}"
		// -- see https://lg.good-loop.com/data?d=green&q=macro:xandr&breakdown=none&start=2023-01-01&size=10000
		// (NB recorded under keys "width" and "height" which aren't DataLogEvent fields)
		// ${SITE_ID} - Very poor resolve rate - in excess of 99.5% "${SITE_ID}"
		// -- see https://lg.good-loop.com/data?d=green&q=macro:xandr&breakdown=none&start=2023-01-01&size=10000
		// (NB recorded under key "site_id" which isn't a DataLogEvent field)
		name: 'Xandr',
		params: {
			macro_type: 'xandr',
			creative_id: '${CREATIVE_ID}',
			creative_size: '${CREATIVE_SIZE}',
			url_macro: '${REFERER_URL_ENC}',
		},
		regex: /(\$\{\w+\})/g, // eg ${CREATIVE_ID}
	},
	yahoo: {
	// Creative ID, device
	// https://developer.yahooinc.com/native/guide/v1-api/dynamic-parameters.html
	// "Tracking Macros" session suggests these are only for clickthrough URLs.
	// {creative} resolves to Yahoo creative ID.
	// -- see https://lg.good-loop.com/data?d=green&q=macro:yahoo&breakdown=vert&start=2023-01-01
	// {device} resolves to "p", "t", "c" - Phone, Tablet, Computer?
	// -- see https://lg.good-loop.com/data?d=green&q=macro:yahoo&breakdown=env&start=2023-01-01
		name: 'Yahoo',
		params: {
			macro_type: 'yahoo',
			creative_id: '{creative}',
			device_type: '{device}',
		},
		regex: /(\{\w+:?\w*\})/g, // i.e. {param} or {param:defaultValue}
	},
	// amzn: {
	// // Tried these on legacy system but nothing ever resolved.
	// // https://advertising.amazon.com/en-gb/resources/ad-policy/mmp-measurement-urls
	// // vert=__CS_CREATIVE_ID__ - see https://lg.good-loop.com/data?d=green&q=macro:amzn&breakdown=vert&start=2023-01-01
	// // size=__CS_AD_SIZE__ - see https://lg.good-loop.com/data?d=green&q=macro:amzn&breakdown=size&start=2023-01-01
	// // pub=__AAX_SITE_NAME__ - see https://lg.good-loop.com/data?d=green&q=macro:amzn&breakdown=pub&start=2023-01-01
	// 	name: 'Amazon',
	// 	params: {
	// 		macro_type: 'amzn',
	// 		vert: '__CS_CREATIVE_ID__',
	// 	 	size: '__CS_AD_SIZE__',
	// 		pub: '__AAX_SITE_NAME__',
	// 	},
	// 	regex: /(__\w+__)/g, // eg __CS_AD_NAME__
	// }
};


// Restructure for use as <select> options
const macroOptions = Object.entries(MACROS).map(([key, object]) => {
	return {label: object.name, value: key};
});


export default MACROS;
export { macroOptions };