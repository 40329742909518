import { Box, Typography, Grid, useTheme } from '@mui/material';
import {  ContentWrapper} from './MetricComponents';
import {  PieChart } from '@mui/x-charts';
import { metricWeightString} from '../../utilities/utils';


const chartBoxSx = {
	display: 'flex',
	flexGrow: 1,
	justifyContent: 'center',
	width: '100%',     // Full width of the parent container
	height: '100%'    // Dynamic height of the parent container
};


function TwoInputDoughnutChart({ title, firstInputTotal, secondInputTotal, firstInputColour, secondInputColour, firstInputText, secondInputText, sx={}}) {

	const roundRatio = (val) => val > 0 ? Math.round(val / (firstInputTotal + secondInputTotal) * 10000) / 100 : 0;
	const firstRatio = roundRatio(firstInputTotal);
	const secondRatio = roundRatio(secondInputTotal);

	return (
		<ContentWrapper title={title} sx={{...sx, display: 'flex', flexDirection: 'column', mb:0}}>
			<Box sx={chartBoxSx}>
				<Grid container spacing={1} sx={{ alignContent: 'center', height: '100%', pt:2}}>
					<PieChart
						series={[
							{
								data: [
									{ id: 1, value: secondInputTotal, color: secondInputColour, label: secondInputText },
									{ id: 0, value: firstInputTotal, color: firstInputColour, label: firstInputText },
								],
								valueFormatter: (e) => metricWeightString(e.value),
								cx: '75%',
								innerRadius: '80%',
							},
						]}
						slotProps={{legend: { hidden: true } }}
						height={150}
						// This is kinda insane, but a width higher or lower than 300 no longer center aligns and I do not know why mui does this? Lower aligns left and higher aligns right.
						width={300}  
					/>
					<Grid container spacing={1} maxHeight="unset !important" sx={{fontSize: 'clamp(0.5rem, 0.8vw, 4rem)', overflow: 'hidden', pt: 1}}>
						<Grid item xs={6} sx={{whiteSpace:'nowrap'}}>
							<Box sx={{ backgroundColor: firstInputColour, width: 'clamp(10px, 1vw, 40px)', height: 'clamp(10px, 1vw, 40px)', borderRadius: '50%', border: 'solid 1px black', display: 'inline-flex' }} />
							<Typography component="b" sx={{ fontWeight: 'bold', display: 'inline-flex', ml: 1, verticalAlign: 'super', whiteSpace:'wrap'}}>{firstInputText}</Typography>
						</Grid>
						<Grid item xs={6} sx={{whiteSpace:'nowrap'}}>
							<Box sx={{ backgroundColor: secondInputColour, width: 'clamp(10px, 1vw, 40px)', height: 'clamp(10px, 1vw, 40px)', borderRadius: '50%', border: 'solid 1px black', display: 'inline-flex' }} />
							<Typography component="b" sx={{ fontWeight: 'bold', display: 'inline-flex', ml: 1, verticalAlign: 'super', whiteSpace:'wrap' }}>{secondInputText}</Typography>
						</Grid>
						<Grid item xs={6} sx={{ pt: '0 !important' }}>
							<Typography component="p" sx={{ display: 'inline-flex', ml: 4, verticalAlign: 'super', fontWeight: 'medium' }}>{firstRatio}%</Typography>
						</Grid>
						<Grid item xs={6} sx={{ pt: '0 !important' }}>
							<Typography component="p" sx={{ display: 'inline-flex', ml: 4, verticalAlign: 'super', fontWeight: 'medium' }}>{secondRatio}%</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ContentWrapper>
	);
}
export default TwoInputDoughnutChart;