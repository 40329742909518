import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { noop } from 'lodash';

/**
 * @typedef {Object} DialogButtonProps
 * @extends EditDialogProps
 * @property {String|ReactNode} label Will be rendered on the button.
 * @property {ReactComponent<DialogProps>} Dialog The dialog component to render alongside the button.
 * @property {ReactComponent} [ButtonComponent=Button] Should accept onClick
 * @property {Function} [onClose] See {@link DialogProps#onClose}. If omitted, {@link DialogProps#open} will be ignored and state managed internally.
 * @property {String} [variant="outlined"] Passed to the button component. Overrides {@link buttonProps.variant}
 * @property {String} [color="primary"] Passed to the button component. Overrides {@link buttonProps.color}
 * @property {String} [size] Passed to the button component. Overrides {@link buttonProps.size}
 * @property {Function} onClick Passed to the button component. Click handler.
 * @property {String} [href] Passed to the button component, making it act as a link.
 * @property {Object} [buttonProps] See {@link Button} API. Spread to the <Button> component.
 */


/**
 * @param {DialogButtonProps} props
 * @returns {React.ReactElement}
 */
function DialogButton({
	onClick = noop, onClose = noop,
	label, variant = 'outlined', color = 'primary', size, buttonProps,
	Dialog, ButtonComponent = Button, ...props
}) {
	const [open, setOpen] = useState(false);

	// Bundle setOpen(false) and provided onClose() together
	const _onClose = useCallback(() => {
		onClose();
		setOpen(false);
	}, [setOpen, onClose]);

	// Bundle setOpen(true) and provided onClick() together
	const _onClick = useCallback(() => {
		onClick();
		setOpen(true);
	}, [onClick]);

	return <>
		<ButtonComponent {...buttonProps} variant={variant} color={color} size={size} onClick={_onClick}>
			{label}
		</ButtonComponent>
		<Dialog open={open} onClose={_onClose} {...props} />
	</>;
}


export default DialogButton;
