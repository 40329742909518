import { useMemo } from 'react';
import { Box } from '@mui/material';


import { ReactComponent as DistributionSvg }  from '../../img/icons/distribution.svg';
import { ReactComponent as ConsumptionSvg } from '../../img/icons/consumption.svg';
import { ReactComponent as GlossarySvg } from '../../img/icons/glossary.svg';
import { ReactComponent as EyeSvg } from '../../img/icons/eye.svg';
import { ReactComponent as Globe1Svg } from '../../img/icons/globe-2.svg';
import { ReactComponent as Globe2Svg } from '../../img/icons/globe-2.svg';
import { ReactComponent as GraphSvg } from '../../img/icons/graph.svg';
import { ReactComponent as NewsSvg } from '../../img/icons/full-coverage.svg';

import { useMergeSx } from '../../utilities/utils';


const frameStyle = {
	position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'visible'
};

function SvgFrame({rx = 0, strokeWidth = 0, ...props}) {
	const rectProps = useMemo(() => {
		const offset = strokeWidth / 2;
		const size = 24 - strokeWidth;
		return {rx, x: offset, y: offset, width: size, height: size, strokeWidth};
	}, [rx, strokeWidth]);

	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" style={frameStyle} {...props}>
			<rect {...rectProps} />
		</svg>
	);
}

const wrapperSx = {
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	aspectRatio: 1/1,
};


function SvgWrapper({svg: SvgComponent, icon = false, circle = false, iconSize = '100%', border, sx, backgroundColor, ...props}) {
	const boxSx = useMergeSx(wrapperSx, sx);

	const svgStyle = {width: iconSize, height: iconSize, zIndex: 1};

	const frameProps = {
		strokeWidth: border ? 1 : 0,
		rx: circle ? '50%' : 0
	};

	return (
		<Box sx={boxSx} {...props}>
			{backgroundColor && <Box component={SvgFrame} fill="currentColor" stroke="none" sx={{color: backgroundColor}} {...frameProps} />}
			{SvgComponent && <SvgComponent style={svgStyle} />}
			{border && <SvgFrame fill="none" stroke="currentColor" {...frameProps} />}
		</Box>
	);
}


function WelcomeIcon(props) {
	return <SvgWrapper svg={EyeSvg} circle border iconSize="70%" {...props} />;
}

function DistributionIcon(props) {
	return <SvgWrapper svg={DistributionSvg} circle border iconSize="80%" backgroundColor="chartDistribution.main" {...props} />;
}


function ConsumptionIcon(props) {
	return <SvgWrapper svg={ConsumptionSvg} circle border iconSize="60%" backgroundColor="chartConsumption.main" {...props} />;
}

function PublisherEmissionsIcon(props) {
	return <SvgWrapper svg={NewsSvg} circle border iconSize="60%" backgroundColor="success.main" {...props} />;
}

function GMPEmissionsIcon(props) {
	return <SvgWrapper svg={GraphSvg} circle border iconSize="50%" backgroundColor="success.main" {...props} />;
}

function GlossaryIcon(props) {
	return <SvgWrapper svg={GlossarySvg} {...props} />;
}


export {
	SvgWrapper,
	WelcomeIcon,
	DistributionIcon,
	ConsumptionIcon,
	PublisherEmissionsIcon,
	GMPEmissionsIcon,
	GlossaryIcon
};