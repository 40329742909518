import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, CircularProgress, Divider, Tab, Tabs } from '@mui/material';

import { Group } from '../../model/types/Group';
import { useGroup } from '../../state/apiHooks';
import { Header, HeaderActions, PageContainer, PageSection } from '../../components/common/LayoutComponents';
import { EditBrandButton } from '../../components/edit/BrandDialog';

import EntityList from './EntityList';
import GoodFormatsBreadcrumbs from './GoodFormatsBreadcrumbs';
import AdCampaignListItem from '../../components/lists/AdCampaignListItem';
import { EditAdCampaignButton } from '../../components/edit/AdCampaignDialog';


const thumbnailSx = {
	objectFit: 'contain',
	height: '2em',
	width: '2em'
};


function ShowBrand() {
	const { brandId } = useParams();
	const { data: brand } = useGroup(brandId) as { data: Group };
	const listQuery = useMemo(() => ({ brandId }), [brandId]);

	const listProps = useMemo(() => {
		return brand ? {
			title: <>Campaigns for {brand.name}</>,
			actionButtons: <EditAdCampaignButton base={{brandId}} label="New Campaign" />
		} : {};
	}, [brandId, brand]);

	if (!brand) return <PageContainer><CircularProgress /> Loading brand <code>{brandId}</code>...</PageContainer>;

	return <>
		<GoodFormatsBreadcrumbs brandId={brandId} />
		<PageContainer>
			<PageSection>
				<Header rank={5}>
					<Box display="inline-flex" alignItems="center">
						<Box component="img" src={brand.logoUrl} sx={thumbnailSx} mr={2} />
						{brand.name}
					</Box>
					<HeaderActions>
						<EditBrandButton label="Edit Brand" id={brandId} />
					</HeaderActions>
				</Header>
				<Divider sx={{my: 3}} />
				<EntityList type="campaign" ItemComponent={AdCampaignListItem} query={listQuery} {...listProps} />
			</PageSection>
		</PageContainer>
	</>;
}


export default ShowBrand;
