import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';


function ConfirmDialog({open, onClose, title, text, error, proceedLabel, proceed, disabled}) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{text}</DialogContentText>
				<Collapse in={error}>
					{error && <Alert severity="error">Error {error.status}: {error.message || 'Unknown error.'}</Alert>}
				</Collapse>
			</DialogContent>
			<DialogActions>
				<Button onClick={proceed} disabled={disabled} variant="outlined" color="primary">{proceedLabel}</Button>
				<Button onClick={onClose} disabled={disabled} variant="outlined" color="secondary" autoFocus>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
}

function SuccessDialog({open, onClose, title, text}) {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<Alert severity="success">{text}</Alert>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="outlined" color="primary">OK</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * A "Do Important Action" button with "Are you sure?" confirmation & "Success" post-commit dialog.
 * @param {Object} props
 * @param {String} name Entity name
 * @param {Function} onComplete Called when the user dismisses the dialog after successful action.
 * @param {boolean} disabled Can't currently do the thing - but the button should still be shown.
 * @returns {import('react').ReactElement}
 */
function ButtonWithConfirm({
	label,
	confirmTitle,
	confirmText,
	successTitle,
	successText,
	disabled,
	commitFn,
	onComplete
}) {

	// Control "Confirm" dialog
	const [confirmOpen, setConfirmOpen] = useState(false);
	// Control "Success" dialog
	const [successOpen, setSuccessOpen] = useState(false);

	// On outer button click, clear any errors from previous attempts and open "Confirm"
	const openConfirm = () => {
		setError(null);
		setConfirmOpen(true);
	};

	// Replace "Confirm" dialog with "Success" dialog on successful action.
	const commitThen = () => {
		setConfirmOpen(false);
		setSuccessOpen(true);
	};

	// Execute callback (e.g. close containing dialog) when "Success" dialog is dismissed.
	const closeSuccess = () => {
		setSuccessOpen(false);
		onComplete?.();
	};

	// Error message for failed action
	const [error, setError] = useState(null);

	// On failed action, get and display the error message
	const commitCatch = response => {
		const { status } = response;
		response.text().then(message => setError({status, message}));
	};

	// On confirm button click, clear any errors from previous attempts and execute commit function.
	const proceed = () => {
		setError(null); // Clear any previous errors
		commitFn().then(commitThen).catch(commitCatch);
	};

	return <>
		<Button variant="contained" color="secondary" onClick={openConfirm} disabled={disabled}>
			{label}
		</Button>
		<ConfirmDialog
			open={confirmOpen} onClose={() => setConfirmOpen(false)}
			proceed={proceed} proceedLabel={label}
			title={confirmTitle} text={confirmText} error={error}
		/>
		<SuccessDialog open={successOpen} onClose={closeSuccess} title={successTitle} text={successText} />
	</>;
}

export default ButtonWithConfirm;
