import { useMemo } from 'react';

import { Box, Grid, IconButton } from '@mui/material';
import Timeline from './Timeline';
import CarbonTotal from './CarbonTotal';
import CarbonEPM from './CarbonEPM';
import DistConsumptionDoughnut from './DistConsumptionDoughnut';
import { PinkHeader, ReadMoreDetails, ReadMoreToggle} from './MetricComponents';
import { screenshotBySelector } from '../../utilities/utils';
import FilterControls, { FilterDesc } from './FilterControls';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import { useGHGData } from './providers/MetricsDataProvider';

import ImpressionsTracked from './ImpressionsTracked';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { WelcomeIcon } from '../../components/common/Icons';

/***
 * Return metadata about tab & content for the Snapshot page
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Snapshot() {
	return {
		title: 'Emission snapshot',
		name: 'snapshot',
		icon: null,
		href: '/metrics/snapshot',
		content: <SnapshotContent />,
	};
};

/**
 * @returns {React.Element} Emissions snapshot page content
 */
function SnapshotContent() {
	const { total, overTime } = useGHGData();

	// Restructure time-series data from array of object to object of arrays
	const chartData = useMemo(() => {
		if (!overTime?.data) return null;

		const distribution = [], consumption = [], totalCO2EPM = [], dates = [], impressions = [];
		for (let val of overTime.data) {
			const ems = val.emissionMeasurements;
			dates.push(new Date(val.dateTime));
			distribution.push(ems.distributionEmissions);
			consumption.push(ems.consumptionEmissions);
			totalCO2EPM.push(ems.totalCO2EPM);
			impressions.push(ems.totalImpressions);
		};

		return {dates, distribution, consumption, totalCO2EPM, impressions};
	}, [overTime]);

	// dummy data taken from totalImpressions search on current metrics
	// I have no idea what this means but I hope it's not accurate any more --RM
	const { totalImpressions, totalEmissions, totalCO2EPM, distributionEmissions, consumptionEmissions } = total?.data || {};

	return (
		<>
			<WelcomeHeader />
			<FilterControls />
			<PageSection id="snapshot-screenshot">
				<Grid container spacing={1} maxHeight="unset !important">
					<Grid item xs={12} position="relative">
						<Header rank={4} sx={{ mb: 2 }}>Your emissions snapshot</Header>
						<IconButton onClick={() => screenshotBySelector('#snapshot-screenshot')} sx={{position: 'absolute', right: 0, top: 0}} name="Screenshot Snapshot">
							<CameraAltOutlinedIcon htmlColor="#000"/>
						</IconButton>
						<FilterDesc />
						<Box sx={{display: 'flex'}}>
							<ImpressionsTracked impressions={totalImpressions} />
						</Box>

						<Grid container spacing={2}>
							{/* First column with width 8 and two stacked elements */}
							<Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<CarbonTotal totalCarbon={totalEmissions} />
								<CarbonEPM epm={totalCO2EPM} chartData={chartData} />
							</Grid>
							{/* Second column with width 4 and one full-height element */}
							<Grid item xs={4} sx={{ display: 'flex'}}>
								<DistConsumptionDoughnut distributionTotal={distributionEmissions} consumptionTotal={consumptionEmissions} sx={{width:'100%'}}/>
							</Grid>
						</Grid>
						<Box sx={{display: 'flex'}}>
						<Timeline chartData={chartData} />
						</Box>
					</Grid>
				</Grid>
			</PageSection>
		</>
	);
}


function WelcomeHeader() {
	return (
		<PinkHeader logo={WelcomeIcon} logoAlt="Eye Icon" title="Welcome">
			Our methodology uses the GMSF framework as its baseline to assess your <strong>distribution</strong> and <strong>consumption</strong> greenhouse gas emissions.
			<ReadMoreToggle />
			<ReadMoreDetails>
				<p><strong>Distribution emissions</strong> are those attributed to the energy used during media selection and ad unit delivery.</p>
				<p><strong>Consumption emissions</strong> are those attributed to user devices when an ad creative is viewed.</p>
			</ReadMoreDetails>
		</PinkHeader>
	);
}

export default Snapshot;
export { WelcomeHeader };
