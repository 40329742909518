import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from '../FormContextProvider';
import { Header } from '../common/LayoutComponents';


/**
 * Preview box sizing:
 * - Aspect ratio 16:9
 * - Max width 100% of container
 * - As wide and tall as possible, under those constraints
 * In viewports up to 900px wide (ie mobile, accounting for device pixel scaling), no additional restrictions.
 * On wider displays, also restrict height to max 45% of viewport
 * */
const previewContainerSx = {
	maxWidth: '100%',
	width: { xs: '100%', md: 'unset' },
	height: { xs: 'unset', md: '100vh' },
	maxHeight: {xs: 'unset', md: '45vh' },
	aspectRatio: '16/9',
	margin: '0 auto',
	borderWidth: '0.2em',
	borderStyle: 'solid',
	borderColor: 'rgba(0, 0, 0, 0.1)'
};


const iframeStyle = {
	height: '100%',
	width: '100%',
	border: 'none'
};


/** Fetch an ad component from the ad-serving API. */
function useAdPart(id, endpoint) {
	const [text, setText] = useState();
	useEffect(() => {
		const url = `${process.env.REACT_APP_BUSINESS_STORE_API_ENDPOINT}/ad/${endpoint}?id=${id}`;
		fetch(url)
			.then(res => res.text())
			.then(setText);
	}, [id, endpoint]);
	return text;
}


/** Fetch the HTML snippet which loads the ad without needing a VAST host. */
function useLoaderHtml(id, endpoint = 'previewTag') {
	const bodyContent = useAdPart(id, endpoint);
	return useMemo(() => `<html><head></head><body>${bodyContent}</body></html>`, [bodyContent]);
}


/**
 * Show the creative in its current state in an iframe.
 */
function CreativePreview({sx, ...props}) {
	const { id, draft, initial } = useFormContext();
	const frameRef = useRef();

	// Change iframe key and re-render when saved version changes - but debounce
	const [previewKey, setPreviewKey] = useState(0);
	const rotatePreviewKey = useCallback(() => setPreviewKey(key => (++key % 10)), [setPreviewKey]);
	useEffect(rotatePreviewKey, [initial, rotatePreviewKey]);

	// The HTML content (ie a couple of script tags) to insert in the iframe
	const frameHtml = useLoaderHtml(id);

	// When the draft updates, inject new ad data into the preview.
	useEffect(() => {
		if (!frameRef.current) return;
		frameRef.current.contentWindow.updateSpec?.(draft);
	}, [frameRef, draft]);

	const _sx = useMemo(() => sx ? [previewContainerSx, sx] : previewContainerSx, [sx]);

	return <>
		<Box sx={_sx} {...props}>
			{frameHtml && <iframe key={previewKey} style={iframeStyle}
				title={`Creative Preview for ${draft?.name || 'untitled draft'}`}
				srcDoc={frameHtml}
				ref={frameRef}
			/>}
		</Box>
		<Box mt={1} textAlign="right">
			<Button variant="contained" onClick={rotatePreviewKey} size="large">Reload</Button>
		</Box>
	</>;
}


/**
 * Show the creative in its current state in an iframe.
 */
function CreativePreviewDemoPage({creativeId, lineItemId, sx, ...props}) {
	// Tag is fetched differently depending on whether it's for a creative or line item.
	const id = lineItemId || creativeId;
	const htmlUrl = lineItemId ? 'directTag' : 'previewTag';
	const jsonUrl = `${lineItemId ? 'advert' : 'creative'}/${id}.json`;

	// The HTML content (ie a couple of script tags) to insert in the iframe
	const frameHtml = useLoaderHtml(id, htmlUrl);
	// AdDTO so we can display the name
	const adJson = useAdPart(id, jsonUrl);
	const adObject = useMemo(() => {
		try {
			return JSON.parse(adJson);
		} catch {
			return '';
		}
	}, [adJson]);

	const _sx = useMemo(() => sx ? [previewContainerSx, sx] : previewContainerSx, [sx]);

	return <>
		<Header rank={4} mt={2} mb={3}>Preview: {adObject.name}</Header>
		<Box sx={_sx} {...props}>
			{frameHtml && <iframe key={0} style={iframeStyle}
				title={`Preview of ${adObject.name}`}
				srcDoc={frameHtml}
			/>}
		</Box>
	</>;
}



export default CreativePreview;
export { CreativePreviewDemoPage };