import { Box, CircularProgress } from '@mui/material';

import goodLoopLogo from '../../img/Good-Loop-symbol.svg';
import { EditGroupButton } from '../../components/edit/GroupDialog';
import UserTable from '../../components/tables/UserTable';
import ApiKeyTable from '../../components/tables/ApiKeyTable';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { EditUserButton } from '../../components/edit/UserDialog';
import { AddApiKeyButton } from '../../components/edit/ApiKeyDialog';
import { GroupStat } from './ShowGroup';
import entity from '../../model/entity';
import { Header, HeaderActions, PageSectionContainer, PageSection, SubSectionItem } from '../../components/common/LayoutComponents';
import GroupTable from '../../components/tables/GroupTable';
import { useWorkspace } from '../../components/WorkspaceProvider';


function WorkspaceIdentity({group: workspace}) {
	const { id: workspaceId } = workspace;

	return <Grid container spacing={2}>
		<Grid container direction="column">
			<Grid><Header rank={3}>{workspace.name}</Header></Grid>
			<Grid>
				<EditGroupButton label="Edit Workspace" id={workspaceId} />
			</Grid>
		</Grid>
		<Grid flex={1}>
			<Box component="img" src={workspace.logoUrl || goodLoopLogo} sx={{height: '200px', width: '100%', objectFit: 'contain'}} />
		</Grid>
	</Grid>;
}


/** Workspace overview */
function ShowWorkspace() {
	const { workspaceId, workspace, isFetched } = useWorkspace();

	if (!isFetched) return <PageSection><CircularProgress /> Loading Workspace</PageSection>;
	if (!workspace) return <PageSection>No workspace available</PageSection>;

	return <>
		<PageSectionContainer >
			<Grid container direction="row" spacing={3}>
				<Grid xs={12} sm={6}>
					<SubSectionItem>
						<WorkspaceIdentity group={workspace} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="Groups" type={entity.GROUP} groupId={workspaceId} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="Users" type={entity.USER} groupId={workspaceId} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="API Keys" type={entity.API_KEY} groupId={workspaceId} />
					</SubSectionItem>
				</Grid>
			</Grid>
		</PageSectionContainer>

		<PageSection>
			<Header rank={5}>
				Groups
				<HeaderActions>
					<EditGroupButton label="Add Group" base={{parentId: workspaceId, workspaceId}} />
				</HeaderActions>
			</Header>
			<GroupTable query={{parent: workspaceId}} />
		</PageSection>
		<PageSection>
			<Header rank={5}>
				Users
				<HeaderActions>
					<EditUserButton label="Add User" base={{workspaceId}} />
				</HeaderActions>
			</Header>
			<UserTable query={{workspaceId}} permissionGroup={workspaceId} editPermission />
		</PageSection>
		<PageSection>
			<Header rank={5}>
				API Keys
				<HeaderActions>
					<AddApiKeyButton groupId={workspaceId} />
				</HeaderActions>
			</Header>
			<ApiKeyTable groupId={workspaceId} />
		</PageSection>
	</>;
}

export default ShowWorkspace;
