import entity from '../../model/entity';
import EntityTable from './EntityTable';
import DeleteButton from '../edit/DeleteButton';
import { EditPublisherButton } from '../edit/PublisherDialog';
import { FetchGroupCell } from './GroupTable';


const columns = [
	{ field: 'name', headerName: 'Name', flex: 1},
	{ field: 'hostname', headerName: 'Domain', flex: 1},
	{ field: 'groupId', headerName: 'Owner', renderCell: FetchGroupCell, flex: 1}
];


/** Render "Edit Publisher" dialog button */
function EditAction({row}) {
	return <EditPublisherButton label="Edit" id={row.id} />;
}

/** Render "Delete Publisher" dialog button */
function DeleteAction({row}) {
	return <DeleteButton type={entity.PUBLISHER} id={row.id} />;
}

/** Default actions for a user - show, edit, delete. */
const defaultActions = [EditAction, DeleteAction];


/**
 * Fetches users and shows them in a DataGrid table. See EntityTable for more details.
 * @param {*} p
 * @param {Component[]} [p.actions] Action buttons or links. Will be inserted in the rightmost column.
 * @param	{String} [p.permissionGroup] Group ID - "edit/show permission" column will be included for this group if present.
 * @param	{Boolean} [p.editPermission] True to allow editing group permissions; false to only show current level.
 * @param {Object} [p.query] Generic query to filter users, e.g. by workspaceId
 */
function PublisherTable({actions = defaultActions, workspaceId, permissionGroup, editPermission, query, ...props}) {
	return <EntityTable columns={columns} actions={actions} type={entity.PUBLISHER} query={query} {...props} />;
}


export default PublisherTable;
