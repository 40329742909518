import { OutlinedInput } from '@mui/material';
import InputWrapper from './InputWrapper';


/**
 *
 * @param {Object} params
 * @param {string} params.name - Term of the text input field.
 * @param {string?} params.label - Label of the text input field. Auto generated if not provided.
 * @param {import("react").MutableRefObject} params.inputRef - Reference to the text input field.
 * @param {boolean} params.required - Whether the text input field is required.
 * @param {string} params.info - Information to display next to the text input field.
 * @param {Object} params.sx - Styling object for the FormControl component.
 */
function TextInputField(props) {
	return <InputWrapper {...props} Input={OutlinedInput} />;
}

export default TextInputField;
