import Headlines from '../Metrics/Headlines';
import { BreakdownBarChart} from '../Metrics/Breakdown';
import { Box, Paper } from '@mui/material';
import TwoInputDoughnutChart from '../Metrics/TwoInputDoughnutChart';

/**
 * 
 * @param {*} props
 * @param {number} props.distributionEmissions 
 * @param {number} props.totalEmissions
 * @param {number} props.distributionCO2EPM
 */
function DistributionSinglePageView(props){
	const distColourLight = props.distColourLight;
	let breakdownData = props.distributionBreakdownData?.data || [];
	let adSpaceSelection = breakdownData.rtbNetworkEmbodiedEmissions + breakdownData.rtbNetworkUsePhaseEmissions + breakdownData.rtbServerEmbodiedEmissions + breakdownData.rtbServerUsePhaseEmissions;
	let adCreativeDelivery = breakdownData.serversProcessingEmbodiedEmissions + breakdownData.serversProcessingUsePhaseEmissions + breakdownData.networkTransmissionsEmbodiedEmissions + breakdownData.networkTransmissionsUsePhaseEmissions;
	return (
		<Paper sx={{height: '100%', p: 1, mx: 1, boxShadow: 0, border: `5px solid ${distColourLight}`, borderRadius:2}}>
			<Box sx={{my:2, mx:2, p:1, backgroundColor: '#fafaf5', textAlign: 'center', fontSize: '1.5rem', fontWeight:'700', borderRadius:2, alignContent: 'center'}}>Distribution</Box>
			<Box sx={{display: 'flex', p:0, mb:1}}>
				<Headlines part={props.distributionEmissions} total={props.totalEmissions} pm={props.distributionCO2EPM}  sx={{m: 0, py: 0, height:'auto'}}/>
			</Box>
			<Box sx={{height: 'auto', display: 'flex', flexDirection: 'row'}}> 
				<Box sx={{height: 'auto', width: '50%', p: 0.5, display:'flex', flexDirection: 'column'}} >
					<TwoInputDoughnutChart title='Distribution breakdown by GMSF' firstInputTotal={adSpaceSelection} secondInputTotal={adCreativeDelivery} firstInputColour={props.distColour} secondInputColour={props.distColourDark} firstInputText='Ad Space Selection' secondInputText='Ad Creative Delivery' />
				</Box>
				<Box sx={{height: 'auto', width: '50%', p: 0.5, display:'flex', flexDirection: 'column'}}>
					<BreakdownBarChart chartData={props.barChartData} title="Distribution breakdown by format"/>
				</Box>
			</Box> 
		</Paper>

	);
}


export default DistributionSinglePageView;