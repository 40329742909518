import { useMemo } from 'react';
import { Button } from '@mui/material';

import { useGroup } from '../../state/apiHooks';
import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../lists/ListCard';
import ExportAdCSVButton from '../../pages/Formats/ExportAdButton';
import CloneButton from '../widgets/CloneButton';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';


/**
 * Renders a Good Formats creative as a list item.
 * @param {Object} props
 * @param {Function} props.onClick Called when the item is clicked
 * @param {Function} props.onExport Called when the "Export" button is clicked
 * @param {GMTag} props.item The tag item itself
 * @returns {JSX.Element}
 */
function CreativeListItem({item: creative}) {
	const { data: brand } = useGroup(creative.brandId);
	const { data: campaign } = useGroup(creative.campaignId);

	const exportQuery = useMemo(() => ({creativeId: creative.id}), [creative]);

	// Focus the new creative on clone
	const navigate = useNavigate();
	const cloneThen = useCallback(newClone => {
		navigate(`/creatives/${newClone.id}`);
	}, [navigate]);

	return (
		<ListCard item={creative} href={`/creatives/${creative.id}`}>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Brand" value={brand?.name} image={brand?.logoUrl} />
			</ListCardSection>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Campaign" value={campaign?.name} />
			</ListCardSection>
			<ListCardActions>
				<ExportAdCSVButton query={exportQuery}>Export all ads</ExportAdCSVButton>
				<Button variant="contained" color="primary" href={`/preview?creative=${creative.id}`}>Preview</Button>
				<CloneButton type="creative" id={creative.id} commitThen={cloneThen} />
			</ListCardActions>
		</ListCard>
	);
}


export default CreativeListItem;
