import {Box} from '@mui/material';
import { CreativePreviewDemoPage } from '../components/edit/CreativePreview';
import { CopyrightFooter, Header, PageContainer } from '../components/common/LayoutComponents';
import goodLoopLogo from '../img/Good-Loop-logo.svg';
import useSearchParam from '../state/useSearchParam';

function DemoPage() {
	const [creativeId] = useSearchParam('creative');
	const [lineItemId] = useSearchParam('lineitem');

	return (
		<Box sx={{backgroundColor: 'background.default', p: {xs: 0, sm: 2}}}>
			<PageContainer>
				<Header rank={4}>
					<Box component="img" src={goodLoopLogo} sx={{ width: '6em' }} />
				</Header>
				<CreativePreviewDemoPage creativeId={creativeId} lineItemId={lineItemId} />
				<CopyrightFooter/>
			</PageContainer>
		</Box>
	);
}

export default DemoPage;
