import { Link, List, ListItem, Typography } from '@mui/material';
import { CO2e, CO2ePM, ContentContainer } from './MetricComponents';
import { Header } from '../../components/common/LayoutComponents';
import { GlossaryIcon } from '../../components/common/Icons';

/***
 * Return metadata about tab & content for the Glossary breakdown tab
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Glossary() {
	return {
		name: 'glossary',
		title: 'Glossary',
		icon: <GlossaryIcon />,
		href: '/metrics/understanding/glossary',
		content: <GlossaryContent />,
	};
};

/**
 * @returns {React.Element} Glossary Tab content
 */
function GlossaryContent() {
	const glossaryComponents = glossaryEntries.map((e) => <Definition entry={e} />);

	return (
		<ContentContainer sx={{mt: 2, py: 4, px: '20% !important'}}>
			<Header rank={4}>Understand your emissions</Header>
			<Typography component="p" fontWeight="medium" color="#333">A quick guide to the terminology used in this dashboard</Typography>
			{glossaryComponents}
		</ContentContainer>
	);
}

function Definition({entry}) {
	let { title, titleFormatted, content, source, url } = entry;
	return <>
		<Header rank={1} fontSize="2rem" color="primary" sx={{mt: 10, mb: '0.5em'}}>{titleFormatted || title}</Header>
		<Typography component="p" fontWeight="medium" fontSize="1.25rem">{content}</Typography>
		<Typography component="p" mt={4}>Source: <Link href={url} fontWeight="bold" target="_blank" rel="noopener noreferrer">{source}</Link></Typography>
	</>;
}

/** URL for the GMSF spec */
const GMSF_URL = 'https://commtoact.se/wp-content/uploads/2024/06/GARM_ANZ_Global-Media-Sustainability-Framework.pdf';

/** Canonical name for the GMSF spec */
const GMSF_SOURCE = 'GMSF Digital GHG Emissions Framework V1.0';

/** URL for the Ecometrica reference PDF */
const ECOMETRICA_URL = 'https://www.ecoonline.com/blog/greenhouse-gases-co2e-carbon-and-more-common-sustainability-terms-you-should-know/';
// Previous link before it was taken down Dec 2024: https://ecometrica.com/assets/GHGs-CO2-CO2e-and-Carbon-What-Do-These-Mean-v2.1.pdf

/** Canonical name for Ecometrica as an information source */
const ECOMETRICA_SOURCE = 'ecometrica.com';

/** Bundled GMSF URL and name for glossary entries */
const gmsfReference = { source: GMSF_SOURCE, url: GMSF_URL };

/** Bundled Ecometrica URL and name for glossary entries */
const ecometricaReference = { source: ECOMETRICA_SOURCE, url: ECOMETRICA_URL };

// ALL require a title, content, source (nice human readable source) and url (actual source link). Later ordered by title alphabetically
let glossaryEntries = [
	{
		title: 'Greenhouse Gas (GHG)',
		content: <>Greenhouse gases are atmospheric gases that trap heat from the sun, keeping the Earth's surface warmer than it would be if these gases were not present. The primary greenhouse gases in Earth's atmosphere are carbon dioxide (CO<sub>2</sub>), methane (CH<sub>4</sub>), nitrous oxide (N<sub>2</sub>O), and fluorinated gases. These gases contribute to the greenhouse effect, leading to global warming and climate change.</>,
		...ecometricaReference
	},
	{
		title: 'CO2e',
		titleFormatted: <CO2e />,
		content: <>
			Carbon dioxide equivalent (<CO2e />) - a unit for estimating carbon footprints.<br/>
			It expresses the impact of different greenhouse gases in terms of the amount of CO<sub>2</sub> that would have the same global warming effect. This allows for the comparison and aggregation of emissions from various greenhouse gases.
		</>,
		...ecometricaReference
	},
	{
		title: 'CO2e PM',
		titleFormatted: <CO2ePM />,
		content: <><CO2ePM /> refers to the normalised impact, in terms of <CO2e />, per thousand impressions from an ad campaign.</>,
		...gmsfReference
	},
	{
		title: 'GMSF',
		content: 'The Global Media Sustainability Framework (GMSF) is a voluntary framework to enable players in the advertising industry to account for their Scope 3 greenhouse gas emissions, using media industry and climate-science based standards and best practices.',
		...gmsfReference
	},
	{
		title: 'Creation',
		content: <>
			In the context of environmental science and sustainability, creation refers to the initial phase of a product's life cycle, including the extraction of raw materials and the manufacturing processes.<br/>
			This phase encompasses all activities involved in producing the final product from raw inputs.<br/>
			Within the context of the GMSF framework for digital media channels, this refers to creation of a digital creative asset.
		</>,
		...gmsfReference
	},
	{
		title: 'Distribution',
		content: <>
			Distribution involves the processes and logistics of transporting and delivering products from the point of creation (manufacturing) to the end user or consumer.<br/>
			Distribution emissions are the greenhouse gas emissions produced from the energy used during media selection and ad unit delivery.<br/>
			Media selection refers to all activities related to media placement and inventory planning, including real-time bidding, direct sales and targeting.<br/>
			Ad unit delivery refers to all activities related to delivering and installing the ad in the selected placement including media storage and media transmission.
		</>,
		...gmsfReference
	},
	{
		title: 'Consumption',
		content: <>
			Consumption emissions are the greenhouse gas emissions attributed to user devices when an ad creative is viewed. This includes:
			<List>
				<ListItem>Device use phase emissions, the emissions produced by energy consumed when an ad creative is downloaded and rendered</ListItem>
				<ListItem>Device embodied emissions, the emissions from the creation, maintenance and disposal of digital devices.</ListItem>
			</List>
		</>,
		...gmsfReference
	},
	{
		title: 'Embodied Emissions',
		content: 'Device embodied emissions; the emissions from the creation, maintenance and disposal of digital devices, excluding the use phase. This includes emissions from creation, manufacturing, and disposal of digital devices.  This is expressed as the GHG emissions produced from energy used in device creation and disposal as a proportion of lifespan.',
		...gmsfReference
	},
	{
		title: 'Use Phase Emissions',
		content: 'Device use phase emissions are generated when energy is used to download and display an ad on a device. These emissions are estimated as the GHGs produced by the device\'s energy consumption based upon the time that ad spent on-screen.',
		...gmsfReference
	},
].sort((a, b) => a.title.localeCompare(b.title));


export default Glossary;
export { GMSF_URL, GMSF_SOURCE, ECOMETRICA_URL, ECOMETRICA_SOURCE };
