import { useGroup } from '../../state/apiHooks';
import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../lists/ListCard';
import { EditCampaignButton } from '../edit/CampaignDialog';


/**
 * Renders a Good Measures tag as a list item.
 * @param {Object} props
 * @param {Function} props.onClick Called when the item is clicked
 * @param {Function} props.onExport Called when the "Export" button is clicked
 * @param {GMTag} props.item The tag item itself
 * @returns {JSX.Element} - The JSX element representing the green ad tag item.
 */
function CampaignListItem({item: campaign}) {
	const { data: group } = useGroup(campaign.groupId);

	return (
		<ListCard item={campaign}>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Group" value={group?.name} image={group?.logoUrl} />
			</ListCardSection>
			<ListCardActions>
				<EditCampaignButton id={campaign.id} label="Edit" />
			</ListCardActions>
		</ListCard>
	);
}



export default CampaignListItem;
