import { useMemo } from 'react';
import { List, ListItem as MuiListItem } from '@mui/material';

import { globalPermissionDescriptions, isSufficientPermission, groupPermissionDescriptions, permissionLevelOptions, permissionLevels } from '../../model/permissionLevels';
import SelectField from './SelectField';
import { InputLabel } from './InputWrapper';


/**
 * Prints a list of capabilities granted by a given permission level.
 * @param {Object} props
 * @param {String} props.level The permission level to list capabilities for
 * @param {Boolean} [props.global] List capabilities for global instead of group-level descriptions.
 * @param {Component|Tag} [props.ListContainer="ul"]
 * @param {Component|Tag} [props.ListItem="li"]
 * @returns {ReactElement}
 */
function PermissionDesc({title, level = permissionLevels.NONE, global, ListContainer = List, ListItem = MuiListItem, ...props}) {
	const descriptions = (global ? globalPermissionDescriptions : groupPermissionDescriptions)[level];
	const items = descriptions?.map((desc, i) => {
		return <ListItem key={i}>{desc}</ListItem>;
	});

	return <>
		{title && <InputLabel>{title}</InputLabel>}
		<ListContainer {...props}>{items}</ListContainer>
	</>;
}


/**
 * Input for assigning user permission levels.
 * @param {Object} props
 * @param {String} maxLevel The highest permission level to show.
 * @returns {ReactElement}
 */
function PermissionSelect({maxLevel = permissionLevels.ADMIN, minLevel = permissionLevels.NONE, ...props }) {
	// Show levels up to the max assignable.
	const options = useMemo(() => (
		Object.values(permissionLevelOptions).filter(level => (
			isSufficientPermission(level.value, minLevel) && isSufficientPermission(maxLevel, level.value)
		))
	), [minLevel, maxLevel]);

	return <SelectField options={options} defaultValue={permissionLevels.NONE} {...props} />;
}

export default PermissionSelect;
export { PermissionDesc };