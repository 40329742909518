import MetricsFilterProvider from '../Metrics/providers/MetricsFilterProvider';
import { GHGDataProvider } from '../Metrics/providers/MetricsDataProvider';
import Timeline from '../Metrics/Timeline';
import CarbonTotal from '../Metrics/CarbonTotal';
import DistConsumptionDoughnut from '../Metrics/DistConsumptionDoughnut';
import { useGHGData } from '../Metrics/providers/MetricsDataProvider';
import DistributionSinglePageView from './DistributionSinglePageView';
import ConsumptionSinglePageView from './ConsumptionSinglePageView';
import { ContentPaper, CO2e, CO2ePM } from '../Metrics/MetricComponents';
import { useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, useTheme } from '@mui/material';
import ImpressionsTracked from '../Metrics/ImpressionsTracked';
import FilterControls, {FilterDesc } from '../Metrics/FilterControls';
import TuneIcon from '@mui/icons-material/Tune';

function SinglePageDashboard() {
	return (
		<MetricsFilterProvider>
			<GHGDataProvider>
				<SinglePageDashboardContent/>
			</GHGDataProvider>
		</MetricsFilterProvider>
	);
}

function SinglePageDashboardContent(){
	const theme = useTheme();
	const { main: distColour, dark: distColourDark, light: distColourLight } = theme.palette.chartDistribution;
	const { main: consColour, dark: consColourDark, light: consColourLight} = theme.palette.chartConsumption;
	const { total, overTime, consumptionBreakdown, distributionBreakdown, consumptionByDeviceType, distributionByCreativeFormat } = useGHGData();

	// Restructure time-series data from array of object to object of arrays
	const chartData = useMemo(() => {
		if (!overTime?.data) return null;

		const distribution = [], consumption = [], totalCO2EPM = [], dates = [], impressions = [];
		for (let val of overTime.data) {
			const ems = val.emissionMeasurements;
			dates.push(new Date(val.dateTime));
			distribution.push(ems.distributionEmissions);
			consumption.push(ems.consumptionEmissions);
			totalCO2EPM.push(ems.totalCO2EPM);
			impressions.push(ems.totalImpressions);
		};

		return {dates, distribution, consumption, totalCO2EPM, impressions};
	}, [overTime]);

	const formatData = distributionByCreativeFormat?.data || [];
	let distributionBarChartData = {};
	if (formatData && formatData.length > 0) {
		distributionBarChartData = {
			xAxis: {
				data: formatData.map(({format}) => format.length > 1 ? format[0] + format.slice(1).toLowerCase() : format),
				label: 'Creative Format'
			},
			totalSeries: [
				{
					data: formatData.map(({distributionEmissions}) => distributionEmissions),
					label: <>Total <CO2e /></>,
					color: distColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					type: 'bar',
				}
			]
		};
	};

	const deviceOverwrite = {
		CTV: 'CTV',
	};

	const deviceData = consumptionByDeviceType?.data || [];
	let consumptionBarChartData = {};
	if (deviceData && deviceData.length > 0) {
		consumptionBarChartData = {
			xAxis: {
				title: 'Device Type',
				data: deviceData.map(({device}) => {
					if(Object.keys(deviceOverwrite).includes(device)) {
						return deviceOverwrite[device];
					}
					if (device.length > 1) {
						return device[0].toUpperCase() + device.slice(1).toLowerCase();
					}
					return device;
				})
			},
			totalSeries: [
				{
					data: deviceData.map(({consumptionEmbodiedEmissions}) => consumptionEmbodiedEmissions),
					label: 'Total Embodied emissions',
					color: consColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				},
				{
					data: deviceData.map(({consumptionUsePhaseEmissions}) => consumptionUsePhaseEmissions),
					label: 'Total Use Phase emissions',
					color: consColourDark,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				}
			],
			pmSeries: [
				{
					data: deviceData.map(({consumptionEmbodiedEmissionsCO2EPM}) => consumptionEmbodiedEmissionsCO2EPM),
					label: <><CO2ePM /> Embodied emissions</>,
					color: consColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				},
				{
					data: deviceData.map(({consumptionUsePhaseEmissionsCO2EPM}) => consumptionUsePhaseEmissionsCO2EPM),
					label: <><CO2ePM /> Use Phase emissions</>,
					color: consColourDark,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				}
			]
		};
	}


	const { totalImpressions, totalEmissions, totalCO2EPM, distributionEmissions, consumptionEmissions, distributionCO2EPM, consumptionCO2EPM } = total?.data || {};
	
	//<CarbonEPM epm={totalCO2EPM} chartData={chartData} /> add back in when I have the total vs epm button
	return (
		<ContentPaper>
			<Accordion sx={{borderRadius: '10px', boxShadow: 0, m: 2, backgroundColor: theme.palette.background.default}}>
				<AccordionSummary sx={{height: '10%', flexDirection: "row-reverse"}} expandIcon={<TuneIcon />}>
					<Box sx={{width: '1%'}}></Box>
					<FilterDesc />
				</AccordionSummary>
				<AccordionDetails sx={{p:0, m:1}}>
					<FilterControls/>
				</AccordionDetails>
			</Accordion>
			<Box sx={{ height: 'auto', width: '100%' }}>
				<Box sx={{display: 'flex', flexDirection: 'row', pb:0, mb:0}}>
					<Box sx={{width: '30%', display: 'flex', flexDirection: 'column', pb:0, mb:3}}>
						<Box sx={{display: 'flex', flexGrow:0}}>
							<ImpressionsTracked impressions={totalImpressions} sx={{pb:0, height:'auto'}}/>
						</Box>
						<Box sx={{flexGrow:1}}>
							<CarbonTotal totalCarbon={totalEmissions} sx={{pt:0, pb:0, mb:0, height:'auto'}}/>
						</Box>
					</Box>
					<Box sx={{minHeight: 0}}>
						<Timeline noWrapper chartData={chartData} sx={{pb:0, mb:0}} />
					</Box>
					<Box sx={{width: '20%'}}>
						<DistConsumptionDoughnut distributionTotal={distributionEmissions} consumptionTotal={consumptionEmissions} sx={{pb:0, mb:0}}/>
					</Box>
				</Box>
				<Box alignItems="stretch" sx={{ height: '60%', display: 'flex', flexDirection: 'row', mx:1}}>
					<Box sx={{ height: 'auto', width: '50%'}}>
						<DistributionSinglePageView distributionEmissions={distributionEmissions} totalEmissions={totalEmissions} distributionCO2EPM={distributionCO2EPM} distributionBreakdownData={distributionBreakdown} distColour={distColour} distColourDark={distColourDark} distColourLight={distColourLight} barChartData={distributionBarChartData}/>
					</Box>
					<Box sx={{ height: 'auto', width: '50%'}}>	
						<ConsumptionSinglePageView consumptionEmissions={consumptionEmissions} totalEmissions={totalEmissions} consumptionCO2EPM={consumptionCO2EPM} consumptionBreakdownData={consumptionBreakdown} consColour={consColour} consColourDark={consColourDark} consColourLight={consColourLight} barChartData={consumptionBarChartData} />
					</Box>				
				</Box>
			</Box>
		</ContentPaper>
	);
}

export default SinglePageDashboard;
