import { useCallback, useMemo, useState } from 'react';

import { Button, Divider, DialogTitle, DialogContent, Dialog } from '@mui/material';
import { ContentCopyRounded } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import exportTag from '../../../utilities/tag-export';
import TextInputField from '../../inputFields/TextInputField';
import SelectField from '../../inputFields/SelectField';
import { Header } from '../../common/LayoutComponents';
import { macroOptions } from '../../../utilities/tag-macros';
import FormContextProvider, { useFormContext } from '../../FormContextProvider';


const TYPE_OPTIONS = [
	{ label: 'Pixel Tag', value: 'pixel' },
	{ label: 'JavaScript', value: 'javascript' },
	{ label: 'URL', value: 'url' },
	/* { label: 'Redirect (to wrap another tag URL)', value: 'redirect' }, */
];


function ExportControls({tag}) {
	const { draft: { exportType, macroFormat } } = useFormContext();

	// Construct tag export
	const exportString = useMemo(() => {
		if (!tag || !exportType) return;
		return exportTag(tag, exportType, macroFormat);
	}, [tag, exportType, macroFormat]);

	const canExport = exportType && macroFormat;

	const copyButton = <CopyButton text={exportString} sx={{flexShrink: 0}} />;

	return <Grid container direction="column" spacing={2}>
		<Grid container spacing={2}>
			<Grid xs={12} sm={6}>
				<SelectField label="Export Type" required fullWidth path="exportType"
					options={TYPE_OPTIONS} labelEmpty="Select a tag type"
				/>
			</Grid>
			<Grid xs={12} sm={6}>
				<SelectField label="Macro Format" required fullWidth path="macroFormat"
					options={macroOptions} labelEmpty="Select DSP macro type"
				/>
			</Grid>
		</Grid>
		<Divider sx={{my: 3}} />
		{canExport ? <>
			<Header rank={5} sx={{textAlign: 'center'}}>Your Tag</Header>
			<TextInputField value={exportString} append={copyButton} fullWidth onFocus={autoSelect} sx={{color: '#000'}} />
		</> : (
			<Header rank={5}>Choose your tag type and macro format to export</Header>
		)}
	</Grid>;
}


/**
 * Button which copies the given text to the clipboard.
 * @param {Object} p
 * @param {*} p.text The text to be copied
 * @param {ReactNode} [p.label='Copy to clipboard'] The button label
 * @returns {ReactElement}
 */
function CopyButton({text, label, ...props}) {
	const onClick = useCallback(() => {
		navigator.clipboard.writeText(text);
	}, [text]);

	return <Button variant="outlined" startIcon={<ContentCopyRounded />} onClick={onClick} {...props}>
		{label || 'Copy to clipboard'}
	</Button>;
}

/** Selects the contents of the element which fired the event */
function autoSelect(event) {
	event.target.select();
}


const emptyForm = {};

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {import('../TagListItem').GMTag} props.tag
 * @returns
 */
function ExportButton({ label = 'Export Tag', tag, ...props}) {
	const [open, setOpen] = useState(false);

	return <>
		<Button onClick={() => setOpen(true)} disabled={!tag} {...props}>{label}</Button>
		<FormContextProvider initial={emptyForm}> {/* Fresh context for export controls */}
			<Dialog open={tag && open} onClose={() => setOpen(false)}>
				<DialogTitle>Export Tag</DialogTitle>
				<DialogContent>
					<ExportControls tag={tag} />
				</DialogContent>
			</Dialog>
		</FormContextProvider>
	</>;
}


export default ExportButton;
export { ExportControls };
