import { permissionLevels } from '../model/permissionLevels';


/**
 * Wraps {@link doFetchLocal} with JSON extraction.
 * @returns {Promise<Response>}
 */
const doJsonFetch = (localUrl, params) => doFetchLocal(localUrl, params).then(res => res.json());

const apiUrl = localUrl => `${process.env.REACT_APP_BUSINESS_STORE_API_ENDPOINT}/api${localUrl}`;


/**
 * Wraps {@link doFetch} with auth header injection and local endpoint resolution.
 * @param {String} localUrl Will be hydrated into a full endpoint URL
 * @param {String} params.accessToken JWT for the authenticating user
 * @returns {Promise<Response>}
 */
const doFetchLocal = (localUrl, { accessToken, headers, ...params}) => {
	// Construct local API endpoint
	const endpoint = apiUrl(localUrl);
	// Insert auth header
	headers = {...headers, Authorization: `Bearer ${accessToken}`};
	// Temporary hack: Insert workspace ID override header
	const workspaceId = new URLSearchParams(window.location.search).get('workspace');
	if (workspaceId) headers['x-gl-workspaceid'] = workspaceId;
	return doFetch(endpoint, { headers, ...params});
};


/**
 * Wrapper for fetch() which covers most use cases.
 * @param {*} url URL to request
 * @param {*} params Passed as "options" argument to fetch(url, options)
 * @param {String} params.method GET, POST, DELETE etc
 * @param {Object} params.data Will be encoded as JSON body, or URL params for method GET
 * @returns {Promise<Response>}
 */
const doFetch = (url, { method, data, ...options}) => {
	// Specify method if non-GET (implicit)
	if (method && method !== 'GET') {
		options.method = method;
	}
	if (!options.headers) {
		options.headers = {};
	}
	if (!options.headers.Accept) {
		options.headers.Accept = '*';
	}
	// Handle data encoding
	if (data) {
		if (options.method) {
			// Non-GET: JSON encode to request body & set matching content-type header
			options.body = JSON.stringify(data);
			options.headers['Content-Type'] = 'application/json';
		} else {
			// GET: encode data to URL params
			url += '?' + new URLSearchParams(data).toString();
		}
	}
	return fetch(url, options)
		// Throw on HTTP errors to enable try/catch/finally semantics
		.then(response => {
			if (!response.ok) throw response;
			return response;
		});
};


/**
 * Create a new entity.
 * @param {String} accessToken JWT for the authenticating user.
 * @param {String} type Name of the entity type (eg "user", "campaign").
 * @param {Object} data New entity object
 * @returns {Promise} Resolves to the created group
 */
function createEntity(accessToken, {type, ...data}) {
	return doJsonFetch(`/${type}`, {accessToken, data, method: 'POST'});
};


/**
 * Update an existing entity.
 * @param {String} accessToken JWT for the authenticating user.
 * @param {String} type Name of the entity type (eg "user", "campaign").
 * @param {Number} id ID of the entity to update.
 * @param {Object} data Updated entity object
 * @returns {Promise} Resolves to the updated entity
 */
function updateEntity(accessToken, {type, ...data}) {
	return doJsonFetch(`/${type}/${data.id}`, {accessToken, data, method: 'PUT'});
};


/**
 * Delete an existing entity.
 * @param {String} accessToken JWT for the authenticating user.
 * @param {String} type Name of the entity type (eg "user", "campaign").
 * @param {String} id ID of the entity to update.
 * @returns {Promise}
 */
function deleteEntity(accessToken, {type, id}) {
	return doFetchLocal(`/${type}/${id}`, {accessToken, method: 'DELETE'});
}


/**
 * Set a user's permission level with one group or workspace. (Set level "NONE" to revoke.)
 * @param {String} accessToken JWT for the authenticating user.
 * @param {Object} data Bundle containing the params for the permission object.
 * @param {String} data.userId User to grant permission
 * @param {String} data.groupId Group to grant permission on
 * @param {String} data.level Permission level to be granted
 * @returns {Promise<Response>}
 */
const setGroupPermission = (accessToken, {userId, groupId, level}) => {
	let method = 'POST', data = {level}, fetchFn = doJsonFetch;
	// Set level to NONE or falsy => switch to DELETE.
	if (!level || level === permissionLevels.NONE) {
		method = 'DELETE';
		data = null;
		fetchFn = doFetchLocal;
	}
	return fetchFn(`/group/${groupId}/userPermissions/${userId}`, {data, method, accessToken});
};


/**
 * Set an admin user's global permission level. (Set level "NONE" to revoke.)
 * @param {String} accessToken JWT for the authenticating user.
 * @param {Object} data Bundle containing the params for the permission object.
 * @param {String} data.userId User to grant permission
 * @param {String} data.level Permission level to be granted
 * @returns {Promise<Response>}
 */
const setGlobalPermission = (accessToken, {userId, level}) => {
	let method = 'POST', data = {level}, fetchFn = doJsonFetch;
	// Set level to NONE or falsy => switch to DELETE.
	if (!level || level === permissionLevels.NONE) {
		method = 'DELETE';
		data = null;
		fetchFn = doFetchLocal;
	}
	return fetchFn(`/globalPermission/${userId}`, {data, method, accessToken});
};


/**
 * Registers an API key to access a workspace or group.
 * @param {String} accessToken JWT for the authenticating user.
 * @param {Object} data The API object.
 * @param {String} data.groupId The workspace or group the API key should receive permission on.
 * @param {String} data.clientId The client key
 * @param {String} data.level Permission level to be granted
 * @returns {Promise<Response>}
 */
const addApiKey = (accessToken, {groupId, ...data}) => {
	return doJsonFetch(`/group/${groupId}/apiKey`, {data, method: 'POST', accessToken});
};


/**
 * Sends a "measure tag" request to the tag analysis service.
 * @param {String} accessToken JWT for the authenticating user.
 * @param {Object} data
 * @param {String} data.url
 * @returns {Promise<Response>}
 */

const measureTag = (accessToken, data) => {
	return doFetch('https://staging-measure-tag.good-loop.com/analyse', { data, method: 'POST' }).then(res => res.json());
};



export {
	apiUrl,
	doFetchLocal, doJsonFetch,
	createEntity,
	updateEntity,
	deleteEntity,
	setGroupPermission, setGlobalPermission, addApiKey,
	measureTag
};
