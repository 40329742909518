import { useCampaign, useGroup } from '../../state/apiHooks';
import ExportButton from './dialogs/ExportTag';
import { EditTagButton } from '../edit/TagDialog';
import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../lists/ListCard';

/**
 * @typedef {Object} GMTag - The props for the GreenAdTagItem component.
 * @property {String} id - The tag ID
 * @property {string} name - The name of the tag.
 * @property {String} campaignId - The ID of the tag's owning campaign.
 * @property {Object} groupId - The ID of the tag's owning groupanization.
 * @property {String} createdById - The ID of the user who created the tag.
 * @property {String} createdDate - The timestamp of the tag's creation.
 * @property {String} creativePreview
 * @property {String} creativePreviewType
 * @property {string} creativeFormat - The format of the creative.
 * @property {number} creativeBytes - The measured transfer size of the display creative.
 * @property {number} creativeMsec - The measured duration of the video creative.
 */


/**
 * Renders a Good Measures tag as a list item.
 * @param {Object} props
 * @param {Function} props.onClick Called when the item is clicked
 * @param {Function} props.onExport Called when the "Export" button is clicked
 * @param {GMTag} props.item The tag item itself
 * @returns {JSX.Element} - The JSX element representing the green ad tag item.
 */
function TagListItem({item: tag}) {
	const { data: group } = useGroup(tag.groupId);
	const { data: campaign } = useCampaign(tag.campaignId);

	return (
		<ListCard item={tag}>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Group" value={group?.name} image={group?.logoUrl} />
			</ListCardSection>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Campaign" value={campaign?.name} />
			</ListCardSection>
			<ListCardSection xs={6} sm={3}>
			</ListCardSection>
			<ListCardActions>
				<EditTagButton id={tag.id} label="Edit" color="secondary" variant="outlined" />
				<ExportButton label="Export" tag={tag} variant="outlined" />
			</ListCardActions>
		</ListCard>
	);
}


export default TagListItem;
