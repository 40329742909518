import { isSufficientPermission } from '../../model/permissionLevels';
import { useAuthUser, useCanDo } from '../../state/apiHooks';


/** Render or suppress children depending on whether the API says the user can perform an action on a target entity. */
function EntityPermissionWrapper({type, action, id, draft, children}) {
	const { data: canDo } = useCanDo({type, action, id, draft});
	if (!canDo) return null;
	return children;
}


/** Render or suppress children depending on whether the user's permissions meet a specified target. */
function UserPermissionWrapper({minGroupPermission, minWorkspacePermission, minGlobalPermission, children}) {
	const { data: user } = useAuthUser();
	if (!user) return null;

	// User's global permission covers global, workspace and group
	if (user.globalPermissionLevel) {
		if (minGlobalPermission && isSufficientPermission(user.globalPermissionLevel, minGlobalPermission)) return children;
		if (minWorkspacePermission && isSufficientPermission(user.globalPermissionLevel, minWorkspacePermission)) return children;
		if (minGroupPermission && isSufficientPermission(user.globalPermissionLevel, minGroupPermission)) return children;
	}
	// User's workspace permission covers workspace and group
	if (user.workspacePermissionLevel) {
		if (minWorkspacePermission && isSufficientPermission(user.workspacePermissionLevel, minWorkspacePermission)) return children;
		if (minGroupPermission && isSufficientPermission(user.workspacePermissionLevel, minGroupPermission)) return children;
	}
	// Group permission: at least one must be sufficient if specified
	if (minGroupPermission) {
		for (let groupId in user.groupPermissionLevels) {
			let level = user.groupPermissionLevels[groupId];
			if (isSufficientPermission(level, minGroupPermission)) return children;
		}
	}
	// No permissions found which matched minimum - don't render children.
	return null;
}


/**
 * Conditionally renders its children depending on whether an action can be performed.
 * Intended for autohiding edit/delete dialog buttons.
 * 2 modes of operation:
 * - Caller provides type and one of [id, draft]: wrapper calls the "check permissions" API.
 *   See {@link EntityPermissionWrapper} and {@link useCanDo}.
 * - Caller provides any of [minGlobalPermission, minWorkspacePermission, minGroupPermission]:
 *   wrapper checks user's permission levels against given targets.
 *   See {@link UserPermissionWrapper}.
 * @param {object} p
 * @param {boolean} [p.enable=true] Set false to override the check.
 * @param {string} [p.type] Type of entity to check permission against
 * @param {string} [p.action] Action to check permission for. Defaults to UPDATE if ID provided or CREATE if not.
 * @param {string} [p.id] ID of entity to check permission against
 * @param {string} [p.draft] Draft entity to check permission for creation
 * @param {string} [p.minGlobalPermission] Minimum global permission level to perform the action
 * @param {string} [p.minworkspacePermission] Minimum workspace permission level to perform the action
 * @param {string} [p.minGroupPermission] Minimum any-group permission level to perform the action
 * @param {import('react').ReactNode | ReactNode[]} [p.children]
 * @return {import('react').ReactElement}
 */
function PermissionWrapper({enable = true, ...props}) {
	if (!enable) return props.children;

	// Generic form - check user's permissions directly
	if (props.minGroupPermission || props.minWorkspacePermission || props.minGlobalPermission) {
		return <UserPermissionWrapper {...props} />;
	}

	// Tolerate empty action prop
	let { type, action, id, draft } = props;
	if (!action) action = props.id ? 'UPDATE' : 'CREATE';

	// Entity-specific form - call API
	if (type && action && (id || draft)) {
		return <EntityPermissionWrapper {...props} />;
	}

	// Not enough information given - just render children.
	return props.children;
};


export default PermissionWrapper;
