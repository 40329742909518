import EntityTable from './EntityTable';

import { keyBy, repeat } from 'lodash';
import { EditGroupButton } from '../edit/GroupDialog';
import DeleteButton from '../edit/DeleteButton';
import entity, { entityDisplayName } from '../../model/entity';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Avatar, Box, Button, CircularProgress, Typography } from '@mui/material';
import { useGroup, useUser } from '../../state/apiHooks';
import { useMemo } from 'react';
import { EditGroupPermissionDropdown, ShowGroupPermission } from '../edit/GroupPermissionDialog';


/** Sort by item.name */
const defaultComparator = (a, b) => (a.name || '').localeCompare(b.name);


/** Show a group-permissions editor for the user */
function EditUserPermissionCell({row, userId}) {
	return <EditGroupPermissionDropdown size="small" userId={userId} groupId={row.id} />;
}

/** Show the user's current permission level with the group */
function ShowUserPermissionCell({row, userId}) {
	return <ShowGroupPermission userId={userId} groupId={row.id} />;
}


/** Performs in-place sort on a nested array-of-arrays. */
const sortRecursive = (list, comparator = defaultComparator) => {
	list.sort(comparator);
	list.forEach(item => sortRecursive(item.children, comparator));
};


/** Flattens out a nested array-of-arrays and attaches depth data to items. */
const flattenAndMark = (list, depth = 0) => {
	const flatList = [];
	list.forEach((entry, i) => {
		entry.depth = depth;
		entry.isFirst = (i === 0);
		entry.isLast = (i === list.length - 1);
		flatList.push(entry);
		flatList.push(...flattenAndMark(entry.children, depth + 1));
	});
	return flatList;
};


/**
 * Prepare a list of groups for display in a table:
 * - Place child groups immediately after their parent.
 * - Sort siblings alphabetically by name.
 * - Attach depth, isFirst, isLast info to help add visible inheritance markers.
 * -- TODO get clever and stateful and attach array of tree line markers
 */
const sortGroupsByDescent = groups => {
	if (!groups) return [];
	// Shallow-copy groups and add "children" field
	const copyGroups = groups.map(group => ({ ...group, children: []}));
	// Map by ID
	const groupsById = keyBy(copyGroups, 'id');
	const rootGroups = [];
	// Assign all groups to either rootGroups or to a parent
	copyGroups.forEach(group => {
		const { parentId } = group;
		if (!parentId || !groupsById[parentId]) {
			rootGroups.push(group);
		} else {
			groupsById[parentId]?.children.push(group);
		}
	});
	// Sort groups by name, and sort their child lists by name
	sortRecursive(rootGroups);
	// Attach depth and first/last child info to groups, and deep-flatten to array.
	return flattenAndMark(rootGroups);
};


function GroupCellContent({id, name, logoUrl, depth, indent}) {
	return (
		<Grid container direction="row" spacing={1} alignItems="center" display="inline-flex" sx={{flexWrap: 'nowrap'}}>
			{indent && <Grid>
				<Box style={{whiteSpace: 'pre'}}>{repeat('\t', depth)}</Box>
			</Grid>}
			<Grid>
				<Avatar src={logoUrl} />
			</Grid>
			<Grid>
				<Typography fontWeight="bold">{name}</Typography>
			</Grid>
		</Grid>
	);
}


function RowGroupCell({ row }) {
	const { id, name, logoUrl, depth } = row;
	return <GroupCellContent id={id} name={name} logoUrl={logoUrl} depth={depth} indent />;
}


function FetchGroupCell({value, ...props}) {
	const { data: group = {}, isPending } = useGroup(value);

	if (!value) return null;
	if (isPending) return <CircularProgress />;
	if (!group) return null;

	return <GroupCellContent {...group} />;
}


function ShowAction({row}) {
	return <Button variant="outlined" color="secondary" href={`/manage/groups/${row.id}`}>Show</Button>;
}


function EditAction({row}) {
	return <EditGroupButton label="Edit" id={row.id} />;
}

function DeleteAction({row}) {
	return <DeleteButton label="Delete" type={entity.GROUP} id={row.id} />;
}


const actions = [ShowAction, EditAction, DeleteAction];


/** Print the group's display name + logo */
const nameColumn = { field: 'name', renderCell: RowGroupCell, headerName: 'Group', flex: 1 };

/** Column set for unfiltered group list: Name and type. */
const baseColumns = [nameColumn];


/**
 * Table of groups.
 * @param {Object} props
 * @param {Boolean} [props.byDescent=true] Collect sub-groups under their parent& add indenting. Disables free-form column sorting.
 * @param {string} [props.permissionUser] Supply a user ID to display/edit their permission on each group in the table.
 * @returns {ReactElement}
 */
function GroupTable({byDescent = true, permissionUser, editPermission, ...props}) {
	// Add "show/edit user's permissions on each group" column if requested.
	const columns = useMemo(() => {
		// No permission column
		if (!permissionUser) return baseColumns;
		// Group-specific permission column
		const PermissionCellComponent = editPermission ? EditUserPermissionCell : ShowUserPermissionCell;
		const renderCell = props => <PermissionCellComponent {...props} userId={permissionUser} />;
		return [
			nameColumn,
			{ field: 'permission', flex: 1, headerName: entityDisplayName(entity.USER), renderCell}
		];
	}, [permissionUser, editPermission]);

	return <EntityTable aria-labelledby="Group table" type={entity.GROUP} columns={columns}
		processRows={byDescent ? sortGroupsByDescent : null} disableColumnSorting={byDescent}
		actions={actions}
		{...props}
	/>;
}

export default GroupTable;
export {
	RowGroupCell, FetchGroupCell
};
