import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';

// import Home from './pages/Home';
import RootLayout from './components/layouts/RootLayout';

import ManageCreatives from './pages/ManageCreatives';
import ShowUser from './pages/Admin/ShowUser';
import ManageBrands from './pages/ManageBrands';
import ManageCampaigns from './pages/ManageCampaigns';
import ManageGlobalUsers from './components/manage/ManageGlobalUsers';
import { PageContainer } from './components/common/LayoutComponents';
import DemoPage from './pages/DemoPage';
import ShowBrand from './pages/Formats/ShowBrand';
import ShowCampaign from './pages/Formats/ShowCampaign';
import ShowCreative from './pages/Formats/ShowCreative';
import EditCreative from './pages/Formats/EditCreative';
import { useEffect } from 'react';


/**
 * Temp hack: there's no /metrics page in Studio, but the login redirect is currently configured to send the user there anyway.
 * Bounce the user to / if they end up there.
 */
function BounceMetrics({history, ...props}) {
	const navigate = useNavigate();
	const baseUrl = new URL(window.location.href);
	baseUrl.pathname = '/';
	const href = baseUrl.pathname + baseUrl.search + baseUrl.hash;
	useEffect(() => {
		navigate(href);
	}, [href, navigate]);
	return null;
}


function StudioManageLayout() {
	return (
		<PageContainer>
			<Outlet />
		</PageContainer>
	);
}



function GoodFormatsStudio({user}) {
	const { isGlobalAdmin } = user;

	return (
		<Routes>
			<Route path="/" element={<RootLayout />}>
				<Route path="metrics" element={<BounceMetrics />}/>
				<Route index element={<ManageBrands />} />
				<Route path="brands" element={<ManageBrands />} />
				<Route path="brands/:brandId" element={<ShowBrand />} />
				<Route path="campaigns" element={<ManageCampaigns />} />
				<Route path="campaigns/:campaignId" element={<ShowCampaign />} />
				<Route path="creatives" element={<ManageCreatives />} />
				<Route path="creatives/new" element={<EditCreative isNew />} />
				<Route path="creatives/:creativeId" element={<ShowCreative />} />
				<Route path="creatives/:creativeId/edit" element={<EditCreative />} />
				<Route path="preview/:creativeId?" element={<DemoPage />} />
				{isGlobalAdmin && (
					<Route path="manage" element={<StudioManageLayout />}>
						<Route path="users" element={<ManageGlobalUsers />} />
						<Route path="users/:userId" element={<ShowUser />} />
					</Route>
				)}
			</Route>
		</Routes>
	);
}


export default GoodFormatsStudio;
