import { useCallback, useState } from 'react';
import { Button, InputAdornment, inputAdornmentClasses, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import UploadField from '../../inputFields/UploadField';
import { itemAdder, SubForm } from '../../SubForms';
import TextInputField from '../../inputFields/TextInputField';
import useLiveRef from '../../../utilities/useLiveRef';
import { useFormContext } from '../../FormContextProvider';


function ImageForm({index, ...uploadProps}) {
	const { actionFns } = useFormContext();

	const removeButton = <Button variant="contained" color="secondary" onClick={actionFns.delete}>Remove</Button>;

	return <UploadField type="image" path="." label={`ad.images.${index}`} {...uploadProps} append={removeButton} size="small" />;
}


/** Add "ad.images." prefix to key input */
const nameFieldProps = {
	sx: {
		fontFamily: 'monospace',
		[`.${inputAdornmentClasses.root}`]: { marginRight: 0 },
	},
	startAdornment: <InputAdornment position="start" disableTypography>ad.images.</InputAdornment>,
};



const AddImageForm = itemAdder(function({addItem, uploadParams}) {
	const [key, setKey] = useState();
	const [url, setUrl] = useState();
	const kvRef = useLiveRef([url, key]);

	// Insert the uploaded file URL in the text field on success.
	const onClickAdd = useCallback(() => {
		addItem(...kvRef.current);
		setKey(null);
		setUrl(null);
	}, [addItem, kvRef]);

	const addButton = <Button variant="contained" onClick={onClickAdd} disabled={!key}>Add</Button>;

	return <Grid xs={12} container spacing={1}>
		<Grid xs={12} sm={6}>
			<UploadField label="Upload new image" type="image" uploadParams={uploadParams} value={url} onChangeValue={setUrl} size="small" fullWidth />
		</Grid>
		<Grid xs={12} sm={6}>
			<TextInputField label="Name" {...nameFieldProps} append={addButton} value={key} onChangeValue={setKey} size="small" fullWidth />
		</Grid>
	</Grid>;
});


function ImageControls({path, uploadProps}) {
	const ImageFormWithParams = useCallback(props => (
		<ImageForm {...uploadProps} {...props} />
	), [uploadProps]);

	return <Stack spacing={2}>
		<AddImageForm path={path} {...uploadProps} />
		<SubForm path={path} ItemForm={ImageFormWithParams} />
	</Stack>;
}

export default ImageControls;
