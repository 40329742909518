import { useGroupPermissions, useUser } from '../../state/apiHooks';
import { FetchGroupCell } from './GroupTable';
import { FetchUserCell } from './UserTable';
import EntityTable from './EntityTable';
import { EditGroupPermissionDropdown } from '../edit/GroupPermissionDialog';
import { useMemo } from 'react';


const groupColumn = { field: 'groupId', headerName: 'Group', renderCell: FetchGroupCell, flex: 1 };

const userColumn = { field: 'userId', headerName: 'User', renderCell: FetchUserCell, flex: 1 };

const valueColumn = { field: 'value', headerName: 'Permission', renderCell: EditPermissionCell, flex: 1 };

const groupColumns = [userColumn, valueColumn];

const userColumns = [groupColumn, valueColumn];



function EditPermissionCell({row}) {
	return <EditGroupPermissionDropdown size="small" {...row} />;
}


const getRowId = row => `${row.userId}-${row.groupId}`;


function TableForGroup({groupId}) {
	const { data: rows, isPending } = useGroupPermissions(groupId);

	// TODO This is not an EntityTable... but it works fine for now, so whatever.
	return <EntityTable getRowId={getRowId} rows={rows} columns={groupColumns} loading={isPending} />;
}


function TableForUser({userId}) {
	const { data: user, isPending } = useUser(userId);

	const rows = useMemo(() => {
		const rows = Object.entries(user.groupPermissions).map(([groupId, value]) => (
			{groupId, value}
		));
		return rows;
	}, [user]);

	// TODO This does not need to be an EntityTable
	return <EntityTable getRowId={getRowId} rows={rows} columns={userColumns} loading={isPending} />;
}



function GroupPermissionTable(props) {
	const Table = props.groupId ? TableForGroup : TableForUser;

	return <Table {...props} />;
}


export default GroupPermissionTable;
