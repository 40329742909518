const unlockTemplateXML = `<Column h="100%">
  <Row size="24" class="upper-row">
    <BrandLogo size="20" mx="3" hmax="80%" />
    <div size="80" class="headline flex center">
      <div switch="\${{!state.donation.unlocked}}" outDuration="200" class="countdown-message flex float fill switch-fade">
        \${{text.countdown}} <Countdown font-size="5" ml="1.5" />
      </div>
      <div switch="\${{state.donation.unlocked}}" inDelay="100" inDuration="200" class="unlocked-message flex float fill switch-fade">
        \${{text.unlocked}}
      </div>
    </div>
  </Row>
  <Row size="76">
    <Video size="76" />
    <Column size="24" class="right-column" px="3">
      <CharityLogo imgClass="container" my="auto">
        <DonationLock class="switch-fade" outDuration="200" />
      </CharityLogo>
      <Ident my="3"/>
    </Column>
  </Row>
</Column>`;

export default unlockTemplateXML;