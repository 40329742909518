import { useCallback } from 'react';
import { useFormContext } from '../FormContextProvider';
import { Button } from '@mui/material';


/**
 * Renders a button which, when clicked, performs the primary "submit" action in the nearest FormContext.
 * @param {object} props
 * @param {boolean} [props.disabled]
 * @param {(any) => any} [props.submitThen]
 */
function SubmitButton({disabled = false, submitThen, ...props}) {
	const { submitFn, submitLabel, saving, valid } = useFormContext();

	// Don't submit while already submitting; lock button if object is invalid
	disabled ||= saving || !valid;

	const onClick = useCallback(() => {
		const submitPromise = submitFn();
		if (submitThen) submitPromise.then(submitThen);
	}, [submitFn, submitThen]);

	return (
		<Button variant="outlined" color="primary" onClick={onClick} disabled={disabled} {...props}>
			{submitLabel}
		</Button>
	);
}

export default SubmitButton;
