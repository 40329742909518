import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import Grid from '@mui/material/Unstable_Grid2';

import { Header, PageContainer, PageSection } from '../../components/common/LayoutComponents';
import { CreativeFormFields, validateCreative } from '../../components/edit/CreativeDialog';
import GoodFormatsBreadcrumbs from './GoodFormatsBreadcrumbs';
import EntityContextProvider from '../../components/EntityContextProvider';
import FormStatus from '../../components/edit/FormStatus';
import SubmitButton from '../../components/edit/SubmitButton';
import DeleteButton from '../../components/edit/DeleteButton';
import { type Creative } from '../../model/types/Creative';
import useSearchParam from '../../state/useSearchParam';
import { useCampaign, useCreative, useGroup } from '../../state/apiHooks';
import { AdCampaign } from '../../model/types/AdCampaign';
import { Group } from '../../model/types/Group';
import { SelectCampaign } from '../../components/inputFields/SelectFromAPI';


/**
 * Returns either the current creative ID from path params (if editing a saved entity),
 * or a base object with brand and campaign IDs inferred from search params & an initial
 * creative setting up various defaults.
 */
function useEditorData(): CreativeEditorData {
	const { creativeId } = useParams();

	const [campaignId] = useSearchParam('campaign');
	const { data: campaign } = useCampaign(campaignId) as { data: AdCampaign };
	const { brandId } = campaign || {};

	const { data: brand } = useGroup(brandId) as { data: Group };

	return useMemo(() => {
		if (creativeId) return { creativeId };
		return {
			base: { brandId, campaignId },
			initial: {
				data: {
					brand: {
						name: brand?.name,
						logo: brand?.logoUrl || 'https://storage.cdn.good-loop.com/images/default/brand-logo-default.svg'
					},
					charity: {
						logo: 'https://storage.cdn.good-loop.com/images/default/charity-image-default.svg'
					},
					images: {
						qrCode: 'https://storage.cdn.good-loop.com/images/default/qr-code-default.png',
					},
					fonts: [
						// TODO Font service doesn't know what to do with subdirs so this can't go under fonts/default
						// TODO Move to prod when font service is running
						{ url: 'https://fonts.dev-cdn.good-loop.com/ABCSocial-Bold.otf' }
					],
					styling: {
						glLogoDark: false,
						color: '#fff',
						contrastColor: '#000',
						bgColor: '#aaa'
					},
					video: { type: 'stream' }
				}
			},
		};
	}, [creativeId, campaignId, brandId, brand]);
}


interface EditCreativeProps {
	/** True if this is a "new creative" form. */
	isNew?: boolean
}


interface CreativeEditorData {
	/** ID of the creative to edit - or absent if creating a new one. */
	creativeId?: string;
	/** Base creative object (when creating new) - locks brandId and campaignId. */
	base?: Partial<Creative>;
	/** Initial creative object (when creating new) - sets some default images, colours and fonts. */
	initial?: Partial<Creative>;
}


/** On deleting a creative, return to its owning campaign. */
function useDeleteThen(creativeId) {
	const { data: creative } = useCreative(creativeId) as { data: Creative };

	const campaignId = creative?.campaignId;
	const navigate = useNavigate();

	// Deletion will invalidate the query cache for creatives & null out the creative fetched above
	// - so trying to read creative.campaignId afterwards will cause an NPE.
	// So we save the callback when there's a campaign ID available - and don't overwrite it when there isn't.
	const [callback, setCallback] = useState() as [(() => void), any];
	useEffect(() => {
		if (campaignId) setCallback(() => (
			() => navigate(`/campaigns/${campaignId}`)
		));
	}, [campaignId, navigate]);

	return callback;
}


function EditCreative({isNew}: EditCreativeProps) {
	const { creativeId, base, initial } = useEditorData();

	const navigate = useNavigate();
	// On first save, switch to editing the now persisted creative.
	const submitThen = useCallback(saved => {
		isNew && navigate(`/creatives/${saved.id}/edit`);
	}, [isNew, navigate]);
	// On deletion, return to the owning campaign's page.
	const deleteThen = useDeleteThen(creativeId);

	/** If the user didn't reach this page via the "show campaign -> new creative" route, pick a campaign first. */
	if (isNew && !base.campaignId) return (
		<PageContainer>
			<Header rank={5}>New Creative</Header>
			<PageSection>
				<Header rank={6} mb={3}>Select a campaign to begin.</Header>
				<SelectCampaign searchParam="campaign" />
			</PageSection>
		</PageContainer>
	);

	const title = isNew ? 'New Creative' : 'Edit Creative';

	return <PageContainer>
		<GoodFormatsBreadcrumbs {...base} creativeId={creativeId} lastLabel={title} />
		<PageSection>
			<EntityContextProvider type="creative" id={creativeId} base={base} initial={initial} validateFn={validateCreative}>
				<Grid container justifyContent="space-between" my={2}>
					<Grid><Header rank={5}>{title}</Header></Grid>
					<Grid display="flex" alignItems="center" gap={1}>
						{!isNew && <FormStatus />}
						<SubmitButton submitThen={submitThen} />
						{!isNew && <DeleteButton onComplete={deleteThen} />}
					</Grid>
				</Grid>
				<CreativeFormFields />
			</EntityContextProvider>
		</PageSection>
	</PageContainer>;
}


export default EditCreative;
