import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import './index.css';

import GoodMeasuresForAdvertisers from './GoodMeasuresForAdvertisers';
import GoodMeasuresForPublishers from './GoodMeasuresForPublishers';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WorkspaceProvider from './components/WorkspaceProvider';
import CustomThemeProvider from './components/CustomThemeProvider';
import AppWrapper from './AppWrapper';
import { CssBaseline } from '@mui/material';
import GoodFormatsStudio from './GoodFormatsStudio';

const root = ReactDOM.createRoot(document.getElementById('root'));


const mainComponents = {
	publishers: GoodMeasuresForPublishers,
	advertisers: GoodMeasuresForAdvertisers,
	studio: GoodFormatsStudio
};

const MainComponent = mainComponents[process.env.REACT_APP_GOOD_MEASURES_FOR] || GoodMeasuresForAdvertisers;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				// Don't retry for 400-series errors, since they're caused by the client
				// - and frequently "404 Not Found" is a valid answer that we want to get in normal operation.
				const status = error?.status;
				if (status) {
					if (status >= 400 && status < 500) return false;
				}

				return failureCount < 5;
			},
			staleTime: 5 * 50 * 1000,
		}
	}
});

root.render(
	<StrictMode>
		<Auth0Provider
			domain={process.env.REACT_APP_DOMAIN}
			clientId={process.env.REACT_APP_CLIENT}
			authorizationParams={{
				redirect_uri: window.location.origin + '/metrics',
				audience: process.env.REACT_APP_BUSINESS_STORE_API_ENDPOINT
			}}
		>
			<BrowserRouter>
				<QueryClientProvider client={queryClient} contextSharing={true}>
					<WorkspaceProvider>
						<CustomThemeProvider>
							<CssBaseline />
							<AppWrapper MainComponent={MainComponent} />
						</CustomThemeProvider>
					</WorkspaceProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</Auth0Provider>
	</StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
