import EntityTable from './EntityTable';

import { EditGroupButton } from '../edit/GroupDialog';
import DeleteButton from '../edit/DeleteButton';
import entity from '../../model/entity';
import { RowGroupCell } from './GroupTable';
import { Button } from '@mui/material';
import { useUrlWithWorkspace } from '../WorkspaceProvider';



// function UserCountCell({ row }) {
// 	const { data: count } = useUsers({workspace: row.id}, {select: data => data.length});
// 	return <Chip size="large" label={count} />;
// }


// function ApiKeyCountCell({ row }) {
// 	const { data: count } = useUsers({workspace: row.id, loginType: 'API'}, {select: data => data.length});
// 	return <Chip size="large" label={count} />;
// }

function ShowAction({row}) {
	const href = useUrlWithWorkspace('/', row.id);
	return (
		<Button href={href} variant="outlined" color="secondary">Enter</Button>
	);
}

function EditAction({row}) {
	return <EditGroupButton label="Edit" id={row.id} />;
}

function DeleteAction({row}) {
	return <DeleteButton label="Delete" type={entity.GROUP} id={row.id} />;
}


const actions = [ShowAction, EditAction, DeleteAction];


/** Print the group's display name + logo */
const nameColumn = { field: 'name', renderCell: RowGroupCell, headerName: 'Workspace', flex: 1 };


const columns = [nameColumn];


/** Query for fetching only workspaces from the groups endpoint. */
const workspaceQuery = {parent: null};


/**
 * Table of workspaces (root groups).
 * @returns {ReactElement}
 */
function WorkspaceTable(props) {
	return <EntityTable type={entity.GROUP} query={workspaceQuery} columns={columns} actions={actions} {...props} />;
}

export default WorkspaceTable;
