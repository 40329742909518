import { useCallback, useEffect, useMemo, useRef, type ReactNode } from 'react';
import { noop } from 'lodash';

import { type InputProps } from '@mui/material';

import { useEntities } from '../../state/apiHooks';
import SelectField from './SelectField';
import { setWithEvent } from './InputWrapper';
import TextInputField from './TextInputField';
import entity, { entityDisplayName } from '../../model/entity';
import { type Entity } from '../../model/types/Entity';
import { article } from '../../utilities/utils';


interface SelectEntityProps extends InputProps {
	/** The input label. */
	label?: ReactNode;
	/** If provided, will be used to label a "select nothing" entry/ */
	labelEmpty?: ReactNode;
	/** Dummy entry label for "nothing to select" case. */
	labelNoOptions?: ReactNode;
	/** Dummy entry label shown while retrieving options */
	labelLoading?: ReactNode;
	/** If true: once the item fetch resolves, if there's only one value, the input is set to it (triggering onChange etc). */
	autosetOne?: boolean;
	/** Converts fetched DB entities into {@link SelectField} option objects. */
	toOptionFn?: (entity: Entity) => {label: string, value: string};
	/** Receives the entity ID on change. */
	onChangeValue?: (value: string) => void;
	/** Receives the entity object on change. */
	onChangeObject?: (entity: Entity) => void;
	/** K-V query object passed to {@link useEntities}. */
	query?: object;
	/** (temp until InputWrapper is properly TSified & fields can inherit its props) */
	searchParam?: string;
}


interface SelectEntityBaseProps extends SelectEntityProps {
	/** The entity type. */
	type: string;
}


/** Default mapper from DB entity to {@link SelectField} option. */
const entityToOption = ({name, id}) => ({label: name, value: id});


/** Common code for Select[Entity] components. */
function SelectEntity({
	toOptionFn = entityToOption,
	onChangeValue = noop, onChangeObject = noop,
	autosetOne = true,
	type, query, labelLoading, ...props
}: SelectEntityBaseProps) {
	// Construct labels for special/synthetic options
	const typeName = entityDisplayName(type);
	props.labelEmpty ??= `Select ${article(typeName)} ${typeName}`;
	props.labelNoOptions ??= `No ${typeName}s available`;

	// Fetch entities from API to construct <option> items
	const { data: entities = [], isPending: entitiesPending } = useEntities(type, query);
	// const entities = data as Entity[];
	// Keep a ref to the underlying <input> for programmatic set
	const inputRef = useRef();

	// Build options list
	let options = useMemo(() => {
		return (entities as Entity[]).map(toOptionFn) || [];
	}, [entities, toOptionFn]);

	// Autoset if only one option is available
	useEffect(() => {
		if (!inputRef.current || options.length > 1 || !autosetOne) return;
		setWithEvent(inputRef.current, options[0].value);
	}, [options, inputRef, autosetOne]);

	// Wrap onChangeValue
	const _onChangeValue = useCallback(id => {
		onChangeValue(id);
		onChangeObject((entities as Entity[]).find(o => o.id === id));
	}, [entities, onChangeValue, onChangeObject]);

	// Dummy input while waiting for options
	if (entitiesPending) {
		// Pull out SelectField-specific item label props that TextInputField will complain about
		const { labelEmpty, labelNoOptions, ...safeProps} = props;
		return <TextInputField {...safeProps} value={labelLoading || `Loading ${typeName}s...`} disabled />;
	}

	return <SelectField options={options} onChangeValue={_onChangeValue} {...props} />;
}


/** Self-populating drop-down list of groups */
function SelectGroup(props: SelectEntityProps) {
	return <SelectEntity type={entity.GROUP} {...props} />;
}


/** Self-populating drop-down list of publishers */
function SelectPublisher(props: SelectEntityProps) {
	return <SelectEntity type={entity.PUBLISHER} {...props} />;
}


/** Self-populating drop-down list of campaigns */
function SelectCampaign(props: SelectEntityProps) {
	return <SelectEntity type={entity.CAMPAIGN} {...props} />;
}


/** Self-populating drop-down list of tags */
function SelectTag(props: SelectEntityProps) {
	return <SelectEntity type={entity.TAG} {...props} />;
}


export {
	SelectGroup,
	SelectCampaign,
	SelectTag,
	SelectPublisher
};
