import { capitalize } from 'lodash';

import PermissionWrapper from './PermissionWrapper';
import ButtonWithConfirm from './ButtonWithConfirm';
import { useFormContext } from '../FormContextProvider';


/**
 * Self-managing "Delete Entity" button with confirm dialog.
 * @param {EditFormProps} props
 * @returns {ReactElement}
 */
function DeleteButton({...props}) {
	// Is an entity context already available?
	const context = useFormContext();
	const { type, id, initial: entity, actionFns, saving } = context;

	props.label ??= 'Delete';
	props.confirmTitle ??= 'Confirm Deletion';
	props.confirmText ??= `Are you sure you want to delete ${entity?.name || `this ${type}`}?`;
	props.successTitle ??= 'Deleted';
	props.successText ??= `${capitalize(type)} ${entity?.name} has been deleted.`;
	props.disabled ||= saving;

	return (
		<PermissionWrapper type={type} id={id} action="DELETE">
			<ButtonWithConfirm commitFn={actionFns.delete} {...props} />
		</PermissionWrapper>
	);
}

export default DeleteButton;
