import { createContext, useContext, useMemo } from 'react';
import { useMetric } from '../../../state/apiHooks';
import { useMetricsFilter } from './MetricsFilterProvider';
import useSearchParam from '../../../state/useSearchParam';

interface MetricResponse {
	data?: any;
	loading?: boolean;
	error?: any;
}

interface GHGDataContextType {
	total: MetricResponse;
	overTime: MetricResponse;
	consumptionBreakdown: MetricResponse;
	distributionBreakdown: MetricResponse;
	consumptionByDeviceType: MetricResponse;
	distributionByCreativeFormat: MetricResponse;
}

const GHGDataContext = createContext<GHGDataContextType>({
	total: {},
	overTime: {},
	consumptionBreakdown: {},
	distributionBreakdown: {},
	consumptionByDeviceType: {},
	distributionByCreativeFormat: {}
});
const PublisherDataContext = createContext({});
const GMPDataContext = createContext({});


/**
 * Convert a date-only ISO 8601 string to a full GMT representation of the start or end of the specified day in the user's time zone.
 * Example: Input is 2024-09-10, isEnd is false, user's time zone is UK.
 *   British Summer Time is in effect on the input date, so the constructed timestamp will be "00:00:00 BST on 10 September 2024".
 *   BST is one hour ahead of GMT, so that will evaluate to "2024-09-09T23:00:00.000Z".
 * @param {String} dateString Format yyyy-mm-dd
 * @param {Boolean} [isEnd] If true, the output timestamp will be bumped ahead by 1 day.
 * @returns {String} Format yyyy-mm-ddThh:mm:ss.sssZ
 */
const dateStringToBound = (dateString, isEnd) => {
	if (!dateString) return null;

	const match = dateString.match(/(\d+)-(\d+)-(\d+)/);
	if (!match) return null;

	let [, year, month, day] = match;
	// End of range should be start of following day, to include the full day's data
	if (isEnd) day = Number(day) + 1;
	return new Date(year, month - 1, day, 0, 0, 0, 0).toISOString();
};

interface NormalizedFilter {
	tagIds?: string[];
	campaignIds?: string[];
	groupIds?: string[];
	startDate?: string;
	endDate?: string;
	channel?: string;
}

/**
 * Adjust filter params to match the format expected by the server:
 * - adjust tagId, campaignId, groupId to tagIds, campaignIds, groupIds & wrap in arrays
 * - transform start = yyyy-mm-dd --> startDate = yyyy-mm-ddT00:00:00.000TZ (user's local time)
 * - transform end = yyyy-mm-dd --> endDate = yyyy-mm-dd+1T00:00:00.000TZ (user's local time)
 */
function useNormalisedFilter(): NormalizedFilter {
	const rawFilter = useMetricsFilter();
	return useMemo(() => {
		const [{ start, end, tagId, campaignId, groupId, ...filter }] = rawFilter;

		const normalised: NormalizedFilter = { ...filter };
		if (tagId) normalised.tagIds = [tagId];
		if (campaignId) normalised.campaignIds = [campaignId];
		if (groupId) normalised.groupIds = [groupId];
		if (start) normalised.startDate = dateStringToBound(start, false);
		if (end) normalised.endDate = dateStringToBound(end, true);

		return normalised;
	}, [rawFilter]);
}


function GHGDataProvider(props) {
	const filter = useNormalisedFilter();

	// Fetch GHG data
	const total = useMetric('/ghg/totalEmissions', { ...filter, limit: 25000 });
	const overTime = useMetric('/ghg/emissionsOverTime', { ...filter, limit: 25000 });
	const consumptionBreakdown = useMetric('/ghg/consumptionBreakdown', filter);
	const distributionBreakdown = useMetric('/ghg/distributionBreakdown', filter);
	const consumptionByDeviceType = useMetric('/ghg/consumptionByDeviceType', filter);
	const distributionByCreativeFormat = useMetric('/ghg/distributionByCreativeFormat', filter);

	// useMemo so that object remains stable as long as constituent elements do
	const data = useMemo(() => ({
		total,
		overTime,
		consumptionBreakdown,
		distributionBreakdown,
		consumptionByDeviceType,
		distributionByCreativeFormat,
	}), [total, overTime, consumptionBreakdown, distributionBreakdown, consumptionByDeviceType, distributionByCreativeFormat]);

	return <GHGDataContext.Provider value={data} {...props} />;
};

function PublisherDataProvider(props) {
	const filter = useNormalisedFilter();

	// Fetch publisher data
	const publisherScores = useMetric('/publisher/publisherScores', filter);
	const impressionsByPercentiles = useMetric('/publisher/impressionsByPercentiles', filter);
	const publisherPercentiles = useMetric('/publisher/publisherPercentiles', {}, { method: 'GET'});

	// useMemo so that object remains stable as long as constituent elements do
	const data = useMemo(() => ({
		publisherScores,
		impressionsByPercentiles,
		publisherPercentiles
	}), [publisherScores, impressionsByPercentiles, publisherPercentiles]);

	return <PublisherDataContext.Provider value={data} {...props} />;
}

function GMPDataProvider(props) {
	const [publisherId] = useSearchParam('publisherId');
    const publisherScore = useMetric(`/publisher/publisherScore/${publisherId}`, {}, { method: 'GET'});
    const publisherPercentiles = useMetric('/publisher/publisherPercentiles', {}, { method: 'GET'});
	// useMemo so that object remains stable as long as constituent elements do
	const data = useMemo(() => ({
		publisherScore,
		publisherPercentiles
	}), [publisherScore, publisherPercentiles]);

	return <GMPDataContext.Provider value={data} {...props} />;
}

/**
 * @returns { total, overTime, consumptionBreakdown, distributionBreakdown, consumptionByDeviceType, distributionByCreativeFormat }
 */
const useGHGData = () => useContext(GHGDataContext);

/**
 * @returns { publisherScores, impressionsByPercentiles, publisherPercentiles }
 */
const usePublisherData = () => useContext(PublisherDataContext);


/**
 * @returns { publisherScore, publisherPercentiles }
 */
const useGMPData = () => useContext(GMPDataContext);

export { GHGDataProvider, PublisherDataProvider, GMPDataProvider, useGHGData, usePublisherData, useGMPData };
