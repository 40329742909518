import { useMemo } from 'react';

import entity from '../../model/entity';
import { EditUserButton } from '../edit/UserDialog';
import DeleteButton from '../edit/DeleteButton';
import { EditGlobalPermissionDropdown } from '../edit/GlobalPermissionDialog';
import { RowUserCell } from './UserTable';
import EntityTable from './EntityTable';


function GlobalPermissionCell({row}) {
	const { id: userId } = row;
	return <EditGlobalPermissionDropdown userId={userId} />;
}

const nameColumn = { field: 'name', headerName: 'User Name', renderCell: RowUserCell, flex: 1};

const permissionColumn = { field: 'permission', headerName: 'Global Permission', renderCell: GlobalPermissionCell,  flex: 1};

const columns = [nameColumn, permissionColumn];


function EditAction({row}) {
	return <EditUserButton label="Edit" id={row.id} />;
}

function DeleteAction({row}) {
	return <DeleteButton type={entity.USER} id={row.id} />;
}

const actions = [EditAction, DeleteAction];



function GlobalUserTable({query, ...props}) {
	// Select only root users (i.e. those who exist outside any workspace)
	const _query = useMemo(() => {
		return Object.assign(query || {}, {workspace: null});
	}, [query]);

	return <EntityTable columns={columns} actions={actions} type={entity.USER} query={_query} {...props} />;
}


export default GlobalUserTable;
