import { useFormContext } from '../FormContextProvider';
import { EntityContextType } from '../EntityContextProvider';
import { CircularProgress, Typography } from '@mui/material';

import { ChangeCircle, CheckCircle, Unpublished } from '@mui/icons-material';


const boxSx = { display: 'flex', minWidth: '6em', textAlign: 'center', gap: 1 };


function StatusBox({color, ...props}) {
	return <Typography component="div" sx={boxSx} color={`${color}.main`} {...props}/>;
}

function InlineSpinner() {
	return <CircularProgress color="inherit" size="1.25em" />;
}

function FormStatus() {
	const { saved, saving, changed, processingInput } = useFormContext() as EntityContextType<any>;


	let color = 'info';
	let message;
	if (saving) {
		message = <><InlineSpinner /> Saving</>;
	} else if (processingInput) {
		message = <InlineSpinner />;
	} else if (!saved) {
		message = <><Unpublished /> Unsaved</>;
	} else if (changed) {
		message = <><ChangeCircle /> Edited</>;
	} else {
		color = 'success';
		message = <><CheckCircle /> Saved</>;
	}

	return <StatusBox color={color}>{message}</StatusBox>;
}

export default FormStatus;
