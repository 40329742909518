import unlockText from './unlockTemplate/text';
import counterText from './counterTemplate/text';
import qrCodeText from './qrCodeTemplate/text';

import qrCodeTemplateXML from './qrCodeTemplate/templateXML';
import qrCodeCSS from './qrCodeTemplate/templateCSS';

import unlockTemplateXML from './unlockTemplate/templateXML';
import unlockCSS from './unlockTemplate/templateCSS';

import counterTemplateXML from './counterTemplate/templateXML';
import counterCSS from './counterTemplate/templateCSS';

export const qrCodeTemplate = {
	template: qrCodeTemplateXML,
	css: qrCodeCSS,
	text: qrCodeText
};

export const unlockTemplate = {
	template: unlockTemplateXML,
	css: unlockCSS,
	text: unlockText
};

export const counterTemplate = {
	template: counterTemplateXML,
	css: counterCSS,
	text: counterText
};