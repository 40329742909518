import { type EntityTypes } from '../../model/types/Entity';
import EntityContextProvider, { type EntityContextProps } from '../EntityContextProvider';

import EditDialog from './EditDialog';
import { type ReactElement, type FunctionComponent } from 'react';


/** Props for a component augmented by {@link withEntityContext}. */
type EntityWrapperProps<T extends keyof EntityTypes, P extends object> = EntityContextProps<T> & P & {
	/** For passing props which collide with members of {@link EntityContextProps} to the wrapped component. */
	wrappedProps: P;
};


/**
 * HOC to wrap a given component in an {@link EntityContextProvider}.
 * Props in {@link EntityContextProps} will be intercepted and passed to the provider.
*/
function withEntityContext
<T extends keyof EntityTypes, P extends object>
(WrappedComponent: FunctionComponent<P>): (props: EntityWrapperProps<T, P>) => ReactElement
{
	return function(props) {
		const { type, id, entity, base, initial, submitLabel, actionLabels, validateFn, autosave, wrappedProps, title, ...rest } = props;
		const providerProps = { type, id, entity, base, initial, submitLabel, actionLabels, validateFn, autosave, title};


		return <EntityContextProvider {...providerProps}>
			<WrappedComponent {...rest as P} {...wrappedProps} />
		</EntityContextProvider>;
	};
}

/**
 * {@link EditDialog} plus {@link EntityContextProvider} wrapper. {@description}
 *  */
const EntityDialog = withEntityContext(EditDialog);


export default EntityDialog;
