import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';

import { EditGroupButton } from '../edit/GroupDialog';
import GroupTable from '../tables/GroupTable';
import { Header, HeaderActions, PageSection } from '../common/LayoutComponents';
import { useAuthUser } from '../../state/apiHooks';
import { useWorkspace } from '../WorkspaceProvider';



/** Table of all existing groups & interface for creating new ones. */
function ManageGroups() {
	const { workspace, isFetched: workspaceFetched } = useWorkspace();
	const { data: user } = useAuthUser();

	// Groups created in this context will be direct children of the focus workspace.
	const groupBase = useMemo(() => ({ parentId: workspace?.id }), [workspace]);

	if (!user) return <PageSection><CircularProgress /> Loading user...</PageSection>;
	if (!workspaceFetched) return <PageSection><CircularProgress /> Loading workspace...</PageSection>;
	if (!workspace) return <PageSection>Workspace unavailable</PageSection>;

	return (
		<PageSection>
			<Header rank={5}>
				{user.isWorkspaceAdmin || user.isGlobalAdmin ? 'All Groups' : 'Your Groups'}
				<HeaderActions>
					<EditGroupButton variant="outlined" label="Create Group" base={groupBase} />
				</HeaderActions>
			</Header>
			<GroupTable query={{workspace: workspace.id, parent: '!null'}} />
		</PageSection>
	);
}

export default ManageGroups;
