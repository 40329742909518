import { createContext, useContext, useMemo } from 'react';
import { useAuthUser, useGroup } from '../state/apiHooks';
import useSearchParam from '../state/useSearchParam';


const WorkspaceContext = createContext({workspace: undefined, workspaceId: undefined, isOverridden: false});

const WORKSPACE_PARAM = 'workspace';


/** Provides the user's current workspace. */
function WorkspaceProvider({children}) {
	const { data: user } = useAuthUser();
	const [workspaceIdOverride] = useSearchParam(WORKSPACE_PARAM);
	const workspaceId = user ? user.workspaceId || workspaceIdOverride : null;

	const { data: workspace, ...response } = useGroup(workspaceId || user?.workspaceId);

	// Bundle up the current workspace ID, the fetched value & rest of UseQueryResult
	const value = { workspaceId, workspace, ...response };

	// Global users can switch workspaces & get flag specifying that they're acting in a foreign workspace.
	if (!user?.workspaceId) {
		value.isOverridden = (workspaceId && !user?.workspaceId);
	}

	return (
		<WorkspaceContext.Provider value={value}>
			{children}
		</WorkspaceContext.Provider>
	);
}


/**
 * Returns structure: {
 * 	workspaceId: the current workspace ID
 *  workspace: the GLGroup object corresponding to workspaceId (may be subject to fetch lag)
 * 	isLoading, isPending, etc: members of the UseQueryResult from fetching the workspace
 * 	[global user only] isOverridden: True if the user is acting in a foreign workspace
 * }
 */
function useWorkspace() {
	return useContext(WorkspaceContext);
}


/**
 * Rewrite a URL to include a specified "workspace" search param
 * (used by admins to enter client workspaces) if it's an internal link.
 */
function useUrlWithWorkspace(url, workspaceId) {
	return useMemo(() => urlWithWorkspace(url, workspaceId), [workspaceId, url]);
}


/**
 * Rewrite a URL to include the current location's "workspace" search param
 * (used by admins to enter client workspaces) if it's an internal link.
 */
function useUrlWithCurrentWorkspace(url) {
	const [workspaceId] = useSearchParam(WORKSPACE_PARAM);
	return useUrlWithWorkspace(url, workspaceId);
}



const urlWithWorkspace = (url, workspaceId) => {
	// No workspace override, nothing to do here.
	if (!workspaceId) return url;

	const hrefUrl = new URL(url, document.location.href);

	// External link, don't mess with it.
	if (hrefUrl.host !== document.location.host) return url;

	// Link already specifies a workspace ID, don't override it.
	const existingParam = hrefUrl.searchParams.get(WORKSPACE_PARAM);
	if (existingParam) return url;

	if (workspaceId) {
		// OK - attach workspace ID.
		hrefUrl.searchParams.set(WORKSPACE_PARAM, workspaceId);
	} else {
		// No ID = remove the param.
		hrefUrl.searchParams.delete(WORKSPACE_PARAM);
	}

	return hrefUrl.toString();
};


export default WorkspaceProvider;
export { useWorkspace, useUrlWithWorkspace, useUrlWithCurrentWorkspace };
