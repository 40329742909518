import { useMemo, forwardRef} from 'react';
import { useBranding } from '../state/hooks';
import { ThemeProvider } from '@emotion/react';
import { Link, type LinkProps } from 'react-router-dom';
import { merge, cloneDeep } from 'lodash';

import { alpha, createTheme } from '@mui/material';

import { goodLoopDefaultTheme, goodLoopStudioTheme } from '../constants/theme';
import { useUrlWithCurrentWorkspace } from './WorkspaceProvider';
import { PaletteColor } from '@mui/material/styles/createPalette';


/** Add our custom chart colours to the theme for TypeScript. */
declare module '@mui/material/styles' {
  interface Palette {
		chartConsumption: Palette['primary'];
    chartDistribution: Palette['primary'];
		chartGMP: Palette['primary'];
  }
  interface PaletteOptions {
		chartConsumption: PaletteOptions['primary'];
		chartDistribution: PaletteOptions['primary'];
		chartGMP: PaletteOptions['primary'];
  }
}

type ConvertLinkProps = Omit<LinkProps, 'to'> & { href: LinkProps['to'] };

/** Use react-router <Link> for hrefs, and (until we have workspace subdomains) preserve the workspaceId prop. */
const WorkspaceRoutingLink = forwardRef<HTMLAnchorElement, ConvertLinkProps>(
	function ({href, ...props}, ref) {
		return <Link ref={ref} to={useUrlWithCurrentWorkspace(href)} {...props} />;
	}
);


/** Override MUI Link and Button, injecting react-router <Link> for internal href handling. */
const routingThemePatch = {
	components: {
		MuiLink: {
			defaultProps: { component: WorkspaceRoutingLink }
		},
		MuiButtonBase: {
			defaultProps: { LinkComponent: WorkspaceRoutingLink }
		},
	}
};


/** Construct a theme object with our universal (non-brandable) overrides applied over the client's theme. */
export const buildTheme = (branding) => {
	const app = process.env.REACT_APP_GOOD_MEASURES_FOR;
	// Copy GL base theme before modifying
	let theme;
	switch (app) {
	case 'studio':
		theme = cloneDeep(goodLoopStudioTheme);
		break;
	default:
		theme = cloneDeep(goodLoopDefaultTheme);
		break;
	}

	// Patch base with brand theme if present
	if (branding?.theme) merge(theme, branding.theme);

	// Mix in router links
	merge(theme, routingThemePatch);

	// Add transparent colour variants
	Object.values(theme.palette).forEach((colourObj: PaletteColor) => {
		if (!colourObj?.main) return;
		// - in increments from 5% to 95% opacity
		for (let i = 5; i < 100; i += 5) {
			colourObj[`alpha${i}`] = alpha(colourObj.main, i / 100);
		}
	});

	return createTheme(theme);
};


/** Provides the current interface theme. */
function CustomThemeProvider({children}) {
	const workspaceBranding = useBranding();
	const theme = useMemo(() => buildTheme(workspaceBranding), [workspaceBranding]);

	return <ThemeProvider theme={theme}>
		{children}
	</ThemeProvider>;
}


export default CustomThemeProvider;