import { CircularProgress, Link, Tab, Tabs } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, PageContainer, PageSection } from '../common/LayoutComponents';
import { useAuthUser } from '../../state/apiHooks';
import { useWorkspace } from '../WorkspaceProvider';


function LinkTab(props) {
	return <Tab component={Link} {...props} />;
}


/** */
function ManageLayout() {
	const { data: user } = useAuthUser();
	const { pathname } = useLocation();
	const { workspace } = useWorkspace();

	const tabValue = pathname.match(/\/manage\/?([^/]*)/)?.[1];

	if (!workspace) {
		return (
			<PageContainer>
				<PageSection><CircularProgress /> Loading workspace...</PageSection>
			</PageContainer>
		);
	}

	const canManageWorkspace = user && (user.isGlobalAdmin || user.isWorkspaceAdmin);
	// Group admins can see this page, but they can't act on the workspace - only their own groups.
	const titleNoun = canManageWorkspace ? 'Workspace' : 'Group';


	return (
		<PageContainer>
			<Header rank={3}>{titleNoun} Management</Header>
			<Tabs value={tabValue} role="navigation">
				{canManageWorkspace && <LinkTab label="Workspace" href="/manage" value="" />}
				<LinkTab label="groups" href="/manage/groups" value="groups" />
				<LinkTab label="Users" href="/manage/users" value="users" />
			</Tabs>
			<Outlet />
		</PageContainer>
	);
}

export default ManageLayout;
