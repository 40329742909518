const qrCodeTemplateXML = `<Row class="float" t="0" l="3.5" r="3.5" h="12.5">
  <div class="headline flex" font-size="3.5" size="grow">\${{text.headline}}</div>
  <Row class="logos" gap="1.5">
    <BrandLogo />
    <Divider h="50%" />
    <CharityLogo />
  </Row>
</Row>
<Video class="float" b="3" l="3" w="70" h="40" />
<Column class="qr-box container float" font-size="2" b="3" r="3" w="20" h="40" p="1.5">
  <div class="donation">
    <div class="amount" font-size="200%">\${{text.donationAmount}}</div>
    <div class="raised">\${{text.donationRaised}}</div>
  </div>
  <Column class="scan">
    <QRCode my="1" />
    <div class="learn-more" font-size="85%">\${{text.qrScan}}</div>
  </Column>
  <Divider w="100%" />
  <GLLockupHorizontal class="gl-logo" w="70%" />
</Column>`;

export default qrCodeTemplateXML;