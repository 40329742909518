import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import { SelectGroup } from '../inputFields/SelectFromAPI';
import entity from '../../model/entity';
import DialogButton from '../widgets/DialogButton';
import PermissionWrapper from './PermissionWrapper';
import EntityDialog from './EntityDialog';


const validatePublisher = (authUser, publisher) => {
	return publisher.name && publisher.groupId && publisher.hostname;
};


/**
 *
 * @param {Object} params
 * @returns {import('react').ReactElement}
 */
function PublisherFormFields() {
	return (
		<Grid container spacing={2}>
			<Grid xs={12} sm={6}>
				<TextInputField label="Site Name" required fullWidth path="name" />
			</Grid>
			<Grid xs={12} sm={6}>
				<TextInputField label="Site Domain" required fullWidth path="hostname" />
			</Grid>
			<Grid xs={12} sm={6} spacing={2}>
				<SelectGroup label="Workspace" required fullWidth
					path="groupId" query={{parent: null}}
				/>
			</Grid>
		</Grid>
	);
};


function PublisherDialog(props) {
	return <EntityDialog type={entity.PUBLISHER} validateFn={validatePublisher} {...props}>
		<PublisherFormFields />
	</EntityDialog>;
}

function EditPublisherButton({label = 'Edit Publisher', ...props}) {
	return (
		<PermissionWrapper type={entity.PUBLISHER} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={PublisherDialog} {...props} />
		</PermissionWrapper>
	);
}


export default PublisherDialog;
export { PublisherFormFields, EditPublisherButton };
