import { useMemo } from 'react';

import { PageContainer, PageSection } from '../components/common/LayoutComponents';
import AdCampaignListItem from '../components/lists/AdCampaignListItem';
import { EditAdCampaignButton } from '../components/edit/AdCampaignDialog';
import GoodFormatsBreadcrumbs from './Formats/GoodFormatsBreadcrumbs';
import EntityList from './Formats/EntityList';


/** Page for listing & managing all Good Formats campaigns. */
function ManageCampaigns() {
	const actionButtons = useMemo(() => {
		return <EditAdCampaignButton label="New Campaign" />;
	}, []);

	return <>
		<GoodFormatsBreadcrumbs lastLabel="Campaigns" />
		<PageContainer>
			<PageSection>
				<EntityList title="All Campaigns" type="campaign" ItemComponent={AdCampaignListItem} actionButtons={actionButtons} />
			</PageSection>
		</PageContainer>
	</>;
}


export default ManageCampaigns;
