const counterCSS = `.video-container {
  border-radius: 2.5vw;
	margin: auto;
}

.logos .logo {
  height: 60%;
  max-width: 12vw;
}`;

export default counterCSS;