import { useParams } from 'react-router';

import { Box, CircularProgress, Container, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { MediaEmissions } from './MediaEmissions';
import { CO2e, CO2ePM, ContentWrapper } from '../Metrics/MetricComponents';
import { useState } from 'react';
import { BarPlot, ChartsGrid, ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot, MarkPlot, ResponsiveChartContainer } from '@mui/x-charts';
import MetricsFilterProvider from '../Metrics/providers/MetricsFilterProvider';
import { PublisherDataProvider } from '../Metrics/providers/MetricsDataProvider';
// import { MetricsTabs } from '../Metrics/Metrics';
import { Header, PageContainer } from '../../components/common/LayoutComponents';



export default function GoodMedia() {
	const pageData = [MediaEmissions()/*, MediaAccessibility(allData)*/];
	const { tab = pageData[0].name } = useParams();
	const activeTab = pageData.find(({href}) => href === `/media/${tab}`)?.content || <h1>404 - Tab doesn't exist!</h1>;

	return (
		<MetricsFilterProvider>
			<PublisherDataProvider>
				<PageContainer>
					<Header rank={3}>Responsible Reach</Header>
					{/* <MetricsTabs pageData={pageData} /> */}
					{activeTab}
				</PageContainer>
			</PublisherDataProvider>
		</MetricsFilterProvider>

	);
}


export function MediaTimeline({ chartData, chartTitle = 'Your publisher GHG score over time', lockedState = false }) {
	const theme = useTheme();
	const [chartSetting, setChartSetting] = useState(lockedState ? lockedState : 'total');

	if (!chartData || chartData.totalEmissions === undefined|| chartData.totalCO2EPM === undefined) {
		return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<CircularProgress />
		</Box>;
	};

	// copied from current metrics emissionsOverTime response

	const dayMonthFormat = new Intl.DateTimeFormat(undefined, { month: 'numeric', day: 'numeric' });

	const ddMMFormatter = (date) => {
		const dayMonth = dayMonthFormat.format(date);
		return dayMonth;
	};

	const sum = (arr) => arr ? arr.reduce((total, cur) => total + cur, 0) : 0;
	const toTonnes = (e) => `${Math.round(e / 10) / 100} tonnes`;

	let isTotal = chartSetting === 'total';

	let yAvg = (isTotal ? sum(chartData.totalEmissions): sum(chartData.totalCO2EPM)) / chartData.dates.length;
	let minPM = Math.min(...chartData.totalCO2EPM);
	let maxPM = Math.max(...chartData.totalCO2EPM);
	let yAxisConfig = isTotal ? { beginAtZero: true } : { min: minPM * 0.95, max: maxPM * 1.05 };

	let chart = (
		<Container sx={{ height: { xs: '100vw', sm: '600px' }, width: '100%', m: '0', mb: '10%', p: '0 !important', maxWidth: 'unset !important' }}>
			{!lockedState && <>
				<FormControl>
					<RadioGroup value={chartSetting} onChange={(e) => setChartSetting(e.target.value)} row>
						<FormControlLabel control={<Radio />} value="total" label={<>Total <CO2e /></>} />
						<FormControlLabel control={<Radio />} value="pm" label={<CO2ePM />} />
					</RadioGroup>
				</FormControl>
				<Divider/>
			</>
			}
			<Grid container sx={{height: ['-webkit-fill-available', 'inherit']}}>
				<Grid item xs={0.25}>
					<Typography type="body1" height="100%" alignContent="center" whiteSpace="nowrap">
						<span style={{display: 'block', transform: 'rotate(-90deg) translate(-2rem, 50%)'}}>
							{isTotal ? <>Tonnes <CO2e /></> : <>kg <CO2ePM /></>}
						</span>
					</Typography>
				</Grid>
				<Grid item xs={11.75}>
					<ResponsiveChartContainer
						xAxis={[
							{
								label: 'Date',
								data: chartData.dates,
								scaleType: 'band',
								sx: { fontFamily: 'ABCSocial' },
								valueFormatter: ddMMFormatter, // date month {year?}
							}
						]}

						yAxis={[
							{
								valueFormatter: val => `${isTotal ? val / 1000 : val}`,
								beginAtZero: true,
								id: '0',
								...yAxisConfig
							},
							// Impressions yAxis - hacky solution! To avoid messing around with axis width, just stack it and set total amount shown to a range of 0 -> 0
							{

								id: '1',
								max: 0,
								min: 0,
								...yAxisConfig
							}
						]}

						series={
							(isTotal ?
								[
									{ data: chartData.totalEmissions, stack: 'A', label: 'Total Emissions', type: 'bar', color: theme.palette.success.main, valueFormatter: toTonnes},
									// see above impression axis hacky solution
									{ data: chartData.impressions, stack: 'A', yAxisKey: '1', label: 'Impressions', type: 'bar', color: 'white'}
								] : [
									{ data: chartData.totalCO2EPM, label: 'total CO2EPM', type: 'line', color: 'black', valueFormatter: (e) => <>{Math.round(e * 1000) / 1000} kg <CO2ePM /></>},
									{ data: chartData.impressions, yAxisKey: '1', label: 'Impressions', type: 'line', color: 'white'}
								]
							)
						}
						margin={{ left: 50 }}
					>
						<BarPlot />
						{(yAvg != null) && <ChartsReferenceLine y={yAvg} label="Your average" labelAlign="end" lineStyle={{ stroke: theme.palette.primary.main, strokeWidth: 2 }} labelStyle={{ fontWeight: 'bold', fill: theme.palette.primary.main, backgroundColor: 'blue' }} />}
						<LinePlot />
						<MarkPlot />
						<ChartsTooltip />
						<ChartsGrid horizontal />
						<ChartsXAxis disableLine disableTicks label="" />
						<ChartsYAxis min={0.5} beginAtZero disableLine disableTicks labelStyle={{ transform: 'translateX(-10px) rotate(-90deg)', transformOrigin: '-26px 300px', }} />
					</ResponsiveChartContainer>
				</Grid>
			</Grid>
		</Container>
	);

	return (
		<ContentWrapper title={chartTitle}>
			{chart}
		</ContentWrapper>
	);
}