import { OutlinedInput, InputAdornment } from '@mui/material';
import InputWrapper, { setWithEvent, usePathValue } from './InputWrapper';
import { useCallback, useRef } from 'react';

import { useFormContext } from '../FormContextProvider';
import UploadButton from './UploadButton';


const previewSx = {
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	height: '100%',
	maxHeight: 'unset',
	aspectRatio: '1/1',
	cursor: 'default',
	ml: -1
};


/**
 * Show a preview of the uploaded item, if applicable, in a thumbnail attached to the input. Currently only images.
 * @param {object} props
 * @param {string} props.type See matching prop for {@link UploadField}.
 * @param {string} [props.path] If present: the path to retrieve the current field value from FormContext
 * @param {string} [props.value] If present: the explicit (i.e. managed from calling component with useState etc) value of the field.
 */
function UploadPreview({type, path, value}) {
	const { draft } = useFormContext();
	const draftValue = usePathValue(draft, path);
	if (path) value = draftValue;

	if (type === 'image') {
		return <InputAdornment position="start" sx={[{backgroundImage: `url(${value})`}, previewSx]} />;
	}
}


/** @typedef {import('@mui/material').InputProps} InputProps */

/**
 * @typedef {object} UploadFieldProps
 * @member {string} type e.g. "video", "image"
 * @member {object} [uploadParams] Will be appended as form fields alongside the file upload
 */


/**
 * Upload a file to a predefined endpoint on the store server.
 * @param {UploadFieldProps & InputProps} props
 */
function UploadField({type, uploadParams, startAdornment, appendGroup, ...props}) {
	const inputRef = useRef();

	// Insert the uploaded file URL in the text field on success.
	const onComplete = useCallback(mediaEntity => {
		setWithEvent(inputRef.current, mediaEntity.publicUrl);
	}, [inputRef]);

	// Attach "Upload" button to URL field
	const uploadButton = <UploadButton type={type} onComplete={onComplete} uploadParams={uploadParams} />;
	appendGroup = appendGroup ? <>{uploadButton}{appendGroup}</> : uploadButton;

	// Show image preview thumbnail, if applicable
	const preview = <UploadPreview type={type} {...props} />;
	startAdornment = startAdornment ? <>{preview}{startAdornment}</> : preview;

	return <InputWrapper startAdornment={startAdornment} inputRef={inputRef} Input={OutlinedInput} type="url" appendGroup={appendGroup} {...props} />;
}

export default UploadField;
