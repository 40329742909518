import { useMemo, useCallback, useState, useEffect, Fragment } from 'react';
import { noop, debounce, get} from 'lodash';
import { set as cloneAndSet } from 'lodash/fp';

import { ChangeCircleOutlined, HelpOutline, Lock } from '@mui/icons-material';
import { Box, ButtonGroup, FormControl, FormHelperText, Tooltip, Typography } from '@mui/material';

import useSearchParam from '../../state/useSearchParam';
import { useFormContext } from '../FormContextProvider';
import useLiveRef from '../../utilities/useLiveRef';
import { disableNthChildWarning } from '../../utilities/utils';


/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('react').ReactElement} ReactElement */
/** @typedef {import('react').Component} Component */
/** @typedef {import('react').ComponentProps<any>} ComponentProps */
/** @typedef {import('@mui/material').SxProps} SxProps */

/** The universal "get event.target.value" function */
const targetVal = e => e.target.value;


/** "Set value" function extracted from the HTMLInputElement prototype. */
const inputValueSetFn = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;


/** Value setter which fires a synthetic event to trigger onChange etc on the target input. */
const setWithEvent = (target, value) => {
	inputValueSetFn.call(target, value);
	target.dispatchEvent(new Event('input', { bubbles: true }));
};


/**
 * 
 * @param {object} props
 * @param {import('@mui/material').SxProps} [props.sx]
 * @param {any} [props.children]
 * @returns
 */
function InputLabel({sx, ...props}) {
	sx = useMemo(() => ({display: 'flex', alignItems: 'center', mb: 0.5, ...sx}), [sx]);
	return (
		<Typography fontWeight="bold" component="label" sx={sx} {...props} />
	);
}

const tooltipSx = {ml: 0.5, fontSize: 'large'};

const flagSx = {...tooltipSx, alignSelf: 'flex-end'};


function Info({info}) {
	return info ? (
		<Tooltip title={info} size="large" arrow sx={tooltipSx}>
			<HelpOutline fontSize="small" />
		</Tooltip>
	) : null;
}

function Changed({changed}) {
	return changed ? (
		<Tooltip title={`Changed from ${changed.from}`} size="large" arrow sx={flagSx}>
			<ChangeCircleOutlined fontSize="small" />
		</Tooltip>
	) : null;
}

function Locked({locked}) {
	return locked ? (
		<Tooltip title="Locked" size="large" arrow sx={flagSx}>
			<Lock fontSize="small" />
		</Tooltip>
	) : null;
}


const groupSx = {
	[disableNthChildWarning('& > :not(:first-child)')]: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0
	},
	[disableNthChildWarning('& > :not(:last-child)')]: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		marginRight: '-1px'
	},
};

const companionSx = {
	display: 'flex',
	alignItems: 'stretch',
	[disableNthChildWarning('& > :not(:last-child)')]: {
		marginRight: 1
	},
};

/**
 * @typedef {import('react').ComponentProps<any> & object} CompanionBoxProps
 * @property {boolean} [group] If true: Remove margins & internal border-radius between items.
 */

/**
 * Collects inputs & companion buttons together & matches heights between inputs and buttons.
 * @param {CompanionBoxProps} props
 */
function CompanionBox(props) {
	return <Box sx={companionSx} {...props} />;
}


function GroupBox(props) {
	return <ButtonGroup sx={groupSx} {...props} />;
}


/**
 * Covers lots of boilerplate for rendering & connecting input elements.
 * @param {object} p
 * @param {ReactNode} [p.label] The input field label. Defaults to the [name] attribute supplied to the input.
 * @param {ReactNode} [p.info] Explanatory text which will appear in a tooltip on an (i) button.
 * @param {string} [p.name] HTML input "name" attribute
 * @param {SxProps} [p.sx] Additional inline styling to be applied to the outer <FormControl>.
 * @param {Function} [p.getValueFn] Function which extracts the new input value from the change event. Defaults to event.target.value.
 * @param {Component} p.Input Should render a HTMLInputElement or something with a similar interface.
 * @param {Function} [p.onChange] Will be called with the raw change event fired by the inner input element.
 * @param {Function} [p.onChangeValue] Will be called with the value extracted from the onChange event.
 * @param {Function} [p.onChangeKV] Will be called with a key-value object {[inputName]: newValue} when the inner input value changes.
 * @param {ReactNode} [p.prepend] Element or elements to place before the input.
 * @param {ReactNode} [p.append] Element or elements to place after the input.
 * @param {boolean} [p.group] If set, internal margins and borderRadius between input and prepend/append will be removed.
 * @param {{from: object}} [p.changed] If present, the "value changed" marker will be shown next to the input.
 * @param {boolean} [p.locked] True if the input's value is preset and cannot be changed - will mark input with a lock icon.
 * @returns {ReactElement}
 */
function InputWrapperCore({
	info, label, sx, Input,
	getValueFn = targetVal, onChange = noop, onChangeValue = noop, onChangeKV = noop,
	prepend, append, prependGroup, appendGroup, changed, locked,
	...props}) {
	// ID for pairing label to input - generate & remember random string if name is omitted
	const fieldId = useMemo(() => {
		const identifier = props.name || Math.floor(Math.random() * 0xf00000).toString(16);
		return `input-field-${identifier}`;
	}, [props.name]);

	// Convenience: Destructure the onChange event and call onChangeValue and onChangeKV
	const _onChange = useCallback((event, ...args) => {
		onChange(event, ...args); // Raw event
		onChangeValue(getValueFn(event)); // Extracted value
		onChangeKV({[props.name]: getValueFn(event)}); // Key-value object like { [props.name]: event.target.value }
	}, [onChange, onChangeValue, onChangeKV, props.name, getValueFn]);

	const CompanionComponent = (prepend || append) ? CompanionBox : Fragment;
	const GroupComponent = (prependGroup || appendGroup) ? GroupBox : Fragment;

	return <FormControl fullWidth={props.fullWidth}>
		{label && (
			<InputLabel htmlFor={fieldId}>
				{label}
				<Info info={info} />
				<Changed changed={changed} />
				<Locked locked={locked} />
			</InputLabel>
		)}
		<CompanionComponent>
			{prepend}
			<GroupComponent>
				{prependGroup}
				<Input onChange={_onChange} id={fieldId} sx={sx} {...props} />
				{appendGroup}
			</GroupComponent>
			{append}
		</CompanionComponent>
		{props.required && <FormHelperText>Required</FormHelperText>}
	</FormControl>;
};


function ParamInputWrapper({searchParam, onChange = noop, ...props}) {
	const [paramValue, setParam] = useSearchParam(searchParam);
	const { getValueFn = targetVal } = props;

	props.onChange = useCallback((...args) => {
		onChange(...args);
		setParam(getValueFn(...args));
	}, [onChange, setParam, getValueFn]);

	return <InputWrapperCore value={paramValue} {...props} />;
}


/** Wraps {@link get} with special-case handling for "." as the identity path. */
function getPath(obj, path) {
	if (path === '.') return obj;
	return get(obj, path);
}


/** Wraps {@link cloneAndSet} (aka "set" from @lodash/fp) with special-case handling for "." as the identity path. */
function buildPathSetter(rootSetter, path) {
	return value => rootSetter(prevRoot => {
		if (path === '.') return value; // Identity path = overwrite = no clone/merge
		return cloneAndSet(path, value, prevRoot);
	});
}


/** Memoized {@link getPath}. NB Relies on {@link obj} and {@link path} breaking identity on change - mutations won't trigger an update. */
function usePathValue(obj, path) {
	return useMemo(() => {
		if (path === '.') return obj;
		return get(obj, path);
	}, [obj, path]);
}


/**
 * Returns a function which wraps the provided setter & when invoked sets the sub-object at the specified path.
 * @param {import('../FormContextProvider').StateSetter<any>} setter A setter function which accepts an "update by mutator function" argument.
 * @param {string} path A path in a format accepted by Lodash's {@link set}.
 */
function usePathSetter(setter, path) {
	return useMemo(() => buildPathSetter(setter, path), [setter, path]);
}


/** Expects to render inside an EntityContext. */
function PathInputWrapper({path, onChangeValue, ...props}) {
	const {base, initial, draft, setDraft, saved, saving, setProcessingInput, debounceDuration } = useFormContext();

	// Extract deep values from base, initial, draft
	const baseValue = usePathValue(base, path);
	const initialValue = usePathValue(initial, path);
	const draftValue = usePathValue(draft, path);

	// Store immediately-updating local value
	const [localValue, setLocalValue] = useState(draftValue);
	// Draft value should supersede local if changed elsewhere
	useEffect(() => setLocalValue(draftValue), [draftValue]);

	// Update draft object on short debounce
	const setDraftPath = usePathSetter(setDraft, path);
	const updateDraftDebounced = useMemo(() => {
		const updateRaw = (value => {
			setDraftPath(value);
			setProcessingInput(false);
		});
		return debounce(updateRaw, debounceDuration);
	}, [setDraftPath, setProcessingInput, debounceDuration]);

	// Commit changes immediately when focus changes.
	props.onBlur = updateDraftDebounced.flush;

	// On input, immediately update local state & request update to draft object
	const onChangeRef = useLiveRef(onChangeValue); // guard against inline-declared callback
	props.onChangeValue = useCallback(val => {
		setLocalValue(val);
		updateDraftDebounced(val);
		setProcessingInput(true);
		onChangeRef.current?.(val);
	}, [onChangeRef, setLocalValue, setProcessingInput, updateDraftDebounced]);

	// Normalise undefined to empty & stop "Changing uncontrolled to controlled" warnings
	props.value = localValue ?? '';
	// Flag for "value locked" marker
	props.locked = baseValue !== undefined;
	// Info for "value changed" marker: previous value & revert function
	props.changed = useMemo(() => {
		if (!saved || draftValue === initialValue) return undefined;
		return { from: initialValue, revert: () => setDraftPath(initialValue)};
	}, [saved, draftValue, setDraftPath, initialValue]);
	// Disable input if the entity is currently saving, or the target field is locked by the base object.
	props.disabled ||= (saving || props.locked);

	return <InputWrapperCore {...props} />;
}


function InputWrapper(props) {
	if (props.searchParam) return <ParamInputWrapper {...props} />;
	if (props.path) return <PathInputWrapper {...props} />;
	return <InputWrapperCore {...props} />;
}


export default InputWrapper;
export {
	targetVal,
	inputValueSetFn,
	setWithEvent,
	InputLabel,
	getPath, buildPathSetter as cloneWithSetPath,
	usePathValue, usePathSetter
};
