import { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import PermissionSelect, { PermissionDesc } from '../inputFields/PermissionSelect';
import { permissionLevels } from '../../model/permissionLevels';
import entity, { entityDisplayName } from '../../model/entity';
import { useAddApiKey, useGroup } from '../../state/apiHooks';
import DialogButton from '../widgets/DialogButton';
import { useWorkspace } from '../WorkspaceProvider';
import FormContextProvider, { useFormContext } from '../FormContextProvider';
import EditDialog from './EditDialog';


/** Basic validation of the API Key input form */
const validateApiKey = (authUser, draft) => {
	// API key accounts must have a key, permission level, and owning group.
	return draft.loginId && draft.level && draft.groupId;
};


/**
 * The inner form for an API Key editor.
 */
function ApiKeyFormFields() {
	const { draft } = useFormContext();
	const { level } = draft;

	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<TextInputField label="Client Key" required path="loginId" />
			</Grid>
			<Grid xs={12} sm={3}>
				<PermissionSelect label="Permission Level" required
					path="level"
					minLevel={permissionLevels.READ}
					maxLevel={permissionLevels.ADMIN}
				/>
			</Grid>
			<Grid xs={12} sm={9}>
				<PermissionDesc title="Capabilities" level={level} />
			</Grid>
		</Grid>
	);
}


/** Title bar for "add user to group" mode: fetch group and insert its display name */
function ApiKeyTitle() {
	const { base } = useFormContext();
	const { data: group } = useGroup(base.groupId);

	return <>Add API Key to {group?.name || entityDisplayName(entity.GROUP)}</>;
}


/** EntityDialog for creation of API-key users. */
function ApiKeyDialog({groupId, ...props}) {
	const { workspaceId } = useWorkspace();
	const addApiKey = useAddApiKey();

	// Construct base & initial objects
	const base = useMemo(() => ({ groupId, workspaceId }), [groupId, workspaceId]);
	const initial = useMemo(() => ({...base, level: permissionLevels.WRITE }), [base]);

	return (
		<FormContextProvider validateFn={validateApiKey}
			title={<ApiKeyTitle />}
			base={base} initial={initial} submitFn={addApiKey}
			submitLabel="Add API Key"
			{...props}
		>
			<EditDialog {...props}>
				<ApiKeyFormFields />
			</EditDialog>
		</FormContextProvider>
	);
}

function AddApiKeyButton({label = 'Add API Key', ...props}) {
	return <DialogButton label={label} Dialog={ApiKeyDialog} {...props} />;
}


export default ApiKeyDialog;
export { ApiKeyFormFields, AddApiKeyButton };
