import { type SetStateAction, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import useLiveRef from '../utilities/useLiveRef';


/** A plain object generated from the key-value pairs of a {@link URLSearchParams}. */
interface SearchParamsObject {
	[key: string]: string;
}

/** Get a freshly-parsed {@link SearchParamsObject} from the current URL. */
function getParamsObject(): SearchParamsObject {
	return Object.fromEntries(new URLSearchParams(window.location.search));
};


/**
 * Wrap {@link useSearchParams} from react-router-dom to fix some friction points:
 * - Value returned as object instead of a URLSearchParams
 * - Setter function has stable identity
 * - Set action which wouldn't change params is a no-op & doesn't trigger updates
 * */
function useSearchParamsObject(): [SearchParamsObject, (next: SetStateAction<SearchParamsObject>) => void] {
	// Delegate actual setting of search params to react-router-dom
	const [, setSearchParams] = useSearchParams();

	// Let hook trigger re-renders, but rebuild params object only when window.location.search changes.
	const paramsObject = useMemo(getParamsObject, [window.location.search]);

	// Bundle unstable react-router-dom setter in a ref so our setter doesn't constantly rebuild
	const setterRef = useLiveRef(setSearchParams);

	// Stable-valued setter resolves current setSearchParams function when called
	const setParamsObject = useCallback((nextParams: SetStateAction<SearchParamsObject>) => {
		// Get fresh params: chained setParam(s) calls can execute before outer hook gets another render loop.
		const prevParams = getParamsObject();
		if (typeof nextParams === 'function') nextParams = nextParams(prevParams); // Resolve set-by-function
		if (isEqual(prevParams, nextParams)) return; // Ignore no-op
		setterRef.current(nextParams); // Do it
	}, [setterRef]);

	return [paramsObject, setParamsObject];
}



export default useSearchParamsObject;
export { SearchParamsObject };

