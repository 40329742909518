import { useMemo } from 'react';

import { PageContainer, PageSection } from '../components/common/LayoutComponents';
import BrandListItem from '../components/lists/BrandListItem';
import { useWorkspace } from '../components/WorkspaceProvider';
import { EditBrandButton } from '../components/edit/BrandDialog';
import GoodFormatsBreadcrumbs from './Formats/GoodFormatsBreadcrumbs';
import EntityList from './Formats/EntityList';


/** Page for listing & managing all Good Formats brands. */
function ManageBrands() {
	// Create new brands as children of current workspace
	const { workspaceId } = useWorkspace();

	const actionButtons = useMemo(() => {
		return <EditBrandButton label="New Brand" base={{ parentId: workspaceId }} />;
	}, [workspaceId]);

	return <>
		<GoodFormatsBreadcrumbs lastLabel="Brands" />
		<PageContainer>
			<PageSection>
				<EntityList title="All Brands" type="group" ItemComponent={BrandListItem} actionButtons={actionButtons} />
			</PageSection>
		</PageContainer>
	</>;
}

export default ManageBrands;
