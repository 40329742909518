import { useCallback } from 'react';
import { CircularProgress } from '@mui/material';


import PermissionSelect from '../inputFields/PermissionSelect';
import { permissionLevels } from '../../model/permissionLevels';
import { useGlobalPermission, useSetGlobalPermission } from '../../state/apiHooks';


function EditGlobalPermissionDropdown({userId}) {
	const { data: permissionObj = {}, isPending } = useGlobalPermission(userId);
	const setPermission = useSetGlobalPermission();

	const { level } = permissionObj;

	const onChange = useCallback(event => {
		setPermission({userId, level: event.target.value});
	}, [userId, setPermission]);

	if (isPending) return <CircularProgress />;

	return <PermissionSelect size="small" maxLevel={permissionLevels.ADMIN} onChange={onChange} value={level} />;
}


export { EditGlobalPermissionDropdown };
