import { useCallback } from 'react';

import FileField from './inputFields/FileField';
import entity from '../model/entity';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { SelectGroup } from './inputFields/SelectFromAPI';
import DialogButton from './widgets/DialogButton';
import PermissionWrapper from './edit/PermissionWrapper';
import { SelectOrNewCampaign } from './edit/TagDialog';
import { useS3Upload } from '../state/apiHooks';
import EditDialog from './edit/EditDialog';
import FormContextProvider from './FormContextProvider';


const validateFile = (authUser, item) => {
	return item && item.campaignId; // && item.file
};


/**
 * The inner form for an "upload impressions CSV" dialog.
 * @param {FormFieldsProps} props
 * @returns {import('react').ReactElement}
 */
function CSVUploadFormFields() {
	// On file pick, extract handle to first file
	const getFileValue = useCallback(event => event.target.files[0], []);

	return <Grid container spacing={2}>
		<Grid xs={6}>
			<SelectOrNewCampaign />
		</Grid>
		<Grid xs={6}>
			<SelectGroup label="Group" fullWidth path="groupId" />
		</Grid>
		<Grid>
			<FileField label="Upload File" required fullWidth path="file" getValueFn={getFileValue} />
		</Grid>
	</Grid>;
}


/**
 *
 * @param {object} props
 * @returns {import('react').ReactElement}
 */
function CSVUploadDialog(props) {
	const submitUpload = useS3Upload();
	return <FormContextProvider validateFn={validateFile} submitFn={submitUpload}>
		<EditDialog type={entity.FILE_UPLOAD} {...props}>
			<CSVUploadFormFields />
		</EditDialog>
	</FormContextProvider>;
}


function CSVUploadButton({label = 'Upload File', ...props}) {
	return (
		<PermissionWrapper type={entity.FILE_UPLOAD} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={CSVUploadDialog} {...props} />
		</PermissionWrapper>
	);
}


export default CSVUploadDialog;
export { CSVUploadFormFields, CSVUploadButton};
