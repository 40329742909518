import { useState } from 'react';
import { PinkHeader, ContentPaper, ContentContainer, ReadMoreToggle, ReadMoreDetails } from './MetricComponents';
import { Typography, Grid, Button, TableContainer, Table, TableHead, TableRow, TableBody, styled, TableCell, tableCellClasses, Link  } from '@mui/material';
import DialogWrapper from '../../components/widgets/Dialog';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import { WelcomeIcon } from '../../components/common/Icons';
/***
 * Return metadata about tab & content for the CalcExplanation breakdown tab
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function CalcExplanation() {
	return {
		name: 'calculations',
		title: 'Your calculations explained',
		icon: null,
		href: '/metrics/understanding/calculations',
		content: <CalcExplanationContent />,
	};
};


/**
 * @returns {React.Element} CalcExplanation Tab content
 */
function CalcExplanationContent() {
	return <>
		<CalcHeader />
		<PageSection>
			<Header rank={4} mb={4}>Our methodology</Header>
			<Header rank={5} mb={4}>Learn more about our methodology at <Link target="_blank"  href="https://good-loop.com/good-measures-methodology">good-loop.com/good-measures-methodology</Link></Header>
		</PageSection>
		{/* <PageSection>
			<Header mb={4}>Your distribution GHG</Header>
			<FilterDesc />
			<CalcCampaigns />
		</PageSection> */}
	</>;
}


function CalcHeader() {
	const [headerExpanded, setHeaderExpanded] = useState(false);

	return (
						<PinkHeader logo={WelcomeIcon} logoAlt="Eye Icon" title="Calculations Explained">
								Our methodology uses the GMSF framework as its baseline to assess your <strong>distribution</strong> and <strong>consumption</strong> emissions.
							<ReadMoreToggle />
    					<ReadMoreDetails>
								<Header rank={5}>
									Distribution emissions are the greenhouse gas emissions produced by the energy used during media selection and ad unit delivery.
									<br />
									<br />
									Consumption emissions are the greenhouse gas emissions attributed to the user devices when an ad creative is viewed
								</Header>
	          	</ReadMoreDetails>
						</PinkHeader>
			);
}

function CalcCampaigns() {

	const [open, setOpen] = useState(undefined);

	const campaignData = [
		{name: 'Campaign 1', id: 1, variables: {device: ['Smartphone', 'PC'], channel: ['programmatic'], consumerLocation: ['New Zealand']}},
		{name: 'Campaign 2', id: 2, variables: {device: ['Desktop'], consumerLocation: ['Australia']}},
	];

	const campaignButtons = campaignData.map((campaign) => {
		const {name, id, variables} = campaign;

		return (
			<Grid item xs={12}>
				<ContentContainer>
					<Button sx={{width: '100%', height: '100%', py: 4, justifyContent: 'left'}} key={id} onClick={() => setOpen(campaign)}>
						<Typography pl={4} variant="body1" textTransform="none" fontStyle="italic" color="#888">{name}</Typography>
					</Button>
				</ContentContainer>
			</Grid>
		);
	});


	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  fontWeight: 'bold',
		  fontSize: 'large',
		  border: '1px solid #888'
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 'large',
		  border: '1px solid #888',
		},
		'&.no-data': {
			backgroundColor: theme.palette.warning.main,
			fontWeight: 'bold',
		  },
	  }));

	const tableRows = (campaign) => {
		const variables = Object.keys(campaign.variables);
		return [
			{key: 'Device', value: variables.includes('device') ? campaign.variables.device.join(', ') : 'no data', info: 'Calc description'},
			{key: 'Smartphone to browser ratio', value: variables.includes('ratio') ? campaign.variables.ratio : 'no data', info: 'Calc description'},
			{key: 'Consumer location', value: variables.includes('consumerLocation') ? campaign.variables.consumerLocation.join(', ') : 'no data', info: 'Calc description'},
			{key: 'Channel', value: variables.includes('channel') ? campaign.variables.channel.join(', ') : 'no data', info: 'Calc description'},
			{key: 'Creative format', value: variables.includes('creativeFormat') ? campaign.variables.creativeFormat.join(', ') : 'no data', info: 'Calc description'},
			{key: 'Creative Weight', value: variables.includes('creativeWeight') ? campaign.variables.creativeWeight : 'no data', info: 'Calc description'},
			{key: 'View time', value: variables.includes('viewTime') ? campaign.variables.viewTime : 'no data', info: 'Calc description'},
		];
	};

	let campaingDialog = (
		<DialogWrapper name="campaign" open={open !== undefined} onClose={() => setOpen(undefined)} title={open && `${open.name} emissions profile`}>
			{open && <TableContainer>
				<Table sx={{my: 6}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Campaign Variable</StyledTableCell>
							<StyledTableCell>Data Input</StyledTableCell>
							<StyledTableCell>Information</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{open && tableRows(open).map((row) => (
							<TableRow key={row.key}>
								<StyledTableCell>
									{row.key}
								</StyledTableCell>
								<StyledTableCell className={row.value == 'no data' ? 'no-data' : ''}>{row.value}</StyledTableCell>
								<StyledTableCell className={row.value == 'no data' ? 'no-data' : ''}>{row.info}</StyledTableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</TableContainer>}
		</DialogWrapper>
	);

	return (
		<ContentPaper title="Select a campaign to discover how we calculated its emissions and to review our assumptions">
			<h3 style={{marginTop: 0, color: '#666'}}>Select a campaign to discover how we calculated its emissions and to review our assumptions</h3>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<Grid container direction="column" spacing={2}>
						{campaignButtons}
						{campaingDialog}
					</Grid>
				</Grid>
			</Grid>
		</ContentPaper>
	);
}


export default CalcExplanation;
