import { useMemo } from 'react';
import { Typography, Grid, TableHead, TableRow, TableBody, TableCell, List, ListItem, Link, useTheme } from '@mui/material';
import {ContentWrapper, ContentContainer, ContentPaper, CircleIcon } from '../Metrics/MetricComponents';
import { metricGramsWeightString } from '../../utilities/utils';
import { BreakdownPieChart } from '../Metrics/Breakdown';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import PublisherScoreTable from '../../components/common/PublisherScoreTable';
import { useGMPData } from '../Metrics/providers/MetricsDataProvider';
import { SelectPublisher } from '../../components/inputFields/SelectFromAPI';
import useSearchParam from '../../state/useSearchParam';
import { useGroup, usePublisher } from '../../state/apiHooks';
import { GMPEmissionsIcon } from '../../components/common/Icons';
import { ReactComponent as EyeIcon } from '../../img/icons-original/Eye-Icon-Black.svg';


export function Emissions() {
	return {
		name: 'emissions',
		icon: null,
		href: '/gmp/emissions',
		content: <EmissionsContent/>,
		title: 'Emissions'
	};
};

function EmissionsContent() {
	const [groupId, setgroupId] = useSearchParam('group');
	const { data: group } = useGroup(groupId);
	const [publisherId, setPublisherId] = useSearchParam('publisherId');
	const { data: publisher } = usePublisher(publisherId);

	const publisherQuery = useMemo(() => {
		if (groupId) return { group: groupId };
		return undefined;
	}, [groupId]);

	const query = useMemo(() => {
		return publisherId ? {publisher: publisherId} : null;
	}, [publisherId]);

	let site = 'No Publisher Selected';
	let score = 0.0;
	let contentEmissions = 0.0;
	let distributionEmissions = 0.0;
	let consumptionEmissions = 0.0;
	let scoreBoundaries = [0, 0.01, 0.02, 0.03];
	let lastScored = '';
	let version = '';

	const { publisherScore, publisherPercentiles } = useGMPData();
	if (publisherScore?.data) {
		site = publisherScore.data['site'];
		score = publisherScore.data['totalEmissionsKgCO2e'];
		contentEmissions = publisherScore.data['publisherContentEmissionsKgCo2e'];
		distributionEmissions = publisherScore.data['distributionEmissionsKgCO2e'];
		consumptionEmissions = publisherScore.data['consumptionEmissionsKgCO2e'];
		lastScored = publisherScore.data['lastScored'];
		version = publisherScore.data['version'];
	}

	if (publisherPercentiles?.data) {
		scoreBoundaries = [
			publisherPercentiles.data['25thPercentile'],
			publisherPercentiles.data['50thPercentile'],
			publisherPercentiles.data['75thPercentile'],
			publisherPercentiles.data['100thPercentile']
		];
	}

	return (
		<PageSection>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Header rank={4} sx={{mb: 4}}>Greenhouse gas (GHG) emissions report</Header>
						<URLScored publisherQuery={publisherQuery} publisherId={publisherId} setPublisherId={setPublisherId} site={site}/>
					</Grid>
					<Grid item xs={12}>
						<Header rank={5} sx={{mb: 2, mt: 4}}>Your Good-Loop GHG emission score</Header>
						<GlEmissionsScore co2ePM={score*1000} />
					</Grid>
					<Grid item xs={12}>
						<Header rank={5} sx={{mb: 2, mt: 4}}>How does your score compare?</Header>
						<ScoreComparison score={score} scoreBoundaries={scoreBoundaries} />
					</Grid>
					<Grid item xs={12}>
						<Header rank={5} sx={{mb: 2, mt: 4}}>How is your score calculated?</Header>
						<ScoreExplained />
					</Grid>
					<Grid item xs={12}>
						<Header rank={5} sx={{mb: 2, mt: 4}}>What's the breakdown of your score?</Header>
						<ScoreBreakdown contentEmissions={contentEmissions} distributionEmissions={distributionEmissions} consumptionEmissions={consumptionEmissions}/>
					</Grid>
					<Grid item xs={12}>
						<Header rank={5} sx={{mb: 2, mt: 4}}>How can you improve your score?</Header>
						<ScoreImprovements />
					</Grid>
					<Grid item xs={12}>
						<ScoreDetails scoreDate={lastScored} scorerVersion={version} site={site} />
					</Grid>
				</Grid>
			</Grid>
		</PageSection>
	);
}


function URLScored({ site, publisherQuery, publisherId, setPublisherId}){
	return (
		<ContentWrapper>
			<Grid spacing={2} container>
				<Grid item xs={12} sm={4.5}>
					<SelectPublisher label="Publishers" query={publisherQuery} name="publisherId" value={publisherId} onChangeValue={setPublisherId}/>
				</Grid>
				<Grid item xs={12} sm={7.5}>
					<Typography variant="body1" p={2} fontWeight="bold">Url scored: {site}</Typography>
				</Grid>
			</Grid>
		</ContentWrapper>
	);
}


function GlEmissionsScore({co2ePM}){
	return (
		<ContentPaper>
			<Grid spacing={2} container>
				<Grid item xs={12} sm={4.5}>
					<ContentContainer style={{height: '150px', alignContent: 'center'}}>
						<Grid container spacing={2} height="100%" pt="8px">
							<Grid item xs={0} sm={3} p="0 !important" alignContent="center" mx={2}>
								<GMPEmissionsIcon />
							</Grid>
							<Grid item xs={12} sm={8} p="0 !important" alignContent="center">
								<Header rank={4}>{metricGramsWeightString(co2ePM)}</Header>
								<Typography variant="body1" >CO2e per 1000 site visits visit</Typography>
							</Grid>
						</Grid>
					</ContentContainer>
				</Grid>
				<Grid item xs={12} sm={7.5}>
					<ContentContainer backgroundColor="primary.alpha5" style={{alignContent: 'center'}}>
						<Header rank={5}>
							That's {metricGramsWeightString(co2ePM * 1000) /*per 1000 -> per 1,000,000*/} per month
						</Header>
						<Typography variant="body1">(based on 1 million site visits per month)</Typography>
					</ContentContainer>
				</Grid>
			</Grid>
		</ContentPaper>
	);
}


function ScoreComparison({score, scoreBoundaries}){
	const tableRows = useMemo(() => {
		const scoredRowIndex = scoreBoundaries.findIndex((boundary, i) => score < boundary);
		return [
			{key: 'top25', value: 'Top 25%', selected: scoredRowIndex === 0},
			{key: 'top50', value: 'Top 50%', selected: scoredRowIndex === 1},
			{key: 'top75', value: 'Top 75%', selected: scoredRowIndex === 2},
			{key: 'top100', value: '100%',  selected: scoredRowIndex === 3},
		];
	}, [score, scoreBoundaries]);

	return (
		<ContentWrapper>
			<PublisherScoreTable sx={{my: 4, width: '90%', ml: '5%'}}>
				<TableHead>
					<TableRow>
						<TableCell className="pad-left">Emission Percentile</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{tableRows.map((row, i) => (
						<TableRow key={row.key} className={row.selected && 'selected'}>
							<TableCell className="pad-left">{row.value}</TableCell>
							<TableCell className="pad-left">{row.selected ? 'You are here' : ''}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PublisherScoreTable>
		</ContentWrapper>
	);
}

function ScoreBreakdown({contentEmissions, distributionEmissions, consumptionEmissions}) {
	const theme = useTheme();

	const chartData = [
		{title: 'Content', color: theme.palette.success.main, values: {content: contentEmissions*1000}},
		{title: 'Distribution', color: theme.palette.success.dark, values: {distribution: distributionEmissions*1000}},
		{title: 'Consumption', color: theme.palette.success.light, values: {consumption: consumptionEmissions*1000}},
	];
	return <BreakdownPieChart chartData={chartData}></BreakdownPieChart>;
};


function ScoreExplained() {
	return (
		<ContentContainer style={{height: 'fit-content'}} backgroundColor="primary.alpha5">
			<Grid container spacing={2} height="fit-content" py={4} px={2}>
				<Grid item xs={12} sm={1}>
					<CircleIcon icon={EyeIcon}/>
				</Grid>
				<Grid item xs={12} sm={11}>
					<Typography variant="h6">Publisher GHG emissions are accounted for over three main areas:</Typography>
					<List sx={{pt: 1, pl: 2, pb: 4, listStyleType: 'disc' }}>
						<ListItem sx={{p: 0, display: 'list-item' }}>
							<Header display="inline" rank={6}>Publisher Content</Header>
							- the CO2e attributed to ad content produced when a publisher site is visited
						</ListItem>
						<ListItem sx={{p: 0, display: 'list-item' }}>
							<Header display="inline" rank={6}>Distribution</Header>
							- the CO2e attributed to the broadcast of an ad campaign and programmatic auctioning or direct buys
						</ListItem>
						<ListItem sx={{p: 0, display: 'list-item' }}>
							<Header display="inline" rank={6}>Consumption</Header>
							- the CO2e attributed to the viewing of an ad on a user device, including device usage and embodied emissions
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</ContentContainer>
	);
}


const borderCircle = {border: 'solid 2px', borderRadius: '50%', width: '2rem', height: '2rem', textAlign: 'center'};


function ScoreImprovements() {
	return (
		<ContentWrapper>
			<Grid container p={4}>
				<Grid item xs={12} sm={12}>
					<Grid container>
						<Grid item xs={3} sm={1}>
							<Header rank={5} sx={borderCircle}>1</Header>
						</Grid>
						<Grid item xs={9} sm={11}>
							<Header rank={5}>
								Analyse your site using a tool like <Link href="https://greenpages.aalto.fi/">Aalto's Green Page Analyzer</Link> to identify ways to reduce digital waste in your page content
							</Header>
						</Grid>
					</Grid>
					<Grid container mt={4}>
						<Grid item xs={3} sm={1}>
							<Header rank={5} sx={borderCircle}>2</Header>
						</Grid>
						<Grid item xs={9} sm={11}>
							<Header rank={5}>
								Optimise your supply path - consider selling direct rather than through intermediaries
							</Header>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ContentWrapper>
	);
}

function ScoreDetails({scoreDate, scorerVersion, site}) {
	const localDate = new Date(scoreDate).toLocaleDateString();

	return (
		<ContentPaper>
			<Grid container spacing={2} height="fit-content">
				<Grid item xs={12} sm={4}>
					<ContentContainer style={{height: 'fit-content'}}>
						<Typography variant="body1" p={2} fontWeight="bold">url last scored: {localDate}</Typography>
					</ContentContainer>
				</Grid>
				<Grid item xs={12} sm={4}>
					<ContentContainer style={{height: 'fit-content'}}>
						<Typography variant="body1" p={2} fontWeight="bold">version: {scorerVersion}</Typography>
					</ContentContainer>
				</Grid>
			</Grid>
		</ContentPaper>
	);
}
