import {Container, Typography } from '@mui/material';

function Resources() {
	return (
		<Container sx={{minWidth: '100%', bgcolor: 'whitesmoke'}}>
			<Typography variant="h3">Resources Stub</Typography>
		</Container>
	);
}

export default Resources;
