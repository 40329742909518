import { Box, OutlinedInput, ToggleButton, ToggleButtonGroup } from '@mui/material';
import InputWrapper from './InputWrapper';
import { useCallback, useMemo, useState } from 'react';



const heightMatchSx = {
	display: 'flex',
	alignItems: 'center',
};


/**
 * Wraps its children in a flexbox with a hidden <OutlinedInput>
 * - with the effect that button-type elements take the same height as a text or select field.
 * @param {object} props
 * @param {import('@mui/material').SxProps} [props.sx]
 * @param {string} [props.size]
 * @param {any} [props.children]
 */
function HeightMatchWrapper({sx, size, children}) {

	const _sx = useMemo(() => {
		return { ...sx, visibility: 'hidden', width: 0 };
	}, [sx]);

	return <Box sx={heightMatchSx}>
		<OutlinedInput size={size} sx={_sx} disabled />
		{children}
	</Box>;
}

/**
 *
 */
function ButtonGroupFieldControlled({value, options, buttonProps, color = 'primary', matchHeight, ...props}) {
	const buttonGroup = (
		<ToggleButtonGroup value={value} color={color} exclusive {...props}>
			{options.map(({label, ...optProps}) => (
				<ToggleButton key={optProps.value} {...buttonProps} {...optProps}>
					{label}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);

	// Match height to an adjacent input field?
	if (matchHeight) return <HeightMatchWrapper {...props}>{buttonGroup}</HeightMatchWrapper>;
	// ...or just return bare
	return buttonGroup;
}


/**
 * MUI ToggleButtonGroup isn't naturally uncontrolled
 * - so wrap it with some state management to make it act like it is.
 */
function ButtonGroupFieldUncontrolled({onChange, value, defaultValue = null, ...props}) {
	const [_value, setValue] = useState(defaultValue);

	// Wrap onChange to intercept values
	const _onChange = useCallback((event, ...args) => {
		if (onChange) onChange(event, ...args);
		setValue(event.target.value);
	}, [setValue, onChange]);

	return <ButtonGroupFieldControlled value={_value} onChange={_onChange} {...props} />;
}


/** Renders the actual MUI ToggleButtonGroup  */
function ButtonGroupFieldInner(props) {
	// No value provided: shim in "uncontrolled" behaviour
	if (props.value === undefined) return <ButtonGroupFieldUncontrolled {...props} />;
	// Normal case
	return <ButtonGroupFieldControlled {...props} />;
}


/**
 * A group of buttons which act like an <input type="radio">.
 * @param {*} props
 * @param {Object[]} props.options Options in form { label, value }.
 *   Label will be drawn on the button; value will be passed to onChange handlers.
 * @param {Boolean} props.matchHeight Set the buttons' height to match an <OutlinedInput>.
 *   If "size" property is supplied, the height will be adjusted accordingly.
 * @param {Boolean} [exclusive=true] Set false to allow multiple selections.
 *   In this case, the expected type of the "value" field (and onChange value) will be an array.
 */
function ButtonGroupField(props) {
	return <InputWrapper {...props} Input={ButtonGroupFieldInner} />;
}


export default ButtonGroupField;
export { HeightMatchWrapper };
