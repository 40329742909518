import { useCallback, useMemo, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

import { useFormContext } from '../../components/FormContextProvider';
import SwitchField from '../../components/inputFields/SwitchField';
import { CheckCircle } from '@mui/icons-material';



function KeepSwitch({partName, ...props}) {
	return <Grid xs={3}>
		<SwitchField label={`Keep current ${partName}?`} labelOff="Discard" labelOn="Keep" {...props} />
	</Grid>;
}


function TemplateButtonConfirm({template, label, diffs, ...props}) {
	const { setDraft } = useFormContext();
	const [keepXML, setKeepXML] = useState(false);
	const [keepCSS, setKeepCSS] = useState(false);
	const [keepText, setKeepText] = useState(false);

	// Apply the template, with user's priority preferences
	const setTemplate = useCallback(() => {
		return setDraft(prev => {
			let next = {...prev, ...template};
			if (keepXML) next.template = prev.template;
			if (keepCSS) next.css = prev.css;
			if (keepText) next.text = {...template.text, ...prev.text};
			return next;
		});
	}, [keepXML, keepCSS, keepText, setDraft, template]);

	// Dialog state
	const [open, setOpen] = useState(false);

	// Open confirm, close confirm, apply template and close
	const [onClick, onClose, setAndClose] = useMemo(() => [
		() => setOpen(true),
		() => setOpen(false),
		() => { setTemplate(); setOpen(false); }
	], [setOpen, setTemplate]);

	return <>
		<Button variant="contained" onClick={onClick} {...props}>{label}</Button>
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Overwrite current design?</DialogTitle>
			<DialogContent>
				<p>This template will overwrite parts of your current design:</p>
				<ul>
					{diffs.descs.map(diff => <li>{diff}</li>)}
				</ul>
				<Grid container>
					{diffs.xml && <KeepSwitch partName="XML" value={keepXML} onChangeValue={setKeepXML} />}
					{diffs.css && <KeepSwitch partName="CSS" value={keepCSS} onChangeValue={setKeepCSS} />}
					{diffs.text && <KeepSwitch partName="text" value={keepText} onChangeValue={setKeepText} />}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={setAndClose}>Use Template</Button>
				<Button variant="outlined" color="secondary" onClick={onClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	</>;
}


/**
 * Button to apply a creative template to the current draft.
 * If the draft fields are identical, or there's nothing to overwrite, clicking will apply the template immediately.
 * If it would overwrite existing data, clicking will first pop up a confirmation dialog detailing what will be overwritten,
 * and allowing the user to keep certain parts of their current design.
 */
function TemplateButton({template, label, ...props}) {
	const { draft, setDraft } = useFormContext();

	// Audit for fields which would be overwritten by the template
	const diffs = useMemo(() => {
		// Note which specific parts would be changed
		let xml = false, css = false, text = false, descs = [];
		// Also check for template entries which are absent from the draft
		// - OK to overwrite these without confirming, since there's no data loss,
		// but if we find any it means this isn't the template currently in use.
		let missingFields = false;

		// Template XML and CSS: Safe if the draft version is empty or identical.
		if (draft.template) {
			xml = draft.template !== template.template;
		} else missingFields = true;
		if (xml) descs.push('Template XML');

		if (draft.css) {
			css = draft.css !== template.css;
		} else missingFields = true;
		if (css) descs.push('Template CSS');

		// Advert text: Safe if the draft counterpart for each item in the template is empty or identical.
		for (let key in template.text) {
			if (!draft.text?.[key]) {
				missingFields = true;
				if (draft.text) continue; else break; // Can stop now if the draft has no text to overwrite.
			}
			if (draft.text[key] === template.text[key]) continue;
			text = true;
			descs.push(`Text: ${key}`);
		}
		// Safe = no data loss by applying the template
		const safe = !xml && !css && !text;
		// In-use = All template fields are identical (ie safe + none missing)
		const inUse = safe && !missingFields;
		return { xml, css, text, descs, safe, inUse };
	}, [draft, template]);

	// Action: Apply the template to the current draft
	const setTemplate = useCallback(() => {
		setDraft(prev => ({...prev, ...template }));
	}, [template, setDraft]);

	if (diffs.inUse) {
		props.endIcon = <CheckCircle />;
		props.title = 'This is the current template for this creative.';
	}

	// No fields will be overwritten = simple button
	if (diffs.safe) return <Button variant="contained" onClick={setTemplate} {...props}>{label}</Button>;

	// Some fields will be overwritten = confirm first
	return <TemplateButtonConfirm label={label} diffs={diffs} template={template} {...props} />;
}


export default TemplateButton;
