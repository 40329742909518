import { Divider } from '@mui/material';
import CalcExplanation from './CalcExplanation';
import Glossary from './Glossary';
import { MetricsTabs } from './Metrics';


/**
 * Return metadata about tab & content for the Understanding breakdown tab
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Understanding() {
	return {
		title: 'Understand your emissions',
		name: 'understanding',
		icon: null,
		href: '/metrics/understanding',
		content: <UnderstandingContent />,
	};
};


const understandingTabToHref = tab => `/metrics/understanding/${tab}`;

/**
 * Tab options for Distribution Breakdown & Consumption Breakdown
 * @returns {React.Element} Understanding tab options
 */
function UnderstandingContent() {
	const subpageData = [CalcExplanation(), Glossary()];

	return <>
		<Divider />
		<MetricsTabs
			pageData={subpageData}
			tabParam="subTab"
			tabToHref={understandingTabToHref}
		/>
	</>;
}

export default Understanding;
