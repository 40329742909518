import { useMemo } from 'react';
import { useGroup } from '../../state/apiHooks';
import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../lists/ListCard';
import ExportAdCSVButton from '../../pages/Formats/ExportAdButton';


/**
 * Renders a Good Measures tag as a list item.
 * @param {Object} props
 * @param {Function} props.onClick Called when the item is clicked
 * @param {Function} props.onExport Called when the "Export" button is clicked
 * @param {GMTag} props.item The tag item itself
 * @returns {JSX.Element} - The JSX element representing the green ad tag item.
 */
function AdCampaignListItem({item: campaign}) {
	const { data: brand } = useGroup(campaign.brandId);

	const exportQuery = useMemo(() => ({campaignId: campaign.id}), [campaign]);

	return (
		<ListCard item={campaign} href={`/campaigns/${campaign.id}`}>
			<ListCardSection xs={6} sm={3}>
				<ItemAttribute label="Brand" value={brand?.name} image={brand?.logoUrl} />
			</ListCardSection>
			<ListCardActions>
				<ExportAdCSVButton query={exportQuery}>Export all ads</ExportAdCSVButton>
			</ListCardActions>
		</ListCard>
	);
}



export default AdCampaignListItem;
