import {
	type ComponentProps, type Context, type Dispatch, type MutableRefObject, type SetStateAction, type JSX,
	createContext, useEffect, useMemo, useState,
	useContext
} from 'react';
import { clone, isEqual, mapValues } from 'lodash';
import { merge as cloneMerge } from 'lodash/fp';

import { yes } from '../utilities/utils';
import { User } from '../model/types/User';

import { useAuthUser } from '../state/apiHooks';
import useLiveRef from '../utilities/useLiveRef';


const FormContext: Context<FormContextType<any>> = createContext({} as FormContextType<any>);


/** A partial object containing fields whose values should be locked. */
type Base<T> = Partial<T>;

/** A partial object holding uncommitted edits. */
type Draft<T> = Partial<T>;

/** Shorthand for a setter function from {@link useState}. */
type StateSetter<T> = Dispatch<SetStateAction<Partial<T>>>;

/** A draft validation function. */
type ValidateFunction<T> = (authUser: User, draft: Draft<T>) => boolean;

/** A form action function, e.g. "save", "update", "delete", "revert" */
type Action<T> = (draft: Draft<T>) => Promise<T> | any;

/** A collection of named {@link Action}s. */
type Actions<T> = { [key: string]: Action<T> };

/** An {@link Action}, wrapped to provide the draft parameter. */
type WrappedAction<T> = () => Promise<T>;

/** A collection of named {@link WrappedAction}s. */
type WrappedActions<T> = { [key: string]: WrappedAction<T> };

/** Something which can be put in the DOM as e.g. a label. */
type Renderable = string | JSX.Element;



interface FormContextBase<T> {
	/** The form title, e.g. "Edit This Item". */
	title?: Renderable;
	/** Descriptive name/label for the primary {@link FormContextProps.submitFn}. */
	submitLabel?: Renderable;
	/** Descriptive names/labels for the auxiliary {@link FormContextProps.actionFns}. */
	actionLabels?: { [action: string]: Renderable }
	/** A template object specifying field values which should be protected from edits. */
	base?: Base<T>;
	/** A template object specifying the initial value of the draft. */
	initial?: Partial<T>;
	/** True if {@link initial} reflects a "canonical" saved version & changes from it should be flagged. */
	saved?: boolean;
	/** True if the context isn't ready for use yet (e.g. because the saved / initial draft value is still fetching). */
	loading?: boolean;
	/** Milliseconds to debounce inputs before updating the draft, recalculating changes, and autosaving. */
	debounceDuration?: number;
}


interface FormContextProps<T> extends FormContextBase<T>, ComponentProps<any> {
	/** Function which performs the form's primary action. */
	submitFn?: Action<T>;
	/** A set of named functions which perform auxiliary actions (e.g. delete, revert) on the form. */
	actionFns?: Actions<T>;
	/** Accepts a draft object and determines if it's ready to commit. */
	validateFn?: ValidateFunction<T>;
	/** True if the form should auto-submit when the draft changes. */
	autosave?: boolean;
}


interface FormContextType<T> extends FormContextBase<T> {
	/** Wrapped zero-args {@link FormContextProps.submitFn} with the current draft injected.*/
	submitFn?: WrappedAction<T>;
	/** Any error returned from the last submitFn invocation, if it failed. */
	submitError?: PromiseRejectedResult;
	/** Wrapped zero-args {@link FormContextProps.actionFns} with the current draft injected. */
	actionFns?: WrappedActions<T>;
	/** Errors from the most recent invocations of actionFns members, if they failed. */
	actionErrors?: { [key: string]: PromiseRejectedResult };
	/** Current draft object. Reflects / reflected by input state. */
	draft: Draft<T>;
	/** Setter function from the {@link useState} hook backing the draft object. */
	setDraft: StateSetter<T>;
	/** True if the current draft passes the validation function; nullish while waiting for {@link processingInput}. */
	valid?: boolean;
	/** True if a commit action is currently underway. */
	saving: boolean;
	/** True if the draft object has differences from the initial version; nullish while waiting for {@link processingInput}. */
	changed?: boolean;
	/** True if the user is currently making debounced inputs which haven't propagated back to {@link draft} and {@link valid} yet. */
	processingInput: boolean;
	/** Setter for {@link processingInput}: set true on outer input handler & false on debounced {@link setDraft}. */
	setProcessingInput: StateSetter<boolean>;
}


type WrapActionArgs<T> = [
	actionFn: Action<T>,
	setSaving: StateSetter<boolean>,
	draftRef: MutableRefObject<Draft<T>>,
	setError: StateSetter<PromiseRejectedResult | null>,
];


/** Inject current draft data, set/release "saving" flag, and record errors */
function wrapAction<T> (
	...[actionFn, setSaving, draftRef, setError]: WrapActionArgs<T>
): WrappedAction<T> {
	return () => {
		const draft = draftRef.current;
		setSaving(true);
		// Allow non-promise actions - promises are unaltered by this, non-promises are wrapped & immediately resolve
		const commitPromise = Promise.resolve(actionFn(draft));
		commitPromise.catch(setError); // Record error on failure
		commitPromise.then(() => setError(null)); // Clear previous error on success
		commitPromise.finally(() => setSaving(false));
		return commitPromise;
	};
}


/** Get the closest FormContext available to the current component. */
function useFormContext() {
	return useContext(FormContext);
}


/**
 * Value which draft/base/initial will definitely be different from,
 * so "new value" behaviour always triggers when {@link useDraft} mounts.
 */
const UNSET = Symbol();


/** Combine automatic setState when {@link initial} or {@link base} changes with immediate replacement of previous draft object. */
function useDraft<T>(base: T, initial: T): [T, StateSetter<T>] {
	const [prevDraft, setPrevDraft] = useState<T>(UNSET as T);
	const [prevInitial, setPrevInitial] = useState<T>(UNSET as T);

	// Overwrite draft when new initial value is supplied
	let draft = prevDraft;
	if (initial !== prevInitial) draft = clone(initial);
	// Re-apply base if draft has been reinitialised
	if (draft !== prevDraft && base != undefined) {
		// Overwrite if primitive, merge if not.
		draft = (typeof draft !== 'object') ? base : cloneMerge(draft, base);
	}

	// Commit changes all at once, outside of render flow
	useEffect(() => {
		setPrevInitial(initial);
		setPrevDraft(draft);
	}, [draft, initial, setPrevDraft, setPrevInitial]);

	return [draft, setPrevDraft];
}


/** Provides context and functions required by generic object-editor forms. */
function FormContextProvider<T>({
	base, initial, loading,
	submitFn: rawSubmitFn,
	actionFns: rawActionFns,
	validateFn = yes, autosave,
	children, debounceDuration = 300,
	...rest
}: FormContextProps<T>) {
	// Maintain a draft object for the form
	const [draft, setDraft] = useDraft(base, initial);
	// ...and a wrapped version for hooks which need to access it but not be triggered by changes
	const draftRef = useLiveRef(draft);

	// Maintain "now saving" flag
	const [saving, setSaving] = useState(false);

	// Maintain "currently processing input" flag
	const [processingInput, setProcessingInput] = useState(false);

	// Check draft validity on change, but return undefined if there's input ongoing.
	const { data: authUser } = useAuthUser();
	const valid = useMemo(() => (
		processingInput ? undefined : Boolean(validateFn(authUser as User, draft as Draft<T>))
	), [draft, processingInput, authUser, validateFn]);

	// Check draft for edits on change, but return undefined if there's input ongoing.
	const changed = useMemo(() => (
		processingInput ? undefined : !isEqual(draft, initial)
	), [draft, processingInput, initial]);

	// Track errors
	const [submitError, setSubmitError] = useState(null);
	const [actionErrors, setActionErrors] = useState({});

	// Wrap submit function to zero-args form
	const submitFn = useMemo(() => {
		if (rawSubmitFn) {
			return wrapAction(rawSubmitFn, setSaving, draftRef, setSubmitError);
		}
	}, [rawSubmitFn, setSaving, draftRef, setSubmitError]);
	// Wrap action functions
	const actionFns = useMemo(() => (
		mapValues(rawActionFns, (actionFn, name) => {
			const onError = error => setActionErrors(errors => ({...errors, [name]: error}));
			return actionFn && wrapAction(actionFn, setSaving, draftRef, onError);
		})
	), [rawActionFns, setSaving, draftRef, setActionErrors]);

	// Autosave if requested
	const autosaveFnRef = useLiveRef(autosave && !loading && submitFn);
	useEffect(() => {
		if (changed && autosaveFnRef.current) autosaveFnRef.current();
	}, [changed, autosaveFnRef]);

	// Assemble context object
	const context = {
		...rest,
		base, initial, draft, setDraft, loading,
		processingInput, setProcessingInput,
		submitFn, actionFns, submitError, actionErrors,
		saving, valid, changed, debounceDuration
	};

	return <FormContext.Provider value={context}>
		{children}
	</FormContext.Provider>;
}


export default FormContextProvider;
export {
	useFormContext, FormContext, FormContextProps, FormContextType,
	Base, Draft, StateSetter, ValidateFunction, Action
};
