import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAccessToken } from './hooks';
import { doJsonFetch, createEntity, updateEntity, deleteEntity, setGroupPermission, setGlobalPermission, addApiKey, apiUrl } from './api';
import { useCallback } from 'react';
import entity from '../model/entity';


/** @typedef {import('@tanstack/react-query').QueryClient} QueryClient */
/** @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult */
/** @typedef {(...args: any) => Promise<Response>} ApiFunction */

/** Not null or undefined */
const isValue = v => (v != null);

/**
 * Uses the useQuery hook to enable autocaching and expose extra information
 * @param {String} endpoint Local URL for the API endpoint to call
 * @param {*[]} [queryKey] Will be used by react-query to cache results - omit to skip caching
 * @param {Object} queryArgs Additional arguments for useQuery
 * @param {Boolean} [queryArgs.enabled=true] Set false to stop the hook making calls
 * @param {Object} [fetchParams] Parameters passed to doJsonFetch()
 * @param {String} [fetchParams.method='GET'] HTTP method (GET, POST, PUT, DELETE)
 * @param {Object} [fetchParams.data] Will be stringified and sent as request body
 * @param {(...args: any) => any} [catchFn] If provided, will catch errors thrown by doJsonFetch.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
 */
const useAPI = (endpoint, queryKey, {enabled = true, ...queryArgs} = {}, fetchParams, catchFn) => {
	const accessToken = useAccessToken();
	enabled &&= !!accessToken; // Don't try to make the request until auth token is available.

	return useQuery({
		queryKey: (queryKey?.length) ? [accessToken, ...queryKey] : [],
		queryFn: () => {
			const fetchPromise = doJsonFetch(endpoint, {...fetchParams, accessToken});
			return catchFn ? fetchPromise.catch(catchFn) : fetchPromise;
		},
		enabled,
		...queryArgs
	});
};


/**
 * Common method for fetching a single thing by a known ID.
 * Query disabled if {@link type} or {@link id} is missing.
 * @param {String} type Type of object - assuming its API endpoint is /${type}
 * @param {String} [id] Object's row ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
 */
const useEntity = (type, id, args) => useAPI(
	`/${type}/${id}`,
	[type, 'getById', id],
	{enabled: isValue(type) && isValue(id), ...args}
);


/**
 * Common method for fetching a list of things by query.
 * @param {String} type Type of object - assuming its API endpoint is /${type}
 * @param {Object} [query] Field values and names to query on
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
 */
const useEntities = (type, query, args) => useAPI(
	`/${type}`,
	[type, 'query', query ? Object.entries(query) : 'all'],
	args,
	{ data: query }
);


/** Backstop "past" date: 1970-01-01 */
const ISO_8601_EPOCH = new Date(0).toISOString();
/** Backstop "future" date: Last msec of 2099 */
const ISO_8601_FUTURE = new Date('2099-12-31T23:59:59.999Z').toISOString();

/**
 * Common method for fetching a data series.
 * @param {String} endpoint The data endpoint
 * @param {Object} [filter] Set of filters for the data, eg start/end, campaign, group
 * @param {Object} [fetchParams] Additional params e.g. override HTTP method
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
 */
const useMetric = (endpoint, filter = {}, fetchParams) => {
	// Specify explicit dates for absent start/end - "epoch 0" and "tomorrow".
	if (!filter.startDate) filter.startDate = ISO_8601_EPOCH;
	if (!filter.endDate) filter.endDate = ISO_8601_FUTURE;

	return useAPI(
		`/metrics${endpoint}`,
		['data', endpoint, filter ? Object.entries(filter) : 'all'],
		{},
		{ data: filter, method: 'POST' , ...fetchParams}
	);
};


/**
 * Fetch one group by its row ID.
 * @param {String} id Group ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useGroup = (id, args) => useEntity(entity.GROUP, id, args);


/**
 * Fetch a list of groups by query.
 * @param {Object} [query] Field values and names to query on
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useGroups = (query, args) => useEntities(entity.GROUP, query, args);


/**
 * Common base for useSubGroups, useGroupTags, useGroupCampaigns, useGroupUsers.
 * @param {string} depType See one-to-many join fields on Group.java
 * @param {string} id ID of the root group
 * @param {Boolean} recurse Fetch dependents of descendant groups as well.
 * @returns {UseQueryResult}
 */
const useGroupDependents = (depType, id, recurse = true, query, args) => useAPI(
	`/group/${id}/${depType}`,
	['group', id, depType, (recurse ? 'recursive' : 'direct')],
	{enabled: isValue(id), ...args},
	{data: {recurse, ...query}}
);


/**
 * Get sub-groups of the designated group.
 * @param {string} id ID of the root group
 * @param {Boolean} [recurse] Fetch entire group tree, not just immediate children.
 * @param {object} [query] Additional query parameters
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useSubGroups = (id, recurse, query, args) => useGroupDependents('children', id, recurse, query, args);



/**
 * Get entities under the designated group.
 * @param {string} type Entity type
 * @param {string} groupId Group ID.
 * @param {Boolean} [recurse] Also fetch entities owned by descendant groups.
 * @param {object} [query] Additional query parameters
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useEntitiesWhereGroup = (type, groupId, recurse, query, args) => {
	const groupParam = recurse ? 'groupOrDescendant' : 'group';
	return useEntities(type, {...query, [groupParam]: groupId}, args);
};


/**
 * Get tags under the designated group.
 * @param {string} [id] Group ID.
 * @param {Boolean} [recurse] Also fetch tags owned by descendant groups.
 * @param {object} [query] Additional query parameters
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useGroupTags = (id, recurse, query, args) => useEntitiesWhereGroup(entity.TAG, id, recurse, query, args); // useGroupDependents('tags', id, recurse, args);


/**
 * Get campaigns under the designated group.
 * @param {string} [id] Group ID.
 * @param {Boolean} [recurse] Also fetch campaigns owned by descendant groups.
 * @param {object} [query] Additional query parameters
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useGroupCampaigns = (id, recurse, query, args) => useEntitiesWhereGroup(entity.CAMPAIGN, id, recurse, query, args); // useGroupDependents('campaigns', id, recurse, args);


/**
 * Get users under the designated group.
 * @param {string} [id] Group ID.
 * @param {Boolean} [recurse] Also fetch users owned by descendant groups.
 * @param {object} [query] Additional query parameters
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useGroupUsers = (id, recurse, query, args) => useEntitiesWhereGroup(entity.USER, id, recurse, query, args); // useGroupDependents('users', id, recurse, args);


/**
 * Fetch one Creative by row ID.
 * @param {String} [id] Creative ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
*/
const useCreative = (id, args) => useEntity(entity.CREATIVE, id, args);


/**
 * List Creatives by simple match-all query.
 * @param {Object} [query] e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
*/
const useCreatives = (query, args) => useEntities(entity.CREATIVE, query, args);


/**
 * Fetch one Line Item by row ID.
 * @param {String} [id] LineItem ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
*/
const useLineItem = (id, args) => useEntity(entity.LINE_ITEM, id, args);


/**
 * List Line Items by simple match-all query.
 * @param {Object} [query] e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @param {Object} [args]
 * @returns {UseQueryResult}
*/
const useLineItems = (query, args) => useEntities(entity.LINE_ITEM, query, args);



/**
 * Fetch one Tag by row ID.
 * @param {String} [id] Tag ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult} {data, isLoading, isError, isSuccess}
 */
const useTag = (id, args) => useEntity(entity.TAG, id, args);

/**
 * List Tags by simple match-all query.
 * @param {Object} [query] e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useTags = (query, args) => useEntities(entity.TAG, query, args);


/**
 * Fetch one Campaign by row ID.
 * @param {String} id Campaign's ID
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useCampaign = (id, args) => useEntity(entity.CAMPAIGN, id, args);


/**
 * List Campaigns by simple match-all query.
 * @param {Object} [query] e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @param {object} [args] Additional arguments to pass to {@link useQuery}.
 * @returns {UseQueryResult}
 */
const useCampaigns = (query, args) => useEntities(entity.CAMPAIGN, query, args);


/**
 * Fetch one GLUser by row ID.
 * @param {string} id User's ID
 * @returns {UseQueryResult}
 */
const useUser = (id, args) => useEntity(entity.USER, id, args);

/**
 * List GLUsers by simple match-all query.
 * @param {Object} query e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @returns {UseQueryResult}
 */
const useUsers = (query, args) => useEntities(entity.USER, query, args);


/**
 * Fetch one Publisher by row ID.
 * @param {string} id Publisher ID
 * @returns {UseQueryResult}
 */
const usePublisher = (id, args) => useEntity(entity.PUBLISHER, id, args);

/**
 * List Publishers by simple match-all query.
 * @param {Object} query e.g. {a: "val1", b: "val2"} --> "WHERE obj.a = val1 AND obj.b = val2"
 * @returns {UseQueryResult}
 */
const usePublishers = (query, args) => useEntities(entity.PUBLISHER, query, args);


/**
 * Fetch the GLUser for the currently authorised user.
 * @param {boolean} [enabled=true] Set falsy to disable this hook
 * @returns {UseQueryResult}
 */
const useAuthUser = (enabled = true) => useAPI(
	'/login/authuser',
	['login', 'authUser'],
	{ enabled },
	null,
	err => {
		// 401 = resolve to "no user"
		if (err?.status === 401) return null;
		// Other errors still get thrown.
		throw err;
	}
);



/**
 * Ask the store whether the authed user can perform a specific action on an entity.
 * Args object must have one of "id" (for read/update/delete) or "draft" (for create) populated.
 * The draft entity should be populated, where possible, with fields relevant to permissions - e.g. parentId for type=GROUP.
 * @param {Object} p
 * @param {String} p.type Entity type
 * @param {String} [p.action="UPDATE"] Will be overridden to "CREATE" if entity ID is absent
 * @param {String} [p.id] Entity ID
 * @param {Object} [p.draft] Draft entity
 * @returns {UseQueryResult} Resolves to true or false.
 */
const useCanDo = ({type, action = 'UPDATE', id, draft}) => {
	let endpoint, method, data;
	// Create or read/update/delete?
	if (!id) {
		action = 'CREATE';
		endpoint = `/${type}/canCreate`;
		method = 'POST';
		data = draft;
	} else {
		endpoint = `/${type}/${id}/canDo/${action}`;
	}
	return useAPI(
		endpoint,
		['canDo', action, type, (id || draft)],
		{enabled: type && action && (id || draft)},
		{method, data}
	);
};


/** Get the specified user's permission level on the specified group. */
const useGroupPermission = (userId, groupId) => useAPI(
	`/group/${groupId}/userPermissions/${userId}`,
	['groupPermission', groupId, 'forUser', userId],
	{enabled: userId && groupId}
);


/** List users who have any permission level on the specified group. */
const useGroupPermissions = (id, recurse, query, args) => useGroupDependents('userPermissions', id, recurse, query, args);


const useGlobalPermission = (userId) => useAPI(
	`/globalPermission/${userId}`,
	['globalPermission', userId],
	{enabled: userId}
);


/** Get the API-key accounts associated with the specified group. */
const useGroupApiKeys = (id, recurse, query, args) => useGroupDependents('apiKey', id, recurse, query, args);


const useEmissions = (filter) => (
	useMetric('/ghg/emissionsOverTime', { ...filter })
);


/**
 * Query key generator for the generic use case of "POST/PUT/DELETE to /api/{type}(/{id})?"
 * @param {Object} args The arguments object which will be passed to the API-calling function
 */
const queryKeyFnDefault = ({type}) => {
	return [type];
};


/**
 * Common function for useCreateEntity, useUpdateEntity, useDeleteEntity
 * @param {ApiFunction} apiFn Calls the API
 * @param {Object} fixedArgs Augments the argument object provided at invocation
 * @param {(any) => string[]} queryKeyFn Generates a query key for the section of state to invalidate.
 * @returns {(any) => Promise} A function
 */
const useWriteApi = (apiFn, fixedArgs, queryKeyFn = queryKeyFnDefault) => {
	const accessToken = useAccessToken();
	const queryClient = useQueryClient();

	return useCallback((callerArgs) => {
		// Make the API call (not mediated through useQuery)
		const combinedArgs = {...fixedArgs, ...callerArgs};
		return apiFn(accessToken, combinedArgs)
			.then(response => {
				// Partially invalidate the react-query cache
				queryClient.invalidateQueries({
					queryKey: [accessToken, ...queryKeyFn(combinedArgs)]
				});
				return response;
			});
		// TODO When the back-end invalidation logic is solid, port it here too?
		// - Or just have it return a digest of invalidated objects?
	}, [accessToken, queryClient, apiFn, fixedArgs, queryKeyFn]);
};


const useS3Upload = () => {
	const accessToken = useAccessToken();

	return useCallback(event => {
		// Q: Why does this use XHR instead of the Fetch API?
		// A: Fetch still doesn't support upload progress events.
		const xhr = new XMLHttpRequest();
		xhr.open('POST', apiUrl('/v1/file/upload'));
		xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
		xhr.responseType = 'text';
		xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');
		const formData = new FormData();
		formData.append('file', event.file);
		formData.append('campaignId', event.campaignId);
		formData.append('groupId', event.groupId);

		return new Promise((resolve, reject) => {
			xhr.onerror = () => reject(xhr);
			xhr.onload = () => {
				if (xhr.status < 200 || xhr.status >= 400) {
					reject(xhr);
					return;
				}
				resolve(xhr.response);
			};
			xhr.send(formData);
		});
	}, [accessToken]);
};

/**
 * Returns a function which POSTs a new entity to the relevant endpoint.
 * @param {String} type See entity.js
 * @returns {ApiFunction}
 */
const useCreateEntity = (type) => useWriteApi(createEntity, {type});


/**
 * Returns a function which PUTs an updated entity to the relevant endpoint.
 * @param {String} type See entity.js
 * @returns {ApiFunction}
 */
const useUpdateEntity = (type) => useWriteApi(updateEntity, {type});


/**
 * Returns a function which DELETEs an entity from the relevant endpoint.
 * @param {String} type See entity.js
 * @returns {ApiFunction}
 */
const useDeleteEntity = (type) => useWriteApi(deleteEntity, {type});


/** Generates query key for all user-group permissions pertaining to a group. */
const groupPermissionQueryKeyFn = ({groupId}) => ['group', groupId, 'userPermissions', groupId];


/**
 * Returns a function which sets a user-group permission level.
 * @returns {ApiFunction} Takes object argument {userId, groupId, level}
 */
const useSetGroupPermission = () => {
	return useWriteApi(setGroupPermission, {}, groupPermissionQueryKeyFn);
};


/** Generates query key for one user's global permission object. */
const globalPermissionQueryKeyFn = ({userId}) => ['globalPermission', userId];


/**
 * Returns a function which sets a user's global permission level.
 * @returns {ApiFunction} Takes object argument {userId, level}
 */
const useSetGlobalPermission = () => {
	return useWriteApi(setGlobalPermission, {}, globalPermissionQueryKeyFn);
};


/** Generates query key for one user's global permission object. */
const apiKeyQueryKeyFn = ({groupId}) => ['group', groupId, 'apiKey'];


/**
 * Returns a function which creates an API key with access to a specific group.
 * @returns {ApiFunction} Takes object argument {groupId, clientId, level}
 */
const useAddApiKey = () => {
	return useWriteApi(addApiKey, {}, apiKeyQueryKeyFn);
};


/**
 * Returns an event handler which, when attached to a file input's onChange, will upload the selected file.
 * @param {string} type "video", "image", "font". Dictates the endpoint and subsequent processing of the uploaded file.
 * @param {object} options
 * @param {Function} [options.setProgress] Receives updates with fractional values from 0 to 1 as the upload progresses.
 * @param {Function} [options.onComplete] Receives the Media entity corresponding to the uploaded file on success.
 * @param {object} [options.params] Will be included as FormData with the upload request.
 * @returns A function returning a Promise which resolves with the Media entity corresponding to the uploaded file
 *          on success, and rejects with the XHR object on failure.
 */
const useUploadFileHandler = (type, {setProgress, onComplete, params}) => {
	const accessToken = useAccessToken();

	return useCallback(event => {
		// Q: Why does this use XHR instead of the Fetch API?
		// A: Fetch still doesn't support upload progress events.
		const xhr = new XMLHttpRequest();

		if (setProgress) {
			xhr.upload.onprogress = e => e?.lengthComputable && setProgress(e.loaded / e.total);
			xhr.upload.onload = () => setProgress(1);
		}

		// const onProgress = e => e?.lengthComputable && setProgress(e.loaded / e.total);
		// xhr.upload.addEventListener('progress', onProgress, false);
		// const onComplete = () => setProgress(1);
		// xhr.upload.addEventListener('load', onComplete, false);

		xhr.open('POST', apiUrl(`/upload/${type}`));
		xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
		xhr.responseType = 'json';
		// TODO Is this necessary? (copy-paste from MDN)
		xhr.overrideMimeType('text/plain; charset=x-user-defined-binary');

		const formData = new FormData();
		formData.append('file', event.target.files[0]);
		Object.keys(params).forEach(key => {
			formData.append(key, params[key]);
		});

		return new Promise((resolve, reject) => {
			xhr.onerror = () => reject(xhr);
			xhr.onload = () => {
				if (xhr.status < 200 || xhr.status >= 400) {
					reject(xhr);
					return;
				}
				resolve(xhr.response);
				onComplete && onComplete(xhr.response);
			};
			xhr.send(formData);
		});
	}, [accessToken, type, setProgress, onComplete, params]);
};


export {
	// Authed user & properties
	useAuthUser, useCanDo,
	// Generic
	useMetric, useEntity, useEntities, useAPI,
	// Group
	useGroup, useGroups, useSubGroups,
	// Campaign
	useCampaign, useCampaigns, useGroupCampaigns,
	// Tag
	useTag, useTags, useGroupTags,
	// Creative
	useCreative, useCreatives,
	// Line Item
	useLineItem, useLineItems,
	// User
	useUser, useUsers, useGroupUsers,
	// Publisher
	usePublisher, usePublishers,
	// API keys
	useGroupApiKeys,
	// Permissions
	useGroupPermission, useGroupPermissions,
	useGlobalPermission,
	// Set permissions
	useSetGroupPermission, useSetGlobalPermission,
	useAddApiKey,
	useCreateEntity, useUpdateEntity, useDeleteEntity,
	// Metrics
	useEmissions,
	useS3Upload,
	// Uploader
	useUploadFileHandler,
};
