import { Routes, Route } from 'react-router-dom';

import GMPRootLayout from './components/layouts/GMPRootLayout';

import ManageWorkspaces from './components/manage/ManageWorkspaces';
import ManageGlobalUsers from './components/manage/ManageGlobalUsers';
import GoodMeasuresPublishers from './pages/GoodMeasuresPublishers/GoodMeasuresPublishers';

import { useWorkspace } from './components/WorkspaceProvider';
import ManagePublishers from './components/manage/ManagePublishers';


function GoodMeasuresForPublishers() {
	const { workspaceId } = useWorkspace();
	if (!workspaceId) return (
		<Routes>
			<Route path="/" element={<GMPRootLayout />}>
				<Route index element={<ManageWorkspaces />} />
				<Route path="workspaces" element={<ManageWorkspaces />} />
				<Route path="users" element={<ManageGlobalUsers />} />
				<Route path="publishers" element={<ManagePublishers />} />
			</Route>
		</Routes>
	);

	// One-minute introductory notes on React Router:
	// 1: The longest matching path will be used. So if the user is at /resources/tutorials and there are routes in the tree for
	//    "/resources" and "/resources/tutorials" - naturally the second one will be used.
	// 2: When routes are nested, their paths are concatenated - so because "home" is inside "/", its actual matched path is "/home"
	// 3: The "index" marker means "Use this when the subpath is empty" - so location / routes to the same place as /home.
	// 4: You can pull data out of routes by putting a colon-prefixed part in the path, like "tags/:tagId".
	//    The subpath string in that position will be available in the component from the useParams() hook
	//    - so for the above path spec, in a component rendering at /tags/myTagId, useParams() will return { tagId: 'myTagId' }.
	return (
		<Routes>
			<Route path="/" element={<GMPRootLayout />}>
			    <Route index element={<GoodMeasuresPublishers />} />
			    <Route path="manage" element={<ManagePublishers />} />
				<Route path="metrics" element={<GoodMeasuresPublishers />} />
			</Route>
		</Routes>
	);
}


export default GoodMeasuresForPublishers;
