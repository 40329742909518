import { darken, lighten } from '@mui/material';

/** Brand primary colours */
const goodLoopRed = '#ff084f';
const offWhite = '#fafaf5';
const black = '#000000';

/** Brand secondary colours */
const pineGreen = '#0a8a75';
const cobaltBlue = '#0061ba';
const lightMagenta = '#ffa1e0';
const sunYellow = '#ffe566';

const goodLoopDefaultTheme = {
	palette: {
		mode: 'light',
		primary: {
			main: goodLoopRed,
		},
		secondary: {
			main: black,
		},
		background: {
			default: offWhite,
			paper: '#f0f0ed',
		},
		success: {
			main: pineGreen,
		},
		info: {
			main: cobaltBlue,
		},
		warning: {
			main: sunYellow,
		},
		action: {
			active: goodLoopRed,
		},
		chartDistribution: {
			main: lightMagenta,
			dark: darken(lightMagenta, 0.3),
			light: lighten(lightMagenta, 0.3),
		},
		chartConsumption: {
			main: cobaltBlue,
			dark: darken(cobaltBlue, 0.3),
			light: lighten(cobaltBlue, 0.2),
		},
		chartGMP: {
			main: pineGreen,
			dark: darken(pineGreen, 0.3),
			light: lighten(pineGreen, 0.3),
		}
	},
	typography: {
		fontFamily: 'ABCSocial',
	},
	components: {
		MuiLink: {
			defaultProps: {
				color: 'primary',
				underline: 'hover',
			}
		},
		MuiDialog: {
			defaultProps: {
				fullWidth: true,
				maxWidth: 'md',
			},
			styleOverrides: {
				paper: {
					borderRadius: '10px',
				}
			}
		},
		MuiDialogTitle: {
			defaultProps: {
				fontWeight: 'bold',
			}
		},
		MuiDialogContent: {
			defaultProps: {
				dividers: true,
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none', // No all-caps buttons
				}
			}
		},
		MuiChip: {
			styleOverrides: {
				icon: {
					color: 'inherit', // No partially-transparent icon lines
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '1rem',
					fontWeight: 400,
					lineHeight: 1.25,
					padding: '0.75rem'
				}
			}
		}
	}
};

const goodLoopStudioTheme = {
	...goodLoopDefaultTheme, // Extend from default theme
	palette: {
		...goodLoopDefaultTheme.palette,
		primary: {
			main: cobaltBlue,
		},
	},
};

export {
	goodLoopRed,
	goodLoopRed as red,
	offWhite,
	offWhite as white,
	black,
	pineGreen,
	pineGreen as green,
	cobaltBlue,
	cobaltBlue as blue,
	lightMagenta,
	lightMagenta as magenta,
	sunYellow,
	sunYellow as yellow,

	goodLoopDefaultTheme,
	goodLoopStudioTheme,
};