import Grid from '@mui/material/Unstable_Grid2';

import { useFormContext } from '../FormContextProvider';

import UploadField from '../inputFields/UploadField';
import TextInputField from '../inputFields/TextInputField';
import SelectField from '../inputFields/SelectField';
import { Header, HeaderActions } from '../common/LayoutComponents';
import { SubForm, itemAdder } from '../SubForms';
import { Button, Divider, Paper, Stack } from '@mui/material';
import { useCallback } from 'react';
import { HeightMatchWrapper } from '../inputFields/ButtonGroupField';
import { InputLabel } from '../inputFields/InputWrapper';



const fontWeightOptions = [
	{ value: 'normal', label: 'Normal' },
	{ value: 'bold', label: 'Bold' },
];


const fontStyleOptions = [
	{ value: 'normal', label: 'Normal' },
	{ value: 'italic', label: 'Italic' },
];


function fontName(font) {
	let name = font.name || 'Default Font';
	const modifiers = [];
	if (font?.weight === 'bold') modifiers.push('Bold');
	if (font?.style === 'italic') modifiers.push('Italic');
	if (!modifiers.length) return name;
	return `${name} (${modifiers.join(' ')})`;
}


/** Controls for a single font spec attached to a Creative. */
function FontForm({uploadProps}) {
	const { draft: font, actionFns} = useFormContext();

	return <Paper sx={{p: 2}}>
		<Header rank={6} display="block">
			{fontName(font)}
			<HeaderActions>
				<Button variant="contained" color="secondary" size="small" onClick={actionFns.delete}>Remove</Button>
			</HeaderActions>
		</Header>
		<Divider/>
		<Grid container spacing={2}>
			<Grid xs={12} sm={4}>
				<TextInputField label="Name" type="font" fullWidth
					path="name" size="small"
				/>
			</Grid>
			<Grid xs={12} sm={4}>
				<UploadField label="Font File" type="font" fullWidth
					path="url" {...uploadProps} size="small"
				/>
			</Grid>
			<Grid xs={6} sm={2}>
				<SelectField label="Weight" fullWidth
					path="weight" options={fontWeightOptions} size="small"
				/>
			</Grid>
			<Grid xs={6} sm={2}>
				<SelectField label="Style" fullWidth
					path="style" options={fontStyleOptions} size="small"
				/>
			</Grid>
		</Grid>
	</Paper>;
}

/** Button to append an empty font object to the list. */
const AddFontForm = itemAdder(function ({addItem}) {
	return <Grid>
		<Button variant="contained" onClick={() => addItem({})}>
			Add Font
		</Button>
	</Grid>;
});


/** Collective control for the array of font specifications attached to a Creative. */
function FontControls({uploadProps}) {

	// Inject uploadProps (to attach uploaded assets to their owning brand) in FontForm
	const FontFormWithProps = useCallback(props => <FontForm {...props} uploadProps={uploadProps} />, [uploadProps]);

	return <Stack spacing={2}>
		<AddFontForm path="data.fonts" />
		<SubForm path="data.fonts" ItemForm={FontFormWithProps} />
	</Stack>;
}

export default FontControls;
