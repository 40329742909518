import { useMemo } from 'react';
import { useParams } from 'react-router';

import { Button, ButtonProps, CircularProgress, Divider } from '@mui/material';

import { useCampaign } from '../../state/apiHooks';
import { Header, HeaderActions, PageContainer, PageSection } from '../../components/common/LayoutComponents';

import EntityList from './EntityList';
import GoodFormatsBreadcrumbs from './GoodFormatsBreadcrumbs';
import CreativeListItem from '../../components/lists/CreativeListItem';
import { EditAdCampaignButton } from '../../components/edit/AdCampaignDialog';
import { AdCampaign } from '../../model/types/AdCampaign';


function NewCreativeButton({campaign, ...props}: ({campaign: AdCampaign} & ButtonProps)) {
	return <Button variant="outlined" color="primary" href={`/creatives/new?campaign=${campaign.id}`} {...props}>New Creative</Button>;
}


function ShowCampaign() {
	const { campaignId } = useParams();
	const { data: campaign } = useCampaign(campaignId) as { data: AdCampaign };
	const listQuery = useMemo(() => ({ campaignId }), [campaignId]);

	const listProps = useMemo(() => {
		return campaign ? {
			title: <>Creatives for {campaign.name}</>,
			actionButtons: <NewCreativeButton campaign={campaign} />
		 } : {};
	}, [campaign]);

	if (!campaign) return <PageContainer><CircularProgress /> Loading campaign <code>{campaignId}</code>...</PageContainer>;

	return <>
		<GoodFormatsBreadcrumbs campaignId={campaignId} />
		<PageContainer>
			<PageSection>
				<Header rank={5}>
					{campaign.name}
					<HeaderActions>
						<EditAdCampaignButton label="Edit Campaign" id={campaignId} />
					</HeaderActions>
				</Header>
				<Divider sx={{my: 3}} />
				<EntityList type="creative" ItemComponent={CreativeListItem} query={listQuery} {...listProps} />
			</PageSection>
		</PageContainer>
	</>;
}


export default ShowCampaign;
