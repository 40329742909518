import { EditUserButton } from '../edit/UserDialog';
import GlobalUserTable from '../tables/GlobalUserTable';
import { Header, HeaderActions, PageContainer, PageSection } from '../common/LayoutComponents';



function ManageGlobalUsers() {
	return (
		<PageContainer>
			<Header rank={3}>Manage Users</Header>
			<PageSection>
				<Header rank={5}>
					Good-Loop Users
					<HeaderActions>
						<EditUserButton label="Add Good-Loop User" />
					</HeaderActions>
				</Header>
				<GlobalUserTable />
			</PageSection>
		</PageContainer>
	);
}

export default ManageGlobalUsers;
