import { Box, Typography, Grid, useTheme } from '@mui/material';
import {  ContentWrapper} from './MetricComponents';
import {  PieChart } from '@mui/x-charts';
import { metricWeightString} from '../../utilities/utils';


const chartBoxSx = {
	display: 'flex',
	justifyContent: 'center',
	width: '100%',     // Full width of the parent container
	height: '100%'     // Dynamic height of the parent container
};


function DistConsumptionDoughnut({ distributionTotal, consumptionTotal, sx }) {
	const theme = useTheme();
	const distColour = theme.palette.chartDistribution.main;
	const consColour = theme.palette.chartConsumption.main;

	const roundRatio = (val) => val > 0 ? Math.round(val / (distributionTotal + consumptionTotal) * 10000) / 100 : 0;
	const distRatio = roundRatio(distributionTotal);
	const consRatio = roundRatio(consumptionTotal);

	return (
		<ContentWrapper sx={sx}>
			<Box sx={chartBoxSx}>
				<Grid container spacing={1} sx={{ alignContent: 'center' }} aria-label="Distribution and Consumption Doughnut">
					<PieChart
						id='consumption-distribution-pie-chart'
						series={[
							{
								data: [
									{ id: 1, value: consumptionTotal, color: consColour, label: 'Consumption' },
									{ id: 0, value: distributionTotal, color: distColour, label: 'Distribution' },
								],
								valueFormatter: (e) => metricWeightString(e.value),
								cx: '75%',
								innerRadius: '80%',
							},
						]}
						slotProps={{legend: { hidden: true } }}
						height={150}
						// This is kinda insane, but a width higher or lower than 300 no longer center aligns and I do not know why mui does this? Lower aligns left and higher aligns right.
						width={300}
					/>
					<Grid container spacing={1} maxHeight="unset !important" sx={{fontSize: 'clamp(0.25em, 1vw, 1.25em)', whiteSpace:'nowrap', overflow: 'hidden', alignItems: 'center', justifyContent: 'center'}}>
						<Grid sx={{ width:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Box sx={{ backgroundColor: 'chartDistribution.main', width: 'clamp(10px, 1vw, 40px)', height: 'clamp(10px, 1vw, 40px)', borderRadius: '50%', border: 'solid 1px black', display: 'inline-flex' }} />
						</Grid>
						<Grid sx={{ width:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Box sx={{ backgroundColor: 'chartConsumption.main', width: 'clamp(10px, 1vw, 40px)', height: 'clamp(10px, 1vw, 40px)', borderRadius: '50%', border: 'solid 1px black', display: 'inline-flex' }} />
						</Grid>
						<Grid item xs={6} sx={{ width:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Typography component="b" sx={{ fontWeight: 'bold', display: 'inline-flex', verticalAlign: 'super' }}>Distribution</Typography>
						</Grid>
						<Grid item xs={6} sx={{ width:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Typography component="b" sx={{ fontWeight: 'bold', display: 'inline-flex', verticalAlign: 'super' }}>Consumption</Typography>
						</Grid>
						<Grid item xs={6} sx={{ width:'50%', pt: '0 !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Typography component="p" sx={{ display: 'inline-flex', verticalAlign: 'super', fontWeight: 'medium' }} aria-label="Distribution pie value">{distRatio}%</Typography>
						</Grid>
						<Grid item xs={6} sx={{ width:'50%', pt: '0 !important', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Typography component="p" sx={{ display: 'inline-flex', verticalAlign: 'super', fontWeight: 'medium' }} aria-label="Consumption pie value">{consRatio}%</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</ContentWrapper>
	);
}
export default DistConsumptionDoughnut;