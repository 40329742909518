import { Typography, Grid, type SxProps } from '@mui/material';
import { ContentPaper, ContentContainer, TooltipWrapper} from './MetricComponents';
import { metricWeightString, useMergeSx} from '../../utilities/utils';
import { ReactComponent as LanguageIcon } from '../../img/icons/globe-2.svg';
import { SvgWrapper} from '../../components/common/Icons';
import { Header } from '../../components/common/LayoutComponents';
import { CO2e } from './MetricComponents';


/** Kilogrammes CO2e emitted by a return flight LHR <-> JFK. */
const returnFlightCarbonKg = 671;


/** Convert kg carbon to equivalent number of one-way LHR-JFK flights. */
function flightCount(kgCarbon = 0) {
	// This comment was alongside the original calculation: is it still relevant?
	// TODO: what's the actual ratio?
	let count = kgCarbon / (returnFlightCarbonKg / 2);
	return Math.round(count * 100) / 100;
}


function CarbonTotal({ totalCarbon, sx }: { totalCarbon: any, sx?: SxProps}) {
	const style = { height: '100%', alignContent: 'center', pr: '0 !important' };
	const flightDist = flightCount(totalCarbon);
	sx = useMergeSx({height: '100%'}, sx);

	return (
		<ContentPaper sx={sx} aria-label="Total emissions box">
			<Grid container spacing={2} sx={{height: '100%'}}>
				<Grid item xs={6} sx={{ alignContent: 'center' }}>
					<ContentContainer style={style}>
						<Grid container spacing={2} sx={{ height: '100%', py: 3 }}>
							<Grid item xs={4} sx={{ p: '1 !important', width:'20%', display:'flex', alignItems: 'center'}}>
								<SvgWrapper svg={LanguageIcon} circle border iconSize="60%" backgroundColor="primary.main" sx={{width:'100%'}}/>
							</Grid>
							<Grid item xs={8} sx={{ pl: 4, justifyContent: 'center', alignContent: 'center' }}>
								<Header rank={4}  name="Total emissions value" sx={{fontSize: 'clamp(0.75rem, 1.4vw, 4rem)', whiteSpace: 'nowrap'}}>{metricWeightString(totalCarbon)}</Header>
								<Typography component="p" fontWeight="bold" sx={{fontSize: 'clamp(0.75rem, 0.75vw, 2rem)'}}>
									<CO2e define /> emitted
								</Typography>
							</Grid>
						</Grid>
					</ContentContainer>
				</Grid>
				<Grid item xs={6}>
					<ContentContainer style={style} backgroundColor="primary.alpha5">
						<Grid container spacing={0} sx={{ px: 1, py: 3 }}>
							<Grid item xs={12}>
								<Header rank={5} sx={{fontSize: 'clamp(0.75rem, 1.25vw, 3rem)'}}>
									that's <Typography component="span" color="primary" sx={{ fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit'}} aria-label="Total emissions comparison stat">{flightDist} long haul flights</Typography>
								</Header>
							</Grid>
							<Grid item xs={12}>
								<Typography component="p" fontWeight="bold" sx={{fontSize: 'clamp(0.75rem, 0.75vw, 2rem)'}}>
									from LHR to JFK <TooltipWrapper>This is calculated based on the Aviation Environment Federation's calculation of a return flight from LHR to JFK producing 671kg of emissions (<CO2e />).</TooltipWrapper>
								</Typography>
							</Grid>
						</Grid>
					</ContentContainer>
				</Grid>
			</Grid>
		</ContentPaper>
	);
}

export default CarbonTotal;