import { CircularProgress, Paper } from '@mui/material';

import UserTable from '../tables/UserTable';
import { EditUserButton } from '../edit/UserDialog';
import { Header, HeaderActions, PageSection } from '../common/LayoutComponents';
import { useWorkspace } from '../WorkspaceProvider';



function ManageUsers() {
	const { workspaceId, workspace, isFetched } = useWorkspace();

	if (!isFetched) return <PageSection><CircularProgress /> Loading Workspace</PageSection>;
	if (!workspace) return <PageSection>No workspace available</PageSection>;


	return (
		<Paper sx={{ backgroundColor: 'background.default', borderRadius: '24px', boxShadow: 0, p: 4, my: 6 }}>
			<Header rank={5}>
				Users for {workspace.name}
				<HeaderActions>
					<EditUserButton label="Add user to workspace" base={{workspaceId}} />
				</HeaderActions>
			</Header>
			<UserTable query={{workspaceId}} permissionGroup={workspaceId} editPermission />
		</Paper>
	);
}

export default ManageUsers;
