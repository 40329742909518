import { useMemo, useState } from 'react';

import { Box, Chip, SvgIcon, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import entity from '../../model/entity';

import PermissionWrapper from './PermissionWrapper';
import TextInputField from '../inputFields/TextInputField';
import DialogButton from '../widgets/DialogButton';
import UploadField from '../inputFields/UploadField';
import NumberField from '../inputFields/NumberField';
import CodeField from '../inputFields/CodeField';
import CreativePreview from './CreativePreview';
import SelectField from '../inputFields/SelectField';
import { useFormContext } from '../FormContextProvider';
import EntityDialog from './EntityDialog';

import FontControls from './FontControls';

import { InputLabel, usePathValue } from '../inputFields/InputWrapper';
import { Header } from '../common/LayoutComponents';
import { qrCodeTemplate, counterTemplate, unlockTemplate } from './creative/templates/templates';
import ColorField from '../inputFields/ColorField';
import ShowEntity from '../common/ShowEntity';
import ImageControls from './creative/ImageControls';
import TextControls from './creative/TextControls';
import SwitchField from '../inputFields/SwitchField';

import { ReactComponent as GoodLoopIcon } from '../../img/icons/gl-symbol-icon.svg';
import { SelectCampaign } from '../inputFields/SelectFromAPI';
import TemplateButton from '../../pages/Formats/TemplateButton';


const validateCreative = (authUser, creative) => {
	return creative.name && creative.brandId;
};


const videoTypeOptions = [{
	label: 'Good-Loop hosted',
	value: 'stream',
}, {
	label: 'VAST tag',
	value: 'vast',
}];


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Grid container xs={12} spacing={2}>{children}</Grid>
			)}
		</div>
	);
}

/**
 * Temporary safety hack: Don't allow uploading if the creative isn't attached to a brand
 * Uploaded assets get a DB entry which should be connected to a specific brand, for library-management purposes.
 */
function useUploadProps() {
	const { draft } = useFormContext();
	const { brandId } = draft;
	return useMemo(() => (brandId) ? (
		{ uploadParams: { brandId: brandId } }
	) : (
		{ disabled: true }
	), [brandId]);
}


/** Controls for basic setup, i.e. brand and charity. */
function BasicSetupTab() {
	const uploadProps = useUploadProps();
	const { draft } = useFormContext();

	return <>
		<Grid xs={12} sm={6}>
			<TextInputField label="Creative name" required fullWidth path="name" />
		</Grid>
		<Grid xs={6} sm={3} spacing={2}>
			<ShowEntity type="group" label="Brand" id={draft.brandId} />
		</Grid>
		<Grid xs={6} sm={3} spacing={2}>
			{/* Temporary hack: Allow assigning old creatives with null campaign. */}
			{draft.campaignId ? (
				<ShowEntity type="campaign" id={draft.campaignId} />
			) : (
				<SelectCampaign path="campaignId" />
			)}
		</Grid>
		<Grid xs={6} spacing={2}>
			<TextInputField label="Brand Name" fullWidth path="data.brand.name" />
		</Grid>
		<Grid xs={6} spacing={2}>
			<UploadField label="Brand Logo" type="image" fullWidth
				path="data.brand.logo" {...uploadProps}
			/>
		</Grid>
		<Grid xs={6} spacing={2}>
			<TextInputField label="Charity Name" fullWidth path="data.charity.name" />
		</Grid>
		<Grid xs={6} spacing={2}>
			<UploadField label="Charity Logo" type="image" fullWidth
				path="data.charity.logo" {...uploadProps}
			/>
		</Grid>
		<Grid xs={6} spacing={2}>
			<UploadField label="QR Code" type="image" fullWidth
				path="data.images.qrCode" {...uploadProps}
			/>
		</Grid>
	</>;
}


/** Controls for uploading and selecting the creative's video. */
function VideoTab() {
	const uploadProps = useUploadProps();
	const { draft } = useFormContext();
	const { data } = draft;

	return <>
		<Grid xs={12} sm={6} spacing={2}>
			<SelectField label="Video Type"
				options={videoTypeOptions} defaultValue="stream"
				path="data.video.type"
			/>
		</Grid>
		<Grid xs={12} sm={6} spacing={2}>
			{data?.video?.type === 'vast' ? (
				<TextInputField label="VAST tag" type="url" path="data.video.vastUrl" />
			) : (
				<UploadField label="Upload video" type="video" fullWidth
					path="data.video.streamUrl" {...uploadProps}
				/>
			)}
		</Grid>
		<Grid xs={12} sm={6} spacing={2}>
			<NumberField label="Seconds to unlock" fullWidth path="data.secondsToUnlock" />
		</Grid>
		<Grid xs={6} sm={3} spacing={2}>
			<SwitchField label="Autoplay" fullWidth path="data.video.autoplay"
				info="Try to autoplay video. If serving into VAST, this will be ignored: the hosting site will decide when to play."
			/>
		</Grid>
		<Grid xs={6} sm={3} spacing={2}>
			<SwitchField label="Mute" fullWidth path="data.video.mute"
				info="Start the video muted."
			/>
		</Grid>
	</>;
}


/** Toggle the Good-Loop ident between light and dark modes, for visibility on different backgrounds. */
function LogoModeField(props) {
	const { draft } = useFormContext();
	const value = usePathValue(draft, props.path);

	const demoChip = useMemo(() => {
		const sx = { backgroundColor: 'rgba(127, 127, 127, 0.5)', fontWeight: '400', color: value ? '#000' : '#fff' };
		return <Chip icon={<SvgIcon component={GoodLoopIcon} />} label="Good-Loop" sx={sx} />;
	}, [value]);

	return (
		<SwitchField label="Good-Loop Logo Colour" fullWidth size="small"
			labelOff="Light"
			labelOn="Dark"
			append={demoChip}
			{...props}
		/>
	);
}

/** "Select template" buttons. */
function SelectTemplate({boxProps, buttonProps}) {
	return (
		<Box display="flex" flexDirection="row" gap={1} {...boxProps}>
			<TemplateButton {...buttonProps} template={qrCodeTemplate} label="QR Code"  />
			<TemplateButton {...buttonProps} template={unlockTemplate} label="Watch To Unlock"  />
			<TemplateButton {...buttonProps} template={counterTemplate} label="Donation Counter" />
		</Box>
	);
}




/** Controls for basic setup: brand, charity, basic appearance. */
function DesignTab() {
	const uploadProps = useUploadProps();

	return <>
		<Grid xs={12}>
			<InputLabel>Creative Template</InputLabel>
			<SelectTemplate />
		</Grid>
		<Grid xs={4}>
			<ColorField label="Text Colour" fullWidth size="small"
				path="data.styling.color"
			/>
		</Grid>
		<Grid xs={4}>
			<ColorField label="Contrast Colour" fullWidth size="small"
				path="data.styling.contrastColor"
			/>
		</Grid>
		<Grid xs={4}>
			<LogoModeField path="data.styling.glLogoDark" />
		</Grid>

		<Grid xs={4} spacing={2}>
			<UploadField label="Background Image" type="image" fullWidth size="small"
				path="data.styling.bgImg"
				{...uploadProps}
			/>
		</Grid>
		<Grid xs={4}>
			<ColorField label="Background Colour" fullWidth size="small"
				path="data.styling.bgColor"
			/>
		</Grid>
		<Grid container xs={12}>
			<Grid xs={12} spacing={2} mt={3}>
				<Header rank={6}>Advert Text</Header>
				<TextControls path="text" />
			</Grid>
			<Grid xs={12} spacing={2} mt={3}>
				<Header rank={6}>Extra Images</Header>
				<ImageControls path="data.images.extra" uploadProps={uploadProps} />
			</Grid>
			<Grid xs={12} spacing={2} mt={3}>
				<Header rank={6}>Custom Fonts</Header>
				<FontControls uploadProps={uploadProps} />
			</Grid>
		</Grid>
	</>;
}


function AdvancedTab() {
	return <>
		<Grid container xs={12} spacing={2}>
			<Grid xs={6}>
				<ColorField label="Container Colour" fullWidth size="small"
					path="data.styling.containerColor"
				/>
			</Grid>
			<Grid xs={6}>
				<TextInputField label="Background Gradient" fullWidth size="small"
					path="data.styling.bg"
				/>
			</Grid>
			<CodeField label="Template XML" language="xml" required fullWidth path="template" />
			<CodeField label="CSS" language="css" required fullWidth path="css" />
		</Grid>
	</>;
}


const noPreviewSx = {
	height: '6em',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: '1px solid silver',
	fontSize: '125%',
};


function NoPreview(props) {
	return <Header rank="6" sx={noPreviewSx} {...props} />;
}

/** Placeholder for the creative preview */
function PreviewWrapper(props) {
	const { saved, draft, initial } = useFormContext();

	if (!saved || !draft.template || !initial.template) return <NoPreview>
		Select a template and save your work to preview your creative.
	</NoPreview>;

	return <CreativePreview />;
}


function CreativeFormFull() {
	const [tab, setTab] = useState(0);
	const onTabChange = (event, newTab) => setTab(newTab);
	return <>
		<Grid mb={1}>
			<Tabs value={tab} onChange={onTabChange} centered>
				<Tab label="Basic Setup" />
				<Tab label="Design" />
				<Tab label="Video" />
				<Tab label="Advanced" />
			</Tabs>
		</Grid>
		<PreviewWrapper />
		<Grid mt={1} width="100%">
			<TabPanel value={tab} index={0}>
				<BasicSetupTab />
			</TabPanel>
			<TabPanel value={tab} index={1}>
				<DesignTab />
			</TabPanel>
			<TabPanel value={tab} index={2}>
				<VideoTab />
			</TabPanel>
			<TabPanel value={tab} index={3}>
				<AdvancedTab />
			</TabPanel>
		</Grid>
	</>;
}

const initialSelectProps = {
	boxProps: { justifyContent: 'center' },
	buttonProps: { size: 'large' }
};

function CreativeFormInitial() {

	return <Box my={4} textAlign="center">
		<Header rank="6" mb={2}>Select a template to begin.</Header>
		<SelectTemplate {...initialSelectProps} />
	</Box>;
}


function CreativeFormFields() {
	const { draft } = useFormContext();

	return (
		<Grid container direction="column" spacing={2} width="100%">
			{draft.template ? <CreativeFormFull /> : <CreativeFormInitial />}
		</Grid>
	);
};


function CreativeDialog({onUpdateDraft, ...props}) {
	return (
		<EntityDialog type={entity.CREATIVE} validateFn={validateCreative} canDelete {...props} >
			<CreativeFormFields />
		</EntityDialog>
	);
}


function EditCreativeButton({label = 'Edit Creative', ...props}) {
	return (
		<PermissionWrapper type={entity.CREATIVE} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={CreativeDialog} closeOnCommit={false} {...props} />
		</PermissionWrapper>
	);
}


export default CreativeDialog;
export { CreativeFormFields, EditCreativeButton, validateCreative };
