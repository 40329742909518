const NONE = 'NONE';
const READ = 'READ';
const WRITE = 'WRITE';
const ADMIN = 'ADMIN';
const IMPOSSIBLE = 'IMPOSSIBLE';


/** Set of string constants for easy reference */
const permissionLevels = { NONE, READ, WRITE, ADMIN, IMPOSSIBLE };


/** Mapping of enum values to display names */
const permissionLevelNames = {
	NONE: 'None',
	READ: 'Read',
	WRITE: 'Write',
	ADMIN: 'Admin',
	IMPOSSIBLE: 'Impossible'
};


/** Permissions as {label, value} options for a <SelectField>. */
const permissionLevelOptions = [NONE, READ, WRITE, ADMIN].map(value => ({ label: permissionLevelNames[value], value }));


/** Describes the capabilities of a user with each permission level on a group */
const groupDescs = {
	NONE: [
		'You have no permissions in this group.',
		'You can still assign more specific permissions in sub-groups.'
	],
	READ: [
		'View the group and its descendants.',
		'View campaigns and tags under the group.'
	],
	WRITE: [
		'View the group and its descendants.',
		'View, create, edit, and delete campaigns and tags under the group.'
	],
	ADMIN: [
		'View and edit the group and its descendants.',
		'View, create, edit, and delete campaigns and tags under the group.',
		'Add and remove sub-groups and users.',
		'Grant users access to the group and its descendants.'
	],
	IMPOSSIBLE: []
};


/** Describes the capabilities of a user with each global permission level. */
const globalDescs = {
	NONE: ['Don\'t grant this user any global permissions.'],
	READ: ['View all groups, users, campaigns, tags, permissions, and price plans.'],
	WRITE: ['Create, edit, and delete campaigns and tags belonging to any group.'],
	ADMIN: ['Create and delete workspaces and global users.', 'Assign global permissions.', 'Edit and assign price plans.'],
	IMPOSSIBLE: [],
};

// Global permission descriptions don't need so much rewording, just stack the descriptions up.
globalDescs[WRITE].unshift(...globalDescs[READ]);
globalDescs[ADMIN].unshift(...globalDescs[WRITE]);



/** Turn a string into a permission level key, tolerating case & padding differences */
const getLevel = level => (
	permissionLevels[level]
	|| permissionLevels[level?.trim().toUpperCase()]
	|| NONE
);


/** Arbitrary but comparable numeric values for each permission. */
const numerics = {
	NONE: 0,
	READ: 10,
	WRITE: 100,
	ADMIN: 1000,
	IMPOSSIBLE: Infinity,
};


/**
 * Returns true if the granted permission level meets or exceeds the target.
 * @param {String} granted The actor's permission level.
 * @param {String} target The permission level required by the action.
 */
const isSufficientPermission = (granted, target) => {
	target = getLevel(target);
	granted = getLevel(granted);
	// No granted permission is enough to execute an IMPOSSIBLE action (but NONE can execute NONE).
	if (target === IMPOSSIBLE) return false;
	// Everything else can just be done with a numeric comparison.
	return numerics[granted] >= numerics[target];
};


export {
	permissionLevels,
	permissionLevelNames,
	permissionLevelOptions,
	isSufficientPermission,
	groupDescs as groupPermissionDescriptions,
	globalDescs as globalPermissionDescriptions,
};
