import { DataGrid, GridRow } from '@mui/x-data-grid';
import { useEntities } from '../../state/apiHooks';
import { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import EntityContextProvider from '../EntityContextProvider';


const tableSx = {
	my: 2,
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'bold',
		fontSize: 'large',
	},
	'& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
		border: 'solid 1px rgba(0,0,0,0.1)',
	},
};


const defaultColumns = [
	{field: 'id', headerName: 'ID' },
	{field: 'name', headerName: 'Name'},
];


/** Add an EntityContextProvider around every grid row for inline actions. */
function EntityRow({type, ...props}) {
	return <EntityContextProvider type={type} entity={props.row}>
		<GridRow {...props} />
	</EntityContextProvider>;
}



function CommonEntityTable({ type, rows = [], columns = defaultColumns, BulkActions, ...props}) {
	// TODO Refactor backend to attach a "type" field to every entity so this kind of code can be skipped
	// Also TODO quit using DataGrid but that will be useful no matter what
	const gridSlots = useMemo(() => {
		if (!type) return { row: GridRow }; // No type prop = we can't provide an entity context
		// inform the row wrapper of the entity type
		function row(rowProps) { return <EntityRow type={type} {...rowProps} />; };
		return { row };
	}, [type]);

	return <DataGrid rows={rows} columns={columns}
		pageSizeOptions={[25, 50, 100]} sx={tableSx}
		initialState={{pagination: { paginationModel: { pageSize: 25 } }}}
		autosizeOnMount slots={gridSlots}
		{...props}
	/>;
}


function FetchingEntityTable({query, processRows, ...props}) {
	const { data: rawRows, isPending} = useEntities(props.type, query);

	const rows = useMemo(() => {
		if (!processRows) return rawRows;
		return processRows(rawRows);
	}, [rawRows, processRows]);

	return <CommonEntityTable rows={rows} loading={isPending} {...props} />;
}


function EntityTable({columns, actions, ...props}) {
	// Add dynamic "Actions" column with Show/Edit/etc buttons
	const _columns = useMemo(() => {
		if (!actions) return columns;
		function RenderActions(props) {
			return <Grid container spacing={1}>
				{actions.map((Action, i) => <Grid key={i}><Action {...props} /></Grid>)}
			</Grid>;
		};
		const ActionColumn = { field: 'actions', renderCell: RenderActions, headerName: 'Actions', flex: 1 };
		return [...columns, ActionColumn];
	}, [columns, actions]);

	// Data is provided by caller - put it straight in a table
	if (props.rows) return <CommonEntityTable columns={_columns} {...props} />;

	// Data not provided - fetch using useEntities, type, query
	return <FetchingEntityTable columns={_columns} {...props}/>;
}


export default EntityTable;
