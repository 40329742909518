import { useEffect, useState } from 'react';
import { Button, styled } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useUploadFileHandler } from '../../state/apiHooks';


const HiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});


const progressSx = {position: 'absolute', width: '100%', height: '100%', top: 0, right: 0, opacity: 0.8, fontWeight: 'bold', lineHeight: 1.5};


/** Overlays a progress readout on the "Upload" button */
function UploadProgress({fraction}) {
	if (fraction == null) return null;
	const text = (fraction === 1) ? 'Upload Complete' : `${Math.ceil(fraction * 100)}%`;
	return <Button variant="contained" color="secondary" sx={progressSx}>{text}</Button>;
}


/**
 * A file input wrapped in a pretty button. TODO Should accept drops as well.
 * @param {object} props
 * @param {string} props.type "video", "image", "font"
 * @param {object} [props.uploadParams] Will be appended as form fields alongside the file upload
 * @param {Function} [props.onComplete] Called with the Media entity corresponding to the uploaded file on success.
 * @param {boolean} [props.disabled]
 */
function UploadButton({type, onComplete, uploadParams: params, ...props}) {
	// Display percentage uploaded while in progress
	const [progress, setProgress] = useState(null);
	// Show "complete" state for 1 second before returning to base state.
	useEffect(() => {
		if (progress === 1) setTimeout(() => setProgress(null), 1000);
	}, [progress, setProgress]);

	const uploadHandler = useUploadFileHandler(type, {setProgress, onComplete, params});

	return (
		<Button
			component="label"
			role={undefined}
			variant="contained"
			tabIndex={-1}
			startIcon={<CloudUploadIcon />}
			disabled={progress != null}
			{...props}
		>
			Upload
			<HiddenInput type="file" onChange={uploadHandler} />
			<UploadProgress fraction={progress} />
		</Button>
	);
}

export default UploadButton;
