import { Typography, Grid, CircularProgress} from '@mui/material';
import { ContentPaper, ContentContainer, TooltipWrapper } from './MetricComponents';
import { metricWeightString} from '../../utilities/utils';
import { Header} from '../../components/common/LayoutComponents';
import { ReactComponent as GraphIcon } from '../../img/icons/graph.svg';
import { SvgWrapper} from '../../components/common/Icons';
import { CO2e, CO2ePM } from './MetricComponents';

function CarbonEPM({ epm, chartData }) {
	const style = { alignContent: 'center', pr: '0 !important' };

	if (!epm || !chartData) {
		return <ContentPaper style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress /></ContentPaper>;
	}

	/**
	 * Note this is assuming a rolling fortnight AND daily data
	 */
	const fortnightData = chartData.totalCO2EPM.slice(-14);
	const thisWeek = fortnightData.slice(7, 14).reduce((sum, cur) => sum + cur, 0);
	const lastWeek = fortnightData.slice(0, 7).reduce((sum, cur) => sum + cur, 0);
	const epmComparison = Math.round((thisWeek - lastWeek) * 1000) / 1000;

	return (
		<ContentPaper>
			<Grid container spacing={2} >
				<Grid item xs={6}>
					<ContentContainer style={style}>
						<Grid container spacing={2} xs={12} sx={{ py: 3 }}>
							<Grid item xs={4} sx={{ p: '1 !important', display:'flex', alignItems: 'center' }}>
								<SvgWrapper svg={GraphIcon} circle border iconSize="40%" backgroundColor="primary.main"  sx={{width:'100%'}}/>
							</Grid>
							<Grid container spacing={0} xs={8} sx={{ pl: 4, alignContent: 'center' }}>
								<Grid item xs={12}>
									<Header rank={4} name="Co2epm value" sx={{fontSize: 'clamp(0.5rem, 1.5vw, 4rem)'}}>{metricWeightString(epm)}</Header>
								</Grid>
								<Grid item xs={12}>
									<Typography component="p" fontWeight="bold" sx={{fontSize: 'clamp(0.5rem, 0.75vw, 2rem)'}}>
										<CO2ePM define />
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ContentContainer>
				</Grid>
				<Grid item xs={6} >
					<ContentContainer style={style} backgroundColor="primary.alpha5">
						<Grid container spacing={0} xs={12} sx={{ px: 1, py: 3 }}>
							<Grid item xs={12}>
								<Header rank={5} color="primary" name="Co2epm weekly comparison" sx={{fontSize: 'clamp(0.5rem, 1.25vw, 3rem)'}}>
									{Math.abs(epmComparison)} kg {epmComparison > 0 ? 'higher' : 'lower'}
								</Header>
							</Grid>
							<Grid item xs={12}>
								<Typography component="p" fontWeight="bold" sx={{fontSize: 'clamp(0.5rem, 0.75vw, 2rem)'}}>
									than last week <TooltipWrapper>Compares total sum of <CO2e /> emitted over the last 14 days, split into two 7 day sets.</TooltipWrapper>
								</Typography>
							</Grid>
						</Grid>
					</ContentContainer>
				</Grid>
			</Grid>
		</ContentPaper>
	);
}

export default CarbonEPM;