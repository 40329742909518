import { LoginPage, LoadingPage, NoAccountPage, NoPermissionsPage, UnverifiedPage } from './components/LoginFlow';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthUser } from './state/apiHooks';
import DemoPage from './pages/DemoPage';

const isStudio = process.env.REACT_APP_GOOD_MEASURES_FOR === 'studio';

/** Hack: Studio builds skip auth check for /preview route. */
function isDemoPage() {
	return isStudio && window.location.pathname.match(/^\/preview/);
}

function AppWrapper({MainComponent}) {
	// Is the user authenticated?
	const { isAuthenticated, user: auth0User, isLoading: authIsLoading } = useAuth0();
	// Are they authenticated as someone with an account?
	const { data: user, isPending: userPending } = useAuthUser();
	// Route /preview to demo page without auth check on studio
	if (isDemoPage()) return <DemoPage />;
	// Waiting for Auth0 to respond
	if (authIsLoading) return <LoadingPage />;
	// No Auth0 auth - user needs to log in.
	if (!isAuthenticated) return <LoginPage />;
	// Email address unverified.
	if (!auth0User.email_verified) return <UnverifiedPage />;
	// Waiting for business store to return user.
	if (userPending) return <LoadingPage />;
	// Authenticated but no user - user needs to have an account created.
	if (!user) return <NoAccountPage />;
	// User doesn't have any permissions at all.
	if (user.hasNoPermissions) return <NoPermissionsPage />;
	// Good to go!

	return <MainComponent user={user} />;
}


export default AppWrapper;
