import { ComponentProps, useCallback, useMemo, useState, type ReactElement } from 'react';
import { set as cloneAndSet } from 'lodash/fp';

import { FormContext, FormContextType, StateSetter, useFormContext } from './FormContextProvider';
import { getPath, usePathValue } from './inputFields/InputWrapper';
import { clone, isArray, isEqual, mapValues } from 'lodash';
import useLiveRef from '../utilities/useLiveRef';


/** A form used to edit one sub-item within an object or array. */
type ItemFormComponent = (props: {
	/** The sub-item's index inside its container. Provided for display purposes. */
	index: number|string;
}) => ReactElement;


/** Common props signature for {@link ArraySubForm} and {@link ObjectSubForm}. */
interface SubFormProps {
	/** The path to the object or array this sub-form should manage. */
	path: string;
	/** A component which renders a form for editing one sub-item. */
	ItemForm: ItemFormComponent;
}



/** Props signature for the {@link SubFormItem} wrapper which provides context to each {@link ItemFormComponent}. */
interface SubFormContextProps extends ComponentProps<any> {
	/** The sub-item's index inside the target container. */
	index: string|number;
	/** The outer form's context object. */
	context: FormContextType<any>;
	/** The target sub-trees of the outer context's base/initial/draft objects. */
	containers: {
		/** Target sub-tree of the outer context's {@link FormContextType.base} object. */
		base: object;
		/** Target sub-tree of the outer context's {@link FormContextType.initial} object. */
		initial: object;
		/** Target sub-tree of the outer context's {@link FormContextType.draft} object. */
		draft: object;
	}
	/** Accepts & invokes a function which performs an in-place mutation on a clone of the target container. */
	updateFn: (mutator: (target: any) => void) => void;
	/** Array or object-specific mutator which removes (by delete or splice) the item at {@link index} from {@link target}.*/
	deleter: (target: any, index: string|number) => void;
	/** Array or object-specific mutator which moves the item at {@link fromIndex} to {@link toIndex}. */
	mover: (target: any, fromIndex: string|number, toIndex: string|number) => void;
}


/** Constructs a FormContext which acts as a window onto a sub-tree of the containing FormContext. */
function SubFormContextProvider({index, updateFn, deleter, mover, containers, context, ...props}: SubFormContextProps) {
	// Pull out the target index from the base/initial/draft containers.
	const { base, initial, draft } = mapValues(containers, obj => obj?.[index]);

	// Insert mutators in container update function to make update, delete, move functions for this item
	const setDraft = useCallback(value => updateFn(next => {
		next[index] = (typeof value === 'function' ? value(next[index]) : value);
	}), [updateFn, index]);
	const deleteFn = useCallback(() => updateFn(next => deleter(next, index)), [updateFn, deleter, index]);
	const moveFn = useCallback(toIndex => updateFn(next => mover(next, index, toIndex)), [updateFn, mover, index]);

	// Replace root processingInput / setProcessingInput with sub-form-specific ones
	// Input on sub-form implies input on root form - but input on root form doesn't imply input on sub-form.
	const rootSetProcessingInput = context.setProcessingInput;
	const [processingInput, setProcessingInputRaw] = useState(false);
	const setProcessingInput = useCallback(value => {
		setProcessingInputRaw(value); rootSetProcessingInput(value);
	}, [setProcessingInputRaw, rootSetProcessingInput]);

	// Replace root "changed" flag with sub-form-specific one
	const changed = useMemo(() => (
		processingInput ? undefined : !isEqual(draft, initial)
	), [processingInput, draft, initial]);

	// Construct sub-context
	const subContext = Object.assign({}, context, {
		base, initial, draft, setDraft,
		processingInput, setProcessingInput, changed,
		actionFns: { delete: deleteFn, move: moveFn }
	});

	// Remove root submitFn for consistency: delete/move don't act on the root object here
	delete subContext.submitFn;

	return <FormContext.Provider value={subContext} {...props} />;
}


const objDeleter = (next, key) => delete next[key];
const arrayDeleter = (next, key) => next.splice(key, 1);

const objMover = (next, fromKey, toKey) => {
	next[toKey] = next[fromKey];
	delete next[fromKey];
};

const arrayMover = (next, fromKey, toKey) => {
	next.splice(toKey, 0, next.splice(fromKey, 1)[0]);
};


/**
 * Returns the keys of an object in a stable order. (For arrays, this just returns the indices.)
 * If keys are simultaneously added and removed, the new key will be inserted in place of the removed one
 * - on the understanding that this was probably a re-key operation.
 */
function useStableKeys(draft: any) {
	// Remember previous ordering for comparison.
	const [prevKeys, setPrevKeys] = useState(null);
	// No update loops - hide prevKeys value from useMemo deps.
	const prevKeysRef = useLiveRef(prevKeys);

	const keys = useMemo(() => {
		if (!draft) return [];
		// Array: Use natural ordering
		if (isArray(draft)) return Object.keys(draft);
		const _prevKeys = prevKeysRef.current;
		// First run: Sort items by key
		if (!_prevKeys) return Object.keys(draft).sort();
		// Subsequent runs: check for new keys...
		const added = Object.keys(draft).filter(key => !_prevKeys.includes(key));

		// Update the ordered key list, try to preserve position of entries with changed keys.
		const orderedKeys = _prevKeys.map(key => {
			// Try to replace removed keys with newly added (will insert undefined if added is empty)
			return (key in draft)? key : added.shift();
		}).filter(a => a != null); // Remove undefined
		// Append remaining added keys
		orderedKeys.push(...added);
		return orderedKeys;
	}, [draft, prevKeysRef]);

	if (prevKeys !== keys) setPrevKeys(keys);

	return keys;
}


/**
 * Returns an update function which can be used to set, delete, or move values:
 * - clones the target container from the previous draft
 * - mutates the clone (e.g. overwrites, moves, or deletes one index)
 * - reinserts the clone in a copy of the root object.
 */
function useUpdateFn(path: string, setDraft: StateSetter<any>) {
	return useCallback(mutator => {
		setDraft(prev => {
			const next = clone(getPath(prev, path));
			mutator(next);
			return cloneAndSet(path, next, prev);
		});
	}, [path, setDraft]);
}


/**
 * Allows editing collections of similar sub-objects in a container (array or key-value object) within a FormContext.
 * @param path The path string where the target container is found
 * @param ItemForm Will be rendered for each item in the target container, and provided with a FormContext whose root is that item.
 */
function SubForm({path, ItemForm}: SubFormProps) {
	const context = useFormContext();

	// Pull out the target containers from context so the sub-forms only have to do a simple index instead of get-by-path.
	const containers = {
		base: usePathValue(context.base, path),
		initial: usePathValue(context.initial, path),
		draft: usePathValue(context.draft, path),
	};

	// Construct updater for set/move/delete
	const updateFn = useUpdateFn(path, context.setDraft);

	// Assign appropriate move/delete mutators for type of draft object.
	// NB if draft is nullish this is irrelevant, since there's nothing to delete or move.
	const draftIsArray = isArray(containers.draft);
	const deleter = draftIsArray ? arrayDeleter : objDeleter;
	const mover = draftIsArray ? arrayMover : objMover;

	// Collect properties which are passed unmodified to all sub-forms
	const subProps = {updateFn, deleter, mover, containers, context};

	const keys = useStableKeys(containers.draft);
	return <>
		{keys?.map((key, index) => {
			// Why are key and index reversed? Because:
			// (a) We want stable list ordering (including when items get their keys changed) for UX reasons
			// (b) We want item identity bound to position (so re-keyed items don't get replaced & shake off user focus)
			// (c) The built-in React prop that governs identity for array items is called "key" and we can't change that
			// ...and so we pass array index to the "key" prop, and the item key to the pedantically correct "index" prop.
			return <SubFormContextProvider key={index} index={key} {...subProps}>
				<ItemForm index={key} />
			</SubFormContextProvider>;
		})}
	</>;
}


/** A component which accepts a path string as one of its props. */
type AcceptsPath = (props: {
	/** Path string designating the component's target. */
	path: string;
} & any) => ReactElement;


/** A component which presents controls for adding new sub-items to a container (array or key-value object). */
type AddItemComponent = (props: {
	/** Function which inserts a new item in the target container, appending if it's an array or under the specified key if not. */
	addItem: (value?: any, key?: string|number,) => void;
} & any) => ReactElement;


/**
 * Higher-order component whose output component:
 * - intercepts string prop "path", designating a target sub-tree in the nearest FormContext
 * - constructs a function which inserts or appends a new entry in the target container
 * - supplies a that function to the wrapped component as prop "addItem"
 * @param Form The form component to wrap.
 */
function itemAdder(Form: AddItemComponent): AcceptsPath {
	return function(props) {
		const { setDraft } = useFormContext();
		const { path } = props;

		const addItem = useCallback((value, key) => {
			setDraft(prev => {
				let nextContainer = clone(getPath(prev, path));
				// If the container doesn't exist yet, but no key has been supplied, create it as an array.
				nextContainer ??= (key == null) ? [] : {};
				// Absent key as "append to array"
				key ??= nextContainer?.length;
				nextContainer[key] = value;
				// Reinsert updated container in root draft.
				return cloneAndSet(path, nextContainer, prev);
			});
		}, [path, setDraft]);

		return <Form {...props} addItem={addItem} />;
	};
}


export { SubForm, itemAdder };
