import { useMemo, useState } from 'react';
import { clone } from 'lodash';

import { Button, DialogProps, type ButtonProps } from '@mui/material';

import { type EntityTypes } from '../../model/types/Entity';
import { useEntities, useEntity } from '../../state/apiHooks';
import EntityDialog from '../edit/EntityDialog';
import TextInputField from '../inputFields/TextInputField';
import { entityDisplayName } from '../../model/entity';



interface CloneBaseProps<T extends keyof EntityTypes> extends Omit<ButtonProps<any>, 'type'> {
	/** Entity type name. */
	type: T;
	/** Entity ID. */
	id: string;
	/** Callback which receives the new entity on successful clone */
	commitThen?: (entity: EntityTypes[T]) => any;
}

type CloneButtonProps<T extends keyof EntityTypes> = CloneBaseProps<T> & Omit<ButtonProps<any>, 'type'>;
type CloneDialogProps<T extends keyof EntityTypes> = CloneBaseProps<T> & DialogProps;



/** Regex for identifying the "(copy)" or "(copy N)" marker on a cloned entity, and extracting the iteration number from it. */
const COPY_MARKER = /\s+\(copy(?:\s(\d+))?\)$/;


function validateClone(authUser, entity) {
	return !!entity.name;
};


function CloneDialog<T extends keyof EntityTypes>({type, id, ...props}: CloneDialogProps<T>) {
	// /*
	// Reinstate when server-side clone operation is merged?
	// Maybe - this interim step of setting the name feels like good UX.
	// */
	// const cloneFn = useCloneEntity(type);
	// const onClick = useCallback(() => {
	// 	cloneFn({id}).then(newClone => {
	// 		cloneThen?.(newClone as unknown as ET);
	// 	});
	// }, [cloneFn, cloneThen, id]);

	type ET = EntityTypes[T];
	const { data: entity } = useEntity(type, id) as { data: ET };

	/** Query to fetch entities with same prefix name as the clone target. */
	const copiesQuery = useMemo(() => ({'!keyword': entity?.name?.replace(COPY_MARKER, '')}), [entity]);
	const { data: copies } = useEntities(type, copiesQuery, {enabled: props.open && entity}) as { data: ET[] };

	// Construct a draft with a somewhat sensible default name.
	props.initial = useMemo(() => {
		if (!entity || !copies) return null;
		const cd = clone(entity);
		cd.id = null;

		// What's the highest iteration number in all the maybe-copy entities?
		const number = copies?.reduce((num, copy) => {
			const suffixMatch = copy.name.match(COPY_MARKER);
			if (!suffixMatch) return num; // No suffix = ignore
			const copyNumber = parseInt(suffixMatch?.[1]) || 1; // "(copy)" with no number = iteration 1
			return Math.max(num, copyNumber + 1);
		}, 0);
		const newMarker = ` (copy${number ? ` ${number}` : ''})`; // ie "(copy N)" or "(copy)"
		cd.name = entity.name.replace(COPY_MARKER, '') + newMarker;
		return cd;
	}, [entity, copies]);


	props.title = useMemo(() => (
		`Clone ${entityDisplayName(type)}: ${entity?.name || id}`
	), [entity, type, id]);

	return <EntityDialog type={type} validateFn={validateClone} {...props} submitLabel="Create Clone" enabled={props.initial}>
		<TextInputField path="name" label="Name" required fullWidth />
	</EntityDialog>;
}


/** Button which creates a clone of the specified entity, with a "(copy)" marker on the name. */
function CloneButton<T extends keyof EntityTypes>({type, id, commitThen, children, ...props}: CloneButtonProps<T>) {
	const [open, setOpen] = useState(false);
	const [onClick, onClose] = useMemo(() => [() => setOpen(true), () => setOpen(false)], [setOpen]);

	return <>
		<Button variant="outlined" color="secondary" onClick={onClick} {...props}>
			{children || 'Clone'}
		</Button>
		<CloneDialog type={type} id={id} commitThen={commitThen} open={open} onClose={onClose} />
	</>;
};


export default CloneButton;
