import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { downloadBlob } from '../../utilities/utils';
import { useAccessToken } from '../../state/hooks';


interface AdExport {
	name: string;
	id: string;
	vastUrl: string;
	directTag: string;
}

/**
 * Nasty copy-paste hack: Standard API functions are deeply locked to the /api prefix, but it's VERY
 * hard to put the export code anywhere but ServeAdApiController - which is unprefixed at /ad.
 */
function useAdApi(path, queryKey, enabled = true) {
	const accessToken = useAccessToken();
	enabled &&= !!accessToken; // Don't try to make the request until auth token is available.

	return useQuery({
		queryKey: [accessToken, ...queryKey],
		queryFn: () => {
			const url = `${process.env.REACT_APP_BUSINESS_STORE_API_ENDPOINT}${path}`;

			return fetch(url, {
				headers: { Authorization: `Bearer ${accessToken}`, Accept: '*' }
			}).then(response => {
				if (!response.ok) throw response;
				return response.json();
			});
		},
		enabled
	});
}


/** Bulk export a collection of line items by query. */
const useExports = (query, enabled) => {
	let path = '/ad/export';
	if (query) path +=  `?${new URLSearchParams(query).toString()}`;
	const queryKey = ['lineItemExport', 'query', query ? Object.entries(query) : 'all'];
	return useAdApi(path, queryKey, enabled);
};



/** Export a single line item by ID. */
const useExport = (id, enabled = true) => {
	const path = `/ad/export/${id}`;
	const queryKey = ['lineItemExport', 'getById', id];
	return useAdApi(path, queryKey, enabled);
};



function ExportDialog({open, onClose, query}) {
	const { data: adExports } = useExports(query, open) as { data: AdExport[] };

	const onClickDownload = useCallback(() => {
		if (!adExports) return null;

		const rows = [['Line Item', 'ID', 'VAST tag', 'HTML tag']];
		for (let ae of adExports) {
			rows.push([ae.name, ae.id, ae.vastUrl, ae.directTag]);
		}
		const rowStrings = rows.map(row => (
			row.map(cell => {
				if (!cell?.match(',')) return cell;
				return `"${cell.replace(/"/g, '""')}"`;
			}).join(',')
		));
		const csv = rowStrings.join('\n');
		downloadBlob(new Blob([csv], {type: 'text/csv'}), 'AdExport.csv');
	}, [adExports]);


	return <Dialog open={open} onClose={onClose}>
		<DialogTitle>Export Ad Tags</DialogTitle>
		<DialogContent>
			{onClickDownload ? (
				<Button variant="contained" color="primary" onClick={onClickDownload}>Download .csv</Button>
			) : <CircularProgress />}
		</DialogContent>
	</Dialog>;
}


function ExportAdCSVButton({query, children}) {
	const [open, setOpen] = useState(false);
	const [onClick, onClose] = useMemo(() => [
		() => setOpen(true),
		() => setOpen(false)
	], [setOpen]);

	return <>
		<Button color="primary" variant="contained" onClick={onClick}>{children}</Button>
		<ExportDialog query={query} open={open} onClose={onClose} />
	</>;
}


function ExportButtonBase({lineItem, exportField, ...props}) {
	const { data: liExport } = useExport(lineItem.id);

	const onClick = useMemo(() => {
		if (!liExport) return null;
		return () => navigator.clipboard.writeText(liExport[exportField]);
	}, [liExport, exportField]);

	return <Button variant="contained" color="primary" onClick={onClick} disabled={!onClick} {...props} />;
}


/** Button which copies one LineItem's vast.xml URL to the clipboard. */
function ExportVASTButton(props) {
	return <ExportButtonBase exportField="vastUrl" {...props} />;
}


/** Button which copies one LineItem's direct HTML tag to the clipboard. */
function ExportDirectButton(props) {
	return <ExportButtonBase exportField="directTag" {...props} />;
}


export default ExportAdCSVButton;
export { ExportVASTButton, ExportDirectButton };
