import Grid from '@mui/material/Unstable_Grid2/Grid2';


import { CO2e, CO2ePM, ContentContainer, ContentPaper, TooltipWrapper } from './MetricComponents';
import { SvgWrapper } from '../../components/common/Icons';
import { Header } from '../../components/common/LayoutComponents';
import { useMergeSx } from '../../utilities/utils';

import { ReactComponent as PieChartSvg } from '../../img/icons/piechart.svg';
import { ReactComponent as GraphSvg } from '../../img/icons/graph.svg';
import { ReactComponent as WorldSvg } from '../../img/icons/globe-2.svg';
import { metricWeightString } from '../../utilities/utils';
import { CircularProgress } from '@mui/material';


/** Common layout for the three headline blocks */
function HeadlineBlock({loading, icon, iconSize = '50%', headline, info, name, percentile = false}) {
	return (
		<Grid xs={4} sx={{py:0, my:1, height: '100%'}}>
			<ContentContainer sx={{height: '100%', width: '10%', px:'8px !important'}}>
				<Grid container height="100%">
					<Grid xs={4} width="25%" sx={{ml:2}}>
						<SvgWrapper svg={icon} border circle iconSize={iconSize} backgroundColor="primary.main" sx={{width:'100%', height:'100%'}}/>
					</Grid>
					<Grid xs={8} pl={3} alignContent="center" height="100%" display="flex" flexDirection="column">
						{loading ? <CircularProgress /> : <>
							<Header rank={4} color={percentile ? "primary" : "black"} name={name} sx={{fontSize: 'clamp(0.5rem, 1.5vw, 4rem)', whiteSpace:'nowrap', height: '50%', display: 'flex', alignItems: 'flex-end'}}>{headline}</Header>
							<Header rank={6} sx={{fontSize: 'clamp(0.5rem, 1vw, 2rem)', lineHeight: '1.25', mb:'fill', height: '50%'}}>{info}</Header>
						</>}
					</Grid>
				</Grid>
			</ContentContainer>
		</Grid>
	);
}




/** Fraction of CO2e emissions attributable to this part of the breakdown */
function RatioBlock({part, total, loading}) {
	const headline = total && `${Math.round(part / total * 100)}%`;
	return <HeadlineBlock loading={loading} icon={PieChartSvg} iconSize="60%" name="Headline ratio" headline={headline} info="of your total emissions" percentile = {true}/>;
}


/** Mass of CO2e emissions attributable to this part of the breakdown */
function AbsoluteBlock({part, loading}) {
	const headline = part && metricWeightString(part);
	return <HeadlineBlock loading={loading} icon={WorldSvg} iconSize="60%" name="Headline Total" headline={headline} info={<><CO2e define /> emitted</>} />;
}


/** CO2e emissions per thousand impressions attributable to this part of the breakdown */
function PMBlock({pm, loading}) {
	const headline = pm && metricWeightString(pm);
	return <HeadlineBlock loading={loading} icon={GraphSvg} iconSize="50%" name="Headline co2epm" headline={headline} info={<CO2ePM define />} />;
}


/**
 * Common block of 3 headline figures for distribution/consumption emissions:
 * - "X% of your total emissions" (ie the fraction attributable to this part of the breakdown)
 * - "Y kg CO2e" (the absolute mass of this part of the breakdown)
 * - "Z g CO2e per thousand impressions"
 * @param {*} props
 * @param {boolean} props.loading
 * @param {number} props.part The relevant part of the overall emissions (ie distribution/consumption) (g co2e)
 * @param {number} props.total The overall emissions (g co2e)
 * @param {number} props.pm The normalised emissions per thousand impressions (g co2e)
 */
function Headlines(props) {
	const { sx, loading, ...otherProps } = props;
	const mergedSx = useMergeSx({height: '100%', px:1}, sx);
	return (
		<ContentPaper sx={mergedSx}>
			<Grid container spacing={2} sx={{height: '100%', m:0}}>
				<RatioBlock {...props} />
				<AbsoluteBlock {...props} />
				<PMBlock {...props} />
			</Grid>
		</ContentPaper>
	);
}

export default Headlines;
