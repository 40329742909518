import { createContext, useCallback, useContext, useMemo } from 'react';
import useSearchParam from '../../../state/useSearchParam';
import { noop } from 'lodash';


interface MetricsFilterParams {
  groupId?: string | null;
  campaignId?: string | null;
  tagId?: string | null;
  start?: string | null;
  end?: string | null;
  channel?: string | null;
}

type MetricsFilterContextType = [MetricsFilterParams, (params: Partial<MetricsFilterParams>) => void];

const baseContext: MetricsFilterContextType = [null as any, noop];

const MetricsFilterContext = createContext<MetricsFilterContextType>(baseContext);


/** Provides a context containing the current Metrics filter bank */
function MetricsFilterProvider({children}) {
	const [groupId, setGroupId] = useSearchParam('group');
	const [campaignId, setCampaignId] = useSearchParam('campaign');
	const [tagId, setTagId] = useSearchParam('tag');
	const [start, setStart] = useSearchParam('start');
	const [end, setEnd] = useSearchParam('end');
	const [channel, setChannel] = useSearchParam('channel');

	// Keep object identity stable unless values change
	const filter = useMemo(() => ({
		groupId, campaignId, tagId, start, end, channel
	}), [groupId, campaignId, tagId, start, end, channel]);

	// Prepare map of key names to setter functions
	const setters = useMemo(() => ({
		groupId: setGroupId, campaignId: setCampaignId, tagId: setTagId, start: setStart, end: setEnd, channel: setChannel
	}), [setGroupId, setCampaignId, setTagId, setStart, setEnd, setChannel]);

	// Receive new filter object & copy all recognised values to URL
	const setFilter = useCallback(newFilter => {
		for (let key in setters) {
			const newValue = newFilter[key];
			const setter = setters[key];
			/* eslint-disable-next-line eqeqeq */  // Allow type coercion as URL values are always strings
			if (newValue == filter[key] || !setter) continue;
			setter(newValue);
		}
	}, [filter, setters]);

	return <MetricsFilterContext.Provider value={[filter, setFilter]}>
		{children}
	</MetricsFilterContext.Provider>;
}


/** @returns {MetricsFilterContextType} */
const useMetricsFilter = () => useContext(MetricsFilterContext);


/** Keys = internal, accurate names; values: nicer/shorter names for URL param use. */
const paramNames = {
	groupId: 'group',
	campaignId: 'campaign',
	tagId: 'tag',
	supplyChannel: 'channel',
};


/**
 * @returns {String} URL search parameter string encoding the current filter set.
 */
const useMetricsParamString = () => {
	const [filter] = useMetricsFilter();

	return useMemo(() => {
		const paramString = Object.entries(filter)
			.filter(([k, v]) => v != null)
			.map(([k, v]) => {
				if (k in paramNames) k = paramNames[k];
				return `${k}=${v}`;
			})
			.join('&');
		if (paramString.length) return '?' + paramString;
		return '';
	}, [filter]);
};


export default MetricsFilterProvider;
export { useMetricsFilter, useMetricsParamString as useMetricsFilterParams };
