import { createContext, useCallback, useContext, useMemo, useState, type ReactNode, type Dispatch, type SetStateAction, type CSSProperties, type ComponentType } from 'react';
import { Box, Container, Paper, Grid, IconButton, Tooltip, svgIconClasses, SvgIcon, Collapse, Link, iconClasses, type ContainerProps, type PaperProps, type TooltipProps, type SxProps, type BoxProps } from '@mui/material';

import { Header, PageSection } from '../../components/common/LayoutComponents';
import {
	InfoOutlined as InfoOutlinedIcon,
	FileDownloadOutlined as FileDownloadOutlinedIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	type SvgIconComponent
} from '@mui/icons-material';
import { useMergeSx } from '../../utilities/utils';
import { StyledComponent } from '@emotion/styled';


type ReadMoreContextType = [
	/** True if the "Read More" accordion should be expanded. */
	expanded: boolean,
	/** Expand or collapse the "Read More" accordion. */
	setExpanded: Dispatch<SetStateAction<boolean>>
];

// Initialize the context with the correct type
const ReadMoreContext = createContext<ReadMoreContextType>(undefined as ReadMoreContextType);

/** Provides a context which allows {@link ReadMoreToggle} and {@link ReadMoreDetails} components to wwork together. */
function ReadMore(props: { children: ReactNode }) {
	const expandState = useState(false);
	return <ReadMoreContext.Provider value={expandState} {...props} />;
}


/** Uses the context provided by {@link ReadMore} to expand/collapse when a {@link ReadMoreToggle} in the same context is clicked. */
function ReadMoreDetails(props: { children: ReactNode }) {
	const [expanded] = useContext(ReadMoreContext);
	return <Collapse in={expanded} {...props} />;
}


const toggleSx = {
	display: 'inline-flex',
	[`& .${svgIconClasses.root}`]: { placeSelf: 'center', fontSize: 'inherit' },
	fontWeight: 500,
};

/** Controls {@link ReadMoreDetails} collapsers when placed as a child of the same {@link ReadMore}. */
function ReadMoreToggle({children = 'Read more', sx, ...props}: BoxProps) {
	const [expanded, setExpanded] = useContext(ReadMoreContext) || [];
	const toggle = useCallback(() => setExpanded && setExpanded(ex => !ex), [setExpanded]);
	sx = useMergeSx(toggleSx, sx);
	return (
		<span>
			<span style={{ display: 'inline-block', width: '0.5em' }} />
			<Link component="a" href="#" onClick={toggle} sx={sx} {...props}>
				{children} {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</Link>
		</span>
	);
}

interface PinkHeaderProps extends BoxProps {
	/** Logo to place beside the headline. */
	logo?: ComponentType<any>;
	/** Alt text for the logo, if supplied. */
	logoAlt?: string;
}

const pinkHeaderSx = { p: 6, my: 4, fontSize: '1.5rem' };

/**
 * See e.g. the "Welcome" box at the top of the Snapshot page.
 * Place a {@link ReadMoreToggle} and {@link ReadMoreDetails} anywhere inside to enable accordion functionality.
 */
function PinkHeader({logo: Logo, logoAlt, title, sx, children}: PinkHeaderProps) {
	sx = useMergeSx(pinkHeaderSx, sx);
	return (
		<ReadMore>
			<PageSection highlight sx={sx}>
				<Grid container spacing={2}>
					{Logo && (
						<Grid item xs={1} sx={{aspectRatio: '1/1'}}>
							<Logo alt={logoAlt} />
						</Grid>
					)}
					<Grid item xs={Logo ? 11 : 12} sx={{pr: 8}}>
						{title && <Header rank={4} mb={2}>{title}</Header>}
						{children}
					</Grid>
				</Grid>
			</PageSection>
		</ReadMore>
	);
}


const circleIconSx = {
	aspectRatio: '1 / 1',
	borderRadius: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

interface CircleIconProps extends BoxProps {
	svg?: SvgIconComponent;
	src?: string;
	icon?: StyledComponent<any>;
	iconSize?: number;
	backgroundColor: CSSProperties['color'];
	iconSx: SxProps<any>
}

function CircleIcon({ svg, src, icon: Icon, iconSize = 1, backgroundColor, border, sx = {}, iconSx = {}}: CircleIconProps) {
	const boxSx = useMemo(() => {
		const _boxSx = {...circleIconSx, backgroundColor, ...sx} as any;
		if (border) _boxSx.border = (typeof border === 'boolean') ? 'solid 2px' : border;
		return _boxSx;
	}, [backgroundColor, border, sx]);

	if (svg) return <SvgIcon component={svg} inheritViewBox sx={boxSx} />;

	let icon;
	if (src) {
		icon = <Box component="img" src={src} sx={iconSx} />;
	} else if (Icon) {
		icon = <Icon sx={iconSx} />;
	} else {
		icon = <Box sx={iconSx} />;
	}

	return <Box className={iconClasses.root} sx={boxSx}>{icon}</Box>;
}



const contentPaperSx = { flexGrow: 1, backgroundColor: 'background.paper', borderRadius: '10px', boxShadow: 0, px: 2, py: 2, mb: 2, position: 'relative' };

/** Grey box used to contain a subsection within a full-width page section. */
function ContentPaper({style, sx, ...props}: PaperProps) {
	sx = useMergeSx(contentPaperSx, style, sx);

	return <Paper sx={sx} {...props} />;
}


const contentContainerSx = { p: 1, minWidth: '100%', backgroundColor: 'background.default', borderRadius: '10px', height: '90%' };

interface ContentContainerProps extends ContainerProps {
	/** Will be included in the {@link Container}'s sx prop. */
	backgroundColor?: CSSProperties['color'];
}

/** White box used to contain a widget within a subsection. */
function ContentContainer({title, style, sx, backgroundColor, ...props}: ContentContainerProps) {
	const dynamicSx = useMemo(() => {
		const dSx = {} as any;
		if (backgroundColor) dSx.backgroundColor = backgroundColor;
		if (title) dSx.height = 'calc(100% - 1rem)';
		return dSx;
	}, [backgroundColor, title]);

	sx = useMergeSx(contentContainerSx, style, sx, dynamicSx);

	return <Container sx={sx} {...props} />;
}


interface ContentWrapperProps extends PaperProps {
	/** Function which downloads the data displayed in this component as a file (e.g. CSV), if applicable */
	downloadDataFn?: () => void;
}

/** Convenience for a {@link ContentPaper} with a header, download widget, and {@link ContentContainer} holding children. */
function ContentWrapper({children, title, downloadDataFn, sx}: ContentWrapperProps) {
	sx = useMergeSx({height: '100%'}, sx);
	return (
		<ContentPaper sx={sx} title={title}>
			{title && <Header rank={6} mt={0} mb={2} pr={2} color="#666">{title}</Header>}
			{downloadDataFn && (
				<Box sx={{position: 'absolute', top: '10px', right: '10px'}}>
					<Tooltip title="Download chart data CSV">
						<IconButton onClick={downloadDataFn}>
							<FileDownloadOutlinedIcon sx={{fill: 'black'}}/>
						</IconButton>
					</Tooltip>
				</Box>
			)}
			<ContentContainer>{children}</ContentContainer>
		</ContentPaper>
	);
}


/** Convenience for a {@link Tooltip} on an (i) icon. */
function TooltipWrapper({children}) {
	return (
		<Tooltip title={children}>
			<IconButton sx={{p: 0}}><InfoOutlinedIcon htmlColor="black" /></IconButton>
		</Tooltip>
	);
}


/** Props for {@link Definition}. */
interface DefinitionProps extends Omit<TooltipProps, 'children'> {
	/** {@link Definition} wraps its children, so doesn't have {@link Tooltip}'s singleton constraint. */
	children: ReactNode;
}

/** Wraps some text (e.g. a technical term) with a tooltip providing a definition. */
function Definition({title, children}: DefinitionProps) {
	return (
		<Tooltip title={title} enterTouchDelay={0} describeChild arrow>
			<Box component="span">
				{children}
				<Box component="sup"><InfoOutlinedIcon fontSize="inherit" /></Box>
			</Box>
		</Tooltip>
	);
}


const co2e = <>CO<sub>2</sub>e</>;

const co2eExplanation = 'Carbon dioxide equivalent: a standard unit for measuring greenhouse gas emissions.';

interface DefinableProps {
	/** True if the term should be wrapped in a tooltip with its definition. */
	define?: boolean;
}

/** Canned "CO₂e" with font-preserving subscript & optional explanatory tooltip on hover. */
function CO2e({define}: DefinableProps) {
	if (define) return <Definition title={co2eExplanation}>{co2e}</Definition>;
	return co2e;
}


const co2ePMExplanation = 'Carbon dioxide equivalent emitted per 1000 impressions.';

/** Canned "CO₂e PM" with font-preserving subscript & optional explanatory tooltip on hover. */
function CO2ePM({define}: DefinableProps) {
	if (define) return <Definition title={co2ePMExplanation}>{co2e} PM</Definition>;
	return <>{co2e} PM</>;
}


export {
	PinkHeader, CircleIcon, ContentWrapper, ContentPaper, ContentContainer, TooltipWrapper,
	ReadMore, ReadMoreDetails, ReadMoreToggle,
	Definition, CO2e, CO2ePM
};
