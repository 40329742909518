import { useCallback, useMemo } from 'react';
import { Box, Typography, Grid, TableHead, TableRow, TableBody, TableCell, Link } from '@mui/material';
import { CO2e, PinkHeader, ContentWrapper, ContentContainer, ContentPaper} from '../Metrics/MetricComponents';
import { DataGrid } from '@mui/x-data-grid';
import { countString, dataToCSV, metricWeightString, metricGramsWeightString } from '../../utilities/utils';
import FilterControls, { FilterDesc } from '../Metrics/FilterControls';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import { usePublisherData } from '../Metrics/providers/MetricsDataProvider';
import PublisherScoreTable from '../../components/common/PublisherScoreTable';
import { ReactComponent as WorldSvg } from '../../img/icons/globe-2.svg';
import { PublisherEmissionsIcon, SvgWrapper, WelcomeIcon } from '../../components/common/Icons';


export function MediaEmissions() {
	return {
		name: 'emissions',
		icon: null,
		href: '/media/emissions',
		content: <MediaEmissionsContent />,
		title: 'Emissions'
	};
};


function MediaEmissionsContent() {
	const { publisherScores, impressionsByPercentiles, publisherPercentiles } = usePublisherData();

	// {host: 'rogue-lineage.fandom.com', imps: 1, score: 0}
	let sortedPublishers = (publisherScores.data ?
		publisherScores.data.sort((a, b) => b.imps - a.imps).slice(0, 21)
		:
		[]
	).filter(({host}) => !!host).slice(0,20);

	const scoreBoundaries = publisherPercentiles.data ? [
		publisherPercentiles.data['25thPercentile'],
		publisherPercentiles.data['50thPercentile'],
		publisherPercentiles.data['75thPercentile'],
		publisherPercentiles.data['100thPercentile']
	] : [0, 0.01, 0.02, 0.03];

	const impressionsByScore = impressionsByPercentiles.data ? [
		impressionsByPercentiles.data['25thPercentile'],
		impressionsByPercentiles.data['50thPercentile'],
		impressionsByPercentiles.data['75thPercentile'],
		impressionsByPercentiles.data['100thPercentile']
	] : [0, 0, 0, 0];

	let scoredCO2ePM = (publisherScores.data && publisherScores.data.reduce((acc, {score, imps}) => {return {sum: acc.sum + score, count: acc.count + 1};}, {sum: 0, count: 0})) || {sum: 0, count: 0};
	scoredCO2ePM = scoredCO2ePM.count ? 1000 * (scoredCO2ePM.sum / scoredCO2ePM.count) : 0;

	return (
		<>
			<PinkHeader logo={PublisherEmissionsIcon} title="Publisher emissions explained">
				Our media estimations are designed to help you support responsible media through responsible reach. Publisher site emissions are outside the scope of the Global Media Sustainability Framework (GMSF)
			</PinkHeader>
			<FilterControls />
			<PageSection>
				<Grid container vertical spacing={1}>
					<Grid item xs={12}>
						<Grid item xs={12}>
							<Typography variant="h4" fontWeight="bold" sx={{mb: 2}}>Publisher greenhouse gas (GHG) emissions report</Typography>
							<FilterDesc my={null} mb={2} />
							<GlEmissionsScore co2ePM={scoredCO2ePM} />
						</Grid>
						<Grid item xs={12}>
							<Header rank={5} sx={{mb: 2, mt: 4}}>Your publisher emission snapshot</Header>
							<PublisherTable publishers={sortedPublishers}/>
						</Grid>
						<Grid item xs={12}>
							<Header rank={5} sx={{mb: 2, mt: 4}}>Where are your publishers placed in our low emissions ranking?</Header>
							<ScoreComparison scoreBoundaries={scoreBoundaries} impressionsByScore={impressionsByScore}/>
						</Grid>
						<Grid item xs={12}>
							<Header rank={5} sx={{mb: 2, mt: 4}}>How is your score calculated?</Header>
							<GoodPackageCTA />
						</Grid>
					</Grid>
				</Grid>
			</PageSection>
		</>
	);
}

function GlEmissionsScore({co2ePM}) {
	return (
		<ContentPaper>
			<ContentContainer style={{height: '150px'}}>
				<Grid container >
					<Grid item xs={0} sm={2}>
						<SvgWrapper circle border svg={WorldSvg} iconSize="60%" backgroundColor="primary.main" sx={{maxHeight: '100px', maxWidth: '100%'}}/>
					</Grid>
					<Grid item xs={12} sm={9} alignContent="center">
						<Header rank={4} name="avg publisher emissions">{metricGramsWeightString(co2ePM)}</Header>
						<Typography variant="h6">average publisher emissions per 1000 site visits</Typography>
					</Grid>
				</Grid>
			</ContentContainer>
		</ContentPaper>
	);
}


function ScoreComparison({scoreBoundaries, impressionsByScore}) {
	const tableRows = useMemo(() => {
		const scoredRowIndex = impressionsByScore.findIndex((impressions, i) => impressions === Math.max(...impressionsByScore));
		return [
			{key: 'top25', value: 'Top 25%', impressions: impressionsByScore[0], score: `< ${metricGramsWeightString(scoreBoundaries[0])}`, selected: scoredRowIndex === 0},
			{key: 'top50', value: 'Top 50%', impressions: impressionsByScore[1], score: `< ${metricGramsWeightString(scoreBoundaries[1])}`, selected: scoredRowIndex === 1},
			{key: 'top75', value: 'Top 75%', impressions: impressionsByScore[2], score: `< ${metricGramsWeightString(scoreBoundaries[2])}`, selected: scoredRowIndex === 2},
			{key: 'top100', value: '100%', impressions: impressionsByScore[3], score: `< ${metricGramsWeightString(scoreBoundaries[3])}`, selected: scoredRowIndex === 3},
		];
	}, [impressionsByScore, scoreBoundaries]);

	return (
		<ContentWrapper>
			<PublisherScoreTable sx={{my: 4, width: '90%', ml: '5%'}}>
				<TableHead>
					<TableRow>
						<TableCell className="pad-left">% scored publishers</TableCell>
						<TableCell>Publisher CO<sub>2</sub>e score</TableCell>
						<TableCell>Your no. of impressions</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{tableRows.map(row => (
						<TableRow data-field={row.value} key={row.key} className={row.selected && 'selected'}>
							<TableCell data-field="Quartile" className="pad-left">{row.value}</TableCell>
							<TableCell data-field="Score">{row.score}</TableCell>
							<TableCell data-field="Impressions">{row.impressions}</TableCell>
							<TableCell data-field="Majority mark" className="pad-left long-text">{row.selected ? 'a large volume of your impressions are here' : ''}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PublisherScoreTable>
		</ContentWrapper>
	);
}

// with last-col etc
// MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft MuiTableCell-sizeMedium selected pad-left last-col css-12ba02d-MuiTableCell-root
//without last-col etc


function GoodPackageCTA() {
	return (
		<PinkHeader logo={WelcomeIcon} sx={{backgroundColor: 'primary.alpha10'}}>
			<Typography variant="h5">Want to support our top low emission publishers? Chat to our sales team about our <strong>Good for the Planet Package</strong>.</Typography>
			<br />
			<Typography variant="h5"><Link href="https://good-loop.com/good-packages"><strong>Learn More</strong></Link></Typography>
		</PinkHeader>
	);
}


function ColumnHeader({colDef}) {
	let { headerName, headerNameRich } = colDef;
	return <Header rank={5}>{headerNameRich || headerName}</Header>;
}

function ColumnCell({formattedValue}) {
	return <Typography height="100%" alignContent="center" variant="h6">{formattedValue}</Typography>;
}


/** Common properties for all publisher table columns */
const columnBase = { flex: 1, renderHeader: ColumnHeader, renderCell: ColumnCell };


/** Publisher table column set, without score */
const baseColumns = [
	{ ...columnBase, field: 'publisher', headerName: 'Publisher' },
	{ ...columnBase, field: 'impressions', headerName: 'No. impressions', valueFormatter: countString },
];

/** Common properties for columns representing a CO2e quantity */
const co2ColumnBase = { ...columnBase, valueFormatter: metricWeightString };

/** Column showing total CO2e emissions for all recorded site visits */
const co2TotalColumn = 	{...co2ColumnBase, field: 'totalCO2', headerName: 'Total CO₂e', headerNameRich: <>Total <CO2e /></>};

/** Column showing CO2e emissions rate per thousand site visits */
const co2PMColumn = {...co2ColumnBase, field: 'co2e', headerName: 'CO₂e per 1000 site visits', headerNameRich: <><CO2e /> per 1000 site visits</>};

/** Publisher table column set with total CO2e emissions */
const publisherColumnsTotal = [...baseColumns, co2TotalColumn];

/** Publisher table column set with per-1000 CO2e emissions */
const publisherColumnsPM = [...baseColumns, co2PMColumn];


/** Rename some fields from publisher-score row & add a serial row ID. */
function mapPubToRowObject({host, imps, score}, id) {
	return {
		id,
		publisher: host,
		impressions: imps,
		co2e: score,
		totalCO2: score * imps
	};
};



function PublisherTable({publishers}) {
	// e.g. {host: 'rogue-lineage.fandom.com', imps: 1, score: 0}
	const rows = publishers.map(mapPubToRowObject);

	const downloadDataFn = useCallback(() => {
		const hosts = publishers.map(({host}) => host);
		const impsScores = publishers.map(({imps, score}) => (`${imps}, ${score}`));
		return dataToCSV(hosts, impsScores);
	}, [publishers]);

	// Pick column set - currently static but preserve dummied-out "switch to CO2E PM" functionality
	const columns = true ? publisherColumnsTotal : publisherColumnsPM;

	return (
		<ContentWrapper title="Your top 20 publishers by volume of impressions" downloadDataFn={downloadDataFn}>
			<Box sx={{ '& .MuiDataGrid-withBorderColor': { border: 'none' } }} m={4}>
				<DataGrid
					rows={rows}
					columns={columns}
					rowHeight={80}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 5 },
						},
					}}
				/>
			</Box>
		</ContentWrapper>
	);
}

export { GoodPackageCTA };
