import { useMemo } from 'react';
import { classes, interleave } from '../../utilities/utils';

const dayFirst = language => {
	const parts = new Intl.DateTimeFormat(language).formatToParts();
	return parts.findIndex(p => p.name === 'day') < parts.findIndex(p => p.name === 'month');
};


/**
 * Bundle up a collection of locale-specific date/time formatting functions such that:
 * - The day, month and year are separated and can be included or omitted as desired
 * - The day and month are in the appropriate order for the locale
 * - The time is in the appropriate format (12/24-hour and localised AM/PM markers) for the locale
 */
const deriveLocaleDateFormatParts = (language = navigator.language, monthStyle = 'short', timeStyle = 'short') => {
	// Build and extract format functions
	const dayFn = new Intl.DateTimeFormat(language, { day: 'numeric' }).format;
	const monthFn = new Intl.DateTimeFormat(language, { month: monthStyle}).format;
	const yearFn = new Intl.DateTimeFormat(language, { year: 'numeric' }).format;
	const timeFn = new Intl.DateTimeFormat(language, { timeStyle }).format;

	const time = (date) => ({name: 'time', value: timeFn(date)});
	// Day/month order according to locale
	const dayMonth = dayFirst(language) ? (
		date => [
			{name: 'month', value: monthFn(date)},
			{name: 'day', value: dayFn(date)}
		]
	) : (
		date => [
			{name: 'day', value: dayFn(date)},
			{name: 'month', value: monthFn(date)}
		]
	);
	const year = (date) => ({name: 'year', value: yearFn(date)});

	return { time, dayMonth, year };
};


const dateToFilteredParts = (date, formatParts) => {
	const now = new Date();
	const dayStart = new Date(date).setHours(0, 0, 0, 0);
	const dayEnd = new Date(date).setHours(24, 0, 0, 0);
	const sameDay = now.getTime() >= dayStart &&  now.getTime() < dayEnd;
	const sameYear = sameDay || date.getFullYear() === now.getFullYear();

	const {time, dayMonth, year} = formatParts;

	// Same day = show time only
	if (sameDay) return [time(date)];
	// Day/month, plus optionally year
	const parts = dayMonth(date);
	if (!sameYear) parts.push(year(date));
	return parts;
};


/**
 * Renders a date conditionally depending on relationship to current time.
 * If it's today, shows only time; otherwise shows month, day and (if it's different) year.
 * Follows browser locale settings for month/day order.
 * @param {*} props
 * @param {Date|String|Number} props.date Anything accepted by a Date constructor.
 * @param {String} [props.language={navigator.language}]
 * @param {String} [props.monthStyle="short"] See Intl.DateTimeFormat. Default = Jan/Feb/Mar, in English.
 * @param {Component|Tag} [props.Container="span"] Wraps the whole date/time
 * @param {Component|Tag} [props.PartContainer="span"] Wraps each part (year, month, date, time)
 * @returns {ReactElement}
 */
function ShortDate({
	date, language = navigator.language, monthStyle = 'short',
	Container = 'span', PartContainer = 'span', className, ...props
}) {
	const formatParts = useMemo(() => (
		deriveLocaleDateFormatParts(language)
	), [language]);

	const [fullString, partElements] = useMemo(() => {
		const _date = new Date(date);
		// Render out elements...
		let partEls = dateToFilteredParts(_date, formatParts)
			.map(({name, value}) => (
				<PartContainer key={name} className={name}>{value}</PartContainer>
			));
		// Insert spaces
		partEls = interleave(partEls, ' ');
		return [_date.toLocaleString(), partEls];
	}, [date, formatParts]);

	return <Container className={classes('date', className)} title={fullString} {...props}>
		{partElements}
	</Container>;
}


export {
	ShortDate
};