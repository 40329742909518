import { useCallback, useState } from 'react';
import { Button, InputAdornment, inputAdornmentClasses, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useFormContext } from '../../FormContextProvider';
import { itemAdder, SubForm } from '../../SubForms';
import TextInputField from '../../inputFields/TextInputField';
import useLiveRef from '../../../utilities/useLiveRef';

/** An ObjectSubForm item form for a template text entry. */
function TextEntryForm({index}) {
	const { actionFns } = useFormContext();
	const deleteButton = <Button variant="contained" color="secondary" onClick={actionFns.delete}>Remove</Button>;
	return <TextInputField label={`text.${index}`} path="." size="small" append={deleteButton} />;
}


/** Add "text." prefix to key input */
const nameFieldProps = {
	sx: {
		fontFamily: 'monospace',
		[`.${inputAdornmentClasses.root}`]: { marginRight: 0 },
	},
	startAdornment: <InputAdornment position="start" disableTypography>text.</InputAdornment>,
};


/** A form for adding new text entries to the creative spec. */
const AddTextEntryForm = itemAdder(function({addItem}) {
	const [key, setKey] = useState('');
	const [value, setValue] = useState('');
	const kvRef = useLiveRef([value, key]);

	// Insert the uploaded file URL in the text field on success.
	const onClickAdd = useCallback(() => {
		addItem(...kvRef.current);
		setKey(null);
		setValue(null);
	}, [addItem, kvRef]);

	const addButton = <Button variant="contained" onClick={onClickAdd} disabled={!key}>Add</Button>;

	return <Grid xs={12} container spacing={1}>
		<Grid xs={12} sm={6}><TextInputField label="Add new text" value={value} onChangeValue={setValue} size="small" fullWidth /></Grid>
		<Grid xs={12} sm={6}><TextInputField label="Name" value={key} onChangeValue={setKey} size="small" fullWidth append={addButton} {...nameFieldProps} /></Grid>
	</Grid>;
});


/** Renders key-value inputs for assigning custom text values to a creative. */
function TextControls({path}) {
	return <Stack spacing={2}>
		<AddTextEntryForm path={path} />
		<SubForm path={path} ItemForm={TextEntryForm} />
	</Stack>;
}


export default TextControls;
