import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, CircularProgress, Link } from '@mui/material';
import goodLoopLogo from '../img/Good-Loop-symbol.svg';
import { Header } from './common/LayoutComponents';

/** The name of the current site family (ie Good Formats or Good Measures). */
const siteName = {
	studio: 'Good Formats'
}[process.env.REACT_APP_GOOD_MEASURES_FOR] || 'Good Measures';


const centreBoxSx = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	gap: 4,
	height: '100vh',
};


/**
 * @param {object} props
 * @param {import('react').ReactNode} [props.description] Short header description of why the user can't get in.
 * @param {import('react').ReactNode} [props.children]
 */
function LoginFlowWrapper({description, children}) {
	return <Box sx={centreBoxSx}>
		<Box component="img" src={goodLoopLogo} sx={{ width: '4em' }} />
		<Header rank={3} sx={{fontWeight: 500}}>{siteName}</Header>
		{description && <Header rank={5}>{description}</Header>}
		<Typography component="div" variant="body1">
			{children}
		</Typography>
	</Box>;
};


/** Email link to GL support. */
function EmailSupport() {
	return <Link href="mailto:support@good-loop.com">support@good-loop.com</Link>;
}


/** "[Log in] to continue". */
function LogInPrompt() {
	const { loginWithRedirect } = useAuth0();
	return <><Link href="#" onClick={loginWithRedirect}>Log in</Link> to continue.</>;
}


/**`"[Log out] and try a different account." */
function LogOutPrompt() {
	const { logout } = useAuth0();
	return <p>You can also <Link href="#" onClick={logout}>log out</Link> and try a different account.</p>;
}


/**
 * "You're authenticated as [user], but-"
 * @param {object} props
 * @param {import('react').ReactNode} props.problem The rest of the sentence.
 */
function AuthenticatedBut({problem}) {
	const { user } = useAuth0();
	return <p>
		{user ? <>You're authenticated as <strong>{user.email}</strong>, but {problem}</> : <CircularProgress />}
	</p>;
}


/** "Log in" prompt: shows when the user has no Auth0 authentication at all. */
function LoginPage() {
	return (
		<LoginFlowWrapper description={<LogInPrompt />}>
			Don't have an account? Contact your workspace manager or <EmailSupport /> to get started.
		</LoginFlowWrapper>
	);
}


/**
 * User is authenticated, but their auth doesn't do anything on this site.
 * @param {object} props
 * @param {import('react').ReactNode} props.description Short summary of the problem for the header
 * @param {import('react').ReactNode} props.problem Completes the sentence "You're authenticated as {user}, but-"
 */
function AccountProblemPage({description, problem}) {
	return (
		<LoginFlowWrapper description={description}>
			<AuthenticatedBut problem={problem} />
			<p>Please contact your workspace manager or <EmailSupport /> to get started.</p>
			<LogOutPrompt />
		</LoginFlowWrapper>
	);
}


/** User is authenticated, but their email doesn't correspond to a user in the DB. */
function NoAccountPage() {
	return (
		<AccountProblemPage description={`No ${siteName} account found`} problem="it looks like your account hasn't been set up yet." />
	);
}


/** User is authenticated, but their user doesn't have permissions to see anything. */
function NoPermissionsPage() {
	return (
		<AccountProblemPage description="Account has no access" problem="it looks like your account hasn't been given access to any groups." />
	);
}


/** User is "authenticated" with Auth0 but hasn't verified their email. */
function UnverifiedPage() {
	return (
		<LoginFlowWrapper description="Unverified account">
			<AuthenticatedBut problem={`you need to verify your email to access ${siteName}`} />
			<LogOutPrompt />
		</LoginFlowWrapper>
	);
}


/** Currently waiting for Auth0 or DB user. */
function LoadingPage() {
	return <LoginFlowWrapper>
		<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<CircularProgress />
			<Header rank={5} sx={{ml: 2, fontWeight: 500}}>Loading…</Header>
		</Box>
	</LoginFlowWrapper>;
};



export { LoginPage, LoadingPage, NoAccountPage, NoPermissionsPage, UnverifiedPage };
