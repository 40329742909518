import { useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { Box, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import PermissionSelect, { PermissionDesc } from '../inputFields/PermissionSelect';
import { permissionLevels } from '../../model/permissionLevels';
import entity from '../../model/entity';

import DialogButton from '../widgets/DialogButton';
import { FetchGroupCell } from '../tables/GroupTable';
import { InputLabel } from '../inputFields/InputWrapper';
import PermissionWrapper from './PermissionWrapper';
import { useUsers } from '../../state/apiHooks';
import { Header } from '../common/LayoutComponents';
import { useFormContext } from '../FormContextProvider';
import EntityDialog from './EntityDialog';


/** Basic validation of the user input form */
const validateUser = (authUser, draftUser) => {
	// Only global admins can create root users
	if (!authUser?.isGlobalAdmin && !draftUser.workspaceId) return false;
	// Users must be created with a name and email address
	return !!(draftUser.name && draftUser.loginId);
};


const thumbnailSx = {
	objectFit: 'contain',
	height: '100px',
};


/** Email entry field + check whether entered value is in use. */
function EmailField() {
	const { draft } = useFormContext();
	const { id: userId } = draft;

	// Live-check for email address already in use
	const [emailQuery, setEmailQuery] = useState();
	const onChangeEmail = useMemo(() => {
		// Don't hammer API: wait half a second for user to finish typing before setting the query
		const checkInUse = debounce(email => setEmailQuery({loginId: email}), 500);
		// Clear the email query outside debounce, so usersForEmail is invalidated immediately
		return e => {
			checkInUse(e.target.value);
			// Remove the query immediately so usersForEmail gets invalidated
			setEmailQuery(null);
		};
	}, []);

	const { data: usersForEmail } = useUsers(emailQuery, {enabled: !!emailQuery}); // NB plural but should be 0 or 1 entries
	const emailInUse = useMemo(() => {
		// No query / no answer yet --> not safe to commit changes.
		if (!emailQuery || !usersForEmail) return undefined;
		// List received. Make sure the only user on it is the current one.
		return !!usersForEmail.find(u => (u.id !== userId));
	}, [emailQuery, usersForEmail, userId]);


	return (
		<Box sx={{display: 'flex', alignItems: 'center'}}>
			<TextInputField label="User Email" type="email" required path="loginId" onChange={onChangeEmail} error={emailInUse} />
			{emailInUse && (
				<Chip variant="outlined" label="Email address in use" color="error" sx={{ml: 2}} />
			)}
		</Box>
	);
}


/**
 * The inner form for a User editor.
 * @param {FormFieldsProps} props
 */
function UserFormFields() {
	const { id, draft } = useFormContext();
	const { pictureUrl, workspaceId, workspacePermissionLevel, globalPermissionLevel } = draft;

	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<TextInputField label="Name" required path="name" />
			</Grid>
			<Grid xs={12}>
				<EmailField />
			</Grid>
			<Grid container xs={12}>
				<Grid>
					<TextInputField label="Picture" type="url" path="pictureUrl" />
				</Grid>
				<Grid>
					<Box component="img" src={pictureUrl} sx={thumbnailSx} />
				</Grid>
			</Grid>
			{workspaceId && <>
				<Grid xs={12}>
					<InputLabel>Workspace</InputLabel>
					<FetchGroupCell value={workspaceId} />
				</Grid>
				<Grid xs={12} sm={4}>
					<PermissionSelect label="Workspace Permission Level" required
						path="workspacePermissionLevel" maxLevel={permissionLevels.ADMIN}
					/>
				</Grid>
				<Grid xs={12} sm={8}>
					<PermissionDesc title="Capabilities" level={workspacePermissionLevel} />
				</Grid>
			</>}
			{!workspaceId && <>
				{!id && (
					<Grid xs={12}>
						<Header rank={6}>Creating Global User</Header>
					</Grid>
				)}
				<Grid xs={12} sm={4}>
					<PermissionSelect label="Global Permission Level" required path="globalPermissionLevel" />
				</Grid>
				<Grid xs={12} sm={8}>
					<PermissionDesc title="Capabilities" level={globalPermissionLevel} global />
				</Grid>
			</>}
		</Grid>
	);
}


/** Display special titles for creating global and workspace users */
const UserTitle = () => {
	const { id, base } = useFormContext();
	if (id) return 'Edit User';
	if (base?.workspaceId) return 'Add User to Workspace';
	return 'Create Root User';
};


/** User-specific extensions to EntityDialog */
function UserDialog(props) {
	return (
		<EntityDialog type={entity.USER} validateFn={validateUser} title={<UserTitle />} {...props}>
			<UserFormFields />
		</EntityDialog>
	);
}


function EditUserButton({label = 'Edit User', ...props}) {
	return (
		<PermissionWrapper type={entity.USER} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={UserDialog} {...props} />
		</PermissionWrapper>
	);
}


export default UserDialog;
export { UserFormFields, EditUserButton };
