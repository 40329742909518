import { type MutableRefObject, useRef } from 'react';

/**
 * Returns a stable {@link MutableRefObject} containing the supplied value.
 * @param current Will be inserted in the returned ref.
 */
function useLiveRef<T>(current: T): MutableRefObject<T> {
	const ref = useRef(current);
	ref.current = current;
	return ref;
};

export default useLiveRef;
