import { useMemo, useState } from 'react';

import { Editor } from 'prism-react-editor';
import { BasicSetup } from 'prism-react-editor/setups';

import InputWrapper from './InputWrapper';

// Basic editor layout
import 'prism-react-editor/layout.css';
import 'prism-react-editor/themes/github-dark.css';

// Enable CSS support
import 'prism-react-editor/languages/css';
import 'prism-react-editor/prism/languages/css';

// Enable XML support
import 'prism-react-editor/languages/xml';
import 'prism-react-editor/prism/languages/xml';



function CodeEditor({value, language, onChange, ...props}) {
	// Prism Editor is uncontrolled & does NOT like being constantly fed a new value prop.
	// So:
	// - Cache the initially-provided value
	// - Dispatch onChange events as normal (the updated value won't reach the component)
	// - Update initialValue on blur, when the user's done editing.
	const [initialValue, setInitialValue] = useState(value);

	const textareaProps = useMemo(() => ({
		onBlur: event => setInitialValue(event.target.value),
		onChange
	}), [setInitialValue, onChange]);

	return (
		<Editor language={language} value={initialValue} textareaProps={textareaProps} {...props}>
			{editor => <BasicSetup editor={editor} />}
		</Editor>
	);
};

/**
 *
 * @param {Object} params
 * @param {string} params.name - Term of the text input field.
 * @param {string?} params.label - Label of the text input field. Auto generated if not provided.
 * @param {import("react").MutableRefObject} params.inputRef - Reference to the text input field.
 * @param {boolean} params.required - Whether the text input field is required.
 * @param {string} params.info - Information to display next to the text input field.
 * @param {Object} params.sx - Styling object for the FormControl component.
 */
function CodeField(props) {
	return <InputWrapper {...props} Input={CodeEditor} />;
}

export default CodeField;
