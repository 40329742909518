import { Box, CircularProgress } from '@mui/material';

import goodLoopLogo from '../../img/Good-Loop-symbol.svg';
import { useGroup, useSubGroups, useGroupCampaigns, useGroupUsers, useGroupTags, useGroupApiKeys } from '../../state/apiHooks';
import { EditGroupButton } from '../../components/edit/GroupDialog';
import { useParams } from 'react-router';
import ApiKeyTable from '../../components/tables/ApiKeyTable';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import entity from '../../model/entity';
import { AddApiKeyButton } from '../../components/edit/ApiKeyDialog';
import { EditGroupPermissionButton } from '../../components/edit/GroupPermissionDialog';
import GroupPermissionTable from '../../components/tables/GroupPermissionTable';
import { Header, HeaderActions, PageSection, PageSectionContainer, SubSectionItem } from '../../components/common/LayoutComponents';
import GroupTable from '../../components/tables/GroupTable';

function GroupIdentity({group}) {
	const { id: groupId } = group;

	const groupTypeName = (group.parentId) ? 'Group' : 'Workspace';

	return <Grid container spacing={2}>
		<Grid container direction="column">
			<Grid><Header rank={5}>{groupTypeName}</Header></Grid>
			<Grid><Header rank={3}>{group.name}</Header></Grid>
			<Grid container>
				<Grid xs="6">
					<EditGroupButton label="Edit Group" id={groupId} />
				</Grid>
				<Grid xs="6">
					<EditGroupButton label="Add Sub-Group" base={{parentId: groupId}} />
				</Grid>
			</Grid>
		</Grid>
		<Grid flex={1}>
			<Box component="img" src={group.logoUrl || goodLoopLogo} sx={{height: '200px', width: '100%', objectFit: 'contain'}} />
		</Grid>
	</Grid>;
}


const dependentHooks = {
	[entity.USER]: useGroupUsers,
	[entity.API_KEY]: useGroupApiKeys,
	[entity.GROUP]: useSubGroups,
	[entity.CAMPAIGN]: useGroupCampaigns,
	[entity.TAG]: useGroupTags,
};

const queryArgs = { select: entities => entities.length };


function GroupStat({title, groupId, type}) {
	const { data: count, isLoading } = dependentHooks[type](groupId, true, null, queryArgs);
	return <>
		<Header rank={5}>{title}</Header>
		{isLoading ? <CircularProgress /> : (
			<Header rank={3}>{count}</Header>
		)}
	</>;
}


/** Group (or workspace) overview */
function ShowGroup() {
	const { groupId } = useParams();
	const { data: group, isPending} = useGroup(groupId);

	if (isPending) return <PageSection><CircularProgress /> Loading Group</PageSection>;

	return <>
		<PageSectionContainer>
			<Grid container direction="row" spacing={3}>
				<Grid xs={12} sm={6}>
					<SubSectionItem>
						<GroupIdentity group={group} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="Sub-groups" type={entity.GROUP} groupId={groupId} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="Campaigns" type={entity.CAMPAIGN} groupId={groupId} />
					</SubSectionItem>
				</Grid>
				<Grid xs={4} sm={2}>
					<SubSectionItem>
						<GroupStat title="Tags" type={entity.TAG} groupId={groupId} />
					</SubSectionItem>
				</Grid>
			</Grid>
		</PageSectionContainer>
		<PageSection>
			<Header rank={5}>
				Sub-Groups
				<HeaderActions>
					<EditGroupButton label="Add Sub-Group" base={{parentId: groupId}} />
				</HeaderActions>
			</Header>
			<GroupTable query={{parent: groupId}} />
		</PageSection>
		<PageSection>
			<Header rank={5}>
				User Permissions
				<HeaderActions>
					<EditGroupPermissionButton groupId={groupId} />
				</HeaderActions>
			</Header>
			<GroupPermissionTable groupId={groupId} />
		</PageSection>
		<PageSection>
			<Header rank={5}>
				API Keys
				<HeaderActions>
					<AddApiKeyButton groupId={groupId} />
				</HeaderActions>
			</Header>
			<ApiKeyTable groupId={groupId} />
		</PageSection>
	</>;
}

export default ShowGroup;
export {
	GroupStat
};
