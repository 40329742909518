import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Typography, Box, IconButton, Avatar, Menu, MenuItem, Divider, AppBar, Toolbar, Link, useScrollTrigger, Slide, Chip, SvgIcon } from '@mui/material';

import { useAuthUser } from '../state/apiHooks';
import {ReactComponent as GoodLoopSymbol } from '../img/Good-Loop-symbol.svg';

import MenuIcon from '@mui/icons-material/Menu';
import LeaveWorkspaceIcon from '@mui/icons-material/ArrowCircleLeft';

import { useWorkspace } from './WorkspaceProvider';
import { isSufficientPermission, permissionLevels } from '../model/permissionLevels';
import { capitalize } from 'lodash';


/** The Good-Loop logo icon shown on the "global user" chip */
const glChipIcon = <SvgIcon alt="Good-Loop logo" component={GoodLoopSymbol} inheritViewBox />;


/** Flags to the user that they're logged in as a global account. */
function GlobalUserBadge(props) {
	const { data: user } = useAuthUser();
	if (!user) return null;
	const gpl = user.globalPermissionLevel;
	if (!isSufficientPermission(gpl, permissionLevels.READ)) return null;

	return (
		<Chip color="primary" sx={{fontWeight: 'medium'}}
			icon={glChipIcon}
			label={capitalize(gpl)}
			title={`Logged in as a Good-Loop user with ${gpl} permission on all workspaces.`}
			{...props}
		/>
	);
}


/** Allows a global user to exit a workspace they've previously entered. */
function LeaveWorkspaceButton(props) {
	let { workspace, isOverridden } = useWorkspace();
	if (!isOverridden) return null;
	return (
		<a href="/" title={`Leave workspace ${workspace?.name}`}>
			<Chip label="Exit" color="secondary" sx={{fontWeight: 'medium'}} icon={<LeaveWorkspaceIcon />} {...props} />
		</a>
	);
}


/** Show the logo or name of the current workspace. */
function WorkspaceBadge(props) {
	let { workspace } = useWorkspace();
	if (!workspace) return null;
	return <>
		{workspace.logo ? (
			<Box component="img" height={48} src={workspace.logo} title={`Workspace: ${workspace.name}`} {...props} />
		) :(
			<Typography fontWeight={500} {...props}>{workspace.name}</Typography>
		)}
	</>;
}


const breadcrumbBoxSx = {
	flexGrow: 1
};


/** Shows the user what workspace they're currently in (and, if they're a cross-workspace user, lets them leave). */
function WorkspaceInfo() {
	return <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center" gap={1}>
		{process.env.REACT_APP_GOOD_MEASURES_FOR === 'studio' && (
			<Box id="breadcrumb-container" sx={breadcrumbBoxSx} />
		)}
		<GlobalUserBadge />
		<WorkspaceBadge />
		<LeaveWorkspaceButton />
	</Box>;
}


function UserIcon({user, toggleMenu, isOpen}) {
	return (
		// <Tooltip title="Account Settings">
		<IconButton
			onClick={toggleMenu}
			size="large"
			aria-controls={isOpen ? 'account-menu' : undefined}
			aria-haspopup="true"
			aria-expanded={isOpen ? 'true' : undefined}
			alt="Account Management"
			name="Account Management"
			label={user.name}
			edge="end"
		>
			{user.name}
			<Avatar src={user.pictureUrl} sx={{ width: 48, height: 48 }}/>
		</IconButton>
		// </Tooltip>
	);
}


function ManageLink({user}) {
	if (process.env.REACT_APP_GOOD_MEASURES_FOR === 'studio') {
		if (user.isGlobalAdmin) return (
			<Link href="/manage/users"><MenuItem>Manage Users</MenuItem></Link>
		);
	}

	if (user.isWorkspaceAdmin) return (
		<Link href="/manage"><MenuItem>Manage Workspace</MenuItem></Link>
	);

	if (user.isGroupAdmin) return (
		<Link href="/manage/groups"><MenuItem>Manage Groups</MenuItem></Link>
	);

	return null;
}


function UserMenu({anchor, setAnchor}) {
	const { logout } = useAuth0();
	// (a) Can the user go to the admin page? (b) What's the label for the link?
	const { data: user = {} } = useAuthUser();

	const closeMenu = () => setAnchor(null);

	return (
		<Menu
			anchorEl={anchor}
			id="account-menu"
			open={Boolean(anchor)}
			onClose={closeMenu}
			onClick={closeMenu}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			disableScrollLock
		>
			<ManageLink user={user} />
			<Divider />
			<MenuItem sx={{fontWeight: 'bold'}} onClick={logout}>
				Log Out
			</MenuItem>
		</Menu>
	);
}

const appBarSx = {
	color: 'black',
	backgroundColor: 'background.default',
	paddingLeft: '100px',
	zIndex: theme => theme.zIndex.drawer + 2
};


// https://mui.com/material-ui/react-app-bar/#hide-app-bar
function HideOnScroll(props) {
	const { children } = props;
	const trigger = useScrollTrigger({
		target: window
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children ?? <div />}
		</Slide>
	);
}

function UserBar({isMobile, toggleDrawer}) {
	const user = useAuthUser();

	const [menuAnchor, setMenuAnchor] = useState(null);
	const toggleMenu = e => setMenuAnchor(menuAnchor ? null : e.currentTarget);

	return (
		<HideOnScroll>
			<AppBar position="fixed" sx={appBarSx} elevation={0}>
				<Toolbar>
					<WorkspaceInfo />
					<UserIcon user={user} isOpen={menuAnchor} toggleMenu={toggleMenu} />
					<UserMenu anchor={menuAnchor} setAnchor={setMenuAnchor} />
					{isMobile && <IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ ml: 2 }}
						onClick={toggleDrawer}
					>
						<MenuIcon />
					</IconButton>}
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	);
}


export default UserBar;
