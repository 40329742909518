import { useMemo } from 'react';
import { useParams } from 'react-router';

import { Button, CircularProgress, Divider } from '@mui/material';

import { Group } from '../../model/types/Group';
import { useCreative } from '../../state/apiHooks';
import { Header, HeaderActions, PageContainer, PageSection } from '../../components/common/LayoutComponents';

import EntityList from './EntityList';
import GoodFormatsBreadcrumbs from './GoodFormatsBreadcrumbs';
import LineItemListItem from './LineItemListItem';
import { EditLineItemButton } from '../../components/edit/LineItemControls';


function ShowCreative() {
	const { creativeId } = useParams();
	const { data: creative } = useCreative(creativeId) as { data: Group };
	const listQuery = useMemo(() => ({ creativeId }), [creativeId]);

	const listProps = useMemo(() => {
		return creative ? {
			title: <>Line Items for {creative.name}</>,
			actionButtons: <EditLineItemButton base={{creativeId}} label="New Line Item" />
		 } : {};
	}, [creative, creativeId]);

	if (!creative) return <PageContainer><CircularProgress />Loading creative <code>{creativeId}</code>...</PageContainer>;

	return <>
		<GoodFormatsBreadcrumbs creativeId={creativeId} />
		<PageContainer>
			<PageSection>
				<Header rank={5}>
					{creative.name}
					<HeaderActions>
						<Button variant="contained" color="primary" href={`/creatives/${creativeId}/edit`}>Edit Creative</Button>
					</HeaderActions>
				</Header>
				<Divider sx={{my: 3}} />
				<EntityList type="lineitem" ItemComponent={LineItemListItem} query={listQuery} {...listProps} />
			</PageSection>
		</PageContainer>
	</>;
}


export default ShowCreative;
