import { useCallback, useMemo } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';


import PermissionSelect, { PermissionDesc } from '../inputFields/PermissionSelect';
import { permissionLevelNames, permissionLevels } from '../../model/permissionLevels';
import entity, { entityDisplayName } from '../../model/entity';

import { useGroup, useGroupPermission, useSetGroupPermission, useUser } from '../../state/apiHooks';
import DialogButton from '../widgets/DialogButton';
import UserTable, { FetchUserCell } from '../tables/UserTable';
import PermissionWrapper from './PermissionWrapper';
import { Header } from '../common/LayoutComponents';
import { InputLabel } from '../inputFields/InputWrapper';
import FormContextProvider, { useFormContext } from '../FormContextProvider';
import EditDialog from './EditDialog';
import GroupTable from '../tables/GroupTable';


/** Basic validation of the UserGroupPermission input form */
const validateDraft = (authUser, draft) => {
	return draft.userId && draft.groupId && draft.level;
};


/**
 * The inner form for a UserGroupPermission edit dialog.
 * @param {FormFieldsProps} props
 */
function GroupPermissionFormFields() {
	const { draft, loading } = useFormContext();
	const { userId, level } = draft || {};

	return (
		<Grid container spacing={2}>
			<Grid xs={12} sm={6}>
				<InputLabel>User</InputLabel>
				<FetchUserCell value={userId} />
			</Grid>
			<Grid xs={12} sm={6}>
				{loading ? <CircularProgress /> : (
					<PermissionSelect label="Current Permission" path="level" />
				)}
			</Grid>
			<Grid xs={12}>
				<PermissionDesc title="Capabilities" level={level} />
			</Grid>
		</Grid>
	);
}


/** Title bar for "add user to group" mode: fetch group and insert its display name. */
function GroupPermissionTitle() {
	const { draft } = useFormContext();
	const { data: group } = useGroup(draft.groupId);

	return <>Grant permission on {group?.name || entityDisplayName(entity.GROUP)}</>;
}


function GrantPermissionDialog({userId, groupId, ...props}) {
	return (
		<GroupPermissionContext groupId={groupId} userId={userId}>
			<EditDialog {...props}>
				<GroupPermissionFormFields />
			</EditDialog>
		</GroupPermissionContext>
	);
}


/** UserGroupPermission-specific extensions to EditDialog */
function SelectUserDialog({open, onClose, groupId, ...props}) {
	// Get target group - we need its workspaceId to filter the user table.
	const { data: group } = useGroup(groupId);
	const userQuery = useMemo(() => (group && {workspaceId: group.workspaceId}), [group]);

	// Override the action column on the user table with "open a sub-form for this user's permissions"
	const tableActions = useMemo(() => [function({row}) {
		return <EditGroupPermissionButton groupId={groupId} userId={row.id} label="Grant" />;
	}], [groupId]);

	return (
		<Dialog open={open} onClose={onClose} {...props}>
			<DialogTitle onClose={onClose}>
				Grant permission on {group?.name || entityDisplayName(entity.GROUP)}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Header rank={6}>Select user</Header>
					<Grid xs={12} container direction="column">
						<UserTable query={userQuery} permissionGroup={groupId} actions={tableActions} />
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}


/** UserGroupPermission-specific extensions to EditDialog */
function SelectGroupDialog({open, onClose, userId, ...props}) {
	// Get target user - we need their workspaceId to filter the user table.
	const { data: user } = useUser(userId);
	const groupQuery = useMemo(() => (user && user.workspaceId && {workspaceId: user.workspaceId}), [user]);

	// Override the action column on the group table with "open a sub-form to grant user permission on this group"
	const tableActions = useMemo(() => [function({row}) {
		return <EditGroupPermissionButton groupId={row.id} userId={userId} />;
	}], [userId]);

	return (
		<Dialog open={open} onClose={onClose} {...props}>
			<DialogTitle onClose={onClose}>
				Grant group permission to {user?.name || entityDisplayName(entity.USER)}
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Header rank={6}>Select group</Header>
					<Grid xs={12} container direction="column">
						<GroupTable query={groupQuery} permissionUser={userId} actions={tableActions} />
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}


function GroupPermissionDialog(props) {
	if (!props.userId) return <SelectUserDialog {...props} />;
	if (!props.groupId) return <SelectGroupDialog {...props} />;
	return <GrantPermissionDialog {...props} />;
}


function EditGroupPermissionButton({label = 'Grant Permission', ...props}) {
	const button = <DialogButton label={label} Dialog={GroupPermissionDialog} {...props} />;
	// No need for / way to perform permissions check if we don't know what group we're acting on yet.
	if (!props.groupId) return button;

	return (
		<PermissionWrapper type={entity.GROUP} action="UPDATE" id={props.groupId}>
			{button}
		</PermissionWrapper>
	);
}


function EditGroupPermissionDropdownCore(props) {
	const { loading, saving } = useFormContext();

	if (saving || loading) return <CircularProgress />;

	return <PermissionSelect path="level" {...props} />;
}


/** An inline <select> for setting a user's permissions on a specific group. Automatically commits changes. */
function EditGroupPermissionDropdown({userId, groupId, ...props}) {
	return <GroupPermissionContext userId={userId} groupId={groupId} autosave>
		<EditGroupPermissionDropdownCore {...props} />
	</GroupPermissionContext>;
}

/** A piece of inline text showing a user's permissions on a specific group. */
function ShowGroupPermission({userId, groupId}) {
	const { data: groupPermission, isLoading } = useGroupPermission(userId, groupId);
	if (isLoading) return <CircularProgress />;
	const levelName = permissionLevelNames[groupPermission?.level];
	return levelName || permissionLevelNames.NONE;
}


const actionLabels = { delete: 'Revoke' };

/**
 * A FormContextProvider for editing user-group permissions, which are accessed differently to other DB entities.
 */
function GroupPermissionContext({userId, groupId, ...props}) {
	const base = useMemo(() => ({userId, groupId}), [userId, groupId]);
	let { data: initial = base, isLoading: loading } = useGroupPermission(userId, groupId);

	// TODO Update backend to report TRANSITIVE user-group permission, fetch auth user's
	// permission on the group, and limit dropdowns to the max level they're able to apply.

	const submitFn = useSetGroupPermission();
	const actionFns = useMemo(() => ({
		delete: () => submitFn({userId, groupId, level: permissionLevels.NONE})
	}), [userId, groupId, submitFn]);

	Object.assign(props, {
		base, initial,
		saved: Boolean(initial),
		loading,
		submitFn, actionFns,
		submitLabel: 'Apply', actionLabels,
		validateFn: validateDraft, title: <GroupPermissionTitle />
	});

	return <FormContextProvider {...props} />;
}


export default GroupPermissionDialog;
export {
	GroupPermissionFormFields,
	EditGroupPermissionButton,
	EditGroupPermissionDropdown,
	ShowGroupPermission
};
