import { Outlet } from 'react-router-dom';

import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';

import GMPNavbars from '../GMPNavbars';
import UserBar from '../UserBar';
import { useCallback, useState } from 'react';
import goodLoopLogo from '../../img/Good-Loop-symbol.svg';


const logoSx = {
	height: '48px',
	width: '100px',
	position: 'fixed',
	top: '20px',
	left: 0,
};

/** website laid out in two columns (which become rows on mobile). First is navigation, second is page content. */
function Layout() {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = useCallback(() => {
		setDrawerOpen(open => !open);
	}, [setDrawerOpen]);


	return <>
		<Box component="img" alt="Good-Loop logo" src={goodLoopLogo} sx={logoSx} zIndex={theme.zIndex.drawer + 3} />
		<UserBar toggleDrawer={toggleDrawer} isMobile={isMobile} /> {/** On desktop there's an additional bar for brand & user info */}
		<Toolbar />
		<Box display="flex" bgcolor="background.default" sx={{zIndex: theme.zIndex.drawer + 1, position: 'relative'}}>
			<GMPNavbars open={drawerOpen} setOpen={setDrawerOpen} isMobile={isMobile} />
			<Outlet />
		</Box>
	</>;
}

export default Layout;
