import { Grid, IconButton, Link, useTheme } from '@mui/material';
import { PinkHeader, ReadMoreToggle, ReadMoreDetails, CO2ePM } from './MetricComponents';
import { BreakdownBarChart, BreakdownPieChart } from './Breakdown';
import { screenshotBySelector } from '../../utilities/utils';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FilterControls, { FilterDesc } from './FilterControls';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import { useGHGData } from './providers/MetricsDataProvider';
import { ConsumptionIcon } from '../../components/common/Icons';
import Headlines from './Headlines';
import { GMSF_SOURCE, GMSF_URL } from './Glossary';

/***
 * Return metadata about tab & content for the Consumption breakdown tab
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Consumption() {
	return {
		title: 'Consumption',
		name: 'consumption',
		href: '/metrics/breakdown/consumption',
		icon: <ConsumptionIcon />,
		content: <ConsumptionContent />,
	};
};

/**
 * @returns Consumption breakdown tab content
 */
function ConsumptionContent() {
	const {total, consumptionBreakdown, consumptionByDeviceType} = useGHGData();
	const theme = useTheme();
	const { main: consColour, dark: consColourDark } = theme.palette.chartConsumption;

	const consumptionData = consumptionBreakdown?.data || [];
	let pieChartData = [];
	if (consumptionData) {
		pieChartData = [
			{
				title: 'Device: embodied emissions',
				color: consColour,
				values: {
					Embodied: consumptionData.consumptionEmbodiedEmissions,
				}
			},
			{
				title: 'Device: use phase emissions',
				color: consColourDark,
				values: {
					'Use Phase': consumptionData.consumptionUsePhaseEmissions,
				}
			}
		];
	}

	const deviceOverwrite = {
		CTV: 'CTV',
	};

	const deviceData = consumptionByDeviceType?.data || [];
	let barChartData = {};
	if (deviceData && deviceData.length > 0) {
		barChartData = {
			xAxis: {
				title: 'Device Type',
				data: deviceData.map(({device}) => {
					if(Object.keys(deviceOverwrite).includes(device)) {
						return deviceOverwrite[device];
					}
					if (device.length > 1) {
						return device[0].toUpperCase() + device.slice(1).toLowerCase();
					}
					return device;
				})
			},
			totalSeries: [
				{
					data: deviceData.map(({consumptionEmbodiedEmissions}) => consumptionEmbodiedEmissions),
					label: 'Total embodied emissions',
					id: 'embodied',
					color: consColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				},
				{
					data: deviceData.map(({consumptionUsePhaseEmissions}) => consumptionUsePhaseEmissions),
					label: 'Total use phase emissions',
					id: 'usePhase',
					color: consColourDark,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				}
			],
			pmSeries: [
				{
					data: deviceData.map(({consumptionEmbodiedEmissionsCO2EPM}) => consumptionEmbodiedEmissionsCO2EPM),
					label: <><CO2ePM /> Embodied emissions</>,
					color: consColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				},
				{
					data: deviceData.map(({consumptionUsePhaseEmissionsCO2EPM}) => consumptionUsePhaseEmissionsCO2EPM),
					label: <><CO2ePM /> Use Phase emissions</>,
					color: consColourDark,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					stack: 'A',
					type: 'bar'
				}
			]
		};
	}

	return (
		<>
			<ConsumptionHeader />
			<FilterControls />
			<PageSection id="consumption-screenshot">
				<Grid container spacing={1}>
					<Grid item xs={12} position="relative">
						<Header rank={4} sx={{mb: 4}}>Your consumption GHG</Header>
						<IconButton onClick={() => screenshotBySelector('#consumption-screenshot')} sx={{position: 'absolute', right: 0, top: 0}} name="Screenshot Consumption">
							<CameraAltOutlinedIcon htmlColor="#000"/>
						</IconButton>
						<FilterDesc />
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ConsumptionHeadline data={total}/>
							</Grid>
							<Grid item xs={12}>
								<BreakdownPieChart chartData={pieChartData} title="Consumption breakdown" />
							</Grid>
							<Grid item xs={12}>
								<BreakdownBarChart chartData={barChartData} title="Consumption breakdown by device" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</PageSection>
		</>	);
}


function ConsumptionHeader() {
	return (
		<PinkHeader logo={ConsumptionIcon} title="Consumption explained">
			Consumption emissions are the greenhouse gas emissions attributed to user devices when an ad creative is viewed.
			<ReadMoreToggle />
			<ReadMoreDetails>
				<p><strong>Ad space selection</strong> refers to all activities related to media placement and inventory planning, including real-time bidding, direct sales and targeting.</p>
				<p><strong>Ad creative delivery</strong> refers to all activities related to delivering and installing the ad in the selected placement including media storage and media transmission.</p>
				Source: <Link target="_blank" href={GMSF_URL}>{GMSF_SOURCE}</Link>
			</ReadMoreDetails>
		</PinkHeader>
	);
}


function ConsumptionHeadline({data}) {
	data = data?.data;
	return <Headlines loading={!data} part={data?.consumptionEmissions} total={data?.totalEmissions} pm={data?.consumptionCO2EPM} />;
}

export default Consumption;
