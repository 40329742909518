import { useCallback, useMemo, useState } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import { Box, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FormContextProvider, { useFormContext } from '../FormContextProvider';
import { useLineItems } from '../../state/apiHooks';
import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../lists/ListCard';
import { EntityTypes } from '../../model/types/Entity';
import EntityContextProvider from '../EntityContextProvider';
import entity from '../../model/entity';
import { Header } from '../common/LayoutComponents';
import TextInputField from '../inputFields/TextInputField';
import { itemAdder, SubForm } from '../SubForms';
import SubmitButton from './SubmitButton';
import FormStatus from './FormStatus';
import PagedList from '../lists/PagedList';
import { InputLabel } from '../inputFields/InputWrapper';
import { HeightMatchWrapper } from '../inputFields/ButtonGroupField';
import ShowEntityField from '../common/ShowEntity';
import DeleteButton from './DeleteButton';
import { set as cloneAndSet } from 'lodash/fp';
import EntityDialog from './EntityDialog';
import PermissionWrapper from './PermissionWrapper';
import DialogButton from '../widgets/DialogButton';


/** Line item list + edit form for a saved creative. */
function SavedLineItemcontrols() {
	const { initial: creative } = useFormContext();
	/** Base object for new LIs & also query object for fetching LIs. */
	const liBaseQuery = useMemo(() => ({creativeId: creative.id}), [creative]);
	const { data: lineItems } = useLineItems(liBaseQuery) as UseQueryResult<any[]>;

	const [editItem, setEditItem] = useState(null);
	const returnToList = useCallback(() => setEditItem(null), [setEditItem]);

	// An LI has been selected for editing - show the editor.
	if (editItem != null) return (
		<SavedLineItemEditor lineItem={editItem} returnToList={returnToList} base={liBaseQuery} />
	);

	// No LI in focus - show the list.
	return <Box width="100%">
		<Box mb={2} display="flex" justifyContent="end">
			<Button variant="contained" onClick={() => setEditItem(lineItems?.length || 0)}>Add Line Item</Button>
		</Box>
		<Stack spacing={2}>
			<PagedList items={lineItems} ItemComponent={SavedListItem} listProps={{setEditItem}} />
		</Stack>
	</Box>;
}


/** Adds an empty object when clicked. */
const AddLineItemButton = itemAdder(function({addItem}) {
	const onClick = useCallback(() => {
		addItem({});
	}, [addItem]);
	return <Button variant="contained" onClick={onClick}>Add Line Item</Button>;
});


/** Line item list + edit form for a draft creative. */
function DraftLineItemControls() {
	const { draft: creative } = useFormContext();
	/** The set of line items which will be posted with the new creative & persisted to the DB at the same time. */
	const initialLineItems = creative.initialLineItems;

	// LIs on a draft creative don't have IDs yet - so we address them by index in the creative's initialLineItems field.
	const [editIndex, setEditIndex] = useState(null);
	/** The "back" button action */
	const returnToList = useCallback(() => setEditIndex(null), [setEditIndex]);

	/** DraftListItem with the setFocusIndex prop injected. */
	const ListItem = useCallback((props) => <DraftListItem setEditIndex={setEditIndex} {...props} />, [setEditIndex]);

	// An LI has been selected for editing - show the editor.
	if (editIndex != null) return (
		<DraftLineItemEditor index={editIndex} returnToList={returnToList} />
	);

	// No LI in focus - show the list.
	return <Box width="100%">
		<Box mb={2} display="flex" justifyContent="end">
			<AddLineItemButton path="initialLineItems" />
		</Box>
		<Stack spacing={2}>
			<SubForm path="initialLineItems" ItemForm={ListItem} />
		</Stack>
	</Box>;
}


/** Wraps LineItemCard for use inside a <SubForm>. */
function DraftListItem({index, setEditIndex}) {
	const onClickEdit = useCallback(() => setEditIndex(index), [index, setEditIndex]);
	return <LineItemCard onClickEdit={onClickEdit} />;
}


/** Wraps LineItemCard for use inside a <PagedList>. */
function SavedListItem({item, setEditItem}) {
	const onClickEdit = useCallback(() => setEditItem(item), [item, setEditItem]);
	return <EntityContextProvider entity={item} type={entity.LINE_ITEM as keyof EntityTypes}>
		<LineItemCard onClickEdit={onClickEdit} />
	</EntityContextProvider>;
}


/** ListCard for a line item - expects the LI to be in FormContext. */
function LineItemCard({onClickEdit}) {
	const { draft: lineItem } = useFormContext();
	return <ListCard item={lineItem}>
		<ListCardSection>
			<ItemAttribute label="Click URL" value={lineItem.clickUrl || 'None'} />
		</ListCardSection>
		<ListCardActions>
			<Button variant="contained" onClick={onClickEdit}>Edit</Button>
			<DeleteButton label="Remove" />
		</ListCardActions>
	</ListCard>;
}


/** Single-field form for editing one callback URL for an event on a line item.*/
function LineItemEventUrl() {
	return (
		<Grid xs={2}>
			<TextInputField type="url" path="." size="small" />
		</Grid>
	);
}


/** Button to add an empty-string callback URL to the list for an event on a line item. */
const AddEventUrl = itemAdder(function({addItem}) {
	return (
		<Grid xs={2}>
			<HeightMatchWrapper size="small">
				<Button variant="contained" onClick={() => addItem('')}>+</Button>
			</HeightMatchWrapper>
		</Grid>
	);
});


/** Form for editing the list of callback URLs associated with an event on a line item. */
function LineItemEventField({evtName}) {
	const path = `eventTags.${evtName}`;
	return <>
		<InputLabel>{evtName}</InputLabel>
		<Grid container xs={12} spacing={1}>
			<SubForm path={path} ItemForm={LineItemEventUrl} />
			<AddEventUrl path={path} />
		</Grid>
	</>;
}


/** Preset list of events we can fetch callbacks for. */
const eventTagNames = ['render', 'videoStart', 'unlock', 'videoComplete', 'click', 'skip'];

/** */
function LineItemForm({returnToList}: {returnToList?: (any) => any}) {
	const { draft, saved } = useFormContext();
	return <>
		<Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
			<Header rank={5}>Edit Line Item</Header>
			<Box display="flex" alignItems="center" gap={1}>
				{saved && <><FormStatus /><SubmitButton /></>}
				{returnToList && <Button variant="outlined" onClick={returnToList}>Back</Button>}
			</Box>
		</Box>
		<Grid xs={12} spacing={2}>
			<TextInputField label="Name" path="name" />
		</Grid>
		{saved && (
			<Grid xs={12} spacing={2}>
				<ShowEntityField label="Creative" type={entity.CREATIVE as keyof EntityTypes} id={draft.creativeId} />
			</Grid>
		)}
		<Grid xs={12} spacing={2}>
			<TextInputField label="Click URL" path="clickUrl" />
		</Grid>
		<Grid xs={12} spacing={2}>
			<Header rank={6}>Event Tags</Header>
			{eventTagNames.map(evtName => <LineItemEventField evtName={evtName} />)}
		</Grid>
	</>;
}


function DraftLineItemEditor({index, returnToList}) {
	const { draft, setDraft } = useFormContext();
	const lineItem = draft.initialLineItems[index];
	// Provide a submit function so autosave writes edits back to the containing creative.
	// TODO SubForm should provide a generalised version of this...
	const submitFn = useCallback(nextLineItem => {
		setDraft(prevCreative => (
			cloneAndSet(`initialLineItems[${index}]`, nextLineItem, prevCreative)
		));
	}, [index, setDraft]);

	return (
		<FormContextProvider type={entity.LINE_ITEM as keyof EntityTypes} initial={lineItem} submitFn={submitFn} autosave>
			<LineItemForm returnToList={returnToList} />
		</FormContextProvider>
	);
}


function SavedLineItemEditor({lineItem, returnToList, base}) {
	return (
		<EntityContextProvider type={entity.LINE_ITEM as keyof EntityTypes} entity={lineItem} base={base}>
			<LineItemForm returnToList={returnToList} />
		</EntityContextProvider>
	);
}


/**  */
function LineItemControls() {
	const { saved } = useFormContext();

	if (saved) return <SavedLineItemcontrols />;
	return <DraftLineItemControls />;
}

function validateLineItem(authUser, lineItem) {
	return (lineItem.name && lineItem.clickUrl);
}


function LineItemDialog(props) {
	return <EntityDialog type={entity.LINE_ITEM} validateFn={validateLineItem} {...props}>
		<LineItemForm />
	</EntityDialog>;
}

function EditLineItemButton({label = 'Edit Line Item', ...props}) {
	return (
		<PermissionWrapper type={entity.LINE_ITEM} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={LineItemDialog} {...props} />
		</PermissionWrapper>
	);
}


export default LineItemControls;

export { LineItemDialog, EditLineItemButton };
