import { ReactNode, type ReactElement } from 'react';
import { FormControl, type InputProps, OutlinedInput, outlinedInputClasses } from '@mui/material';
import { useEntity } from '../../state/apiHooks';
import { InputLabel } from '../inputFields/InputWrapper';
import { entityDisplayName } from '../../model/entity';
import { Entity, EntityTypes } from '../../model/types/Entity';


/** Override default "disabled" transparent text */
const inputSx = {
	[`& .${outlinedInputClasses.disabled}.${outlinedInputClasses.input}`]: {
		color: 'text.primary',
		WebkitTextFillColor: 'currentColor'
	}
};


interface ShowEntityProps extends InputProps {
	/** Entity type. Required to fetch. */
	type: keyof EntityTypes;
	/** Entity ID. Required to fetch. */
	id: string;
	/** Field label. Defaults to the display name for the entity type. */
	label?: ReactNode;
}



function ShowEntityField({type, id, label = entityDisplayName(type), ...props}: ShowEntityProps): ReactElement {
	const { data: entity, isLoading} = useEntity(type, id);

	const sx = (entity || isLoading) ? inputSx : null;

	const name = isLoading ? 'Loading…' : (
		entity ? `${(entity as Entity).name || 'No name'} [${id}]` : 'None'
	);

	return (
		<FormControl fullWidth={props.fullWidth}>
			<InputLabel>{label}</InputLabel>
			<OutlinedInput value={name} {...props} disabled sx={sx} />
		</FormControl>
	);
}

export default ShowEntityField;
