import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, List, SvgIcon} from '@mui/material';

import {
	School as ResourcesIcon,
	SwitchAccessShortcutAdd as AnalyseIcon,
	People as PeopleIcon,
	WorkOutline as WorkspacesIcon,
	Newspaper as PublishersIcon
} from '@mui/icons-material';
import { useLocation } from 'react-router';
import {ReactComponent as MetricsIcon} from '../img/icons/bar_chart_4_bars.svg';
import {ReactComponent as MediaIcon } from '../img/icons/newsmode.svg';
import {ReactComponent as TagsIcon } from '../img/icons/sell.svg';
import { useWorkspace } from './WorkspaceProvider';

/** Nav link specifications: display name, link URL, icon, and a regex for determining if it's the current page */
const workspacePages = [
	{ name: 'Metrics', href: '/metrics', pattern: /^\/metrics/, icon: <SvgIcon component={MetricsIcon} inheritViewBox /> }
];

const adminPages = [
	{ name: 'Workspaces', href: '/workspaces', pattern: /^\/workspaces/, icon: <WorkspacesIcon />},
	{ name: 'GL Users', href: '/users', pattern: /^\/users/, icon: <PeopleIcon />}
];

if (process.env.REACT_APP_GOOD_MEASURES_FOR === 'publishers') {
	adminPages.push({ name: 'Publishers', href: '/publishers', pattern: /^\/publishers/, icon: <PublishersIcon /> });
}


const pageTabA11yProps = index => ({
	id: `simple-tab-${index}`,
	'aria-controls': `vertical-tabpanel-${index}`,
});


/** Which of the given nav tabs is the longest prefix of the current path? */
const resolveLocationValue = (pathname, pages) => {
	const bestPageMatch = pages.reduce((prevBest, page) => {
		let match = pathname.match(page.pattern);
		if (!match) return prevBest; // Discard unmatched
		if (prevBest?.match.length > match[0].length) return prevBest; // Keep longest matching substring
		return { ...page, match: match[0] }; // Hold onto matching substring for comparison
	}, null);
	return bestPageMatch?.href || false; // MUI Tabs complain about out-of-range values unless given explicit "false"
};




const drawerSx = { color: 'black', minWidth: '100px', flexShrink: 0 };

const drawerPaperProps = { sx: { backgroundColor: 'background.default', border: 'none' } };


function DrawerLink({ name, href, icon, isMobile, selected}) {
	// Icon + text stack vertically on desktop
	const buttonSx = isMobile ? undefined : { flexDirection: 'column', p: 0 };
	// Let icon take more padding on mobile
	const iconSx = { color: 'inherit', minWidth: isMobile ? undefined : 'unset', backgroundColor: selected ? 'rgba(255, 8, 79, 0.1)' : 'transparent', p: 1, borderRadius: '16px'};

	return <ListItem>
		<ListItemButton href={href} sx={buttonSx} >
			<ListItemIcon sx={iconSx}>{icon}</ListItemIcon>
			<ListItemText primary={<Typography fontWeight="bold">{name}</Typography>} />
		</ListItemButton>
	</ListItem>;
};


/** The nav bars for the Good Measures site - vertical tabs for large screens and a hamburger menu for mobile. */
function GMPNavbar({ open, setOpen, isMobile }) {
	const { pathname } = useLocation();
	const { workspaceId } = useWorkspace();
	const pages = workspaceId ? workspacePages : adminPages;
	const selectedHref = resolveLocationValue(pathname, pages);
	const onClose = () => setOpen(false);
	const variant = isMobile ? undefined : 'permanent';

	return (
		<Drawer open={open} onClose={onClose} variant={variant} sx={drawerSx} PaperProps={drawerPaperProps} anchor="left">
			<Toolbar />
			<Box sx={{ overflow: 'auto', mt: 1 }}>
				<List>
					{pages.map((pageProps, i) => {
						const selected = pageProps.href.startsWith(selectedHref);
						return <DrawerLink key={i} isMobile={isMobile} {...pageProps} selected={selected} />;
					})}
				</List>
			</Box>
		</Drawer>
	);
}


export default GMPNavbar;
