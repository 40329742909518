import {  useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import entity from '../../model/entity';
import TextInputField from '../inputFields/TextInputField';
import DialogButton from '../widgets/DialogButton';
import PermissionWrapper from './PermissionWrapper';
import UploadField from '../inputFields/UploadField';
import { useFormContext } from '../FormContextProvider';
import EntityDialog from './EntityDialog';


const validateBrand = (authUser, brand) => {
	// Every brand must at least have a name.
	// TODO Logo requirement has been removed for now due to the need for uploads to be attached to an existing brand...
	return (brand.name /* && brand.logoUrl */);
};


/**
 * The inner form for a Brand editor.
 * @param {FormFieldsProps} props
 */
function BrandFormFields() {
	const { id: brandId } = useFormContext();

	// Mark DB metadata for uploaded logo as belonging to the brand..
	const uploadParams = useMemo(() => ({ brandId }), [brandId]);

	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<TextInputField label="Brand Name" path="name" required />
			</Grid>
			{brandId && (
				<Grid container xs={12}>
					<Grid>
						<UploadField type="image" label="Logo" path="logoUrl"
							uploadParams={uploadParams}
						/>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
}



/** Override default type display name: "group" to "brand". */
function BrandDialogTitle() {
	const { id, entity } = useFormContext();
	// ID provided = edit mode
	if (id) return <>Edit {entity?.name || 'Brand'}</>;
	return 'New Brand';
}


function BrandDialog(props) {
	return <EntityDialog type={entity.GROUP} validateFn={validateBrand} title={<BrandDialogTitle />} {...props}>
		<BrandFormFields />
	</EntityDialog>;
}


function EditBrandButton({ label = 'Edit Brand', ...props}) {
	return (
		<PermissionWrapper type={entity.GROUP} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={BrandDialog} {...props} />
		</PermissionWrapper>
	);
}


export default BrandDialog;
export { BrandFormFields, EditBrandButton };