import { useCallback } from 'react';
import { noop } from 'lodash';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

import DeleteButton from './DeleteButton';
import SubmitButton from './SubmitButton';
import { useFormContext } from '../FormContextProvider';
import FormStatus from './FormStatus';


/**
 * @typedef {Object} EditFormProps
 * @param {String} type The type of entity to edit.
 * @param {String|Number} [id] If supplied, the dialog will try to update an existing entity with this ID.
 * @param {Object} [base] If creating a new entity, the dialog will pre-fill the draft entity's fields from this object.
 * @param {Object} [saved] If editing an existing entity, the dialog will pre-fill the draft entity's fields from this object.
 * @param {Function} [commitThen] Called with the result of the commit action on success.
 * @param {Function} [commitCatch] Called with the result of the commit action on failure.
 * @param {validateFn} [validateFn] Validates the draft entity.
 * @param {React.Component<FormFieldsProps>} FormFields Renders the input fields
*/

/**
 * @typedef {Object} EditDialogProps
 * @extends EditFormProps
 * @param {Boolean} open Truthy if the dialog should be open.
 * @param {Function} onClose Called when the user signals to close the dialog, and when an edit action completes.
 * @param {String|Element} [title] Inserted in the dialog header. Overrides dynamic title generation in the default TitleComponent.
 * @param {Component} [TitleComponent] Receives {@link title}, {@link EditFormProps#type}, {@link EditFormProps#id}, {@link EditFormProps#base}
*/




/**
 * @callback FormFields The FormFields component of an EditDialog.
 * @property {Object} [base] Template entity with pre-filled fields. Form components may choose to lock preset values.
 * @property {Object} draft The entity the form is editing
 * @property {Function} updateDraft A function which merges changes into the draft entity
 * @property {Boolean} [isSaving] The entity is currently being committed to the store - edits should be locked.
 * @returns {ReactComponent}
 */

/**
 * @callback validateFn
 * @param {GLUser} authUser The currently-acting user
 * @param {Entity} draft The draft entity for validation
 * @returns {Boolean} True if the draft is ready to save.
 */

/**
 * @callback TitleComponent Used to render the title of an EditDialog.
 * @param {String} title Pre-generated title. Default implementation gives this priority over other params.
 * @param {String} type Entity type.
 * @param {String} [id] Entity ID. Only present if editing.
 * @param {Object} [base] May be present if creating. Template for entity with preset fields.
 * @returns {String|Element}
 */


/**
 * A generic modal dialog form. Expects to be rendered inside a {@link FormContextProvider}.
 * @param {EditDialogProps} props
 * @returns {ReactElement}
 */
function EditDialog({
	open = false, onClose = noop, commitThen = noop, closeOnCommit = true,
	dialogProps, canDelete, children,
	...props
}) {
	const {initial: entity, title: contextTitle, id, loading} = useFormContext();

	// Auto-close dialog on successful submit
	const _commitThen = useCallback((...args) => {
		closeOnCommit && onClose();
		commitThen(...args);
	}, [onClose, closeOnCommit, commitThen]);


	let content;
	if (loading) {
		content = <CircularProgress />;
	} else if (id && !entity) {
		content = <>Couldn't retrieve {props.type} [{id}] to edit.</>;
	} else {
		content = children;
	}


	return (
		<Dialog open={open} onClose={onClose} {...dialogProps}>
			<DialogTitle onClose={onClose}>
				{contextTitle}
			</DialogTitle>
			<DialogContent>
				{ content }
			</DialogContent>
			<DialogActions>
				<FormStatus />
				<SubmitButton submitThen={_commitThen} />
				{canDelete && id && <DeleteButton onComplete={_commitThen} />}
			</DialogActions>
		</Dialog>
	);
}


export default EditDialog;
