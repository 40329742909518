import entity from '../../model/entity';
import { useGroup, useGroupPermission, useUser } from '../../state/apiHooks';
import EntityTable from './EntityTable';
import { EditUserButton } from '../edit/UserDialog';
import DeleteButton from '../edit/DeleteButton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Avatar, Button, CircularProgress, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EditGroupPermissionDropdown } from '../edit/GroupPermissionDialog';
import { permissionLevelNames } from '../../model/permissionLevels';

const sortAlphabetically = users => {
	if (!users) return [];
	return users.toSorted((a, b) => (a.name || '').localeCompare(b.name));
};

/** Default first column: user name and avatar */
const nameColumn = { field: 'name', headerName: 'User Name', renderCell: RowUserCell, flex: 1};


/** Show a group-permissions editor for the user */
function EditGroupPermissionCell({row, groupId}) {
	return <EditGroupPermissionDropdown size="small" userId={row.id} groupId={groupId} />;
}

/** Show the user's current permission level with the group */
function ShowGroupPermissionCell({row, groupId}) {
	const { data: groupPermission, isLoading } = useGroupPermission(row.id, groupId);
	if (isLoading) return <CircularProgress />;
	const levelName = permissionLevelNames[groupPermission?.level];
	return levelName || permissionLevelNames.NONE;
}

/** Appropriately label the "Group Permission" column based on group's name and workspace status */
const headerName = group => {
	if (!group) return 'Permission';
	if (!group.parentId) return 'Workspace Permission';
	if (!group.name) return 'Group Permission'; // Shouldn't happen
	return `${group.name} Permission`;
};


/** Show user's name, email and avatar */
function UserCellContent({id, name, pictureUrl, email}) {
	return (
		<Grid container direction="row" spacing={1} display="inline-flex" sx={{flexWrap: 'nowrap'}}>
			<Grid alignContent="center"><Avatar src={pictureUrl}>{name?.[0]}</Avatar></Grid>
			<Grid alignContent="center">
				<Grid container direction="column">
					<Grid><Typography fontWeight="bold">{name}</Typography></Grid>
					<Grid><Typography>{email}</Typography></Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

/** Pull user's name, email and avatar from the grid row & render with UserCellContent */
function RowUserCell({ row }) {
	const { id, name, email, pictureUrl } = row;

	return <UserCellContent id={id} name={name} email={email} pictureUrl={pictureUrl} />;
}


/** Fetch user by ID & render with UserCellContent */
function FetchUserCell({value}) {
	const { data: user = {}, isPending} = useUser(value);

	if (isPending) return <CircularProgress />;
	if (!user) return null;

	const { id, name, email, pictureUrl } = user;
	return <UserCellContent id={id} name={name} email={email} pictureUrl={pictureUrl} />;
}


/** Render a "Show User" link which goes to the appropriate admin/management page */
function ShowAction({row}) {
	// Workspace users are viewed under /manage/users/$ID; global users under /users/$ID
	const href = `${row.workspaceId ? '/manage' : ''}/users/${row.id}`;
	return <Button variant="outlined" color="secondary" href={href}>Show</Button>;
}

/** Render "Edit User" dialog button */
function EditAction({row}) {
	return <EditUserButton label="Edit" id={row.id} />;
}

/** Render "Delete User" dialog button */
function DeleteAction({row}) {
	return <DeleteButton type={entity.USER} id={row.id} />;
}

/** Default actions for a user - show, edit, delete. */
const defaultActions = [ShowAction, EditAction, DeleteAction];



/**
 * Fetches users and shows them in a DataGrid table. See EntityTable for more details.
 * @param {*} p
 * @param {Component[]} [p.actions] Action buttons or links. Will be inserted in the rightmost column.
 * @param	{String} [p.permissionGroup] Group ID - "edit/show permission" column will be included for this group if present.
 * @param	{Boolean} [p.editPermission] True to allow editing group permissions; false to only show current level.
 * @param {Object} [p.query] Generic query to filter users, e.g. by workspaceId
 */
function UserTable({actions = defaultActions, workspaceId, permissionGroup, editPermission, query, ...props}) {
	const { data: group } = useGroup(permissionGroup);

	const columns = useMemo(() => {
		// No permission column
		if (!permissionGroup) return [nameColumn];
		// Group-specific permission column
		const PermissionCellComponent = editPermission ? EditGroupPermissionCell : ShowGroupPermissionCell;
		const renderCell = props => <PermissionCellComponent {...props} groupId={permissionGroup} />;
		return [
			nameColumn,
			{ field: 'permission', flex: 1, headerName: headerName(group), renderCell}
		];
	}, [permissionGroup, editPermission, group]);


	return <EntityTable aria-labelledby="User table" columns={columns} actions={actions} type={entity.USER} query={query} processRows={sortAlphabetically} {...props} />;
}


export default UserTable;
export {
	RowUserCell,
	FetchUserCell
};
