import { TextField } from '@mui/material';
import InputWrapper from './InputWrapper';

function FileFieldInner(props) {
	return <TextField type="file" {...props} />;
}


function FileField(props) {
	return <InputWrapper  {...props} Input={FileFieldInner} />;
}

export default FileField;
