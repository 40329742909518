import { useCallback, useMemo, useState } from 'react';
import { useCampaign, useGroup, useTag } from '../../state/apiHooks';
import { useMetricsFilter } from './providers/MetricsFilterProvider';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import SelectField from '../../components/inputFields/SelectField';
import { SelectCampaign, SelectGroup, SelectTag } from '../../components/inputFields/SelectFromAPI';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import DateField from '../../components/inputFields/DateField';


const localIsoDatePlugin = (option, dayjsClass, dayjsFactory) => {
	/** Format to yyyy-mm-dd under the user's current time zone. */
	dayjsClass.prototype.toLocalIsoDateString = function() {
		return this.format('YYYY-MM-DD');
	};

	/** Parse yyyy-mm-dd on the assumption that it's a date in the user's current time zone. */
	dayjsFactory.fromLocalIsoDateString = function(dateString, end) {
		if (!dateString) return null;

		const match = dateString.match(/(\d+)-(\d+)-(\d+)/);
		if (!match) return null;

		const [, year, month, day] = match;
		const [hour, minute, second, msec] = end ? [23, 59, 59, 999] : [0, 0, 0, 0];
		return dayjs(new Date(year, month - 1, day, hour, minute, second, msec));
	};
};

dayjs.extend(localIsoDatePlugin);


/** Common properties for the grid items */
const itemProps = { xs: 12, sm: 3, mt: {xs: 1, sm: 0} };
// Removed when changing from 5 items on row to 4
/*sx: {minWidth: {xs: '100%', sm: '20%'}, maxWidth: {xs: '100%', sm: '20%'}, }*/


const channelNames = {
	ctv: 'CTV',
	programmatic: 'Programmatic'
};

const channelOptions = Object.entries(channelNames).map(([value, label]) => ({ label, value }));


const equalsQuarter = (start, end, quarterObj) => {
	return start === quarterObj.start && end === quarterObj.end;
};


const findTimeframe = (start, end, quarters) => {
	return quarters.find(q => equalsQuarter(start, end, q)) || '';
};


/**
 * Get the four most recent quarters from the current date & a corresponding
 * set of { label, value }  objects for use as options in a SelectField.
 */
const getQuartersAndOptions = () => {
	// Map of names (eg "2024 Q2") to uarter objects with start/end
	const _quarters = getRecentQuarters();
	// Array of objects for the select dropdown
	const _quarterOptions = _quarters.map(quarter => {
		return {
			label: quarter.name,
			value: quarter
		};
	});
	return [_quarters, _quarterOptions];
};


function FilterControls() {
	const [filter, setFilter] = useMetricsFilter();
	const { start, end, groupId, campaignId, tagId, channel } = filter;

	// Build timeframe options
	const month = new Date().getMonth();
	const [quarters, quarterOptions] = useMemo(getQuartersAndOptions, [month]); // Regenerate when month changes

	// Update timeframe dropdown & both date fields when a selection is made
	const [timeframe, setTimeframeRaw] = useState(() => findTimeframe(start, end, quarters));
	const setTimeframe = useCallback(val => {
		setTimeframeRaw(val);
		// omit start and end unless present in value
		const { start, end, ...nextFilter } = filter;
		if (val?.start) nextFilter.start = val.start;
		if (val?.end) nextFilter.end = val.end;
		setFilter(nextFilter);
	}, [filter, setFilter, setTimeframeRaw]);

	// Convert stored yyyy-mm-dd strings to date + time
	const [startVal, endVal] = useMemo(() => {
		return [
			dayjs.fromLocalIsoDateString(filter.start),
			dayjs.fromLocalIsoDateString(filter.end),
		];
	}, [filter]);

	const [setStart, setEnd] = useMemo(() => {
		const setTimeBound = (date, boundKey) => {
			const nextFilter = {...filter};
			if (date) {
				nextFilter[boundKey] = date.toLocalIsoDateString();
			} else {
				delete nextFilter[boundKey];
			}
			setFilter(nextFilter);
			setTimeframeRaw(undefined);
		};
		return [
			date => setTimeBound(date, 'start'),
			date => setTimeBound(date, 'end')
		];
	}, [filter, setFilter, setTimeframeRaw]);

	return (
		<PageSection>
			<Grid container spacing={1}>

				<Grid {...itemProps} >
					<SelectGroup fullWidth
						label="Group" labelEmpty="All Groups"
						name="groupId" value={groupId}
						onChangeKV={setFilter} size="small"
					/>
				</Grid>

				<Grid {...itemProps} >
					<SelectCampaign fullWidth
						label="Campaign" labelEmpty="All Campaigns"
						name="campaignId" value={campaignId}
						onChangeKV={setFilter} size="small"
						query={groupId && { groupId }}
					/>
				</Grid>

				<Grid {...itemProps} >
					<SelectTag fullWidth
						label="Tag" labelEmpty="All Tags"
						name="tagId" value={tagId}
						onChangeKV={setFilter} size="small"
						query={(campaignId && {campaignId}) || (groupId && {groupId})}
					/>
				</Grid>

				<Grid {...itemProps} >
					<SelectField fullWidth
						label="Supply Channel" labelEmpty="All Channels"
						name="channel" value={channel} onChangeKV={setFilter}
						options={channelOptions} size="small"
					/>
				</Grid>

				<Grid {...itemProps} >
					<SelectField fullWidth
						label="Timeframe"
						name="Timeframe"
						value={timeframe} onChangeValue={setTimeframe} options={quarterOptions}
						labelEmpty={(start || end) ? 'Custom' : 'All Time'}
						size="small"
					/>
				</Grid>

				<Grid {...itemProps}>
					<DateField fullWidth
						label="From" name="from date"
						value={startVal} onChange={setStart}
						size="small"
					/>
				</Grid>

				<Grid {...itemProps} >
					<DateField fullWidth
						label="To" name="to date"
						value={endVal} onChange={setEnd}
						size="small"
					/>
				</Grid>
			</Grid>
		</PageSection>
	);
}



/**
 * Generate
 * @returns {Object}
 */
const getRecentQuarters = (count = 4) => {
	// Set up a date clamped to start of current quarter
	const cursor = new Date();
	cursor.setMonth(Math.floor(cursor.getMonth() / 3) * 3);
	cursor.setDate(1);

	const quarters = [];

	for (let i = 1; i <= count; i++) {
		// Construct date of quarter end
		const endCursor = new Date(cursor);
		endCursor.setMonth(endCursor.getMonth() + 3);
		endCursor.setDate(endCursor.getDate() - 1); // eg Q1, 1st April --> 31st March

		const year = cursor.getFullYear();
		const startMonth = cursor.getMonth();
		const quarterIndex = Math.floor(startMonth / 3);
		quarters.push({
			name: `${year} Q${quarterIndex + 1}`,
			start: dayjs(cursor).toLocalIsoDateString(),
			end: dayjs(endCursor).toLocalIsoDateString()
		});
		cursor.setMonth(startMonth - 3); // Wraps around and updates year when month becomes negative
	}

	return quarters;
};


// takes ISO date and returns localised date (ie 2024-06-30T00:00:00 -> 30/06/2024 or 06/30/2024 depending on locale)
const toLocalisedDate = (date) => {
	if (!date) return null;
	return new Date(date).toLocaleDateString();
};


/** Prints a description of the currently active filter bank. */
function FilterDesc({Component = Header, rank = 5, color = '#666', my = 4, ...props}) {
	const [{ start, end, groupId, campaignId, tagId, channel }] = useMetricsFilter();

	const { data: group } = useGroup(groupId);
	const { data: campaign } = useCampaign(campaignId);
	const { data: tag } = useTag(tagId);

	let entityString = 'All Tags';
	if (tagId) {
		entityString = `Tag: ${tag?.name || tagId}`;
	} else if (campaignId) {
		entityString = `Campaign: ${campaign?.name || campaignId}`;
	} else if (groupId) {
		entityString = `Group: ${group?.name || groupId}`;
	}

	const month = new Date().getMonth();
	const timeframes = useMemo(getRecentQuarters, [month]);
	const timeframeLabel = findTimeframe(start, end, timeframes)?.name;

	let timeString = 'All Time';
	if (timeframeLabel) {
		timeString = timeframeLabel;
	} else if (start && !end) {
		timeString = `From ${toLocalisedDate(start)}`;
	} else if (!start && end) {
		timeString = ` To ${(toLocalisedDate(end))}`;
	} else if (start && end) {
		timeString = `${toLocalisedDate(start)} to ${toLocalisedDate(end)}`;
	}

	const channelString = channel ? channelNames[channel] : 'All Channels';

	return (
		<Component sx={{fontSize: 'clamp(1rem, 1.25vw, 4rem)'}} rank={rank} color={color} my={my} {...props}>
			{entityString}{entityString && ' - '}
			{timeString}{' - '}
			{channelString}
		</Component>
	);
}

export default FilterControls;
export { FilterDesc };