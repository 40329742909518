import ListCard, { ItemAttribute, ListCardActions, ListCardSection } from '../../components/lists/ListCard';
import { EditLineItemButton } from '../../components/edit/LineItemControls';
import { ExportDirectButton, ExportVASTButton } from './ExportAdButton';
import CloneButton from '../../components/widgets/CloneButton';


/** ListCard for a line item. */
function LineItemListItem({item: lineItem}) {
	return <ListCard item={lineItem}>
		<ListCardSection>
			<ItemAttribute label="Click URL" value={lineItem.clickUrl || 'None'} />
		</ListCardSection>
		<ListCardActions>
			<ExportVASTButton lineItem={lineItem}>Copy VAST URL</ExportVASTButton>
			<ExportDirectButton lineItem={lineItem}>Copy HTML tag</ExportDirectButton>
			<EditLineItemButton id={lineItem.id} label="Edit" />
			<CloneButton type="lineitem" id={lineItem.id} />
		</ListCardActions>
	</ListCard>;
}


export default LineItemListItem;
