import { Button, ButtonGroup, OutlinedInput } from '@mui/material';
import InputWrapper from './InputWrapper';
import { disableNthChildWarning, useMergeSx } from '../../utilities/utils';



const rightCornerSx = null; //{borderTopLeftRadius: 0, borderBottomLeftRadius: 0};

const groupSx = {
	[disableNthChildWarning('& > :not(:first-child)')]: {
		borderTopLeftRadius: 0, borderBottomLeftRadius: 0
	},
	[disableNthChildWarning('& > :not(:last-child)')]: {
		borderTopRightRadius: 0, borderBottomRightRadius: 0
	},
};



function ColorInput({sx, ...props}) {
	const _sx = useMergeSx(sx, rightCornerSx);

	return <ButtonGroup sx={groupSx}>
		<Button component="label" sx={{p: 0, backgroundColor: props.value}}>
			<input type="color" style={{visibility: 'hidden'}} {...props} />
		</Button>
		<OutlinedInput {...props} sx={_sx} />
	</ButtonGroup>;
}


function ColorField(props) {
	return <InputWrapper {...props} Input={ColorInput} />;
}

export default ColorField;
