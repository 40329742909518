import { Box, Container, Typography, Grid, FormControl, RadioGroup, FormControlLabel, Divider, Radio, IconButton, CircularProgress, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { ContentWrapper } from './MetricComponents';
import { ResponsiveChartContainer, BarPlot, MarkPlot, ChartsGrid, ChartsReferenceLine, ChartsXAxis, ChartsYAxis, LinePlot, ChartsTooltip, ResponsiveChartContainerProps } from '@mui/x-charts';
import { dataToCSV, metricWeightString} from '../../utilities/utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { sum, zip } from 'lodash';
import { CO2e, CO2ePM } from './MetricComponents';

const dayMonthFormat = new Intl.DateTimeFormat(undefined, { month: 'numeric', day: 'numeric' });

const ddMMFormatter = (date) => {
	const dayMonth = dayMonthFormat.format(date);
	return dayMonth;
};

function Timeline({ noWrapper = false, chartData, sx = {}}) {
	const theme = useTheme();
	const distColour = theme.palette.chartDistribution.main;
	const consColour = theme.palette.chartConsumption.main;

	const [chartSetting, setChartSetting] = useState('total');
	const isTotal = chartSetting === 'total';

	// Calculate min/max/average
	const [yAvg, minPM, maxPM] = useMemo(() => {
		if (!chartData) return [];
		const allSum = isTotal ? (
			sum(chartData.distribution) + sum(chartData.consumption)
		) : sum(chartData.totalCO2EPM);
		return [
			allSum / chartData.dates.length,
			Math.min(...chartData.totalCO2EPM),
			Math.max(...chartData.totalCO2EPM)
		];
	}, [isTotal, chartData]);

	let yAxisConfig = isTotal ? { beginAtZero: true } : { min: minPM * 0.95, max: maxPM * 1.05 };

	if (!chartData) {
		return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<CircularProgress />
		</Box>;
	};

	const seriesTotal = [
		{ data: chartData.impressions, stack: 'A', yAxisKey: '1', label: 'Impressions', type: 'bar' as const, color: 'white'},
		{ data: chartData.distribution, stack: 'A', id: 'TimelineDistributionBar', label: 'Distribution', type: 'bar' as const, color: distColour, valueFormatter: (e) => metricWeightString(e)},
		{ data: chartData.consumption, stack: 'A', id: 'TimelineConsumptionBar', label: 'Consumption', type: 'bar' as const, color: consColour, valueFormatter: (e) => metricWeightString(e)},
	];

	let chart = (
	<Grid container sx={{height: ['-webkit-fill-available', 'inherit']}}>
			<Grid item xs={0.25} sx={{height: 'inherit'}}>
				<Typography variant="body1" height="100%" alignContent="center" whiteSpace="nowrap">
					<span style={{display: 'block', transform: 'rotate(-90deg) translate(-5rem, 0rem)', transformOrigin: 'left top 0' }}>
						{isTotal ? <>Tonnes <CO2e /></> : <>kg <CO2ePM /></>}
					</span>
				</Typography>
			</Grid>
			<Grid item sx={{height: '100%'}} xs={11.75}>
				<ResponsiveChartContainer
				xAxis={[
					{
						label: 'Date',
						data: chartData.dates,
						scaleType: 'band',
						valueFormatter: ddMMFormatter, // date month {year?}
					}
				]}

				yAxis={[
					{
						valueFormatter: val => `${isTotal ? (val >= 1000 ? val / 1000 : (val / 1000).toFixed(3)) : val.toPrecision(2)}`,
						beginAtZero: true,
						id: '0',
						...yAxisConfig
					},
					// Impressions yAxis - hacky solution! To avoid messing around with axis width, just stack it and set total amount shown to a range of 0 -> 0
					{
						id: '1',
						max: 0,
						min: 0,
						...yAxisConfig
					}
				]}
				// MUI types are incorrect for series.label: it accepts any React component, not just string or () => string
				// @ts-ignore
				series={
					(isTotal ?
						seriesTotal.map(series => ({ ...series, stackOrder: 'reverse' })) :
						[
							{ data: chartData.impressions, yAxisKey: '1', label: 'Impressions', type: 'line' as const, color: 'white'},
							{ data: chartData.totalCO2EPM, label: <>Total <CO2ePM /></>, type: 'line' as const, color: 'black', valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`},
						]
					)
				}
				margin={{ left: 50, right: 50, top: 30, bottom: 20 }}
			>

				<BarPlot />
				<LinePlot />
				{(yAvg != null) && <ChartsReferenceLine y={yAvg} lineStyle={{ stroke: "white", strokeWidth: 4}}  />}
				{(yAvg != null) && <ChartsReferenceLine y={yAvg} label="Your average" labelAlign="end" lineStyle={{ stroke: theme.palette.primary.main, strokeWidth: 2}} labelStyle={{ fontWeight: 'bold', fill: theme.palette.primary.main, backgroundColor: 'blue', textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'}} />}
				<MarkPlot />
				<ChartsTooltip />
				<ChartsGrid horizontal />
				<ChartsXAxis disableLine disableTicks label="" />
				<ChartsYAxis min={0.5} beginAtZero disableLine disableTicks labelStyle={{ transform: 'translateX(-10px) rotate(-90deg)', transformOrigin: '-26px 300px', }} />
			</ResponsiveChartContainer>
			</Grid>
		</Grid>
	);

	if(noWrapper){
		return (
			<ContentWrapper sx={{...sx, display: 'flex', flexDirection: 'column', height: '100%'}}> 
				{chart}
			</ContentWrapper>
		);
	}else{
		return (
			<ContentWrapper title="GHG emissions timeline" sx={sx}>
				<Container sx={{ height: { xs: '100vw', sm: '600px' }, position: 'relative', width: '100%', m: '0', mb: '10%', p: '0 !important', maxWidth: 'unset !important' }}>
			<FormControl>
				<RadioGroup value={chartSetting} onChange={(e) => setChartSetting(e.target.value)} row>
					<FormControlLabel name="Total radio toggle" control={<Radio />} value="total" label={<>Total <CO2e /></>} />
					<FormControlLabel name="CO2epm radio toggle" control={<Radio />} value="pm" label={<CO2ePM />} />
				</RadioGroup>
			</FormControl>
			<IconButton sx={{position: 'absolute', pb: 0, top: '0', right: '0'}} name="Download graph data"
				onClick={() => dataToCSV(chartData.dates, isTotal ? zip(chartData.distribution, chartData.consumption).map((arr) => arr.toString()) : chartData.totalCO2EPM)}>
				<FileDownloadOutlinedIcon sx={{fill: 'black'}}/>
			</IconButton>
			<Divider />
			<Box display="flex" sx={{ ml: 3, mt: 4, visibility: isTotal ? 'unset' : 'hidden'}} gap={2}>
				<Box display="flex" alignItems="center" gap={1} >
					<Box sx={{ width: '4rem', height: '1rem', backgroundColor: 'chartDistribution.main', border: '1px solid black' }} />
					<Typography fontWeight="medium">Distribution</Typography>
				</Box>
				<Box display="flex" alignItems="center" gap={1}>
					<Box sx={{ width: '4rem', height: '1rem', backgroundColor: 'chartConsumption.main', border: '1px solid black' }} />
					<Typography fontWeight="medium">Consumption</Typography>
				</Box>
			</Box>
			{chart}
		</Container>
			</ContentWrapper>
		);
	}
}


export default Timeline;