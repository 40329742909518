import { Box, Skeleton } from '@mui/material';
import ListPager from './ListPager';
import { useState } from 'react';

/** Default key generator function: just use array index. */
function dfltKeyFn(item, index) {
	return index;
}

/**
 * @param {object} props
 * @param {object[]} props.items
 * @param {import('react').FunctionComponent} props.ItemComponent
 * @param {string} [props.listName]
 * @param {boolean} [props.loading]
 * @param {number} [props.pageSize]
 * @param {object} [props.listProps] Passed to each list item component
 * @param {(object, number) => any} [props.keyFn] Maps the item & its index to array key.
 */
function PagedList({ items, ItemComponent, listName, loading, listProps, keyFn = dfltKeyFn, pageSize = 5 }) {
	const [indices, setIndices] = useState();

	return <Box>
		{items && (
			<ListPager
				itemsPerPage={pageSize}
				pageParam={listName && `${listName}Page`}
				totalItems={items.length}
				setIndices={setIndices}
				divider
				sx={{ marginY: 2 }}
			/>
		)}

		{loading && <Skeleton variant="rectangular" width={210} height={118} />}
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
			{items && indices && items.slice(indices.from, indices.to).map((item, index) => (
				<ItemComponent key={keyFn(item)} item={item} {...listProps} />
			))}
		</Box>
	</Box>;

}

export default PagedList;