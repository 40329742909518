import { type SetStateAction, useCallback, useEffect } from 'react';
import useSearchParamsObject, { SearchParamsObject } from './useSearchParamsObject';



/** Is this value acceptable as a search-param key or value? */
const isValidParam = name => (name != null && String(name).length > 0);


/** Function form of {@link SetParamAction<T>}. */
type SetParamFunction<T> = (prev: string) => T;


/**
 * Similar to the type {@link SetStateAction<T>} seen in useState,
 * but the function form always provides the previous value as a string - because it's just been read from the URL.
 */
type SetParamAction<T> = (T | SetParamFunction<T>);


/**
 * Behaves like useState, but stores its value (coerced to string) in a URL search parameter.
 * Throws an error if no parameter name is given.
 * @param {String} name The URL parameter to store the value under - will just act like useState if undefined.
 * @param {Function|any} [init] Will be set if [paramName] isn't in the URL when this hook is first called.
 */
function useSearchParam<T>(name: string, init?: SetParamAction<T>): [string, (next: SetParamAction<T>) => void] {
	const [paramsObject, setParamsObject] = useSearchParamsObject();

	// Param name must be a usable value
	useEffect(() => {
		if (!isValidParam(name)) throw Error('useSearchParam.ts: parameter name must always be provided');
	}, [name]);

	// Build a function which updates requested search parameter
	const setParam = useCallback((nextValue: SetParamAction<T>) => {
		return setParamsObject(prevParams => {
			const prevValue = prevParams[name];
			// Resolve set-by-callback
			if (typeof nextValue === 'function') nextValue = (nextValue as SetParamFunction<T>)(prevValue);
			// Same value = no-op
			if (String(nextValue) === prevValue) return prevParams;
			// Build new params object; remove value if nullish/empty
			const nextParams = {...prevParams, [name]: nextValue} as SearchParamsObject;
			if (!isValidParam(nextValue)) delete nextParams[name];
			return nextParams;
		});
	}, [name, setParamsObject]);

	// Put initialValue in searchParams if provided & param is absent
	useEffect(() => {
		if (!init || name in paramsObject) return;
		setParam(init);
	});

	return [paramsObject[name], setParam];
};


export default useSearchParam;
export { isValidParam as isValidParam };
