import ListCard from '../lists/ListCard';


/**
 * Renders information on a Good Formats creative as a list item.
 * @param {Object} props
 * @param {Function} props.onClick Called when the item is clicked
 * @param {Function} props.onExport Called when the "Export" button is clicked
 * @param {GMTag} props.item The tag item itself
 * @returns {JSX.Element}
 */
function BrandListItem({item: brand}) {
	return <ListCard item={brand} imgUrl={brand.logoUrl} href={`/brands/${brand.id}`}/>;
}


export default BrandListItem;
