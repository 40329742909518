import { Box, Typography, Divider, Grid, Radio, FormControlLabel, RadioGroup, FormControl } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import Distribution from './Distribution';
import Consumption from './Consumption';
import { CO2e, CO2ePM, ContentWrapper } from './MetricComponents';
import { BarPlot, ChartsGrid, ChartsTooltip, ChartsXAxis, ChartsYAxis, PieChart, ResponsiveChartContainer } from '@mui/x-charts';
import { dataToCSV, metricWeightString, metricGramsWeightString } from '../../utilities/utils';
import { MetricsTabs } from './Metrics';
import { Header } from '../../components/common/LayoutComponents';
import { sum, zip } from 'lodash';


/***
 * Return metadata about tab & content for the Breakdown page
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Breakdown() {
	return {
		title: 'Emission breakdown',
		name: 'breakdown',
		icon: null,
		href: '/metrics/breakdown',
		content: <BreakdownContent />,
	};
};

const breakdownTabToHref = tab => `/metrics/breakdown/${tab}`;

/**
 * Tab options for Distribution Breakdown & Consumption Breakdown
 * @returns {React.Element} Breakdown page content
 */
function BreakdownContent() {
	const subpageData = [Distribution(), Consumption()];

	return <>
		<Divider />
		<MetricsTabs
			pageData={subpageData}
			tabParam="subTab"
			tabToHref={breakdownTabToHref}
		/>
	</>;
}

interface LegendValue {
  title: string;
  value: number;
}
interface PieChartLegendItemProps {
  title: string;
  percent: number;
  values: Record<string, LegendValue>;
  color: string;
}

const subItemsBoxSx = { '& > :not(:last-of-type)': { mr: 1 } };

function PieChartLegendItem({title, percent, values, color}: PieChartLegendItemProps) {
	return (
		<Grid container spacing={0} m={4}>
			<Grid item xs={1}>
				<Box bgcolor={color} border="2px solid" borderRadius="50%" width="50%" style={{aspectRatio: '1/1'}}/>
			</Grid>
			<Grid item xs={11}>
				<Box>
					<Header rank={5}>{title}</Header>
					<Header rank={5} sx={{mt: 1, mb: 2}}>{Math.round(percent)}%</Header>
					<Box sx={subItemsBoxSx}>
						{Object.entries(values).map(([key, {title, value}]) => (
							<strong key={key}>{title}: {metricWeightString(value)}</strong>
						))}
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}



function PieChartLegend({data}) {
	// Transform field "values" in each bucket from object of form { $key: $value, ... }
	// to array of form [{title: $key, value: $value}, ...]
	const legendItems = useMemo(() => {
		const items = data.map(bucket => {
			const values = Object.entries(bucket.values).map(
				([title, value]) => ({ title, value })
			);
			return { ...bucket, values };
		});
		return items.reverse();
	}, [data]);

	return legendItems.map(bucket => <PieChartLegendItem key={bucket.title} {...bucket} />);
}


export function BreakdownPieChart({chartData, title, grams = false}) {
	// note we don't have these buttons anymore (PM vs Total). Keeping the login in as removing them didn't seem to be a permanent decision
	// const [chartSetting, setChartSetting] = React.useState('total');

	// Add fields "value" (sum of bucket.values) and "fraction" (value/total) to each chartData bucket
	const data = useMemo(() => {
		let total = 0; // Keep running total
		// Add up "value"
		const _augChartData = chartData.map((bucket, id) => {
			const value = sum(Object.values(bucket.values));
			total += value;
			return { id, value, label: bucket.title, ...bucket};
		});
		// Add "fraction" now total is calculated
		_augChartData.forEach(bucket => {
			bucket.percent = (bucket.value / total) * 100;
		});
		return _augChartData;
	}, [chartData]);


	const downloadDataFn = useCallback(() => {
		const labels = data.map(data => [data.label]);
		const values = data.map(data => [data.value]);
		return dataToCSV(labels, values);
	}, [data]);

	const valueFormatter = useMemo(() => {
		const weightFn = grams ? metricGramsWeightString : metricWeightString;
		return e => weightFn(e.value);
	}, [grams]);

	const chartProps = {
		series: [{ data, innerRadius: '75%', valueFormatter }],
		slotProps: { legend: { hidden: true } },
		height: 500,
		sx: { maxHeight: '80%' }
	};

	return (
		<ContentWrapper sx={{height: 'inherit'}} title={title} downloadDataFn={downloadDataFn}>
			<Box>
				{/*note we don't have these buttons anymore (PM vs Total). Keeping the login in as removing them didn't seem to be a permanent decision
				<FormControl>
					<RadioGroup value={chartSetting} onChange={(e) => setChartSetting(e.target.value)} row>
							<FormControlLabel control={<Radio disabled={!Object.keys(chartData).includes('total')} />} value="total" label={<>Total <CO2e /></>} />
							<FormControlLabel control={<Radio disabled={!Object.keys(chartData).includes('pm')} />} value="pm" label={<CO2ePM />} />
					</RadioGroup>
				</FormControl>
				<Divider />
				*/}
				<Grid container spacing={1}>
					<Grid item xs={7} /* name="Breakdown Doughnut" */>
						<PieChart {...chartProps}/>
					</Grid>
					<Grid item xs={5} sx={{alignContent: 'center'}}>
						<PieChartLegend data={data} />
					</Grid>
				</Grid>
			</Box>
		</ContentWrapper>
	);
}


/**
 * @param {object} props
 * @param {any} props.chartData
 * @param {string} props.title
 * @param {boolean} [props.hideRadio]
 * @param {any} [props.sx]
 */
export function BreakdownBarChart({chartData, title, hideRadio = false, sx={}}) {
	const [chartSetting, setChartSetting] = useState('total');

	const loaded = chartData && Object.keys(chartData).length > 0;

	const showChartOptions = loaded && Object.keys(chartData).length > 2 && !hideRadio;

	const chartHeight = showChartOptions ? '90%' : '99%';

	const seriesSet = chartSetting === 'pm' ? chartData.pmSeries : chartData.totalSeries;


	const downloadDataFn = useMemo(() => {
		if (!chartData) return null;
		const focusData = (chartSetting === 'pm') ? chartData.pmSeries : chartData.totalSeries;
		if (!focusData) return null;
		const csvData = (focusData.length === 1) ? focusData[0].data : zip(focusData[0].data, focusData[1].data);
		return () => dataToCSV(chartData.xAxis.data, csvData);
	}, [chartData, chartSetting]);

	return (
		<ContentWrapper title={title} downloadDataFn={downloadDataFn} sx={{...sx, display: 'flex', flexDirection: 'column', mb:0}}>
			<Box sx={{ height: '100%'}}>
				{showChartOptions && <>
					<FormControl>
						<RadioGroup value={chartSetting} onChange={(e) => setChartSetting(e.target.value)} row>
							<FormControlLabel control={<Radio disabled={!Object.keys(chartData).includes('totalSeries')} />} value="total" label={<>Total <CO2e /></>} />
							<FormControlLabel control={<Radio disabled={!Object.keys(chartData).includes('pmSeries')} />} value="pm" label={<CO2ePM />} />
						</RadioGroup>
					</FormControl>
				</>
				}
				<Divider />
				{!loaded && <Box sx={{height: '500px', width: '100%'}} />}
				{loaded && <Grid container sx={{height: '100%'}}>
					<Grid item xs={0.25}>
						<Typography variant="body1" height="100%" alignContent="center" whiteSpace="nowrap">
							<span style={{display: 'block', transform: 'rotate(-90deg) translate(-2rem, 0rem)'}}>
								{chartSetting === 'total' ? <>Tonnes <CO2e /></> : <>kg <CO2ePM /></>}
							</span>
						</Typography>
					</Grid>
					<Grid sx={{height: chartHeight}} item xs={11.75}>
						<ResponsiveChartContainer
							xAxis={[
								{
									data: chartData.xAxis.data,
									scaleType: 'band',
									label: chartData.xAxis.label
								}
							]}
							yAxis={[
								{
									scaleType: 'linear',
									valueFormatter: val => `${chartSetting === 'total' ? val / 1000 : val}`,
								}
							]}
							series = {seriesSet.map(series => ({ 
								...series, 
								stackOrder: 'reverse',
								barGapRatio: 0.6
							}))}
						>
							<ChartsTooltip />
							<ChartsGrid horizontal />
							<BarPlot/>
							<ChartsXAxis disableLine label="" tickPlacement="middle" tickSize={18} tickLabelInterval={() => true} tickLabelStyle={{
								fontFamily: 'ABCSocial',
								fontSize: 'clamp(0.5rem, 0.75vw, 2rem)',
								transform: 'rotate(-15deg) translate(-10px, 0px)',
							}}/>
							<ChartsYAxis beginAtZero disableLine disableTicks />
						</ResponsiveChartContainer>
					</Grid>
				</Grid>
				}
			</Box>
		</ContentWrapper>
	);
}


export default Breakdown;
