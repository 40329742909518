import { Stack, Switch } from '@mui/material';


import InputWrapper from './InputWrapper';
import { HeightMatchWrapper } from './ButtonGroupField';


function SwitchFieldInner({labelOff = 'Off', labelOn = 'On', value = false, ...props}) {
	return <HeightMatchWrapper size={props.size}>
		<Stack direction="row" alignItems="center" spacing={1}>{labelOff}<Switch checked={value} {...props}/>{labelOn}</Stack>
	</HeightMatchWrapper>;
}


function isChecked(event) {
	return event.target.checked;
}


function SwitchField(props) {
	return <InputWrapper getValueFn={isChecked} Input={SwitchFieldInner} {...props} />;
}


export default SwitchField;
