import html2canvas from 'html2canvas';
import { useMemo } from 'react';


/** Boilerplate for "make this blob the target of a link tag with download hint, and click it" */
const downloadBlob = (blob, filename) => {
	const link = document.createElement('a');
	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};


/**
 * Take a screenshot of an element using html2canvas and download it.
 * No-op (but will log stack trace) if element is nullish.
 */
const screenshotElement = (element) => {
	if (!element) {
		console.error('screenshotElement called with no element', Error().stack);
	}
	const filename = element.id || 'screenshot';
	const blobCallback = blob => downloadBlob(blob, filename);
	html2canvas(element).then(canvas => {
		canvas.toBlob(blobCallback, 'image/png');
	});
};


/** Resolve a selector to an element and download a screenshot of it using screenshotElement() */
const screenshotBySelector = (selector) => screenshotElement(document.querySelector(selector));

/**
 * Zip a pair paired data series together into a CSV, enclose in a Blob, and trigger download.
 * TODO Header row argument, ie dataToCSV(headers, xAxis, yAxis) - tolerate string or array for headers
 * TODO Generalise to dataToCSV(headers, firstColumn, ...columns) and do an inner map+join to construct rows
 * TODO Quote values containing delimiter, quote-and-escape values containing quotation marks
 * or TODO Pull in a CSV library because we don't work like that any more
 */
const dataToCSV = (xAxis, yAxis) => {
	const zipped = xAxis.map((val, i) => `${val},${yAxis[i]}`).join('\n');
	downloadBlob(new Blob([zipped], {type: 'text/csv'}), 'GoodMeasuresData');
};

/** Conversion table for metricGramsWeightString */
const WEIGHT_MAGNITUDES_GRAMMES = [
	[0.001, ' g'],
	[1, ' kg'],
	[1000, ' tonnes'],
];

/** Conversion table for metricWeightString */
const WEIGHT_MAGNITUDES = WEIGHT_MAGNITUDES_GRAMMES.slice(1);

/** Conversion table for countString */
const NUMBER_MAGNITUDES = [
	[1, ''],
	[1000, ' K'],
	[1000000, ' M']
];

/** Generic function for applying units to numbers, e.g. 1500 g -> 1.50 kg, 123400 -> 123.40 K */
const formatUnits = (value = 0, magnitudes, round = false) => {
	const valueAbs = Math.abs(value);

	// Find the largest named divisor smaller than the value - or use the first one.
	let [divisor, unit] = magnitudes[0];
	for (let [d, u] of magnitudes) {
		if (d > valueAbs) break;
		[divisor, unit] = [d, u];
	}
	// Space baked into suffixes in MAGNITUDES (so 0 yields "0" and not "0 ")
	return `${(value / divisor).toFixed(round ? 0 : 2)}${unit}`;
};


/** Print a weight given in kg with automatic units. 0-999 given in kg, 1000+ given in tonnes. */
const metricWeightString = value => formatUnits(value, WEIGHT_MAGNITUDES);


/** Print a weight given in kg with automatic units. <1 given in g, 1-999 given in kg, 1000+ given in tonnes. */
const metricGramsWeightString = value => formatUnits(value, WEIGHT_MAGNITUDES_GRAMMES);


/** Format numbers to 2 decimal places, padding if necessary, and convert 1500 -> 1.50 K, 1500000 -> 1.50 M */
const countString = value => formatUnits(value, NUMBER_MAGNITUDES, true);


/** For combining className values in components. Strips falsy values, flattens nested arrays, joins to a string. */
const classes = (...args) => args.flat().filter(Boolean).join(' ');


/** Always returns true. */
const yes = () => true;

/** Always returns false. */
const no = () => false;


/**
 * Inserts a static element between all elements in an array.
 * @param {*[]} arr The source array
 * @param {*} value The item to insert between elements
 * @returns {*[]}
 */
const interleave = (arr, value) => {
	if (!arr || !arr.flatMap || !arr.length) return arr;
	const end = arr.length - 1;
	return arr.flatMap((item, index) => index === end ? item : [item, value]);
};


/** Combines a set of "sx" props, each of which may or may not be an array (allowed by MUI) into one array. */
const useMergeSx = (...sxs) => useMemo(() => {
	return sxs.reduce((acc, sx) => {
		Array.isArray(sx) ? acc.push(...sx) : acc.push(sx);
		return acc;
	}, []);
}, sxs);


/**
 * Supply the appropriate indefinite article "a/an" for a noun.
 * @param noun
 * @param capital True if "A/An" is needed.
*/
const article = (noun: string, capital?: boolean) => {
	if (!noun) return '';
	return (noun.match(/aeiou/i)) ? (
		capital ? 'An' : 'an'
	) : (
		capital ? 'A' : 'a'
	);
};


/* Emotion (styling library behind MUI) is EXTREMELY insistent that nobody should ever use a first-, last-, or nth-child
 * selector, because in extremely specific circumstances which Emotion has brought about all on their own, it might cause
 * unexpected behaviour when performing server-side rendering.
 * People have been screaming at them about this since 2018. It took several years to reach the point where the warning
 * could be suppressed as "easily" as this:
 * https://github.com/emotion-js/emotion/issues/1105
 * I am ready to ditch MUI over this nonsense. -R */

/**
 * Append absolutely ridiculous warning-suppressing comment to a :first-, :last-, or :nth-child selector.
 * @param {string} selector The CSS selector in question
 */
const disableNthChildWarning = selector => (
	`${selector} /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */`
);


export {
	countString,
	dataToCSV,
	downloadBlob,
	metricWeightString,
	metricGramsWeightString,
	screenshotElement,
	screenshotBySelector,
	classes,
	yes, no,
	interleave,
	useMergeSx,
	article,
	disableNthChildWarning
};

