import Headlines from '../Metrics/Headlines';
import { BreakdownBarChart} from '../Metrics/Breakdown';
import { Box, Paper } from '@mui/material';
import TwoInputDoughnutChart from '../Metrics/TwoInputDoughnutChart';

/**
 * 
 * @param {*} props
 * @param {number} props.distributionEmissions 
 * @param {number} props.totalEmissions
 * @param {number} props.distributionCO2EPM
 */
function ConsumptionSinglePageView(props){
	const consColourLight = props.consColourLight;
	let breakdownData = props.consumptionBreakdownData?.data || [];
	let embodiedEmissions = breakdownData.consumptionEmbodiedEmissions;
	let usePhaseEmissions = breakdownData.consumptionUsePhaseEmissions;
	return (
		<Paper sx={{height: '100%', p: 1, mx: 1, boxShadow: 0, border: `5px solid ${consColourLight}`, borderRadius:2}}>
			<Box sx={{my:2, mx:2, p:1, backgroundColor: '#fafaf5', textAlign: 'center', fontSize: '1.5rem', fontWeight:'700', borderRadius:2, alignContent: 'center'}}>Consumption</Box>
			<Box sx={{display: 'flex', p:0, mb:1}}>
				<Headlines part={props.consumptionEmissions} total={props.totalEmissions} pm={props.consumptionCO2EPM} sx={{m:0, py:0, height:'auto'}}/>
			</Box>
			<Box sx={{height: 'auto', display: 'flex', flexDirection: 'row'}}> 
				<Box sx={{height: 'auto', width: '50%', p: 0.5, display:'flex', flexDirection: 'column'}}>
					<TwoInputDoughnutChart title='Consumption breakdown by GMSF' firstInputTotal={embodiedEmissions} secondInputTotal={usePhaseEmissions} firstInputColour={props.consColour} secondInputColour={props.consColourDark} firstInputText='Device Embodied Emissions' secondInputText='Device Use Phase Emissions' />
				</Box>
				<Box sx={{height: 'auto', width: '50%', p: 0.5, display:'flex', flexDirection: 'column'}}>
					<BreakdownBarChart chartData={props.barChartData} title="Consumption breakdown by device" hideRadio={true}/>
				</Box>
			</Box>
		</Paper>
	);
}

export default ConsumptionSinglePageView;