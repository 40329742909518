import { useCallback, type ReactNode } from 'react';
import { Box, Breadcrumbs, breadcrumbsClasses, CircularProgress, Link, Typography } from '@mui/material';

import { useCampaign, useCreative, useGroup, useLineItem } from '../../state/apiHooks';
import { Group } from '../../model/types/Group';
import { AdCampaign } from '../../model/types/AdCampaign';
import { Creative } from '../../model/types/Creative';
import { LineItem } from '../../model/types/LineItem';
import { createPortal } from 'react-dom';
import { useWaitForElement } from '../../state/hooks';


const breadcrumbsSx = {
	display: 'inline-block',
	mt: 2,
	width: '100%',

	[`.${breadcrumbsClasses.li}`]: {
		position: 'relative',
		'.type-label': {
			position: 'absolute',
			left: 0,
			bottom: '100%',
			fontSize: '75%',
		}
	}
};


interface BreadcrumbProps {
	/** The first section of the crumb link path. */
	page: 'brands' | 'campaigns' | 'creatives' | 'lineitems';
	/** Display text clarifying what this part of the breadcrumb chain is, e.g. "Brand", "Campaign". */
	label: string;
	/** The entity this crumb points to. */
	entity: Group | AdCampaign | Creative | LineItem;
	/**  */
	last?: boolean;
}


function Breadcrumb({page, label, entity, last}: BreadcrumbProps) {
	const content = <>
		{label && <div className="type-label">{label}</div>}
		{entity.name}
	</>;

	return last ?  <Box>{content}</Box> : (
		<Link href={`/${page}/${entity.id}`}>{content}</Link>
	);
}


interface GoodFormatsBreadcrumbsProps {
	/** Brand (group) ID. */
	brandId?: string;
	/** Ad campaign ID. */
	campaignId?: string;
	/** Creative ID. */
	creativeId?: string;
	/** Line item ID. */
	lineItemId?: string;
	/** Marker to insert after the last breadcrumb - e.g. "Creatives" to signify "listing creatives for this campaign" */
	lastLabel?: ReactNode;
}


/**
 * Good-Formats specific hierarchical "breadcrumb" element for showing the user's navigation position.
 *
 * NB This uses createPortal to render into an element with ID "breadcrumb-container" (which should be in
 * UserBar) - if there's no such element in the DOM, nothing will be rendered.
 */
function GoodFormatsBreadcrumbs({brandId, campaignId, creativeId, lineItemId, lastLabel}: GoodFormatsBreadcrumbsProps) {
	const { data: lineItem } = useLineItem(lineItemId) as { data: LineItem };
	if (lineItem) creativeId = lineItem.creativeId;

	const { data: creative } = useCreative(creativeId) as { data: Creative };
	if (creative) campaignId = creative.campaignId;

	const { data: campaign } = useCampaign(campaignId) as { data: AdCampaign };
	if (campaign) brandId = campaign.brandId;

	const { data: brand } = useGroup(brandId) as { data: Group };

	// Find or wait for the render target & set up a shorthand function for rendering to it.
	const target = useWaitForElement('#breadcrumb-container');
	const render = useCallback((elements) => target ? createPortal(elements, target) : null, [target]);

	if ((lineItemId && !lineItem) || (creativeId && !creative) || (campaignId && !campaign) || (brandId && !brand)) {
		return render(<CircularProgress />);
	}
	if ((lineItem && !creative) || (creative && !campaign) || (campaign && !brand)) {
		return render(<CircularProgress />);
	}

	const crumbs = [];
	if (brand) crumbs.push(<Breadcrumb page="brands" key="brand" label="Brand" entity={brand} last={!lastLabel && !campaign} />);
	if (campaign) crumbs.push(<Breadcrumb page="campaigns" key="campaign" label="Campaign" entity={campaign} last={!lastLabel && !creative} />);
	if (creative) crumbs.push(<Breadcrumb page="creatives" key="creative" label="Creative" entity={creative} last={!lastLabel && !lineItem} />);
	if (lineItem) crumbs.unshift(<Breadcrumb page="lineitems" key="lineitem" label="Line Item" entity={lineItem} />);

	return render(
		<Breadcrumbs sx={breadcrumbsSx}>
			<Link key="root" href="/">Good Formats</Link>
			{crumbs}
			{lastLabel && <span>{lastLabel}</span>}
		</Breadcrumbs>
	);
}


export default GoodFormatsBreadcrumbs;
