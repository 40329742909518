import { useLocation } from 'react-router';
import useSearchParam from '../state/useSearchParam';
import { useCampaign, useGroup, useGroups, useTag, useUser } from '../state/apiHooks';
import { Header, PageContainer, PageSection } from './common/LayoutComponents';
import { uniq } from 'lodash';
import { useUrlWithWorkspace } from './WorkspaceProvider';
import { useMemo } from 'react';
import { Avatar, Box, Chip, Divider, Typography } from '@mui/material';
import {
	WorkOutline as WorkspacesIcon
} from '@mui/icons-material';


function EnterWorkspaceButton({workspace, ...props}) {
	const avatar = workspace.logoUrl && <Avatar src={workspace.logoUrl} />;
	const icon = !workspace.logoUrl && <WorkspacesIcon color="secondary" />;
	const label = workspace.name || workspace.id;

	const href = useUrlWithWorkspace(window.location.href, workspace.id);
	const title = `Go to the current URL in workspace ${label}.`;

	return (
		<a href={href} title={title}>
			<Chip avatar={avatar} icon={icon} color="primary" sx={{fontWeight: 500}} label={label} />
		</a>
	);
}


/**
 * Tries to guess the intended workspace by checking filter parameters and URL path components
 * for group, campaign, tag and user IDs, fetching the entities, and checking their workspaces.
 */
function useProbableWorkspaces() {
	// Filter params used by tag management and metrics screens
	const [groupId] = useSearchParam('group');
	const { data: group } = useGroup(groupId);
	const [campaignId] = useSearchParam('campaign');
	const { data: campaign } = useCampaign(campaignId);
	const [tagId] = useSearchParam('tag');
	const { data: tag } = useTag(tagId);

	// Group and user ID URL components used on management screens
	const {pathname} = useLocation();
	const manageGroupId = pathname.match(/\/manage\/groups\/(\w+)/)?.[1];
	const { data: manageGroup } = useGroup(manageGroupId);
	const manageUserId = pathname.match(/\/manage\/users\/(\w+)/)?.[1];
	const { data: manageUser } = useUser(manageUserId);

	// All known workspaces
	const {data: allWorkspaces} = useGroups({parent: null});

	const probableIds = useMemo(() => uniq(
		[group, campaign, tag, manageGroup, manageUser]
			.map(entity => entity?.workspaceId)
			.filter(a => !!a)
	), [group, campaign, tag, manageGroup, manageUser]);

	// Split the set of workspaces into "likely candidates" and "others"
	return useMemo(() => {
		const probableWorkspaces = [], otherWorkspaces = [];
		allWorkspaces?.forEach(workspace => {
			const isProbable = probableIds.find(id => id === workspace.id);
			(isProbable ? probableWorkspaces : otherWorkspaces).push(workspace);
		});
		return [probableWorkspaces, otherWorkspaces];
	}, [probableIds, allWorkspaces]);
}


/**
 * Presents links to the same page in various workspaces,
 * starting with likely candidates based on entity IDs in the URL and filters.
 */
function WorkspaceOptions() {
	// Does the URL give any clues as to the intended workspace?
	const [probableWorkspaces, otherWorkspaces] = useProbableWorkspaces();

	const hasGuesses = probableWorkspaces.length > 0;
	const hasOthers = otherWorkspaces.length > 0;

	return <Box mt={2}>
		{hasGuesses && <>
			<Header rank={5}>Probable workspace{probableWorkspaces.length > 1 && 's'}</Header>
			<Typography component="p">Based on group, campaign, tag and/or user IDs found in the URL and filters.</Typography>
			<Box display="flex" gap={1} mt={2}>
				{probableWorkspaces.map(ws => <EnterWorkspaceButton workspace={ws} />)}
			</Box>
			<Divider sx={{my: 2}} />
		</>}
		{hasOthers && <>
			<Header rank={5} sx={{mb: 2}}>{hasGuesses ? 'Other workspaces' : 'Workspaces'}</Header>
			<Box display="flex" gap={1} mt={2}>
				{otherWorkspaces.map(ws => <EnterWorkspaceButton workspace={ws} />)}
			</Box>
		</>}
	</Box>;
}


/**
 * A global user has hit a page that isn't part of the admin interface.
 * Tell them what's happened, and show them links to fix it.
 */
function EnterWorkspace() {
	return (
		<PageContainer>
			<Header rank={3}>No Workspace</Header>
			<PageSection>
				<Typography component="p">
					You've come to a page that isn't part of the admin interface.<br/>
					If this was a link sent to you by a client, you'll need to enter their workspace to continue.
				</Typography>
				<Typography component="p">
					The buttons below will take you to the same location in the client's workspace.
				</Typography>
				<WorkspaceOptions />
			</PageSection>
		</PageContainer>
	);
}

export default EnterWorkspace;
