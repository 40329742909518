import { useCallback, useMemo, useState } from 'react';

import TextInputField from '../inputFields/TextInputField';
import NumberInputField from '../inputFields/NumberField';
import ButtonGroupField from '../inputFields/ButtonGroupField';
import entity from '../../model/entity';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { SelectCampaign, SelectGroup } from '../inputFields/SelectFromAPI';
import DialogButton from '../widgets/DialogButton';
import { EditCampaignButton } from './CampaignDialog';
import { Button } from '@mui/material';
import { measureTag } from '../../state/api';
import PermissionWrapper from './PermissionWrapper';
import { useFormContext } from '../FormContextProvider';
import useLiveRef from '../../utilities/useLiveRef';
import { usePathSetter } from '../inputFields/InputWrapper';
import EntityDialog from './EntityDialog';


const validateTag = (authUser, tag) => {
	return tag.name;
};


// const creativePreviewTypeOptions = [
// 	{ label: 'Preview Link', value: 'preview-link' },
// 	{ label: 'VAST link', value: 'vast' },
// 	{ label: 'VAST XML', value: 'vast-xml' },
// 	{ label: 'HTML Tag', value: 'html' },
// 	{ label: 'Upload link', value: 'zip' },
// ];


const creativeFormatOptions = [
	{ label: 'Display', value: 'display' },
	{ label: 'Video', value: 'video' },
];


/** Button which sends ad URL for analysis & merges results onto the tag draft. */
function AnalyseButton() {
	const { draft, setDraft, saving } = useFormContext();
	// Hide changes, don't rebuild measure function on every edit
	const draftRef = useLiveRef(draft);
	// Disable button while analysis in progress
	const [analysing, setAnalysing] = useState(false);
	// Merge results into draft
	const updateDraft = useCallback((creativeFormat, creativeBytes, creativeMsec) => {
		setDraft(prev => ({ ...prev, creativeFormat, creativeBytes, creativeMsec}));
	}, [setDraft]);

	// Start analysis on click
	const doMeasureCreative = useCallback(() => {
		const url = draftRef.current.creativePreview;
		setAnalysing(true);
		measureTag(null, {url}).then(response => {
			const { creative_format, duration_seconds, size_bytes } = response.data;
			if (creative_format.startsWith('video')) {
				updateDraft('video', undefined, duration_seconds * 1000);
			} else if (creative_format.startsWith('display')) {
				updateDraft('display', size_bytes, undefined);
			}
		}).finally(setAnalysing(false));
	}, [draftRef, updateDraft]);

	return <Button variant="outlined" onClick={doMeasureCreative} disabled={saving || analysing}>Analyse</Button>;
}


/** Form section holding creative analysis function & results for inspection / manual edits */
function Analysis() {
	const { draft, setDraft } = useFormContext();
	const { creativeFormat, creativeMsec } = draft;

	// Duration input is in seconds but storage is in msec: transform value to/from
	// TODO Integrate transform functions in InputWrapper
	const seconds = (Math.round(creativeMsec / 100) / 10);
	const setMsec = usePathSetter(setDraft, 'creativeMsec');
	const receiveSeconds = useCallback(event => setMsec(Math.round(event.target.value * 1000)), [setMsec]);

	// Be very careful about removing the "key" prop from duration/size: the DOM output is similar enough
	// that React is likely to reuse the element and not reset the value, if the new field value is undefined.

	return <>
		<Grid xs={5}>
			<TextInputField label="Creative preview for analysis" fullWidth
				info="This can be a link to your DSP preview page, a VAST tag or link, a HTML insertion tag, or a link to an uploaded ZIP file."
				path="creativePreview"
				append={<AnalyseButton draft={draft} updateDraft={setDraft} />} group
			/>
		</Grid>
		<Grid xs={3} sx={{textAlign: 'center'}}>
			<ButtonGroupField label="Creative format" matchHeight
				path="creativeFormat"
				options={creativeFormatOptions}
			/>
		</Grid>
		{/* <Grid xs={4}>
			<SelectField label="Creative preview type" fullWidth
				path="creativePreviewType"
				options={creativePreviewTypeOptions}
			/>
		</Grid> */}
		<Grid xs={4}>
			{creativeFormat === 'video' ? (
				<NumberInputField label="Video duration" fullWidth key="size"
					info="Length of the video creative." unit="seconds"
					value={seconds} onChange={receiveSeconds}
				/>
			) : (
				<NumberInputField label="Creative size" fullWidth key="size"
					info="Transfer size of the display creative." unit="bytes"
					path="creativeBytes"
				/>
			)}
		</Grid>
	</>;
}


const newCampaignButtonProps = { sx: {flexShrink: 0} };


function SelectOrNewCampaign() {
	const { draft, setDraft } = useFormContext();
	const { groupId } = draft;

	// When the user creates campaign using the "Create" dialog button, set this tag's campaignId.
	const setCampaignId = usePathSetter(setDraft, 'campaignId');
	const onCreateCampaign = useCallback(campaign => setCampaignId(campaign.id), [setCampaignId]);

	// Maintain consistency: Tags inherit their owning group from their campaign.
	const setGroupId = usePathSetter(setDraft, 'groupId');
	const onChangeCampaign = useCallback(campaign => setGroupId(campaign?.groupId), [setGroupId]);

	// Both query for the <select> and base for creating new campaigns
	const queryBase = useMemo(() => (groupId && {groupId}), [groupId]);

	const createCampaignButton = (
		<EditCampaignButton base={queryBase} buttonProps={newCampaignButtonProps} label="New" onCommit={onCreateCampaign} />
	);

	return (
		<SelectCampaign label="Campaign" required fullWidth path="campaignId"
			query={queryBase} append={createCampaignButton} group
			onChangeObject={onChangeCampaign} disabled={draft?.locked}
		/>
	);
}


/**
 * The inner form for a Tag editor.
 * @param {FormFieldsProps} props
 * @returns {ReactElement}
 */
function TagFormFields() {
	const { initial } = useFormContext();
	const locked = initial?.locked;

	return <Grid container spacing={2}>
		{locked && <Grid xs={12}>
			This tag has been finalised for export. Only its display name and reference can now be changed.
		</Grid>}
		<Grid xs={6}>
			<SelectOrNewCampaign />
		</Grid>
		<Grid xs={6}>
			<SelectGroup label="Group" required fullWidth path="groupId" disabled={locked} />
		</Grid>
		<Grid xs={6}>
			<TextInputField label="Tag name" required fullWidth path="name" />
		</Grid>
		<Grid xs={6}>
			<TextInputField label="Your reference" fullWidth path="reference"
				info="You can use this field to record a connection between this tag and an object in your own systems."
			/>
		</Grid>
		<Analysis />
	</Grid>;
}


/**
 *
 * @param {Object} props
 * @returns {ReactElement}
 */
function TagDialog(props) {
	return <EntityDialog type={entity.TAG} validateFn={validateTag} {...props}>
		<TagFormFields />
	</EntityDialog>;
}


function EditTagButton({label = 'Edit Tag', ...props}) {
	return (
		<PermissionWrapper type={entity.TAG} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={TagDialog} {...props} />
		</PermissionWrapper>
	);
}


export default TagDialog;
export { TagFormFields, EditTagButton, SelectOrNewCampaign };
