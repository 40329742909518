import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import { SelectGroup } from '../inputFields/SelectFromAPI';
import entity from '../../model/entity';

import DialogButton from '../widgets/DialogButton';
import PermissionWrapper from './PermissionWrapper';
import EntityDialog from './EntityDialog';
import { useFormContext } from '../FormContextProvider';
import ShowEntityField from '../common/ShowEntity';


const validateCampaign = (authUser, campaign) => {
	return campaign.name && campaign.groupId;
};


/**
 *
 * @param {Object} params
 * @returns {import('react').ReactElement}
 */
function CampaignFormFields() {
	const { initial } = useFormContext();
	return (
		<Grid container spacing={2}>
			<Grid xs={12} sm={6}>
				<TextInputField label="Campaign name" required fullWidth path="name" />
			</Grid>
			<Grid xs={12} sm={6}>
				<TextInputField label="Your reference" fullWidth path="reference"
					info="You can use this field to record a connection between this campaign and an object in your own systems."
				/>
			</Grid>
			<Grid xs={12} sm={6} spacing={2}>
				{initial.groupId ? (
					<ShowEntityField type="group" label="Group" required fullWidth id={initial.groupId} />
				) : (
					<SelectGroup label="Group" required fullWidth path="groupId" />
				)}
			</Grid>
		</Grid>
	);
};


function CampaignDialog(props) {
	return <EntityDialog type={entity.CAMPAIGN} validateFn={validateCampaign} {...props}>
		<CampaignFormFields />
	</EntityDialog>;
}

function EditCampaignButton({label = 'Edit Campaign', ...props}) {
	return (
		<PermissionWrapper type={entity.CAMPAIGN} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={CampaignDialog} {...props} />
		</PermissionWrapper>
	);
}


export default CampaignDialog;
export { CampaignFormFields, EditCampaignButton };
