const unlockCSS = `.video-container {
  border-bottom: none;
  border-left: none;
  border-top-right-radius: 2.5vw;
}

.charity.logo {
  aspect-ratio: 1/1;
  img {
    border-radius: 50%;
    overflow: hidden;
    border: 0.2vw solid currentColor;
  }
}`;

export default unlockCSS;
