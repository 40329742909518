import { useCallback, useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { SelectCampaign, SelectGroup } from '../inputFields/SelectFromAPI';
import useSearchParam from '../../state/useSearchParam';
import { Header, HeaderActions, PageContainer, PageSection } from '../common/LayoutComponents';
import { useCampaign, useGroup, useTags } from '../../state/apiHooks';
import PagedList from '../lists/PagedList';
import TagListItem from '../tags/TagListItem';
import { EditCampaignButton } from '../edit/CampaignDialog';
import { EditTagButton } from '../edit/TagDialog';
import { Button } from '@mui/material';
import { CSVUploadButton } from '../CSVUploadDialog';


function TagsLayout() {
	const [groupId, setgroupId] = useSearchParam('group');
	const { data: group } = useGroup(groupId);
	const [campaignId, setCampaignId] = useSearchParam('campaign');
	const { data: campaign } = useCampaign(campaignId);

	// Filter tags based on selected campaign/group
	const tagQuery = useMemo(() => {
		if (campaignId) return { campaign: campaignId };
		if (groupId) return { group: groupId };
		return undefined;
	}, [groupId, campaignId]);

	const { data: tags, isLoading } = useTags(tagQuery);

	// Filter campaign dropdown based on selected group
	const campaignQuery = useMemo(() => {
		if (groupId) return { group: groupId };
		return undefined;
	}, [groupId]);

	// Apply selected group to new campaigns created
	const campaignBase = useMemo(() => {
		if (groupId) return { groupId };
		return undefined;
	}, [groupId]);

	// Apply campaign & owning group to new tags created
	const tagBase = useMemo(() => {
		if (campaign) {
			const { groupId, id: campaignId } = campaign;
			return { groupId, campaignId };
		}
		return undefined;
	}, [campaign]);

	// Descriptive header for tag list
	const filterDescription = useMemo(() => {
		if (campaignId) return `Tags for ${campaign?.name || 'this campaign'}`;
		if (groupId) return `Tags for ${group?.name || 'this group'}`;
		return 'All tags';
	}, [campaignId, campaign, groupId, group]);

	// Set new campaign as focus after creating
	const onCreateCampaign = useCallback(newCampaign => {
		setCampaignId(newCampaign.id);
	}, [setCampaignId]);

	const newCampaignButton = <EditCampaignButton label="New" base={campaignBase} commitThen={onCreateCampaign} />;

	return (
		<PageContainer>
			<Header rank={3}>
				Good Measures Tags
				<HeaderActions>
					<Button href="/tags/campaigns" size="large" color="primary" variant="outlined">Manage Campaigns</Button>
				</HeaderActions>
			</Header>
			<PageSection>
				<Header rank={5}>Filter tag list</Header>
				<Grid container direction="row" spacing={2}>
					<Grid>
						<SelectGroup label="Group" name="group" labelEmpty="All Groups"
							value={groupId} onChangeValue={setgroupId}
							size="small"
						/>
					</Grid>
					<Grid>
						<SelectCampaign label="Campaign" name="campaign" labelEmpty="All Campaigns"
							value={campaignId} onChangeValue={setCampaignId} query={campaignQuery}
							appendGroup={newCampaignButton}
							size="small"
						/>
					</Grid>
				</Grid>
			</PageSection>
			<PageSection>
				<Header rank={5}>
					{filterDescription}
					<HeaderActions>
						{tagBase ? (
							<Grid container direction="row" spacing={2}>
								<Grid>
									<EditTagButton label="Create Tag" base={tagBase} />
								</Grid>
								<Grid>
									<CSVUploadButton label="Upload File" />
								</Grid>
							</Grid>
						) : 'Select a Campaign to create a new tag.'}
					</HeaderActions>
				</Header>
				{tags && <PagedList listName="tags" ItemComponent={TagListItem} items={tags} loading={isLoading} />}
			</PageSection>
		</PageContainer>
	);
}


export default TagsLayout;
