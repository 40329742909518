import { useParams } from 'react-router-dom';

import Snapshot from './Snapshot';
import Breakdown from './Breakdown';
import Understanding from './Understanding';
import { LinkChip } from '../Admin';
import { Header, PageContainer } from '../../components/common/LayoutComponents';
import MetricsFilterProvider, { useMetricsFilterParams } from './providers/MetricsFilterProvider';
import { GHGDataProvider } from './providers/MetricsDataProvider';


function MetricsTabs({pageData, tabParam = 'tab', tabToHref}) {
	const filterParams = useMetricsFilterParams();

	// Get path component for this tab selector
	const { [tabParam]: tab = pageData[0].name } = useParams();
	// Which page's URL matches the current tab URL?
	const activeHref = tabToHref(tab);
	const activeTab = pageData.find(({href}) => href === activeHref)?.content || <h1>404 - No page for {activeHref}</h1>;

	return <>
		{pageData.map(({name, title, icon, href}, i) =>
			<LinkChip key={href} icon={icon} name={name} mode={tab} href={href + filterParams} label={title}/>
		)}
		{activeTab}
	</>;
}

const topTabToHref = tab => `/metrics/${tab}`;


function Metrics() {
	return (
		<MetricsFilterProvider>
			<GHGDataProvider>
				<PageContainer>
					<Header rank={3}>Good Measures</Header>
					<MetricsTabs
						pageData={[Snapshot(), Breakdown(), Understanding()]}
						tabParam="tab"
						tabToHref={topTabToHref}
					/>
				</PageContainer>
			</GHGDataProvider>
		</MetricsFilterProvider>
	);
}

export default Metrics;
export { MetricsTabs };
