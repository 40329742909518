import { Box, Grid, IconButton, Link, useTheme } from '@mui/material';
import { PinkHeader, ReadMoreToggle, ReadMoreDetails, CO2e } from './MetricComponents';
import { BreakdownBarChart, BreakdownPieChart } from './Breakdown';
import { screenshotBySelector } from '../../utilities/utils';
import FilterControls, { FilterDesc } from './FilterControls';
import { Header, PageSection } from '../../components/common/LayoutComponents';
import { useGHGData } from './providers/MetricsDataProvider';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { DistributionIcon } from '../../components/common/Icons';
import Headlines from './Headlines';
import { GMSF_SOURCE, GMSF_URL } from './Glossary';


/***
 * Return metadata about tab & content for the Distribution breakdown tab
 * @returns {Object} {title: string, icon: React.Element, content: React.Element}
 */
function Distribution() {
	return {
		title: 'Distribution',
		name: 'distribution',
		href: '/metrics/breakdown/distribution',
		icon: <DistributionIcon />,
		content: <DistributionContent />,
	};
};


/**
 * @returns {React.Element} Distribution breakdown tab content
 */
function DistributionContent() {
	const {total, distributionBreakdown, distributionByCreativeFormat} = useGHGData();
	const theme = useTheme();
	const { main: distColour, dark: distColourDark } = theme.palette.chartDistribution;

	const breakdownData = distributionBreakdown?.data || [];
	let pieChartData = [];
	if (breakdownData) {
		pieChartData = [
			{
				title: 'Ad space selection',
				color: distColour,
				values: {
					'Real time bidding': breakdownData.rtbNetworkEmbodiedEmissions + breakdownData.rtbNetworkUsePhaseEmissions + breakdownData.rtbServerEmbodiedEmissions + breakdownData.rtbServerUsePhaseEmissions,
				}
			},
			{
				title: 'Ad creative delivery',
				color: distColourDark,
				values: {
					Servers: breakdownData.serversProcessingEmbodiedEmissions + breakdownData.serversProcessingUsePhaseEmissions,
					Networks: breakdownData.networkTransmissionsEmbodiedEmissions + breakdownData.networkTransmissionsUsePhaseEmissions,
				}
			}
		];
	}

	const formatData = distributionByCreativeFormat?.data || [];
	let barChartData = {};
	if (formatData && formatData.length > 0) {
		barChartData = {
			xAxis: {
				data: formatData.map(({format}) => format.length > 1 ? format[0] + format.slice(1).toLowerCase() : format),
				label: 'Creative Format'
			},
			totalSeries: [
				{
					data: formatData.map(({distributionEmissions}) => distributionEmissions),
					label: <>Total <CO2e /></>,
					color: distColour,
					valueFormatter: (e) => `${Math.round(e * 1000) / 1000} kg`,
					type: 'bar',
				}
			]
		};
	};


	return (
		<>
			<DistributionHeader />
			<FilterControls />
			<PageSection id="distribution-screenshot">
				<Grid container spacing={1}>
					<Grid item xs={12} position="relative">
						<IconButton onClick={() => screenshotBySelector('#distribution-screenshot')} sx={{position: 'absolute', right: 0, top: 0}} name="Screenshot Distribution">
							<CameraAltOutlinedIcon htmlColor="#000"/>
						</IconButton>
						<Header rank={4} sx={{mb: 4}}>Your distribution GHG</Header>
						<FilterDesc />
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<DistHeadline data={total} />
							</Grid>
							<Grid item xs={12}>
								<Header rank={5} sx={{mb: 2}}>Your distribution emissions</Header>
								<Box sx={{height: '90%'}}>
									<BreakdownPieChart chartData={pieChartData} title="Distribution breakdown" />
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Header rank={5} sx={{mb: 2}}>What variables impact your distribution emissions?</Header>
								<Box sx={{height: '90%'}}>
									<BreakdownBarChart chartData={barChartData} title="Total distribution breakdown by format" />
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</PageSection>
			<DistributionFooter />
		</>
	);
}


function DistributionHeader() {
	return (
		<PinkHeader logo={DistributionIcon} title="Distribution explained" >
			Distribution emissions are the greenhouse gas emissions produced from the energy used during ad space selection and ad creative delivery.
			<ReadMoreToggle />
			<ReadMoreDetails>
				<p><strong>Ad space selection</strong> refers to all activities related to media placement and inventory planning, including real-time bidding, direct sales and targeting.</p>
				<p><strong>Ad creative delivery</strong> refers to all activities related to delivering and installing the ad in the selected placement including media storage and media transmission.</p>
				Source: <Link target="_blank" href={GMSF_URL}>{GMSF_SOURCE}</Link>
			</ReadMoreDetails>
		</PinkHeader>
	);
}


const footerBorderSx = {
	border: 'solid 2px', borderRadius: '50%',
	height: '1.75em', width: '1.75em',
	alignContent: 'center',
	textAlign: 'center',
	flexShrink: 0
};


function DistributionFooter() {
	const headerContent = (
		<Box display="flex" flexDirection="column" gap={3}>
			<Header rank={5} color="#333">
				Distribution offers the greatest scope for improving efficiency and reducing emissions:
			</Header>
			<Box display="flex" gap={2}>
				<Header rank={4} sx={footerBorderSx}>1</Header>
				<Header rank={5} fontWeight="normal">
					<strong>Optimize your creatives</strong> - reducing the weight of your creatives reduces transmission emissions.</Header>
			</Box>
			<Box display="flex" gap={2}>
				<Header rank={4} sx={footerBorderSx}>2</Header>
				<Header rank={5} fontWeight="normal"><strong>Re-evaluate your supply path</strong> based on your campaign and carbon goals - can the number of intermediaries be reduced?</Header>
			</Box>
		</Box>
	);
	return <PinkHeader title="Optimization tips" >{headerContent}</PinkHeader>;
}


function DistHeadline({data}) {
	data = data?.data;
	return <Headlines loading={!data} part={data?.distributionEmissions} total={data?.totalEmissions} pm={data?.distributionCO2EPM} />;
}

export default Distribution;