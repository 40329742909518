import InputWrapper from './InputWrapper';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { identity } from 'lodash';


function DateFieldInner({size, ...props}) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} sx={{ mb: 1, maxWidth: '10%'}}>
			<DatePicker
				slotProps={{actionBar: {actions: ['clear']}, field: {clearable: true}, textField: { size }}}
				format="DD/MM/YYYY"
				{...props}
			/>
		</LocalizationProvider>
	);
}


/**
 * This component renders a FormControl component with a select input.
 * @param {Object} params
 * @param {string} params.name - Term of the select form.
 * @param {string} params.label - Label of the select form, defaults to the term.
 * @param {string} params.info - Information to display next to the select form.
 * @param {selectOptions[]} params.options - The list of options for the select input. Contradicts the apiEndpoint prop.
 * @param {import("react").MutableRefObject} params.inputRef - Reference to the <select> element.
 * @param {Function} params.onChange Called with the change event when the <select> value changes.
 * @param {Object} params.sx - Styling object for the FormControl component.
 * @param {import('react').ReactElement} params.noOptions Rendered in place of the <select> if there are no options to show.
 */
function DateField(props) {
	return <InputWrapper getValueFn={identity} {...props} Input={DateFieldInner} />;
}

export default DateField;
