const counterTemplateXML = `<Column h="100%">
  <Row size="12" class="headline flex center" font-size="3vw">\${{text.headline}}</Row>
  <Row size="70">
    <Video size="70"/>
  </Row>
  <Row class="bottom-row" size="18" w="100%" px="1.5">
    <Row class="logos" gap="1.5" size="grow">
      <BrandLogo />
      <Divider h="70%" />
      <CharityLogo />
    </Row>
    <Column class="counter-container float fill center" gap="1">
      <Counter class="contrast" prefix="\${{text.counterPrefix}}" value="\${{text.counterValue}}" />
      <div class="raised text-center" font-size="70%">\${{text.raised}}</div>
    </Column>
    <Ident w="15" h="auto" />
  </Row>
</Column>`;

export default counterTemplateXML;