import Grid from '@mui/material/Unstable_Grid2/Grid2';
import useSearchParam from '../../state/useSearchParam';
import { Header, HeaderActions, PageContainer, PageSection } from '../common/LayoutComponents';
import { EditPublisherButton } from '../edit/PublisherDialog';
import PublisherTable from '../tables/PublisherTable';
import { SelectGroup } from '../inputFields/SelectFromAPI';
import { Divider } from '@mui/material';
import { useGroup } from '../../state/apiHooks';
import { useMemo } from 'react';


function ManagePublishers() {
	const [groupId, setGroupId] = useSearchParam('groupId');
	const { data: group } = useGroup(groupId);

	const query = useMemo(() => {
		return groupId ? {group: groupId} : null;
	}, [groupId]);

	return (
		<PageContainer>
			<Header rank={3}>Manage Publishers</Header>
			<PageSection>
				<Grid container>
					<Grid xs={12} sm={3}>
						<SelectGroup label="Workspace" fullWidth
							query={{parent: null}}
							name="groupId" value={groupId}
							onChangeValue={setGroupId}
						/>
					</Grid>
				</Grid>
				<Divider sx={{my: 2}} />
				<Header rank={5}>
					{groupId ? `Sites for ${group?.name || `Group ${groupId}`}` : 'All Sites'}
					<HeaderActions>
						<EditPublisherButton label="Add Publisher" />
					</HeaderActions>
				</Header>
				<PublisherTable query={query} />
			</PageSection>
		</PageContainer>
	);
}

export default ManagePublishers;
