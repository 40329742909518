import { useMemo } from 'react';

import { PageContainer, PageSection } from '../components/common/LayoutComponents';
import GoodFormatsBreadcrumbs from './Formats/GoodFormatsBreadcrumbs';
import EntityList from './Formats/EntityList';
import { EditCreativeButton } from '../components/edit/CreativeDialog';
import CreativeListItem from '../components/lists/CreativeListItem';


/** Page for listing & managing all Good Formats creatives. */
function ManageCreatives() {
	const actionButtons = useMemo(() => {
		return <EditCreativeButton label="New" />;
	}, []);

	return <>
		<GoodFormatsBreadcrumbs lastLabel="Creatives" />
		<PageContainer>
			<PageSection>
				<EntityList title="All Creatives" type="creative" ItemComponent={CreativeListItem} actionButtons={actionButtons} />
			</PageSection>
		</PageContainer>
	</>;
}


export default ManageCreatives;
