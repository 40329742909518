import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useWorkspace } from '../components/WorkspaceProvider';



/** Provides the currently logged-in user's Auth0 access token */
export function useAccessToken() {
	const { user, getAccessTokenSilently } = useAuth0();
	const subject = user?.sub; // Refresh access token if user changes.

	const [accessToken, setAccessToken] = useState();
	useEffect(() => {
		getAccessTokenSilently()
			.then(setAccessToken)
			.catch(() => setAccessToken(null));
	}, [subject, getAccessTokenSilently]);

	return accessToken;
}


/** Provides the current workspace's interface branding overrides */
export function useBranding() {
	const { data: workspace} = useWorkspace();
	return workspace?.branding;
}

/**
 * Returns the first element matching the provided selector, or null if it doesn't exist -
 * but sets up a MutationObserver to wait for the element's creation.
 */
export function useWaitForElement(selector) {
	const [element, setElement] = useState(() => document.querySelector(selector));

	// Don't return a false positive if the element has been removed from the DOM
	if (element && element.isConnected) return element;

	// Watch for DOM insertions...
	const mo = new MutationObserver(() => {
		const el = document.querySelector(selector);
		if (el) {
			setElement(el);
			// When the selector yields an element, remove the observer.
			mo.disconnect();
		}
	});
	mo.observe(document, {childList: true, subtree: true});
	return null;
}


/** @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult */
