import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import entity from '../../model/entity';

import DialogButton from '../widgets/DialogButton';
import { useGroup } from '../../state/apiHooks';
import PermissionWrapper from './PermissionWrapper';
import { useFormContext } from '../FormContextProvider';
import EntityDialog from './EntityDialog';



const thumbnailSx = {
	objectFit: 'contain',
	height: '100px',
};


const validateGroup = (authUser, group) => {
	// New workspaces must be created with an admin
	if (!group.id && !group.parentId && !group.adminEmail) return false;
	// Every group must have a name
	return (group.name);
};


/** Different noun for groups with parent ("Group") and those without ("Workspace"). */
const groupNoun = (group) => (group.parentId ? 'Group' : 'Workspace');


/**
 * The inner form for a Group editor.
 * @param {FormFieldsProps} props
 */
function GroupFormFields() {
	const { id, draft } = useFormContext();
	const { logoUrl, parentId } = draft;

	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<TextInputField label={`${groupNoun(draft)} Name`} required path="name" />
			</Grid>
			<Grid container xs={12}>
				<Grid>
					<TextInputField label="Logo" type="url" path="logoUrl" />
				</Grid>
				<Grid>
					<Box component="img" src={logoUrl} sx={thumbnailSx} />
				</Grid>
			</Grid>
			{!id &&
				<Grid xs={12}>
					<TextInputField label="Admin email address" required={parentId} path="adminEmail"
						info="This user will be made admin of the new group. If they don't already have an account, one will be created."
					/>
				</Grid>
			}
		</Grid>
	);
}

/** Fetch draft group's designated parent and generate "Add group to $parent" title. */
function CreateSubGroupTitle({parentId}) {
	const { data: parentGroup } = useGroup(parentId);
	if (parentGroup) return <>Add Group to {parentGroup.name}</>;
	return 'Add Group';
}

/**
 * Construct the title of an "edit group" dialog, which might be:
 * - Create workspace
 * - Edit workspace
 * - Create sub-group
 * - Edit sub-group
 */
function GroupDialogTitle() {
	const { id, draft } = useFormContext();
	const { name, parentId } = draft;
	// ID provided = edit mode
	if (id) <>Edit {name || groupNoun(draft)}</>;
	// Creating new group - is it a sub-group or a workspace?
	if (parentId) return <CreateSubGroupTitle parentId={parentId} />;
	return 'Create Workspace';
}


/** Dialog for editing groups and workspaces. See {@link EntityDialog} for props. */
function GroupDialog(props) {
	return <EntityDialog type={entity.GROUP} validateFn={validateGroup} title={<GroupDialogTitle />} {...props}>
		<GroupFormFields />
	</EntityDialog>;
}


/** Button which pops up a {@link GroupDialog}. See {@link DialogButton} for props. */
function EditGroupButton({label = 'Edit Group', ...props}) {
	return (
		<PermissionWrapper type={entity.GROUP} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={GroupDialog} {...props} />
		</PermissionWrapper>
	);
}


export default GroupDialog;
export { GroupFormFields, EditGroupButton };
