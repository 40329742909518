import { Box, Typography, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, List, SvgIcon} from '@mui/material';

import {
	// Menu as MenuIcon,
	// School as ResourcesIcon,
	// SwitchAccessShortcutAdd as AnalyseIcon,
	People as PeopleIcon,
	DashboardOutlined as DashboardIcon,
	BarChartOutlined as MetricsIcon,
	SellOutlined as TagsIcon,
	NewspaperOutlined as MediaIcon,
	WorkOutline as WorkspacesIcon,
	Newspaper as PublishersIcon,
	Business as BrandIcon,
	VideoLibrary as CampaignIcon,
	SmartDisplay as AdvertIcon,

} from '@mui/icons-material';
import { useLocation } from 'react-router';
import { useWorkspace } from './WorkspaceProvider';


/** Nav link specifications: display name, link URL, icon, and a regex for determining if it's the current page */
let workspacePages = [
	{ name: 'Dashboard', href: '/dashboard', pattern: /^\/dashboard/, icon: <DashboardIcon /> },
	{ name: 'Metrics', href: '/metrics', pattern: /^\/metrics/, icon: <MetricsIcon /> },
	{ name: 'Tags', href: '/tags', pattern: /^\/tags/, icon: <TagsIcon /> },
	{ name: 'Media', href: '/media', pattern: /^\/media/, icon: <MediaIcon /> },
	//{ name: 'Analyse', href: '/analyse', pattern: /^\/analyse/, icon: <AnalyseIcon /> },
	//{ name: 'Resources', href: '/resources', pattern: /^\/resources/, icon: <ResourcesIcon /> }
];


let adminPages = [
	{ name: 'Workspaces', href: '/workspaces', pattern: /^\/workspaces/, icon: <WorkspacesIcon />},
	{ name: 'GL Users', href: '/users', pattern: /^\/users/, icon: <PeopleIcon />}
];


if (process.env.REACT_APP_GOOD_MEASURES_FOR === 'publishers') {
	adminPages.push({ name: 'Publishers', href: '/publishers', pattern: /^\/publishers/, icon: <PublishersIcon /> });
}


// Temporary hack for studio - replace all nav bar entries
if (process.env.REACT_APP_GOOD_MEASURES_FOR === 'studio') {
	workspacePages = [
		{ name: 'Brands', href: '/brands', pattern: /^\/(brands|)/, icon: <BrandIcon /> },
		{ name: 'Campaigns', href: '/campaigns', pattern: /^\/campaigns/, icon: <CampaignIcon /> },
		{ name: 'Creatives', href: '/creatives', pattern: /^\/creatives/, icon: <AdvertIcon /> }
	];
	adminPages = workspacePages;
}


/** Which of the given nav tabs is the longest prefix of the current path? */
const resolveLocationValue = (pathname, pages) => {
	const bestPageMatch = pages.reduce((prevBest, page) => {
		let match = pathname.match(page.pattern);
		if (!match) return prevBest; // Discard unmatched
		if (prevBest?.match.length > match[0].length) return prevBest; // Keep longest matching substring
		return { ...page, match: match[0] }; // Hold onto matching substring for comparison
	}, null);
	return bestPageMatch?.href || false; // MUI Tabs complain about out-of-range values unless given explicit "false"
};




const drawerSx = { color: 'black', minWidth: '100px', flexShrink: 0 };

const drawerPaperProps = { sx: { backgroundColor: 'background.default', border: 'none' } };


function DrawerLink({ name, href, icon, isMobile, selected}) {
	// Icon + text stack vertically on desktop
	const buttonSx = isMobile ? undefined : { flexDirection: 'column', p: 1, borderRadius: '16px' };
	// Let icon take more padding on mobile
	const iconSx = { color: 'inherit', minWidth: isMobile ? undefined : 'unset', backgroundColor: selected ? 'rgba(255, 8, 79, 0.1)' : 'transparent', p: 1, borderRadius: '16px'};

	return <ListItem sx={{py: 0, px: 1}}>
		<ListItemButton href={href} sx={buttonSx} >
			<ListItemIcon sx={iconSx}>{icon}</ListItemIcon>
			<ListItemText primary={<Typography fontWeight="bold">{name}</Typography>} />
		</ListItemButton>
	</ListItem>;
};


/** The nav bars for the Good Measures site - vertical tabs for large screens and a hamburger menu for mobile. */
function Navbars({ open, setOpen, isMobile }) {
	const { pathname } = useLocation();
	const { workspaceId } = useWorkspace();
	const pages = workspaceId ? workspacePages : adminPages;
	const selectedHref = resolveLocationValue(pathname, pages);
	const onClose = () => setOpen(false);
	const variant = isMobile ? undefined : 'permanent';

	return (
		<Drawer open={open} onClose={onClose} variant={variant} sx={drawerSx} PaperProps={drawerPaperProps} anchor="left">
			<Toolbar />
			<Box sx={{ overflow: 'auto', mt: 1 }}>
				<List>
					{pages.map((pageProps, i) => {
						const selected = pageProps.href.startsWith(selectedHref);
						return <DrawerLink key={i} isMobile={isMobile} {...pageProps} selected={selected} />;
					})}
				</List>
			</Box>
		</Drawer>
	);
}


export default Navbars;
