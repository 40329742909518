import { Box, CircularProgress } from '@mui/material';

import goodLoopLogo from '../../img/Good-Loop-symbol.svg';
import { useParams } from 'react-router';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { EditUserButton } from '../../components/edit/UserDialog';
import { Header, PageSection, PageSectionContainer, SubSectionItem } from '../../components/common/LayoutComponents';
import { useUser } from '../../state/apiHooks';



function UserName({user}) {
	const { id: userId } = user;

	return <Grid container spacing={2}>
		<Grid container direction="column">
			<Grid><Header rank={5}>User</Header></Grid>
			<Grid><Header rank={3}>{user.name}</Header></Grid>
			<Grid><EditUserButton label="Edit User" id={userId} /></Grid>
		</Grid>
		<Grid flex={1}>
			<Box component="img" src={user.pictureUrl || goodLoopLogo} sx={{height: '200px', width: '100%', objectFit: 'contain'}} />
		</Grid>
	</Grid>;
}


/** TODO Big copy-paste from ShowGroup */
function ShowUser() {
	const { userId } = useParams();
	const { data: user, isPending} = useUser(userId);

	if (isPending) return <PageSection><CircularProgress /> Loading User</PageSection>;

	return (
		<PageSectionContainer>
			<Grid container direction="row" spacing={3}>
				<Grid xs={12} sm={6}>
					<SubSectionItem>
						<UserName user={user} />
					</SubSectionItem>
				</Grid>
			</Grid>
		</PageSectionContainer>
	);
}

export default ShowUser;
