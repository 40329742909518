import {  useMemo, type ReactNode, type FunctionComponent } from 'react';
import { Box, FormControl } from '@mui/material';


import { type EntityTypes } from '../../model/types/Entity';
import { useEntities } from '../../state/apiHooks';
import useSearchParam from '../../state/useSearchParam';
import TextInputField from '../../components/inputFields/TextInputField';
import PagedList from '../../components/lists/PagedList';
import { Header } from '../../components/common/LayoutComponents';


interface GenericListProps<T extends keyof EntityTypes> {
	/** Display name for the list. */
	title?: ReactNode;
	/** Name of the entity type to list. */
	type: T;
	/** Filter query for retrieving entities (will be augmented with keyword search). */
	query?: object;
	/** The component used to render each item in the list. */
	ItemComponent: FunctionComponent;
	/** Additional action buttons which will be placed alongside the search bar. */
	actionButtons?: ReactNode;
}


/** Use the entity's DB ID as its array key. */
function keyFn(entity, index) {
	return entity.id;
}


function EntityList<T extends keyof EntityTypes>({title, type, query: baseQuery = {}, ItemComponent, actionButtons}: GenericListProps<T>) {
	// Simplify entity type param
	type ET = EntityTypes[T];

	const [search] = useSearchParam('search');
	const query = useMemo(() => {
		const cloneQuery = {...baseQuery};
		if (search) cloneQuery['!keyword'] = search;
		return cloneQuery;
	}, [baseQuery, search]);

	const { data: entities, isLoading } = useEntities(type, query) as { data: ET[], isLoading: boolean };

	return <>
		<Box display="flex" justifyContent="space-between" alignItems="center">
			<Header rank={6}>{title}</Header>
			<TextInputField label="Search" searchParam="search" size="small" append={actionButtons} />
		</Box>
		<PagedList ItemComponent={ItemComponent} items={entities} loading={isLoading} keyFn={keyFn} />
	</>;

}


export default EntityList;