import { Box, Link, linkClasses, Paper, Typography } from '@mui/material';
import { useUser } from '../../state/apiHooks';
import { ShortDate } from '../widgets/Date';
import { Header } from '../common/LayoutComponents';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { disableNthChildWarning } from '../../utilities/utils';



function CreatedDateBy({entity}) {
	const { createdDate, createdById } = entity;
	const { data: user } = useUser(createdById);

	return <>
		{createdDate && <ShortDate date={createdDate} />}
		{user && <>{' by '}<span className="username" title={user.loginId}>{user.name}</span></>}
	</>;
}


const sectionSx = {
	padding: 1,
	display: 'flex',
	'&:not(:first-of-type)': {
		borderLeft: 1,
		borderColor: 'lightgrey'
	}
};

function ListCardSection({children, ...props}) {
	return (
		<Grid sx={sectionSx} {...props}>{children}</Grid>
	);
}


const actionsSx = {
	...sectionSx,
	flexGrow: 1,
	justifyContent: 'flex-end',
	alignItems: 'flex-end',
	[disableNthChildWarning('& > :not(:first-child)')]: {
		ml: 1
	}
};

function ListCardActions({children, ...props}) {
	return (
		<Grid sx={actionsSx} {...props}>{children}</Grid>
	);
}


const imageSx = {
	width: '48px',
	height: '48px',
	objectFit: 'contain',
	borderRadius: 1,
	border: 1,
	borderColor: 'lightgrey',
	marginRight: 1,
};

/**
 * 
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.value
 * @param {string} [props.image]
 */
function ItemAttribute({label, value, image}) {
	return <>
		{image && <Box component="img" src={image} sx={imageSx} />}
		<Box>
			<Typography variant="caption">{label}</Typography>
			<Typography variant="subtitle1">{value}</Typography>
		</Box>
	</>;
}


const cardSx = {
	borderRadius: '10px',
	border: 1,
	borderColor: 'lightgrey',
	overflow: 'hidden',
};

const headerSx = {
	borderBottom: 1,
	borderColor: 'lightgrey',
	'> *': {
		padding: 2,
	},
	'.title': {
		display: 'inline-flex',
		alignItems: 'center',
		img: {
			height: '2em',
			aspectRatio: '1/1',
			mr: 1,
			objectFit: 'contain'
		}
	},
	'.creation': {
		borderLeft: 1,
		borderColor: 'lightgrey',
	}
};

const contentSx = {
	backgroundColor: 'background.default',
	overflow: 'hidden',
};


function TitleLink(props) {
	return <Link underline="none" {...props} />;
}


/**
 * Generic card element for showing an entity in a list.
 * @param {object} props
 * @param {import('../../model/types/Entity').Entity} item
 * @param {string} [imgUrl]
 * @param {string} [href]
 * @param {React.Children} [children]
 */
function ListCard({item, children, imgUrl, href}) {
	const component = href ? TitleLink : undefined;

	return (
		<Paper elevation={1} sx={cardSx} name={item.name}>
			<Box sx={headerSx} display="flex" justifyContent="space-between">
				<Box className="title" component={component} href={href} display="flex" flexGrow="1">
					{imgUrl && <Box component="img" src={imgUrl} />}
					<Header rank={6}>{item.name}</Header>
				</Box>
				<Box className="creation">
					<Typography variant="subtitle1">
						<CreatedDateBy entity={item} />
					</Typography>
				</Box>
			</Box>
			<Grid container sx={contentSx}>
				{children}
			</Grid>
		</Paper>
	);
}



export default ListCard;
export {
	ListCardSection,
	ListCardActions,
	ItemAttribute,
};
