import { Routes, Route } from 'react-router-dom';

// import Home from './pages/Home';
import Metrics from './pages/Metrics/Metrics';
import Analyse from './pages/Analyse';
import Resources from './pages/Resources';
import RootLayout from './components/layouts/RootLayout';
import ManageLayout from './components/layouts/ManageLayout';
import ManageCampaigns from './components/manage/ManageCampaigns';
import ManageTags from './components/manage/ManageTags';
import ManageGroups from './components/manage/ManageGroups';
import ManageUsers from './components/manage/ManageUsers';

import ShowGroup from './pages/Admin/ShowGroup';
import ShowUser from './pages/Admin/ShowUser';
import ManageWorkspaces from './components/manage/ManageWorkspaces';
import ShowWorkspace from './pages/Admin/ShowWorkspace';
import ManageGlobalUsers from './components/manage/ManageGlobalUsers';
import GoodMeasuresPublishers from './pages/GoodMeasuresPublishers/GoodMeasuresPublishers';
import GoodMedia from './pages/GoodMedia/GoodMedia';
import { useWorkspace } from './components/WorkspaceProvider';
import EnterWorkspace from './components/EnterWorkspace';
import SinglePageDashboard from './pages/SinglePageDashboard/SinglePageDashboard';


function GoodMeasuresForAdvertisers({user}) {
	const { workspaceId } = useWorkspace();
	if (!workspaceId) return (
		<Routes>
			<Route path="/" element={<RootLayout />}>
				<Route index element={<ManageWorkspaces />} />
				<Route path="workspaces" element={<ManageWorkspaces />} />
				<Route path="users" element={<ManageGlobalUsers />} />
				<Route path="*" element={<EnterWorkspace />} />
			</Route>
		</Routes>
	);

	const { isGlobalAdmin, isWorkspaceAdmin, isGroupAdmin } = user;
	const canManageWorkspace = isGlobalAdmin || isWorkspaceAdmin;
	const canManageGroups = canManageWorkspace || isGroupAdmin;

	// One-minute introductory notes on React Router:
	// 1: The longest matching path will be used. So if the user is at /resources/tutorials and there are routes in the tree for
	//    "/resources" and "/resources/tutorials" - naturally the second one will be used.
	// 2: When routes are nested, their paths are concatenated - so because "home" is inside "/", its actual matched path is "/home"
	// 3: The "index" marker means "Use this when the subpath is empty" - so location / routes to the same place as /home.
	// 4: You can pull data out of routes by putting a colon-prefixed part in the path, like "tags/:tagId".
	//    The subpath string in that position will be available in the component from the useParams() hook
	//    - so for the above path spec, in a component rendering at /tags/myTagId, useParams() will return { tagId: 'myTagId' }.
	return (
		<Routes>
			<Route path="/" element={<RootLayout />}>
			    <Route index element={<Metrics />} />
			    <Route path="dashboard" element={<SinglePageDashboard />} />
				<Route path="metrics/:tab?/:subTab?" element={<Metrics />} />
				<Route path="media/:tab?" element={<GoodMedia />} />
				<Route path="tags">
					<Route path="" element={<ManageTags />} />
					<Route path="campaigns" element={<ManageCampaigns />}/>
				</Route>
				<Route path="analyse" element={<Analyse />} />
				<Route path="resources" element={<Resources />} />
				{canManageGroups && (
					<Route path="manage" element={<ManageLayout />}>
						{canManageWorkspace && <Route path="" element={<ShowWorkspace />} />}
						<Route path="groups" element={<ManageGroups />} />
						<Route path="groups/:groupId" element={<ShowGroup />} />
						<Route path="users" element={<ManageUsers />} />
						<Route path="users/:userId" element={<ShowUser />} />
					</Route>
				)}
			</Route>
		</Routes>
	);
}


export default GoodMeasuresForAdvertisers;
