import { ContentWrapper } from './MetricComponents';
import { Box, Typography, type SxProps } from '@mui/material';


function ImpressionsTracked({ impressions, sx }: {impressions: any, sx?: SxProps}) {
	const impStr = impressions ? parseFloat(impressions).toLocaleString() : 0; // add commas and check > 0

	return (
		<ContentWrapper sx={sx}>
			<Box sx={{ p: 2, pb: 3}}>
				<Typography component="p" sx={{ fontWeight: 'medium', fontSize: 'clamp(0.75rem, 1.25vw, 4rem)', whiteSpace: 'nowrap'}}>
					Impressions tracked: <Typography component="span" color="primary" sx={{ fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit'}}>{impStr}</Typography>
				</Typography>
			</Box>
		</ContentWrapper>
	);
}


export default ImpressionsTracked;