import entity from '../../model/entity';
import EntityTable from './EntityTable';
import DeleteButton from '../edit/DeleteButton';
import { FetchGroupCell } from './GroupTable';
import { FetchUserCell } from './UserTable';
import { useGroupApiKeys } from '../../state/apiHooks';


const getApiKeyPermissionValue = (value, account) => {
	return Object.values(account.groupPermissionLevels)?.[0];
};


const getApiKeygroupId = (value, account) => {
	return Object.keys(account.groupPermissionLevels)?.[0];
};


function DeleteAction({row}) {
	return <DeleteButton type={entity.USER} id={row.id} />;
}

const actions = [DeleteAction];


const columns = [
	{ field: 'createdById', headerName: 'Added By', renderCell: FetchUserCell, flex: 1},
	// { field: 'groupId', headerName: 'Group', valueGetter: getApiKeygroupId, renderCell: FetchGroupCell, flex: 1}, // Redudant information while the table does not pull keys from child groups.
	{ field: 'permission', headerName: 'Permission', valueGetter: getApiKeyPermissionValue, flex: 1},
];



function ApiKeyTable({groupId, ...props}) {
	const { data: apiKeys } = useGroupApiKeys(groupId);

	return <EntityTable aria-labelledby="API key table" type={entity.USER} rows={apiKeys} columns={columns} actions={actions} {...props}/>;
}


export default ApiKeyTable;

