import { EditGroupButton } from '../edit/GroupDialog';
import WorkspaceTable from '../tables/WorkspaceTable';
import { Header, HeaderActions, PageContainer, PageSection } from '../common/LayoutComponents';


/** Good-Loop admin only: Table of all existing groups & interface for creating new ones. */
function ManageWorkspaces() {
	return (
		<PageContainer>
			<Header rank={3}>Manage Workspaces</Header>
			<PageSection>
				<Header rank={5}>
					All Workspaces
					<HeaderActions>
						<EditGroupButton variant="outlined" label="Create Workspace" />
					</HeaderActions>
				</Header>
				<WorkspaceTable />
			</PageSection>
		</PageContainer>
	);
}

export default ManageWorkspaces;
