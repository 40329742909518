import { TextField } from '@mui/material';
import InputWrapper, { setWithEvent } from './InputWrapper';
import { useMemo } from 'react';
import { debounce, noop } from 'lodash';



function NumberFieldInner({unit, min, max, step = 1, onBlur = noop, onChange = noop, ...props}) {
	// Normalise value according to the input's step value
	// TODO When MUI's NumberField becomes available, switch to it and remove this hack

	// - Ensure value is a number (including e.g. coercing blank to 0)
	// - Round number to step
	// - Write fixed value back to the field using a synthetic onChange-triggering event
	const [_onChange, _onBlur] = useMemo(() => {
		const fixValue = event => {
			const value = event.target.value;
			const numValue = Number(value);
			if (Number.isNaN(numValue)) {
				setWithEvent(event.target, '');
			} else {
				const roundValue = Math.round(numValue / step) * step;
				if (roundValue === numValue) return;
				setWithEvent(event.target, roundValue);
			}
		};
		const fixValueDebounced = debounce(fixValue, 500);
		return [
			(...args) => { fixValue(...args); onChange(...args); }, // _onChange
			(...args) => { fixValueDebounced(...args); onBlur(...args); } // _onBlur
		];
	}, [step, onChange, onBlur]);

	const inputProps = useMemo(() => ({
		endAdornment: unit,
		inputProps: { min, max, step }
	}), [unit, min, max, step]);

	return (
		<TextField InputProps={inputProps} onChange={_onChange} onBlur={_onBlur} type="number" {...props} />
	);
}


/**
 *
 * @param {Object} params
 * @param {string} params.name - Term of the text input field.
 * @param {string?} params.label - Label of the text input field. Auto generated if not provided.
 * @param {string} params.unit - Unit of the text input field.
 * @param {import("react").MutableRefObject} params.inputRef - Reference to the text input field.
 * @param {boolean} params.required - Whether the text input field is required.
 * @param {string} params.info - Information to display next to the text input field.
 * @param {Object} params.sx - Styling object for the FormControl component.
 */
function NumberInputField(props) {
	return (
		<InputWrapper {...props} Input={NumberFieldInner} />
	);
}

export default NumberInputField;
