import MACROS from './tag-macros';


/** Base callback URL */
const GMTAG_URL = process.env.REACT_APP_GMTAG_URL;


/** Split out macros and preserve delimiters before URL-component-encoding the rest */
const encodePreserveMacros = (targetUrl, macroType) => {
	const macroRegex = MACROS[macroType]?.regex;
	if (!macroRegex) return encodeURIComponent(targetUrl);

	return targetUrl.split(macroRegex).reduce((acc, bit) => {
		if (bit.match(macroRegex)) return acc + bit;
		return acc + encodeURIComponent(bit);
	}, '');
};


/**
 * Append a parameter to the URL search string without any encoding.
 * Expects the search to have been started off with at least one param already.
 */
const addRawParam = (url, key, value) => {
	url.search += `&${key}=${value}`;
};


/** Search param names & the corresponding tag fields they're populated from */
const paramNamesToTagFields = {
	tag_id: 'id',
	campaign_id: 'campaignId',
	group_id: 'groupId',
	customer_id: 'workspaceId',
	workspace_id: 'workspaceId', // Transitional: we want to standardise on workspace_id in Clickhouse but the field edit hasn't been made yet.
	stream: 'stream',
	creative_format: 'creativeFormat',
	creative_weight_bytes: 'creativeBytes',
	creative_duration_msec: 'creativeMsec'
};


/**
 * Add tag props & DSP macros as URL parameters.
 * @param {URL} url Modified in-place to add parameters to the search string.
 * @param {GreenTag} tag The Good Measures Tag to apply parameters from
 * @returns {void}
 */
const setParams = (url, tag, macroType) => {
	// These values are safe to add with searchParams.append - they're only ever alphanumerics.
	Object.entries(paramNamesToTagFields).forEach(([paramName, fieldName]) => {
		const fieldVal = tag?.[fieldName];
		// Leave out param for null, undefined, or empty string.
		if (fieldVal == null || fieldVal?.length === 0) return;
		url.searchParams.append(paramName, fieldVal);
	});

	// NB: Add macros after the base params, in case they break the url
	// - e.g. the user selects the wrong macro type for the DSP they're actually using.
	// Using addRawParam because searchParams.append will apply URL %-encoding to values like ${MACRO} and %%MACRO%%
	// - which will break DSP-side macro processing.
	const macroParams = MACROS[macroType]?.params;
	if (!macroParams) return null;
	Object.entries(macroParams).forEach(([key, value]) => {
		addRawParam(url, key, value);
	});
};


/** Maps export types to corresponding export generator functions. */
const generators = {
	pixel: (tag, macroType) => {
		const url = new URL(GMTAG_URL);
		setParams(url, tag, macroType);
		return `<img src="${url.toString()}" style="position:absolute;">`;
	},
	javascript: (tag, macroType) => {
		const url = new URL(GMTAG_URL);
		setParams(url, tag, macroType);
		// TODO Enable the logging endpoint to return a stub script so this doesn't need to be an inlined XHR:
		// return `<script type="text/javascript" src="${url.toString()}"></script>`;
		return `<script type="text/javascript">var x=new XMLHttpRequest();x.open('GET', '${url.toString()}');x.send()</script>`;
	},
	url: (tag, macroType) => {
		const url = new URL(GMTAG_URL);
		setParams(url, tag, macroType);
		return url.toString();
	},
	redirect: (tag, macroType) => {
		const url = new URL(GMTAG_URL);
		setParams(url, tag, macroType);
		// Redirect target needs to be URL-encoded - but any macros in it should be left intact.
		const target = encodePreserveMacros(tag.wrapped, tag.macroType);
		url.addRawParam('link', target);
		return url.toString();
	},
};


function exportTag(tagObj, exportType, macroType) {
	const generator = generators[exportType] || generators.pixel;
	return generator(tagObj, macroType);
}


export default exportTag;
