import { useParams } from 'react-router';
import { LinkChip } from '../Admin';
// import { Accessibility } from './Accessibility';
import { Emissions } from './Emissions';
import { Box, Container, Typography } from '@mui/material';
import { GMPDataProvider } from '../Metrics/providers/MetricsDataProvider';

export default function GoodMeasuresPublishers() {

	const pageData = [Emissions()/* Accessibility() <--- Not in for Sept release! */];
	const { tab = pageData[0].name } = useParams();
	const tabs = pageData.map(({name, title, href}, i) =>
		<LinkChip name={name} mode={tab} href={href} label={title} />
	);
	const activeTab = pageData.find(({href}) => href === `/gmp/${tab}`)?.content || <h1>404 - Tab doesn't exist!</h1>;

	return (
		<GMPDataProvider>
			<Box sx={{display: 'flex', backgroundColor: 'background.default', p: {xs: 0, sm: 2}, pl: '0 !important', pt: '0 !important', ml: '0 !important', width: '100%'}}>
				<Container sx={{backgroundColor: 'background.paper', m: {xs: 0, sm: 2}, ml: '0 !important', px: {xs: 0, sm: '12.5%'}, minWidth: ['-webkit-fill-available', '-moz-available'], maxWidth: 'unset', borderRadius: {xs: 'unset', sm: '10px'}}}>
					<Container sx={{maxWidth: '1400px !important', height: '100%'}}>
						<Typography variant="h3" fontWeight="bold" sx={{pt: 4, pb: 2}}>Good Measures for Publishers</Typography>
						{pageData.length > 1 && tabs}
						{activeTab}
						<Container sx={{ p: 3 }} />
					</Container>
				</Container>
			</Box>
		</GMPDataProvider>
	);
}