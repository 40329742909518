import { Box, Divider, Pagination, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import useSearchParam, { isValidParam } from '../../state/useSearchParam';


function ListPagerDescription({totalItems, from, to}) {
	if (!totalItems) return 'No results.';

	const desc = `${totalItems} result${(totalItems === 1) ? '' : 's'}.`;

	if ((to - from) === totalItems) return desc;

	return `Showing ${from + 1}-${to} of ${desc}`;
}


function ListPagerInner({ page, setPage, itemsPerPage = 10, totalItems, divider = false, setIndices, sx}) {
	// How many pages are needed?
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	// If page is out of bounds, clamp to max/min
	// (May happen due to change in itemsPerPage or underlying list, or broken URL values)
	useEffect(() => {
		if (totalPages === 0) {
			if (page !== 0) setPage(0);
			return;
		}
		if (page > totalPages) {
			setPage(totalPages);
		} else if (page < 1) {
			setPage(1);
		}
	}, [page, totalPages, setPage]);

	const from = ((page - 1) * itemsPerPage);
	const to = Math.min(totalItems, from + itemsPerPage); // Last page may be short

	// Update calling component with start and end of current page
	useEffect(() => {
		setIndices({from, to});
	}, [from, to, setIndices]);


	// Listen for change from Pagination
	const onPageChange = (e, newPage) => setPage(newPage);

	return (
		<Box sx={sx}>
			<Typography variant="subtitle1" marginBottom={1}>
				<ListPagerDescription from={from} to={to} totalItems={totalItems} />
			</Typography>
			{totalPages > 1 &&
				<Pagination count={totalPages} page={page} color="primary" onChange={onPageChange} />
			}
			{divider && <Divider sx={{ marginY: 1 }} />}
		</Box>
	);
}


function ListPagerLocalState(props) {
	const [page, setPage] = useState(1);
	return <ListPagerInner page={page} setPage={setPage} {...props} />;
}


function ListPagerParamState({pageParam, ...props}) {
	const [paramValue, setParamValue] = useSearchParam(pageParam);
	const page = Number(paramValue) || 1;
	const setPage = useCallback(newPage => {
		// If new page number would be superfluous, remove it from search params
		if (newPage === 1 || !newPage) newPage = undefined;
		setParamValue(newPage);
	}, [setParamValue]);

	return <ListPagerInner page={page} setPage={setPage} {...props} />;
}


/**
 * @param {object} uploadProps
 * @param {string} uploadProps.pageParam If set, current page will be synced to/from this key in the URL search params
 * @param {number} uploadProps.itemsPerPage
 * @param {number} uploadProps.totalItems
 * @param {boolean} uploadProps.divider If true, render a MUI <Divider> below the pager
 * @param {import('../FormContextProvider.js').StateSetter<any>} uploadProps.setIndices Will be called with item indices {to, from} for start/end of current page
 * @param {import('@mui/material').SxProps} [uploadProps.sx]
 * @returns
 */
function ListPager(props) {
	if (isValidParam(props.pageParam)) return <ListPagerParamState {...props} />;
	return <ListPagerLocalState {...props} />;
}


export default ListPager;
