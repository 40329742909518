import { capitalize } from 'lodash';


/** Entity type-name constants. */
const entity = {
	USER: 'user',
	API_KEY: 'apiKey',
	GROUP: 'group',
	CAMPAIGN: 'campaign',
	CREATIVE: 'creative',
	TAG: 'tag',
	GLOBAL_PERMISSION: 'globalPermission',
	PRICE_PLAN: 'pricePlan',
	PUBLISHER: 'publisher',
	FILE_UPLOAD: 'fileUpload',
	LINE_ITEM: 'lineitem',
};


/** Entity types the generic name transformer doesn't work for can be hardcoded here. */
const displayNames = {
	lineitem: 'Line Item'
};


/** Generic display name transformer - splits on camelCase boundary, capitalises parts & inserts spaces. */
const makeDisplayName = type => {
	const name = type.split(/(?<=[a-z])(?=[A-Z])/).map(capitalize).join(' ');
	displayNames[type] = name;
	return name;
};


/** Maps e.g. "globalPermission" to "Global Permission" */
const entityDisplayName = type => {
	return displayNames[type] || makeDisplayName(type);
};


export default entity;

export {
	entityDisplayName
};
