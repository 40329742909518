const qrCodeCSS = `.video-container {
  border-radius: 2.5vw;
}

.logos .logo {
  height: 60%;
  max-width: 12vw;
}

.qr-box {
  text-align: center;
  justify-content: space-between;
  &, .qr-code {
    border-radius: 1.5vw;
  }
}`;

export default qrCodeCSS;