import { useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';
import InputWrapper from './InputWrapper';


/**
 * @typedef selectOptions
 * @property {string} label - Name of the option.
 * @property {string} value - ID of the option.
 */


/** Insert - or normalise props of - the "nothing selected" option. */
const fixEmptyOption = (options, value, label, disabled) => {
	const emptyIndex = options.findIndex(o => (o.value == null || !String(o.value).length));
	// No empty option present - insert one, if a name was provided.
	if (emptyIndex < 0) return label ? [{value, label, disabled}, ...options] : options;
	// Empty option already exists - check its props, no changes if they're correct.
	const emptyOption = options[emptyIndex];
	if (emptyOption.value === value && emptyOption.disabled === disabled) return options;
	// Clone options array & replace empty option with corrected copy
	return options.map(o => (o === emptyOption) ? {...emptyOption, value, disabled} : o);
};


function SelectFieldInner({options, value, defaultValue, uncontrolled, labelEmpty, labelNoOptions, ...props}) {
	// Uncontrolled inputs can have value = undefined, but controlled ones must have '' or React complains.
	const emptyValue = uncontrolled ? undefined : '';
	value ??= emptyValue;
	// No default value for controlled <select>!
	if (!uncontrolled) defaultValue = undefined;

	// Tweaks to options list...
	options = useMemo(() => {
		// Insert labelled "Nothing available" option
		if (labelNoOptions && !(options?.length)) return [{label: labelNoOptions, value: emptyValue}];
		// Insert empty-valued "No X / Any X" option
		// - or ensure existing empty-valued option uses correct value for (un)controlled status
		return fixEmptyOption(options, emptyValue, labelEmpty, props.required);
	}, [options, labelEmpty, labelNoOptions, props.required, emptyValue]);

	return (
		<Select value={value} defaultValue={defaultValue} displayEmpty={!!labelEmpty} {...props} >
			{options.map(({label, key, ...props}, i) => (
				<MenuItem key={props.value || i} {...props}>
					{label || props.value}
				</MenuItem>
			))}
		</Select>
	);
}


/**
 * This component renders a FormControl component with a select input.
 * @param {Object} params
 * @param {string} params.name - Term of the select form.
 * @param {string} params.label - Label of the select form, defaults to the term.
 * @param {string} params.info - Information to display next to the select form.
 * @param {selectOptions[]} params.options - The list of options for the select input. Contradicts the apiEndpoint prop.
 * @param {import("react").MutableRefObject} params.inputRef - Reference to the <select> element.
 * @param {Function} params.onChange Called with the change event when the <select> value changes.
 * @param {Object} params.sx - Styling object for the FormControl component.
 * @param {boolean} [params.uncontrolled] True if this <select> will be managing its own state.
 */
function SelectField(props) {
	return <InputWrapper {...props} Input={SelectFieldInner} />;
}


export default SelectField;
