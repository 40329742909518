import { alpha, Table, tableCellClasses, tableRowClasses } from '@mui/material';
import { useMergeSx } from '../../utilities/utils';


/** Default cell styling: bold text, 2px grey internal border. */
const tableCellSx = { fontWeight: 'bold', border: '2px solid #CCC', borderLeft: 'none', borderTop: 'none', textAlign: 'center' };


/** Give cells in the selected/highlighted row a pale green (by default) background */
const bgColorFunction = theme => alpha(theme.palette.success.light, 0.2);


const tableSx = {
	[`& .${tableCellClasses.root}`]: tableCellSx, // Common styles
	[`& .${tableCellClasses.head}`]: { fontSize: 'larger' },
	[`& .${tableCellClasses.body}`]: { fontSize: 'large' },
	[`& .${tableCellClasses.root}:last-of-type`]: { borderRight: 'none'}, // Rightmost column
	[`& .${tableRowClasses.root}:last-of-type .${tableCellClasses.body}`]: { borderBottom: 'none'}, // Bottom row of <tbody>
	[`& .${tableRowClasses.root}.selected .${tableCellClasses.body}`]: { backgroundColor: bgColorFunction }, // Highlight row
	[`& .${tableCellClasses.root}.pad-left`]: { paddingLeft: '3rem', textAlign: 'left' },
	[`& .${tableCellClasses.root}.long-text`]: { fontSize: 'small', maxWidth: '100px' }, // Long text = small font, restrict width
};


/**
 * <Table> with default styling for various places where we tabulate publisher score percentiles.
 * Apply class .selected to a row to highlight it in (by default, uses theme "success" colour) pale green.
 * Apply class .pad-left to cells which should be left-aligned and pushed inwards.
 * Apply class .long-text to cells with large explanatory text to shrink font and restrict width.
 */
function PublisherScoreTable({sx, ...props}) {
	sx = useMergeSx(sx, tableSx);
	return <Table sx={sx} aria-label="simple table" {...props} />;
}


export default PublisherScoreTable;
