import Grid from '@mui/material/Unstable_Grid2/Grid2';

import TextInputField from '../inputFields/TextInputField';
import { SelectGroup } from '../inputFields/SelectFromAPI';
import entity from '../../model/entity';

import DialogButton from '../widgets/DialogButton';
import PermissionWrapper from './PermissionWrapper';
import EntityDialog from './EntityDialog';
import { useFormContext } from '../FormContextProvider';
import ShowEntityField from '../common/ShowEntity';


const validateAdCampaign = (authUser, campaign) => {
	return campaign.name && campaign.brandId;
};


/**
 *
 * @param {Object} params
 * @returns {import('react').ReactElement}
 */
function AdCampaignFormFields() {
	const { initial } = useFormContext();

	return (
		<Grid container spacing={2}>
			<Grid xs={12} sm={6}>
				<TextInputField label="Campaign name" required fullWidth path="name" />
			</Grid>
			<Grid xs={12} sm={6}>
				<TextInputField label="Your reference" fullWidth path="reference"
					info="You can use this field to record a connection between this campaign and an object in your own systems."
				/>
			</Grid>
			<Grid xs={12} sm={6} spacing={2}>
				{initial.brandId ? (
					<ShowEntityField type="group" label="Brand" required fullWidth id={initial.brandId} />
				) : (
					<SelectGroup label="Brand" required fullWidth path="brandId" />
				)}
			</Grid>
		</Grid>
	);
};


function AdCampaignDialog(props) {
	return (
		<EntityDialog type={entity.CAMPAIGN} validateFn={validateAdCampaign} {...props}>
			<AdCampaignFormFields />
		</EntityDialog>
	);
}


function EditAdCampaignButton({label = 'Edit Campaign', ...props}) {
	return (
		<PermissionWrapper type={entity.CAMPAIGN} id={props.id} draft={props.base}>
			<DialogButton label={label} Dialog={AdCampaignDialog} {...props} />
		</PermissionWrapper>
	);
}


export default AdCampaignDialog;
export { AdCampaignFormFields, EditAdCampaignButton };
