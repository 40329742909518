import { Box, BoxProps, Container, Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { disableNthChildWarning, useMergeSx } from '../../utilities/utils';
import { Variant } from '@mui/material/styles/createTypography';


/** Props for a {@link Paper}-based component which also takes a "highlight" flag. */
interface HighlightPaperProps extends PaperProps {
	/** True if the {@link Paper} should take a highlight background colour. */
	highlight?: boolean;
}


/** Standard border-radius for various division components */
const borderRadius = '16px';


const pageContainerSx = { borderRadius, py: 3, m: 2, flex: 1 };

/** Grey box every page is enclosed in. */
function PageContainer({sx, children, ...props}: PaperProps) {
	sx = useMergeSx(pageContainerSx, sx);
	return (
		<Paper elevation={0} sx={sx} {...props}>
			<Container>
				{children}
			</Container>
		</Paper>
	);
}


const pageSectionContainerSx = { my: 2 };

/** Use for a full-width page section which shouldn't be enclosed in a <{@link Paper}>. */
function PageSectionContainer({sx, ...props}: BoxProps) {
	sx = useMergeSx(pageSectionContainerSx, sx);
	return <Box sx={sx} {...props} />;
}


const pageSectionSx = {
	p: 5,
	my: 4,
	[disableNthChildWarning('&:first-child')]: { mt: 0 }
};

/** White box which denotes functional sections of a page. */
function PageSection({highlight, sx, ...props}: HighlightPaperProps) {
	sx = useMergeSx(pageSectionSx, {backgroundColor: highlight ? 'primary.alpha5' : 'background.default'}, sx);
	return <Paper elevation={0} sx={sx} {...props} />;
}


const subSectionSx = { borderRadius, p: 1 };

/** Grey box denoting a sub-section. */
function SubSection({sx, ...props}: PaperProps) {
	sx = useMergeSx(subSectionSx, sx);
	return <Paper elevation={0} sx={subSectionSx} {...props} />;
}


const subSectionItemSx = { borderRadius, p: 2 };

/** White box enclosing an individual item in a sub-section. */
function SubSectionItem({highlight, sx, ...props}: HighlightPaperProps) {
	sx = useMergeSx(subSectionItemSx, {backgroundColor: highlight ? 'primary.alpha5' : 'background.default'}, sx);
	return <Paper elevation={0} sx={sx} {...props} />;
}


interface HeaderProps extends TypographyProps {
	/** The numeral part of a header tag name (ie 1 for h1, 2 for h2, etc). */
	rank: number;
	name?: string;
}

/** Standard bold header. */
function Header({rank, ...props}: HeaderProps) {
	return <Typography variant={`h${rank}` as Variant} fontWeight="bold" {...props} />;
}

function CopyrightFooter(){
	return <Typography sx={{my: 6}} align="center" color="grey">© Good-Loop Ltd, all rights reserved. This website contains logos, trademarks, and branding material such as adverts that are the property of the respective brand owners.
Good-Loop™ is a registered UK company no. SC548356.</Typography>;
}


const actionsSx = {
	float: 'inline-end', // Float to right of header
	'& > :not(:first-of-type)': {ml: 1} // Auto-space immediate children
};

/** Right-floated container for action buttons accompanying header, e.g. close button, create-new etc. */
function HeaderActions({sx, ...props}: BoxProps) {
	sx = useMergeSx(actionsSx, sx);
	return <Box sx={sx} {...props} />;
}


export {
	PageContainer,
	PageSectionContainer,
	PageSection,
	HeaderActions,
	SubSection,
	SubSectionItem,
	Header,
	CopyrightFooter
};
