import { Chip, Link } from '@mui/material';
import { type SxProps } from '@mui/system';
import { type ReactElement } from 'react';


const baseChipStyles: SxProps = {
	fontSize: 'larger',
	fontWeight: 'bold',
	py: 3,
	px: 1,
	my: 1,
	mr: 2,
	borderRadius: '24px',
	'&:active': {
		boxShadow: 'none', // Remove the default active box shadow
		transform: 'none', // Remove the default active transform (scale)
	}
};

interface ChipStyleOptions {
	[key: string]: SxProps;
}


const chipStyles = (additional: SxProps ={}): ChipStyleOptions => ({
	true: {
		...baseChipStyles,
		...additional,
		backgroundColor: 'primary.alpha25'
	},
	false: {
		...baseChipStyles,
		...additional,
		background: 'none !important',
	}
});

interface LinkChipProps {
	name: string;
	mode: string;
	href: string;
	label: string;
	additional?: SxProps;
	icon?: ReactElement;
	[key: string]: any;
}

function LinkChip({ name, mode, href, label, additional = {}, icon, ...props }: LinkChipProps) {
	const active = (mode === name);
	return <Link href={href}>
		<Chip sx={chipStyles(additional)[String(active)]} icon={icon} label={label} {...props} />
	</Link>;
}


export { LinkChip };
