import { useCallback, useMemo } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { mapValues } from 'lodash';

import { useCreateEntity, useDeleteEntity, useEntity, useUpdateEntity } from '../state/apiHooks';
import { type EntityTypes, type SQID } from '../model/types/Entity';
import FormContextProvider, { useFormContext, type FormContextProps, type FormContextType, type Action } from './FormContextProvider';
import { entityDisplayName } from '../model/entity';
import { capitalize } from '@mui/material';


interface EntityContextBase<T extends keyof EntityTypes> { 
	/** Entity type name. TODO When backend update puts type on entities, this should be optional in props but still guaranteed in context output. */
	type: T;
}

/** Props required for "fetch by type + ID" mode of EntityContextProvider */
interface EntityFetchProps<T extends keyof EntityTypes> extends EntityContextBase<T> {
	/** Entity SQID. */
	id: SQID;
}

/** Props required for "entity provided by caller" mode of EntityContextProvider */
interface EntityProvidedProps<T extends keyof EntityTypes> extends EntityContextBase<T> {
	/**  */
	entity: EntityTypes[T];
}

/** Props required for "entity provided by caller" mode of EntityContextProvider */
interface NewEntityProps<T extends keyof EntityTypes> extends EntityContextBase<T> {
	/**  */
	initial?: Partial<EntityTypes[T]>;
}

/** Props required for a functioning {@link EntityContextProvider}. */
type EntityContextProps<T extends keyof EntityTypes> = FormContextProps<EntityTypes[T]> & (
	(EntityFetchProps<T> | EntityProvidedProps<T> | NewEntityProps<T>)
);

interface EntityContextType<T extends keyof EntityTypes>
extends FormContextType<T>, EntityContextBase<T> {};


/** Default title for an entity editor form. */
function EntityFormTitle() {
	const { type, saved } = useFormContext() as FormContextType<any> & EntityContextBase<any>;
	if (saved) return <>Edit {entityDisplayName(type)}</>;
	return <>New {entityDisplayName(type)}</>;
}

/** Default label for a primary action button (e.g. create/update) */
function SubmitLabel() {
	return <>Save</>;
}

/** Default label for an auxiliary action button (e.g. delete) */
function ActionLabel({action}) {
	return <>{capitalize(action)}</>;
}


/** Expands {@link FormContextProvider} with boilerplate specific to DB entities. */
function EntityContextProvider<T extends keyof EntityTypes> ({
	type, id, entity, initial,
	submitLabel, actionLabels,
	title = <EntityFormTitle />,
	...rest
}: EntityContextProps<T>) {
	// Simplify entity type param
	type ET = EntityTypes[T];

	// Fetch initial entity value, if ID provided
	const { data: fetchedEntity, isLoading: loading } = useEntity(type, id) as UseQueryResult<ET>;
	// Coalesce provided & fetched entity to "initial" value
	initial = useMemo(() => (
		entity ?? fetchedEntity ?? initial ?? {} as ET
	), [entity, fetchedEntity, initial]);

	// Select appropriate submit function (these all take an object argument compatible with an entity draft)
	const createFn = useCreateEntity(type) as Action<ET>;
	const updateFn = useUpdateEntity(type) as Action<ET>;
	const submitFn = (id || initial.id) ? updateFn : createFn;
	// TODO Other default actions - revert, clone, ?
	// TODO Allow passing in additional actions - format?
	const deleteFn = useDeleteEntity(type) as Action<ET>;
	const cloneFn = useCallback(draft => {
		const clone = {...draft};
		delete clone.id;
		return createFn(clone);
	}, [createFn]);
	const actionFns = useMemo(() => ({
		delete: deleteFn,
		clone: cloneFn
	}), [deleteFn, cloneFn]);

	// Supply default labels for actions without them
	submitLabel ??= <SubmitLabel />;
	actionLabels = useMemo(() => (
		mapValues(actionFns, (fn, name) => actionLabels?.[name] ?? <ActionLabel action={name} />)
	), [actionFns, actionLabels]);

	// Is this form starting from a persisted copy?
	const saved = Boolean(entity || fetchedEntity);

	const props = {
		...rest,
		type, id, initial, title, loading, saved,
		submitFn, actionFns,
		submitLabel, actionLabels,
	};

	return <FormContextProvider {...props} />;
}


export default EntityContextProvider;
export { EntityContextProps, EntityContextType };
