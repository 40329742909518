import { useMemo } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { SelectGroup } from '../inputFields/SelectFromAPI';
import useSearchParam from '../../state/useSearchParam';
import { Header, HeaderActions, PageContainer, PageSection } from '../common/LayoutComponents';
import { useCampaigns, useGroup } from '../../state/apiHooks';
import PagedList from '../lists/PagedList';
import { EditCampaignButton } from '../edit/CampaignDialog';
import CampaignListItem from '../tags/CampaignListItem';
import { Button } from '@mui/material';


function TagsLayout() {
	const [groupId] = useSearchParam('group');
	const { data: group } = useGroup(groupId);

	// Query for campaign list & base object for creating new ones.
	const campaignQueryBase = useMemo(() => (groupId && {groupId}), [groupId]);

	const { data: campaigns, isLoading } = useCampaigns(campaignQueryBase);

	// Descriptive header for tag list
	const filterDescription = useMemo(() => {
		if (groupId) return `Campaigns for ${group?.name || 'this group'}`;
		return 'All campaigns';
	}, [groupId, group]);


	return (
		<PageContainer>
			<Header rank={3}>
				Good-Measures Campaigns
				<HeaderActions>
					<Button href="/tags" size="large" color="primary" variant="outlined">Manage Tags</Button>
				</HeaderActions>
			</Header>
			<PageSection>
				<Header rank={5}>Filter campaign list</Header>
				<Grid container direction="row" spacing={2}>
					<Grid>
						<SelectGroup label="Group" labelEmpty="All Groups" searchParam="group" size="small" />
					</Grid>
				</Grid>
			</PageSection>
			<PageSection>
				<Header rank={5}>
					{filterDescription}
					<HeaderActions>
						<EditCampaignButton label="Create Campaign" base={campaignQueryBase} />
					</HeaderActions>
				</Header>
				{campaigns && <PagedList listName="campaigns" ItemComponent={CampaignListItem} items={campaigns} loading={isLoading} />}
			</PageSection>
		</PageContainer>
	);
}


export default TagsLayout;
